import { Helmet } from 'react-helmet-async';
// sections
import { CampaignCreateView } from 'src/sections/membership-types/campaign/view';

// ----------------------------------------------------------------------

export default function CampaignTypeCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new product</title>
      </Helmet>

      <CampaignCreateView />
    </>
  );
}
