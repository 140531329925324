import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
// utils
import { ChatDto, ChatsService, Direction } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  conversations: [] as ChatDto[],
  selectedConversation: null as ChatDto | null,
};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getChatsSuccess(state, action) {
      state.isLoading = false;
      state.conversations = action.payload;
    },
    getConversationSuccess(state, action) {
      state.isLoading = false;
      state.selectedConversation = action.payload;
    },
    addMessageToConversation(state, action) {
      state.selectedConversation?.messages?.push(action.payload.messageData);
      state.conversations
        ?.find((el) => el.id === action.payload.id)
        ?.messages?.push(action.payload.messageData);
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChats(
  params: {
    search?: string;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await ChatsService.index(params);

      dispatch(slice.actions.getChatsSuccess(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function resetSelectedConversation() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.getConversationSuccess(null));
  };
}

export function getConversation(
  params: {
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await ChatsService.details(params);

      dispatch(slice.actions.getConversationSuccess(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function updateConversation(
  params: {
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    try {
      const result = await ChatsService.details(params);

      dispatch(slice.actions.getConversationSuccess(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function addMessageToConversation(
  id: number,
  messageData: {
    senderId: any;
    attachments: any[];
    createdDate: DateTime;
    id: string;
    body: string;
    contentType: string;
  }
) {
  return async (dispatch: Dispatch) => {
    try {
      dispatch(
        slice.actions.addMessageToConversation({
          id,
          messageData,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
