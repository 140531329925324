import { m } from 'framer-motion';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
//
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

export default function FaqsForm() {
  const { t } = useLocales();

  return (
    <Stack component={MotionViewport} spacing={3}>
      <m.div variants={varFade().inUp}>
        <Typography variant="h4">{t(`Haven't found the right help?`)}</Typography>
      </m.div>

      <m.div variants={varFade().inUp}>
        <Typography variant="body1">
          {t(
            `Press the button in the bottom right corner and write a message to us. We will get back to you as soon as possible.`
          )}
        </Typography>
      </m.div>
    </Stack>
  );
}
