import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  DiscountVoucherDto,
  DiscountVoucherService,
  getConfigs,
  serviceOptions,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  vouchers: [] as DiscountVoucherDto[],
  totalNumberOfVouchers: 0,
  currentVoucher: {} as DiscountVoucherDto,
};

const slice = createSlice({
  name: 'voucher',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getVouchersSuccess(state, action) {
      state.vouchers = action.payload.vouchers;
      state.totalNumberOfVouchers = action.payload.totalNumberOfVouchers;
    },
    getVoucherSuccess(state, action) {
      state.currentVoucher = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getVouchers(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/discount-vouchers';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getVouchersSuccess({
          totalNumberOfVouchers: parseInt(resp.headers['x-total-count'], 10),
          vouchers: resp.data,
        })
      );

      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getVoucher(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await DiscountVoucherService.getById({ id });
      dispatch(slice.actions.getVoucherSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
