// @mui
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack, { StackProps } from '@mui/material/Stack';
import Box from '@mui/material/Box';
// types
import { m } from 'framer-motion';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { PaymentMethodDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type PaymentItemProps = StackProps & {
  card: PaymentMethodDto;
  onDelete: (card: PaymentMethodDto) => void;
};

export default function PaymentCardItem({ card, onDelete, sx, ...other }: PaymentItemProps) {
  const popover = usePopover();

  const { t } = useLocales();

  const handleDelete = () => {
    popover.onClose();
    onDelete(card);
  };

  const cardTypeIcon = () => {
    if (card.cardType?.toLowerCase() === 'visa') {
      return 'logos:visa';
    }
    if (card.cardType?.toLowerCase() === 'mc') {
      return 'logos:mastercard';
    }
    if (card.cardType?.toLowerCase() === 'visadk') {
      return 'logos:visa';
    }
    if (card.cardType?.toLowerCase() === 'visaelec') {
      return 'logos:visaelectron';
    }
    if (card.cardType?.toLowerCase() === 'dankort') {
      return 'logos:dankort';
    }
    return '';
  };

  return (
    <>
      <Stack
        spacing={1}
        component={Paper}
        variant="outlined"
        sx={{
          p: 2.5,
          width: 1,
          position: 'relative',
          ...sx,
        }}
        {...other}
      >
        <Stack direction="row" alignItems="center" spacing={1}>
          {cardTypeIcon() !== 'logos:dankort' && <Iconify icon={cardTypeIcon()} width={36} />}

          {cardTypeIcon() === 'logos:dankort' && (
            <Box
              component={m.img}
              src="/assets/icons/brands/DK_Logo_CMYK.svg"
              sx={{
                height: 36,
              }}
            />
          )}

          {card.state === 'inactivated' && <Label color="default">{t(card.state ?? '')}</Label>}
          {card.state === 'active' && <Label color="success">{t(card.state ?? '')}</Label>}
          {card.state === 'failed' && <Label color="error">{t(card.state ?? '')}</Label>}
          {card.state === 'pending' && <Label color="default">{t(card.state ?? '')}</Label>}
          {card.state === 'deleted' && <Label color="default">{t(card.state ?? '')}</Label>}
        </Stack>

        <Typography variant="subtitle2">
          {card.maskedCard
            ?.replace(/X/g, '*')
            .match(/.{1,4}/g)
            ?.join(' ')}
        </Typography>

        <Typography variant="subtitle2">
          {t('Expires: {{date}}', { date: card.expDate })}
        </Typography>

        <IconButton
          onClick={popover.onOpen}
          sx={{
            top: 8,
            right: 8,
            position: 'absolute',
          }}
        >
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton>
      </Stack>

      <CustomPopover open={popover.open} onClose={popover.onClose}>
        <MenuItem onClick={handleDelete} sx={{ color: 'error.main' }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
