import { Helmet } from 'react-helmet-async';
// sections
import { ContractListView } from '../../../sections/contracts/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ContractListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Contract List')}</title>
      </Helmet>

      <ContractListView />
    </>
  );
}
