import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// hooks
import { useDebounce } from 'src/hooks/use-debounce';
// api
import { useSearchPosts } from 'src/api/blog';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { IPostFilters, IPostFilterValue, IPostItem } from 'src/types/blog';
//
import ArticleSort from '../article-sort';
import ArticleSearch from '../article-search';
import ArticleListHorizontal from '../article-list-horizontal';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getNews } from '../../../redux/slices/news';
import { Direction, NewsStatus } from '../../../api';

// ----------------------------------------------------------------------

const defaultFilters = {
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ArticleListView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const EVENT_SORT_OPTIONS = [
    { value: 'latest', label: t('Latest') },
    { value: 'oldest', label: t('Oldest') },
  ];

  const [sortBy, setSortBy] = useState('latest');

  const [filters, setFilters] = useState(defaultFilters);

  const [searchQuery, setSearchQuery] = useState('');

  const debouncedQuery = useDebounce(searchQuery);

  const dispatch = useDispatch();

  const isLoading = useSelector((state) => state.news.isLoading);

  const tableData = useSelector((state) => state.news.articles);

  const tableStats = useSelector((state) => state.news.stats);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'default', count: tableStats.total },
    {
      value: NewsStatus.Published,
      label: t(NewsStatus.Published),
      color: 'success',
      count: tableStats.published,
    },
    {
      value: NewsStatus.Planned,
      label: t(NewsStatus.Planned),
      color: 'info',
      count: tableStats.planned,
    },
    {
      value: NewsStatus.Draft,
      label: t(NewsStatus.Draft),
      color: 'warning',
      count: tableStats.draft,
    },
  ] as const;

  useEffect(() => {
    dispatch(
      getNews({
        pageSize: 10,
        pageNumber: 0,
        sortBy: ['publishedOn'],
        direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
        status: filters.status === 'all' ? undefined : filters.status,
      })
    );
  }, [dispatch, filters, sortBy]);

  const { searchResults, searchLoading } = useSearchPosts(debouncedQuery);

  const handleSortBy = useCallback((newValue: string) => {
    setSortBy(newValue);
  }, []);

  const handleFilters = useCallback((name: string, value: IPostFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSearch = useCallback((inputValue: string) => {
    setSearchQuery(inputValue);
  }, []);

  const handleFilterPublish = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleDelete = useCallback(() => {
    dispatch(
      getNews({
        pageSize: 10,
        pageNumber: 0,
        sortBy: ['publishedOn'],
        direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
        status: filters.status === 'all' ? undefined : filters.status,
      })
    );
  }, [dispatch, sortBy, filters]);

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(
        getNews({
          pageNumber: value - 1,
          pageSize,
          sortBy: ['publishedOn'],
          direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
          status: filters.status === 'all' ? undefined : filters.status,
        })
      );
    },
    [dispatch, sortBy, filters]
  );

  const pageSize = 9;

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="List"
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('News'),
            href: paths.news.root,
          },
          {
            name: t('List'),
          },
        ]}
        action={
          <Button
            component={RouterLink}
            href={paths.news.new}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {t('Add news')}
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack
        spacing={3}
        justifyContent="space-between"
        alignItems={{ xs: 'flex-end', sm: 'center' }}
        direction={{ xs: 'column', sm: 'row' }}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <ArticleSearch
          query={debouncedQuery}
          results={searchResults}
          onSearch={handleSearch}
          loading={searchLoading}
          hrefItem={(title: string) => paths.dashboard.post.details(title)}
        />

        <ArticleSort sort={sortBy} onSort={handleSortBy} sortOptions={EVENT_SORT_OPTIONS} />
      </Stack>

      <Tabs
        value={filters.status}
        onChange={handleFilterPublish}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {STATUS_OPTIONS.map((tab) => (
          <Tab
            key={tab.value}
            iconPosition="end"
            value={tab.value}
            label={tab.label}
            icon={
              <Label
                variant={
                  ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                }
                color={tab.color}
              >
                {tab.count}
              </Label>
            }
            sx={{ textTransform: 'capitalize' }}
          />
        ))}
      </Tabs>

      <ArticleListHorizontal
        articles={tableData}
        loading={isLoading}
        totalEvents={tableStats.total}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
        onDelete={handleDelete}
      />
    </Container>
  );
}

// ----------------------------------------------------------------------

const applyFilter = ({
  inputData,
  filters,
  sortBy,
}: {
  inputData: IPostItem[];
  filters: IPostFilters;
  sortBy: string;
}) => {
  const { publish } = filters;

  if (sortBy === 'latest') {
    inputData = orderBy(inputData, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    inputData = orderBy(inputData, ['createdAt'], ['asc']);
  }

  if (sortBy === 'popular') {
    inputData = orderBy(inputData, ['totalViews'], ['desc']);
  }

  if (publish !== 'all') {
    inputData = inputData.filter((post) => post.publish === publish);
  }

  return inputData;
};
