import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import { CardProps } from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { fDateTime } from 'src/utils/format-time';
// types
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useSnackbar } from 'src/components/snackbar';
import TextMaxLine from 'src/components/text-max-line';
import FileThumbnail from 'src/components/file-thumbnail';
import { ConfirmDialog } from 'src/components/custom-dialog';
//
import { AccountDto, ProgramTemplateDto } from '../../../api';
import { useRouter } from '../../../routes/hook';
import { paths } from '../../../routes/paths';
import { useLocales } from '../../../locales';
import TemplatePlanningForm from './template-planning-form';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  document: ProgramTemplateDto;
  selected?: boolean;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
  member: AccountDto;
}

export default function FileManagerFileItem({
  document,
  selected,
  onSelect,
  onDelete,
  member,
  sx,
  ...other
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const checkbox = useBoolean();

  const { t } = useLocales();

  const router = useRouter();

  const confirm = useBoolean();

  const details = useBoolean();

  const popover = usePopover();

  const planning = useBoolean();

  const onClick = () => {
    router.push(paths.programTemplates.edit(document.id!));
  };

  const handleDelete = useCallback(() => {
    confirm.onFalse();
    onDelete();
  }, [confirm, onDelete]);

  const renderIcon = <FileThumbnail file="doc" sx={{ width: 36, height: 36 }} onClick={onClick} />;

  const renderAction = (
    <Stack direction="row" alignItems="center" sx={{ top: 8, right: 8, position: 'absolute' }}>
      <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>
    </Stack>
  );

  const renderText = (
    <>
      <TextMaxLine
        persistent
        variant="subtitle2"
        onClick={details.onTrue}
        sx={{ width: 1, mt: 2, mb: 0.5 }}
      >
        {document.name}
      </TextMaxLine>

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          maxWidth: 0.99,
          whiteSpace: 'nowrap',
          typography: 'caption',
          color: 'text.disabled',
        }}
      >
        <Typography noWrap component="span" variant="caption">
          {fDateTime(document.lastModifiedDate)}
        </Typography>
      </Stack>
    </>
  );

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        alignItems="flex-start"
        sx={{
          p: 2.5,
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          position: 'relative',
          ...((checkbox.value || selected) && {
            bgcolor: 'background.paper',
            boxShadow: (theme) => theme.customShadows.z20,
          }),
          ...sx,
        }}
        {...other}
      >
        <Box onMouseEnter={checkbox.onTrue} onMouseLeave={checkbox.onFalse}>
          {renderIcon}
        </Box>

        {renderText}

        {renderAction}
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths.personalPrograms.edit(document.id!));
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            planning.onTrue();
            popover.onClose();
          }}
        >
          <Iconify icon="icon-park-solid:plan" />
          {t('Schedule')}
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete template')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={handleDelete}>
            {t('Delete')}
          </Button>
        }
      />

      <TemplatePlanningForm
        open={planning.value}
        onClose={planning.onFalse}
        file={document}
        member={member}
      />
    </>
  );
}
