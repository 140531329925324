import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ClazzNewEditForm from '../classes-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getClass } from '../../../redux/slices/classes';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassesEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const currentClazz = useSelector((state) => state.clazz.currentClass);

  useEffect(() => {
    dispatch(getClass(parseInt(id, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          {
            name: t('Classes'),
            href: paths.classes.root,
          },
          { name: currentClazz?.title },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ClazzNewEditForm currentClazz={currentClazz} />
    </Container>
  );
}
