import { Helmet } from 'react-helmet-async';
// sections
import { EventEditView } from 'src/sections/event/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function EventEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Event Edit')}</title>
      </Helmet>

      <EventEditView />
    </>
  );
}
