import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  getConfigs,
  PushNotificationDto,
  PushNotificationService,
  serviceOptions,
} from '../../api';
import { INotificationStats } from '../../types/notification';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  notifications: [] as PushNotificationDto[],
  currentNotification: {} as PushNotificationDto,
  stats: {} as INotificationStats,
};

const slice = createSlice({
  name: 'push-notification',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getNewsSuccess(state, action) {
      state.notifications = action.payload.notifications;
      state.stats = action.payload.stats;
    },
    getCurrentNewsSuccess(state, action) {
      state.currentNotification = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPushNotifications(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    status?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/push-notifications';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getNewsSuccess({
          notifications: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getPushNotification(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await PushNotificationService.getById(params);

      dispatch(slice.actions.getCurrentNewsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------
