import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import QuickLinkNewEditForm from '../quick-link-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProduct } from '../../../redux/slices/product';
import { getProductCategories } from '../../../redux/slices/product-categories';
import { useLocales } from '../../../locales';
import QuickLinkCreateView from './quick-link-create-view';
import { getQuickLink } from '../../../redux/slices/quick-links';

// ----------------------------------------------------------------------

export default function QuickLinkEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const { t } = useLocales();

  const currentQuickLink = useSelector((state) => state.quickLinks.currentQuickLink);

  useEffect(() => {
    dispatch(getQuickLink(parseInt(id, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          {
            name: 'Quick Link',
            href: paths.quickLinks.root,
          },
          { name: currentQuickLink?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <QuickLinkNewEditForm currentQuickLink={currentQuickLink} />
    </Container>
  );
}
