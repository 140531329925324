import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProgramNewEditForm from '../program-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getWorkoutTypes } from '../../../redux/slices/program-template';
import { getClass } from '../../../redux/slices/classes';

// ----------------------------------------------------------------------

export default function ProgramCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const clazz = useSelector((state) => state.clazz.currentClass);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(getWorkoutTypes());

    if (searchParams.get('clazzId') !== null) {
      dispatch(getClass(parseInt(searchParams.get('clazzId') ?? '-1', 10)));
    }
  }, [dispatch, searchParams]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('New program')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Program')}`,
            href: paths.programs.root,
          },
          { name: t('New program') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProgramNewEditForm workoutTypes={workoutTypes} currentClazz={clazz} />
    </Container>
  );
}
