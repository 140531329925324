import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// utils
import { useParams } from 'src/routes/hook';
// api
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ArticleNewEditForm from '../article-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocales } from '../../../locales';
import { getSingleNews } from '../../../redux/slices/news';

// ----------------------------------------------------------------------

export default function ArticleEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const dispatch = useDispatch();

  const { id } = params;

  const article = useSelector((state) => state.news.currentArticle);

  useEffect(() => {
    dispatch(getSingleNews({ id: parseInt(id!, 10) }));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('News'),
            href: paths.news.root,
          },
          {
            name: article?.title,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ArticleNewEditForm currentArticle={article} />
    </Container>
  );
}
