import { Helmet } from 'react-helmet-async';
// sections
import { ClassTemplateDetailsView } from 'src/sections/class-description/view';

// ----------------------------------------------------------------------

export default function ClassDescriptionDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Analytics</title>
      </Helmet>

      <ClassTemplateDetailsView />
    </>
  );
}
