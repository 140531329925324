// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
// types
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch } from '../../redux/store';
//
import PaymentCardItem from '../payment/payment-card-item';
import PaymentNewCardDialog from '../payment/payment-new-card-dialog';
import { AccountDto, MembersService, PaymentMethodDto } from '../../api';
import { useLocales } from '../../locales';
import { getMemberPaymentMethods } from '../../redux/slices/members';

// ----------------------------------------------------------------------

type Props = {
  cards: PaymentMethodDto[];
  member: AccountDto;
};

export default function AccountBillingPayment({ cards, member }: Props) {
  const newCard = useBoolean();

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const handleDeleteCard = useCallback(
    (card: PaymentMethodDto | undefined) => {
      const action = async () => {
        await MembersService.removePaymentMethod({
          memberId: member.id!,
          paymentMethodId: card!.externalId!,
        });
        enqueueSnackbar('Removed!');
        dispatch(getMemberPaymentMethods(member.id!));
      };

      action();
    },
    [dispatch, member, enqueueSnackbar]
  );

  return (
    <>
      <Card sx={{ my: 3 }}>
        <CardHeader title={t('Payment methods')} />

        <Box
          rowGap={2.5}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          sx={{ p: 3 }}
        >
          {cards.map((card) => (
            <PaymentCardItem key={card.id} card={card} onDelete={(c) => handleDeleteCard(c)} />
          ))}
        </Box>
      </Card>

      <PaymentNewCardDialog open={newCard.value} onClose={newCard.onFalse} />
    </>
  );
}
