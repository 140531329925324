import { Helmet } from 'react-helmet-async';
// sections
import { VoucherListView } from '../../../sections/voucher/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function VoucherListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Voucher List')}</title>
      </Helmet>

      <VoucherListView />
    </>
  );
}
