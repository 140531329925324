import { Helmet } from 'react-helmet-async';
// sections
import { ProgramTemplateListView } from '../../../sections/personal-program-template/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function PersonalProgramListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Program Template List')}</title>
      </Helmet>

      <ProgramTemplateListView />
    </>
  );
}
