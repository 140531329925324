import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProgramNewEditForm from '../program-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getWorkoutTypes } from '../../../redux/slices/program-template';
import { getProgram } from '../../../redux/slices/program';

// ----------------------------------------------------------------------

export default function ProgramEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const { id } = params;

  const currentTemplate = useSelector((state) => state.program.currentTemplate);

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const clazz = useSelector((state) => state.clazz.currentClass);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    dispatch(
      getProgram({
        programId: parseInt(id!, 10),
      })
    );
    dispatch(getWorkoutTypes());
  }, [dispatch, id, searchParams]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Program')}`,
            href: paths.programs.root,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProgramNewEditForm
        currentTemplate={currentTemplate}
        workoutTypes={workoutTypes}
        currentClazz={clazz}
      />
    </Container>
  );
}
