import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import { QuickLinkCreateView } from '../../../sections/quick-links/view';

// ----------------------------------------------------------------------

export default function QuickLinkNewPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Quick Link Create')}</title>
      </Helmet>

      <QuickLinkCreateView />
    </>
  );
}
