// @mui
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import LinearProgress from '@mui/material/LinearProgress';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// utils
// types
import { DiscountVoucherDto } from '../../api';
import { fCurrency } from '../../utils/format-number';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: DiscountVoucherDto;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function VoucherTableRow({ row, onViewRow, onDeleteRow, onEditRow }: Props) {
  const { t } = useLocales();

  const {
    name,
    code,
    discountAmount,
    discountCurrency,
    discountPercentage,
    isOneTimeUse,
    validFrom,
    validTo,
    clips,
    usedClips,
  } = row;

  const confirm = useBoolean();

  const popover = usePopover();

  const available = clips! - usedClips!;
  const stockPercentage = clips! <= 0 ? 0 : available / clips!;

  let inventoryType = '';
  if (stockPercentage <= 0) inventoryType = t('none available');
  else if (stockPercentage <= 0.3) inventoryType = t('few left');
  else inventoryType = t('left');

  return (
    <>
      <TableRow hover>
        <TableCell align="left">{name}</TableCell>

        <TableCell align="left">
          {discountAmount ? fCurrency(discountAmount, discountCurrency) : ''}
        </TableCell>

        <TableCell align="left">{discountPercentage ? `${discountPercentage}%` : ''}</TableCell>

        <TableCell sx={{ typography: 'caption', color: 'text.secondary' }}>
          <LinearProgress
            value={stockPercentage}
            variant="determinate"
            color={
              (stockPercentage <= 0 && 'error') ||
              (stockPercentage <= 0.3 && 'warning') ||
              'success'
            }
            sx={{ mb: 1, height: 6, maxWidth: 80 }}
          />
          {!!available && available} {inventoryType}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {isOneTimeUse ? `${t('Yes')}` : `${t('No')}`}
        </TableCell>

        <TableCell>
          <ListItemText
            primary={validFrom!.toLocaleString(DateTime.DATE_MED)}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>

        <TableCell>
          {validTo !== undefined && (
            <ListItemText
              primary={validTo!.toLocaleString(DateTime.DATE_MED)}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          )}
        </TableCell>

        <TableCell align="right">
          <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
