import { Helmet } from 'react-helmet-async';
// sections
import LocationEditView from '../../../sections/location/view/location-edit-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function LocationEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${t('Location: Edit | Booking Board')}`}</title>
      </Helmet>

      <LocationEditView />
    </>
  );
}
