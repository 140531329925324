// @mui
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { Link as RouterLink } from 'react-router-dom';
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { useEffect } from 'react';
import GymList from '../gym-list';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getGyms } from '../../../redux/slices/gym';

// ----------------------------------------------------------------------

export default function GymListView() {
  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const { t } = useLocales();

  const dataFiltered = useSelector((state) => state.gym.gyms);

  useEffect(() => {
    dispatch(getGyms());
  }, [dispatch]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Gyms')}`}
        links={[
          { name: `${t('Dashboard')}`, href: paths.dashboard.root },
          {
            name: `${t('Gym')}`,
            href: paths.gyms.root,
          },
          { name: `${t('List')}` },
        ]}
        action={
          <Button
            component={RouterLink}
            to={paths.gyms.new}
            variant="contained"
            startIcon={<Iconify icon="mingcute:add-line" />}
          >
            {`${t('New gym')}`}
          </Button>
        }
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <GymList gyms={dataFiltered} />
    </Container>
  );
}

// ----------------------------------------------------------------------
