// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import NewsletterNewEditForm from '../newsletter-new-edit-form';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function NewsletterCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const queryParams = new URLSearchParams(window.location.search);

  const eventName = queryParams.get('eventName');

  const membershipTypeName = queryParams.get('membershipTypeName');

  let heading = t('Create a new newsletter');
  if (eventName) {
    heading = t('Create a newsletter for {{name}}', { name: eventName });
  }

  if (membershipTypeName) {
    heading = t('Create a newsletter for {{name}}', { name: membershipTypeName });
  }

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={heading}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Newsletter'),
            href: paths.newsletter.root,
          },
          {
            name: t('Create'),
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <NewsletterNewEditForm />
    </Container>
  );
}
