import { Helmet } from 'react-helmet-async';
// sections
import ClassesListView from '../../../sections/classes/view/classes-list-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassesListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Classes List')}</title>
      </Helmet>

      <ClassesListView />
    </>
  );
}
