import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  ClassesService,
  ClazzDto,
  Direction,
  getConfigs,
  MessageDto,
  ParticipationStatus,
  serviceOptions,
  SignUpDto,
} from '../../api';
import { ClassRegistrationStats } from '../../types/class-registration';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  classes: [] as ClazzDto[],
  totalNumberOfClasses: 0,
  classRegistrations: [] as SignUpDto[],
  totalNumberOfClassRegistrations: 0,
  currentClass: {} as ClazzDto,
  currentClassParticipants: [] as SignUpDto[],
  messages: [] as MessageDto[],
  classRegistrationStats: {} as ClassRegistrationStats,
};

const slice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassesSuccess(state, action) {
      state.classes = action.payload.classes;
      state.totalNumberOfClasses = action.payload.totalNumberOfClasses;
    },
    getClassSuccess(state, action) {
      state.currentClass = action.payload;
    },
    getClassParticipantsSuccess(state, action) {
      state.currentClassParticipants = action.payload;
    },
    getClassMessagesSuccess(state, action) {
      state.messages = (action.payload as MessageDto[]).sort(
        (a, b) =>
          new Date(b.createdDate!.toJSDate()).getTime() -
          new Date(a.createdDate!.toJSDate()).getTime()
      );
    },
    getClassRegistrationsSuccess(state, action) {
      state.classRegistrations = action.payload.classRegistrations;
      state.totalNumberOfClassRegistrations = action.payload.totalNumberOfClassRegistrations;
      state.classRegistrationStats = action.payload.stats;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClasses(
  params: {
    from?: DateTime | null;
    to?: DateTime | null;
    searchString?: string;
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
    employeeId?: number;
    selectedLocationId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/classes';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getClassesSuccess({
          totalNumberOfClasses: parseInt(resp.headers['x-total-count'], 10),
          classes: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getClassRegistrations(
  params: {
    from?: Date | null;
    to?: Date | null;
    searchString?: string;
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
    status?: ParticipationStatus | string | null;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/sign-ups';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getClassRegistrationsSuccess({
          totalNumberOfClassRegistrations: parseInt(resp.headers['x-total-count'], 10),
          classRegistrations: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getClass(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const clazz = await ClassesService.details({ id });

      dispatch(slice.actions.getClassSuccess(clazz));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getClassParticipants(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const clazz = await ClassesService.getRegistrations({ classId: id });

      dispatch(slice.actions.getClassParticipantsSuccess(clazz));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getClassMessages(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const messages = await ClassesService.getMessages({ id });

      dispatch(slice.actions.getClassMessagesSuccess(messages));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}
