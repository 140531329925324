import * as Yup from 'yup';
import 'yup-phone-lite';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Alert from '@mui/material/Alert';
// utils
import { fData } from 'src/utils/format-number';
import { CountryCode } from 'libphonenumber-js/types';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// types
// assets
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField, RHFUploadAvatar } from 'src/components/hook-form';
import { AccountDto, MemberDto, MembersService } from '../../api';
import { useLocales } from '../../locales';
import { countries } from '../../assets/data';

// ----------------------------------------------------------------------

type Props = {
  currentMember?: AccountDto;
};

const MAX_FILE_SIZE = 10 * 1000 * 1000;

export default function MemberNewEditForm({ currentMember }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const [primaryAccount, setPrimaryAccount] = useState<MemberDto | null>(
    currentMember?.primaryAccount !== undefined
      ? ({
          ...currentMember!.primaryAccount,
          emailAddress: currentMember!.primaryAccount!.email,
        } as any)
      : null
  );

  const [inputValue, setInputValue] = useState('');

  const [options, setOptions] = useState<readonly MemberDto[]>([]);

  const [alertMessage, setAlertMessage] = useState('');

  const [countryCode, setCountryCode] = useState<CountryCode>('DK');

  const newSchema = Yup.object().shape({
    firstName: Yup.string().required(t('First name is required')),
    lastName: Yup.string().required(t('Last name is required')),
    middleName: Yup.string(),
    profileImageUrl: Yup.mixed(),
    dateOfBirth: Yup.date().required(t('Date of birth is required')),
    phoneNumber: Yup.string().phone(countryCode).required(t('Phone number is required')),
    email: Yup.string()
      .required(t('Email is required'))
      .email(t('Email must be a valid email address')),
    gender: Yup.string().required(t('Gender is required')),
    addressLine: Yup.string().required(t('Address line is required')),
    city: Yup.string().required(t('City is required')),
    postalNumber: Yup.string().required(t('Postal number is required')),
    country: Yup.string().required(t('Country is required')),
    vatNo: Yup.string(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        firstName: currentMember?.firstName || '',
        lastName: currentMember?.lastName || '',
        middleName: currentMember?.middleName || '',
        profileImageUrl: currentMember?.profileImageUrl || '',
        dateOfBirth: currentMember?.dateOfBirth,
        phoneNumber: currentMember?.phoneNumber || '',
        email: currentMember?.email || '',
        gender: currentMember?.gender || '',
        addressLine: currentMember?.address?.addressLine || '',
        city: currentMember?.address?.city || '',
        postalNumber: currentMember?.address?.postalNumber || '',
        country: currentMember?.address?.country || '',
        vatNo: currentMember?.vatNo || '',
      } as any),
    [currentMember]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const [isSubAccount, setIsSubAccount] = useState(false);

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentMember) {
      reset(defaultValues);
      setIsSubAccount(currentMember?.primaryAccount !== undefined);
      setPrimaryAccount(
        currentMember?.primaryAccount !== undefined
          ? ({
              ...currentMember!.primaryAccount,
              emailAddress: currentMember!.primaryAccount!.email,
            } as any)
          : null
      );

      const result = countries.filter((x) => x.label === currentMember.address?.country);
      if (result.length) {
        setCountryCode(result[0].code as CountryCode);
      }
    }
  }, [currentMember, defaultValues, reset]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions(primaryAccount ? [primaryAccount] : []);
    } else {
      MembersService.findAll({
        pageNumber: 0,
        pageSize: 10,
        sortBy: ['firstName'],
        search: inputValue,
      }).then((resp) => {
        let newOptions: readonly MemberDto[] = [];

        if (primaryAccount) {
          newOptions = [primaryAccount];
        }

        if (resp) {
          newOptions = [...newOptions, ...resp];
        }

        setOptions(newOptions);
      });
    }
  }, [primaryAccount, inputValue]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      let memberId = currentMember?.id;
      if (currentMember === undefined) {
        const result = await MembersService.create({
          body: {
            ...data,
            payerEmail: primaryAccount?.emailAddress,
          } as any,
        });
        memberId = result.id;
        if (data.profileImageUrl) {
          await MembersService.profileImage({ id: memberId!, files: [data.profileImageUrl] ?? [] });
        }
      } else {
        const body = {
          id: currentMember.id!,
          ...data,
          payerEmail: primaryAccount?.emailAddress,
        } as any;
        await MembersService.edit({
          id: currentMember.id!,
          body,
        });
        if (data.profileImageUrl && data.profileImageUrl !== currentMember.profileImageUrl) {
          await MembersService.profileImage({ id: memberId!, files: [data.profileImageUrl] ?? [] });
        }
      }

      reset();
      enqueueSnackbar(currentMember ? t('Update success!') : t('Create success!'));
      router.push(paths.members.view(memberId!));
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const handleGenderChange = (event: SelectChangeEvent<any>) => {
    setValue('gender', event.target.value, { shouldValidate: true });
  };

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('profileImageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleCountrySelect = useCallback(
    (value: any) => {
      setCountryCode(value.code);
      setValue('country', value.label, { shouldValidate: true });
    },
    [setValue]
  );

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">
            <Box dangerouslySetInnerHTML={{ __html: alertMessage }} />
          </Alert>
        </Grid>
      )}
    </>
  );
  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        <Grid xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="profileImageUrl"
                maxSize={MAX_FILE_SIZE}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 3,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.disabled',
                    }}
                  >
                    {t('Allowed *.jpeg, *.jpg, *.png, *.gif')}
                    <br /> {t('max size of {{size}}', { size: fData(MAX_FILE_SIZE) })}
                  </Typography>
                }
              />
            </Box>
          </Card>
        </Grid>

        <Grid xs={12} md={8}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
                md: 'repeat(3, 1fr)',
              }}
            >
              <RHFTextField name="firstName" label={t('First Name')} />
              <RHFTextField name="middleName" label={t('Middle Name')} />
              <RHFTextField name="lastName" label={t('Last Name')} />
            </Box>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{
                mt: 3,
              }}
            >
              <RHFTextField name="email" label={t('Email address')} />
              <RHFTextField name="phoneNumber" label={t('Phone Number')} />
              <Controller
                name="dateOfBirth"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    label={t('Date of birth')}
                    {...field}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
              <Controller
                name="gender"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Gender')}</InputLabel>
                    <Select
                      label={t('Gender')}
                      value={values.gender}
                      onChange={handleGenderChange}
                      autoWidth={false}
                    >
                      <MenuItem key="Male" value="Male">
                        {t('Male')}
                      </MenuItem>
                      <MenuItem key="Female" value="Female">
                        {t('Female')}
                      </MenuItem>
                      <MenuItem key="Unspecified" value="Unspecified">
                        {t('Unspecified')}
                      </MenuItem>
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />

              <RHFTextField name="addressLine" label={t('Address line')} />
              <RHFTextField name="postalNumber" label={t('Postal number')} />
              <RHFTextField name="city" label={t('City')} />
              <Autocomplete
                value={{ label: values.country } as any}
                options={countries}
                autoHighlight
                onChange={(event: any, newValue) => {
                  handleCountrySelect(newValue);
                }}
                isOptionEqualToValue={(opt, val) => opt.value === val.value}
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                      loading="lazy"
                      width="20"
                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                      alt=""
                    />
                    {option.label} ({option.code}) +{option.phone}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Choose a country')}
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
              <RHFTextField name="vatNo" label={t('Vat no')} />
              <FormControlLabel
                control={
                  <Switch
                    checked={isSubAccount}
                    onChange={() => {
                      setIsSubAccount(!isSubAccount);
                      setPrimaryAccount(null);
                    }}
                  />
                }
                label={t('Is sub account?')}
              />
              {isSubAccount && (
                <>
                  <Stack spacing={3}>
                    <Autocomplete
                      value={primaryAccount}
                      filterOptions={(x) => x}
                      onChange={(event: any, newValue: MemberDto | null) => {
                        setPrimaryAccount(newValue);
                      }}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                      options={options}
                      autoComplete
                      includeInputInList
                      filterSelectedOptions
                      noOptionsText={t('No members')}
                      getOptionLabel={(option) => option.fullName!}
                      renderInput={(params) => <TextField {...params} label={t('Member')} />}
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          sx={{ display: 'flex', alignItems: 'center' }}
                          {...props}
                        >
                          <Avatar
                            alt={option.fullName}
                            src={option.profileImageUrl}
                            sx={{ mr: 2 }}
                          />

                          <ListItemText
                            primary={option.fullName}
                            secondary={option.emailAddress}
                            primaryTypographyProps={{ typography: 'body2' }}
                            secondaryTypographyProps={{
                              component: 'span',
                              color: 'text.disabled',
                            }}
                          />
                        </Box>
                      )}
                    />
                  </Stack>
                </>
              )}
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
                {!currentMember ? t('Create') : t('Save Changes')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
