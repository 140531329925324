import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProgramTemplateNewEditForm from '../program-template-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProgramTemplate, getWorkoutTypes } from '../../../redux/slices/program-template';

// ----------------------------------------------------------------------

export default function ProgramTemplateEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const { id } = params;

  const currentTemplate = useSelector((state) => state.programTemplate.currentTemplate);

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getProgramTemplate({
        programId: parseInt(id!, 10),
      })
    );
    dispatch(getWorkoutTypes());
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Program Template')}`,
            href: paths.programTemplates.root,
          },
          { name: currentTemplate?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProgramTemplateNewEditForm currentTemplate={currentTemplate} workoutTypes={workoutTypes} />
    </Container>
  );
}
