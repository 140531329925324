import { Helmet } from 'react-helmet-async';
// sections
import { PersonalTrainingDetailsView } from 'src/sections/membership-types/personal-training/view';

// ----------------------------------------------------------------------

export default function PersonalTrainingMembershipTypeDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Details</title>
      </Helmet>

      <PersonalTrainingDetailsView />
    </>
  );
}
