import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { setError } from 'src/redux/slices/error';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
// utils
// api
import { deleteEvent } from 'src/api/calendar';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFCheckbox,
  RHFSelect,
  RHFSwitch,
  RHFTextField,
} from 'src/components/hook-form';
// types
import { ICalendarEvent } from 'src/types/calendar';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getStaff } from '../../redux/slices/employees';
import { getClassDescriptions } from '../../redux/slices/class-description';
import { getGyms } from '../../redux/slices/gym';
import {
  ClassesService,
  ClazzDto,
  EntityStatuses,
  EventDetailsDto,
  EventService,
  MemberDto,
  MembersService,
  OptionTypeTypes,
  TicketOptionTypeDto,
} from '../../api';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  currentEvent: EventDetailsDto;
};

export default function EventParticipantQuickForm({ currentEvent, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState('');

  const [member, setMember] = useState<MemberDto | null>(null);

  const [options, setOptions] = useState<readonly MemberDto[]>([]);

  useEffect(() => {
    if (inputValue === '') {
      setOptions([]);
    } else {
      MembersService.findAll({
        pageNumber: 0,
        pageSize: 10,
        sortBy: ['firstName'],
        search: inputValue,
      }).then((resp) => {
        let newOptions: readonly MemberDto[] = [];

        if (member) {
          newOptions = [member];
        }

        if (resp) {
          newOptions = [...newOptions, ...resp];
        }

        setOptions(newOptions);
      });
    }
  }, [member, inputValue]);

  useEffect(() => {
    dispatch(
      getStaff({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        isActive: true,
      })
    );
    dispatch(
      getClassDescriptions({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        statuses: [EntityStatuses.Available],
      })
    );
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const EventSchema = Yup.object().shape({
    eventId: Yup.number(),
    ticketTypeId: Yup.number(),
    selectedOptions: Yup.mixed(),
  });

  const defaultValues = useMemo(
    () => ({
      eventId: currentEvent?.id || -1,
      ticketTypeId: -1,
      selectedOptions: Object.assign({}, ...currentEvent.options!.map((e) => ({ [e.name!]: '' }))),
    }),
    [currentEvent]
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      Object.keys(data.selectedOptions).forEach((e) => {
        data.selectedOptions[e] = data.selectedOptions[e].toString();
      });
      await EventService.createRegistration({
        id: currentEvent!.id!,
        body: {
          ...data,
          selectedOptions: data.selectedOptions,
          memberId: member?.id,
        },
      });

      enqueueSnackbar(t('Added!'));

      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  useEffect(() => {
    if (currentEvent) {
      reset(defaultValues);
    }
  }, [currentEvent, defaultValues, reset]);

  const renderOption = (option: TicketOptionTypeDto, index: number) => {
    if (option.type === OptionTypeTypes.Select) {
      return (
        <RHFSelect
          name={`selectedOptions.${option.name}`}
          label={option.name}
          InputLabelProps={{ shrink: true }}
          PaperPropsSx={{ textTransform: 'capitalize' }}
        >
          {option?.values?.map((type) => (
            <MenuItem key={`SelectedOptions-${index}-type-${type}`} value={type}>
              {type}
            </MenuItem>
          ))}
        </RHFSelect>
      );
    }
    if (option.type === OptionTypeTypes.Checkbox) {
      return <RHFCheckbox name={`selectedOptions.${option.name}`} label={option.name} />;
    }
    if (option.type === OptionTypeTypes.Radio) {
      return <RHFSwitch name={`selectedOptions.${option.name}`} label={option.name} />;
    }
    if (option.type === OptionTypeTypes.Text) {
      return (
        <RHFTextField
          name={`selectedOptions.${option.name}`}
          label={option.name}
          InputLabelProps={{ shrink: true }}
        />
      );
    }
    return <></>;
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <Autocomplete
          value={member}
          filterOptions={(x) => x}
          onChange={(event: any, newValue: MemberDto | null) => {
            setMember(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={options}
          autoComplete
          includeInputInList
          filterSelectedOptions
          noOptionsText={t('No members')}
          getOptionLabel={(option) => option.fullName!}
          renderInput={(params) => <TextField {...params} label={t('Member')} />}
          renderOption={(props, option) => (
            <Box component="li" sx={{ display: 'flex', alignItems: 'center' }} {...props}>
              <Avatar alt={option.fullName} src={option.profileImageUrl} sx={{ mr: 2 }} />

              <ListItemText
                primary={option.fullName}
                secondary={option.emailAddress}
                primaryTypographyProps={{ typography: 'body2' }}
                secondaryTypographyProps={{
                  component: 'span',
                  color: 'text.disabled',
                }}
              />
            </Box>
          )}
        />

        <RHFSelect
          name="ticketTypeId"
          label={t('Ticket type')}
          InputLabelProps={{ shrink: true }}
          PaperPropsSx={{ textTransform: 'capitalize' }}
        >
          {currentEvent?.types?.map((type) => (
            <MenuItem key={`ticket-type-${type.id}`} value={type.id}>
              {type.name}
            </MenuItem>
          ))}
        </RHFSelect>
        {currentEvent?.options?.map((opt, idx) => renderOption(opt, idx))}
      </Stack>
      <DialogActions>
        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t('Add')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
