import { Helmet } from 'react-helmet-async';
// sections
import GymEditView from '../../../sections/gyms/view/gym-edit-view';

// ----------------------------------------------------------------------

export default function GymEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Gym Edit</title>
      </Helmet>

      <GymEditView />
    </>
  );
}
