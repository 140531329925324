import { Helmet } from 'react-helmet-async';
// sections
import { ClassTemplateCreateView } from 'src/sections/class-template/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassTemplateCreatePage() {
  const { t } = useLocales();
  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Create a new class template')}</title>
      </Helmet>

      <ClassTemplateCreateView />
    </>
  );
}
