import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
import { useDispatch } from 'src/redux/store';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import FormProvider, { RHFSwitch } from 'src/components/hook-form';
import { useLocales } from '../../../locales';
import {
  CampaignService,
  ContingentService,
  DropInsService,
  MembershipDto,
  MembershipStatuses,
  MembershipUpdateTypes,
  PersonalTrainingService,
  VouchersService,
} from '../../../api';
import { getMemberships } from '../../../redux/slices/members';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onComplete: VoidFunction;
  membership?: MembershipDto;
};

export default function MembershipPauseForm({ open, onClose, onComplete, membership }: Props) {
  const { t } = useLocales();

  const newSchema = Yup.object().shape({
    membershipId: Yup.number().required('Fullname is required'),
    membershipTypeId: Yup.number().required('Fullname is required'),
    onHoldPeriodEnds: Yup.date(),
    withOnHoldFee: Yup.boolean(),
    enableEmailSending: Yup.boolean(),
    onHoldPeriodBegins: Yup.date().required(),
  });

  const defaultValues = useMemo(
    () =>
      ({
        onHoldPeriodBegins: membership?.renewalDate || null,
        onHoldPeriodEnds: null,
        withOnHoldFee: true,
        enableEmailSending: true,
        membershipId: membership?.id || -1,
        membershipTypeId: membership?.membershipTypeId || -1,
      } as any),
    [membership]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = methods;

  const isDropIn = membership?.typeOfMembership === 'DropIn';
  const isPunchCard = membership?.typeOfMembership === 'Voucher';
  const isContingent = membership?.typeOfMembership === 'Contingent';
  const isCampaign = membership?.typeOfMembership === 'Campaign';
  const isPersonalTraining = membership?.typeOfMembership === 'PersonalTrainingMembership';
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      if (isContingent) {
        await ContingentService.update({
          id: data.membershipId,
          body: {
            ...data,
            id: data.membershipId,
            type: MembershipUpdateTypes.OnHold,
          } as any,
        });
      } else if (isCampaign) {
        await CampaignService.update({
          id: data.membershipId,
          body: {
            ...data,
            id: data.membershipId,
            type: MembershipUpdateTypes.OnHold,
          } as any,
        });
      } else if (isDropIn) {
        await DropInsService.update({
          id: data.membershipId,
          body: {
            ...data,
            id: data.membershipId,
            type: MembershipUpdateTypes.OnHold,
          } as any,
        });
      } else if (isPunchCard) {
        await VouchersService.update({
          id: data.membershipId,
          body: {
            ...data,
            id: data.membershipId,
            type: MembershipUpdateTypes.OnHold,
          } as any,
        });
      } else if (isPersonalTraining) {
        await PersonalTrainingService.update({
          id: data.membershipId,
          body: {
            ...data,
            id: data.membershipId,
            type: MembershipUpdateTypes.OnHold,
          } as any,
        });
      }
      reset();
      onComplete();
      onClose();

      dispatch(
        getMemberships({
          id: membership!.ownerId!,
          statuses: [
            MembershipStatuses.Active,
            MembershipStatuses.InActive,
            MembershipStatuses.OnHold,
            MembershipStatuses.Cancelled,
            MembershipStatuses.MissingPayment,
          ],
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  });

  useEffect(() => {
    if (membership) {
      reset(defaultValues);
    }
  }, [membership, reset, defaultValues]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Put membership on hold')}</DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}
          >
            {t('Here you can put the given membership on hold.')}
          </Typography>

          <Stack spacing={3}>
            <Stack spacing={1.5}>
              <Controller
                name="onHoldPeriodBegins"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('On hold begins')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <Controller
                name="onHoldPeriodEnds"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    label={t('On Hold Until')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
            <Stack spacing={1.5}>
              <RHFSwitch name="withOnHoldFee" label={t('With On Hold Fee')} />
            </Stack>
            <Stack spacing={1.5}>
              <RHFSwitch name="enableEmailSending" label={t('Send confirmation email')} />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Put on hold')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
