import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
// utils
import {
  CampaignMembershipTypeDto,
  CampaignTypesService,
  ContingentTypeDto,
  ContingentTypesService,
  Direction,
  DropInTypeDto,
  DropInTypesService,
  getConfigs,
  PersonalTrainingTypeDto,
  PersonalTrainingTypesService,
  serviceOptions,
  VoucherTypeDto,
  VoucherTypesService,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  punchCards: [] as VoucherTypeDto[],
  totalNumberOfPunchCards: 0,
  contingents: [] as ContingentTypeDto[],
  totalNumberOfContingents: 0,
  campaigns: [] as CampaignMembershipTypeDto[],
  totalNumberOfCampaigns: 0,
  dropIns: [] as DropInTypeDto[],
  totalNumberOfDropIns: 0,
  personalTrainings: [] as PersonalTrainingTypeDto[],
  totalNumberOfPersonalTrainings: 0,
  currentPunchCard: {} as VoucherTypeDto,
  currentDropIn: {} as DropInTypeDto,
  currentContingent: {} as ContingentTypeDto,
  currentCampaign: {} as CampaignMembershipTypeDto,
  currentPersonalTraining: {} as PersonalTrainingTypeDto,
};

const slice = createSlice({
  name: 'membership-type',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getPunchCardsSuccess(state, action) {
      state.punchCards = action.payload.products;
      state.totalNumberOfPunchCards = action.payload.totalNumberOfProducts;
    },
    getPunchCardSuccess(state, action) {
      state.currentPunchCard = action.payload;
    },
    getDropInsSuccess(state, action) {
      state.dropIns = action.payload.dropIns;
      state.totalNumberOfDropIns = action.payload.totalNumberOfDropIns;
    },
    getDropInSuccess(state, action) {
      state.currentDropIn = action.payload;
    },
    getContingentsSuccess(state, action) {
      state.contingents = action.payload.products;
      state.totalNumberOfContingents = action.payload.totalNumberOfProducts;
    },
    getContingentSuccess(state, action) {
      state.currentContingent = action.payload;
    },
    getCampaignsSuccess(state, action) {
      state.campaigns = action.payload.products;
      state.totalNumberOfCampaigns = action.payload.totalNumberOfProducts;
    },
    getCampaignSuccess(state, action) {
      state.currentCampaign = action.payload;
    },
    getPersonalTrainingsSuccess(state, action) {
      state.personalTrainings = action.payload.products;
      state.totalNumberOfPersonalTrainings = action.payload.totalNumberOfProducts;
    },
    getPersonalTrainingSuccess(state, action) {
      state.currentPersonalTraining = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPunchCards(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean;
    isPublic?: boolean | null;
    validOn?: Date | DateTime | null;
    availableFrom?: Date | DateTime | null;
    validFrom?: Date | DateTime | null;
    validTo?: Date | DateTime | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/voucher-types';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getPunchCardsSuccess({
          totalNumberOfProducts: parseInt(resp.headers['x-total-count'], 10),
          products: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getPunchCard(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await VoucherTypesService.details({
        id,
      });
      dispatch(slice.actions.getPunchCardSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------

export function getDropIns(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean;
    isPublic?: boolean | null;
    validOn?: Date | DateTime | null;
    availableFrom?: Date | DateTime | null;
    validFrom?: Date | DateTime | null;
    validTo?: Date | DateTime | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/drop-in-types';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getDropInsSuccess({
          totalNumberOfDropIns: parseInt(resp.headers['x-total-count'], 10),
          dropIns: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getDropIn(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await DropInTypesService.details({
        id,
      });
      dispatch(slice.actions.getDropInSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------

export function getContingents(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean;
    isPublic?: boolean | null;
    validOn?: Date | DateTime | null;
    availableFrom?: Date | DateTime | null;
    validFrom?: Date | DateTime | null;
    validTo?: Date | DateTime | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/contingent-types';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getContingentsSuccess({
          totalNumberOfProducts: parseInt(resp.headers['x-total-count'], 10),
          products: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getContingent(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ContingentTypesService.details({
        id,
      });
      dispatch(slice.actions.getContingentSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCampaigns(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean;
    isPublic?: boolean | null;
    validOn?: Date | DateTime | null;
    availableFrom?: Date | DateTime | null;
    validFrom?: Date | DateTime | null;
    validTo?: Date | DateTime | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/campaign-types';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getCampaignsSuccess({
          totalNumberOfProducts: parseInt(resp.headers['x-total-count'], 10),
          products: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getCampaign(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await CampaignTypesService.details({
        id,
      });
      dispatch(slice.actions.getCampaignSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPersonalTrainings(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean;
    isPublic?: boolean | null;
    validOn?: Date | DateTime | null;
    availableFrom?: Date | DateTime | null;
    validFrom?: Date | DateTime | null;
    validTo?: Date | DateTime | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/personal-training-types';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getPersonalTrainingsSuccess({
          totalNumberOfProducts: parseInt(resp.headers['x-total-count'], 10),
          products: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getPersonalTraining(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await PersonalTrainingTypesService.details({
        id,
      });
      dispatch(slice.actions.getPersonalTrainingSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
