import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  getConfigs,
  ProgramTemplateDto,
  ProgramTemplatesService,
  serviceOptions,
  WorkoutTypeDto,
  WorkoutTypesService,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  templates: [] as ProgramTemplateDto[],
  totalNumberOfTemplates: 0,
  currentTemplate: {} as ProgramTemplateDto,
  workoutTypes: [] as WorkoutTypeDto[],
};

const slice = createSlice({
  name: 'program-template',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassTemplatesSuccess(state, action) {
      state.templates = action.payload.templates;
      state.totalNumberOfTemplates = action.payload.totalNumberOfTemplates;
    },
    getClassTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
    },
    getWorkoutTypesSuccess(state, action) {
      state.workoutTypes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProgramTemplates(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    isActive?: boolean | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/program-templates';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getClassTemplatesSuccess({
          totalNumberOfTemplates: parseInt(resp.headers['x-total-count'], 10),
          templates: resp.data,
        })
      );

      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getProgramTemplate(
  params: {
    /**  */
    programId: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ProgramTemplatesService.getById(params);

      dispatch(slice.actions.getClassTemplateSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getWorkoutTypes() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await WorkoutTypesService.findAll();

      dispatch(slice.actions.getWorkoutTypesSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
