import { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import {
  Button,
  Card,
  Container,
  IconButton,
  Stack,
  Tab,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tooltip,
} from '@mui/material';
// routes
import { paths } from '../../../routes/paths';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import ConfirmDialog from '../../../components/custom-dialog/confirm-dialog';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
// sections
import { useDispatch, useSelector } from '../../../redux/store';
import { getStaff } from '../../../redux/slices/employees';
import { Direction, EmployeeDto, EmployeesService } from '../../../api';
import { IInvoiceTableFilters, IInvoiceTableFilterValue } from '../../../types/invoice';
import { useBoolean } from '../../../hooks/use-boolean';
import { useRouter } from '../../../routes/hook';
import { IUserTableFilters } from '../../../types/user';
import EmployeeTableRow from '../employee-table-row';
import { useLocales } from '../../../locales';
import EmployeeTableToolbar from '../employee-table-toolbar';
import Label from '../../../components/label';

// ----------------------------------------------------------------------

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: 'active',
};

// ----------------------------------------------------------------------

export default function EmployeeListView() {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name'), align: 'left' },
    { id: 'phoneNumber', label: t('Phone number'), align: 'left' },
    { id: 'role', label: t('Role'), align: 'left' },
    { id: '' },
  ];

  const [filters, setFilters] = useState(defaultFilters);

  const { themeStretch } = useSettingsContext();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.employee.employees);

  const totalNumberOfEmployees = useSelector((state) => state.employee.totalNumberOfEmployees);

  const tableStats = useSelector((state) => state.employee.stats);

  const STATUS_OPTIONS = [
    { value: 'active', label: t('Active'), color: 'success', count: tableStats.totalActive },
    { value: 'inactive', label: t('Inactive'), color: 'warning', count: tableStats.totalInactive },
    { value: 'all', label: t('All'), color: 'default', count: tableStats.total },
  ] as const;

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  useEffect(() => {
    dispatch(
      getStaff({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        isActive: filters.status === 'all' ? null : filters.status === 'active',
        searchString: filters.name,
      })
    );
  }, [dispatch, filters, order, orderBy, page, rowsPerPage]);

  const table = useTable({ defaultOrderBy: 'createDate' });

  const router = useRouter();

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const denseHeight = dense ? 56 : 76;

  const confirm = useBoolean();

  const handleFilters = useCallback(
    (name: string, value: IInvoiceTableFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const action = async () => {
        await EmployeesService.delete({
          id,
        });

        dispatch(
          getStaff({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            isActive: filters.status === 'all' ? null : filters.status === 'active',
            searchString: filters.name,
          })
        );
      };
      action();
    },
    [dispatch, filters, order, orderBy, page, rowsPerPage]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.employee.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.employee.details(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  return (
    <>
      <Container maxWidth={themeStretch ? false : 'lg'}>
        <CustomBreadcrumbs
          heading={`${t('Employee List')}`}
          links={[
            { name: `${t('Dashboard')}`, href: paths.dashboard.root },
            { name: `${t('Employee')}`, href: paths.employee.root },
            { name: `${t('List')}` },
          ]}
          action={
            <Button
              component={RouterLink}
              to={paths.employee.new}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('New Employee')}
            </Button>
          }
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <EmployeeTableToolbar filters={filters} onFilters={handleFilters} />

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="iconamoon:send-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="solar:printer-minimalistic-bold" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title={t('Delete')}>
                    <IconButton color="primary" onClick={confirm.onTrue}>
                      <Iconify icon="solar:trash-bin-trash-bold" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <EmployeeTableRow
                      key={row.id}
                      row={row}
                      onSelectRow={() => handleViewRow(row.id!)}
                      onEditRow={() => handleEditRow(row.id!)}
                      onDeleteRow={() => handleDeleteRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfEmployees)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfEmployees}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: EmployeeDto[];
  comparator: (a: any, b: any) => number;
  filters: IInvoiceTableFilters;
  dateError: boolean;
}) {
  return inputData;
}
