import { useCallback, useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Unstable_Grid2';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
//
import { AccountDto, DocumentDto, ProgramTemplatesService } from '../../api';
import { useLocales } from '../../locales';
import { getComparator, useTable } from '../../components/table';
import { useSettingsContext } from '../../components/settings';
import { useDispatch, useSelector } from '../../redux/store';
import { getPersonalPrograms } from '../../redux/slices/members';
import { IFileFilters, IFileFilterValue } from '../../types/file';
import FileManagerGridView from './personal/file-manager-grid-view';
import { ConfirmDialog } from '../../components/custom-dialog';
import { fTimestamp } from '../../utils/format-time';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
};

const defaultFilters: IFileFilters = {
  name: '',
  type: [],
  startDate: null,
  endDate: null,
};

export default function PersonalProgram({ member }: Props) {
  const table = useTable({ defaultRowsPerPage: 10 });

  const { t } = useLocales();

  const settings = useSettingsContext();

  const confirm = useBoolean();

  const upload = useBoolean();

  const [view, setView] = useState('grid');

  const tableData = useSelector((state) => state.member.currentMemberPersonalPrograms);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonalPrograms(member.id!));
  }, [dispatch, member]);

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate
      ? filters.startDate.toMillis() > filters.endDate.toMillis()
      : false;

  const dataFiltered = applyFilter({
    inputData: tableData,
    comparator: getComparator(table.order, table.orderBy),
    filters,
    dateError,
  });

  const dataInPage = dataFiltered.slice(
    table.page * table.rowsPerPage,
    table.page * table.rowsPerPage + table.rowsPerPage
  );

  const canReset =
    !!filters.name || !!filters.type.length || (!!filters.startDate && !!filters.endDate);

  const notFound = (!dataFiltered.length && canReset) || !dataFiltered.length;

  const handleChangeView = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: string | null) => {
      if (newView !== null) {
        setView(newView);
      }
    },
    []
  );

  const handleFilters = useCallback(
    (name: string, value: IFileFilterValue) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleDeleteItem = useCallback(
    (id: string) => {
      const deleteTemplate = async () => {
        await ProgramTemplatesService.remove({
          templateId: parseInt(id, 10),
        });

        dispatch(getPersonalPrograms(member.id!));
      };

      deleteTemplate();
    },
    [dispatch, member]
  );

  const handleDeleteItems = useCallback(() => {
    const deleteRows = tableData.filter((row) => !table.selected.includes(`${row.id}`));
    // setTableData(deleteRows);

    table.onUpdatePageDeleteRows({
      totalRows: tableData.length,
      totalRowsInPage: dataInPage.length,
      totalRowsFiltered: dataFiltered.length,
    });
  }, [dataFiltered.length, dataInPage.length, table, tableData]);
  return (
    <>
      <Card>
        <Grid container spacing={2} sx={{ p: 4 }}>
          <FileManagerGridView
            table={table}
            data={tableData}
            dataFiltered={dataFiltered}
            onDeleteItem={handleDeleteItem}
            onOpenConfirm={confirm.onTrue}
            member={member}
          />
        </Grid>
      </Card>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {table.selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeleteItems();
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({
  inputData,
  comparator,
  filters,
  dateError,
}: {
  inputData: DocumentDto[];
  comparator: (a: any, b: any) => number;
  filters: IFileFilters;
  dateError: boolean;
}) {
  const { name, type, startDate, endDate } = filters;

  const stabilizedThis = inputData.map((el, index) => [el, index] as const);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis.map((el) => el[0]);

  if (name) {
    inputData = inputData.filter(
      (contract) => contract.fileOriginalName!.toLowerCase().indexOf(name.toLowerCase()) !== -1
    );
  }

  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (file) =>
          fTimestamp(file.createdDate) >= fTimestamp(startDate) &&
          fTimestamp(file.createdDate) <= fTimestamp(endDate)
      );
    }
  }

  return inputData;
}
