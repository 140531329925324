import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  Direction,
  EventDetailsDto,
  EventDto,
  EventService,
  getConfigs,
  serviceOptions,
  TicketDetailsDto,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  events: [] as EventDto[],
  totalEvents: 0,
  currentEvent: {} as EventDetailsDto,
  currentEventParticipants: [] as TicketDetailsDto[],
};

const slice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getEventsSuccess(state, action) {
      state.events = action.payload.events;
      state.totalEvents = action.payload.totalEvents;
    },
    getEventSuccess(state, action) {
      state.currentEvent = action.payload;
    },
    getEventParticipantSuccess(state, action) {
      state.currentEventParticipants = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    from?: DateTime | null;
    to?: DateTime | null;
    activeDate?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/events';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getEventsSuccess({
          totalEvents: parseInt(resp.headers['x-total-count'], 10),
          events: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getEvent(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await EventService.details(params);

      dispatch(slice.actions.getEventSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getParticipants(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await EventService.getTickets(params);
      dispatch(slice.actions.getEventParticipantSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------
