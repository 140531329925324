import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import { ClassFeedbackDto, Direction, getConfigs, serviceOptions } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  feedback: [] as ClassFeedbackDto[],
  totalFeedback: 0,
};

const slice = createSlice({
  name: 'class-feedback',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getFeedbackSuccess(state, action) {
      state.feedback = action.payload.feedback as ClassFeedbackDto[];
      state.totalFeedback = action.payload.totalFeedback;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getFeedback(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    classId?: number;
    from?: DateTime | null;
    to?: DateTime | null;
    employeeId?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // throw new Error('Parameter is not a number!--feedback');

      const url = '/api/class-feedback';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getFeedbackSuccess({
          totalFeedback: parseInt(resp.headers['x-total-count'], 10),
          feedback: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
