import { Helmet } from 'react-helmet-async';
// sections
import { NewsletterCreateView } from '../../../sections/notification/view';

// ----------------------------------------------------------------------

export default function NotificationCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new post</title>
      </Helmet>

      <NewsletterCreateView />
    </>
  );
}
