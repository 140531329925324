import { m } from 'framer-motion';
import QRCode from 'react-qr-code';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
// config
import { MAPBOX_API } from 'src/config-global';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { varTranHover } from 'src/components/animate';
import Lightbox, { useLightBox } from 'src/components/lightbox';
// types
import { GymDto } from '../../api';
import MapMarkersPopups from './gym-map-markers-popups';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  gym: GymDto;
};

export default function GymOpeningHours({ gym }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Card>
        <Stack spacing={3} sx={{ p: 3 }}>
          {gym.openingHours?.map((item, index) => (
            <Stack spacing={2} key={`day-of-week-${item.dayOfWeek}`}>
              <Typography
                variant="subtitle2"
                sx={{
                  textTransform: 'capitalize',
                }}
              >
                {t(`${item.dayOfWeek}`)}
              </Typography>
              <Stack direction="row" spacing={1.5}>
                <TimePicker label={t('Open')} value={item.from} ampm={false} disabled />

                <TimePicker label={t('Closed')} value={item.to} ampm={false} disabled />
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Card>
    </>
  );
}
