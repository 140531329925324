import { m } from 'framer-motion';
import QRCode from 'react-qr-code';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
// config
import { MAPBOX_API } from 'src/config-global';
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import { varTranHover } from 'src/components/animate';
import Lightbox, { useLightBox } from 'src/components/lightbox';
// types
import { GymDto, PlaceDto } from '../../api';
import { useLocales } from '../../locales';
import GymMapMarkersPopups from './gym-map-markers-popups';
import { useSelector } from '../../redux/store';

// ----------------------------------------------------------------------

type Props = {
  gym: GymDto;
  places: PlaceDto[];
};

export default function GymDetailsContent({ gym, places }: Props) {
  const { name, address, images, checkInQrCode } = gym;

  const { t } = useLocales();

  const StyledMapContainer = styled('div')(({ theme }) => ({
    zIndex: 0,
    height: 560,
    overflow: 'hidden',
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    '& .mapboxgl-ctrl-logo, .mapboxgl-ctrl-bottom-right': {
      display: 'none',
    },
  }));

  const baseSettings = {
    mapboxAccessToken: MAPBOX_API,
    minZoom: 1,
  };

  const slides = images?.map((slide) => ({
    src: slide.url ?? '',
  })) ?? [{ src: '' }];

  const {
    selected: selectedImage,
    open: openLightbox,
    onOpen: handleOpenLightbox,
    onClose: handleCloseLightbox,
  } = useLightBox(slides);

  const renderGallery = (
    <>
      {slides.length > 0 && (
        <Box
          gap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <m.div
            key={slides[0].src}
            whileHover="hover"
            variants={{
              hover: { opacity: 0.8 },
            }}
            transition={varTranHover()}
          >
            <Image
              alt={slides[0].src}
              src={slides[0].src}
              ratio="1/1"
              onClick={() => handleOpenLightbox(slides[0].src)}
              sx={{ borderRadius: 2, cursor: 'pointer' }}
            />
          </m.div>

          <Box gap={1} display="grid" gridTemplateColumns="repeat(2, 1fr)">
            {slides.slice(1, 3).map((slide) => (
              <m.div
                key={slide.src}
                whileHover="hover"
                variants={{
                  hover: { opacity: 0.8 },
                }}
                transition={varTranHover()}
              >
                <Image
                  alt={slide.src}
                  src={slide.src}
                  ratio="1/1"
                  onClick={() => handleOpenLightbox(slide.src)}
                  sx={{ borderRadius: 2, cursor: 'pointer' }}
                />
              </m.div>
            ))}
            <m.div
              key={checkInQrCode}
              whileHover="hover"
              variants={{
                hover: { opacity: 0.8 },
              }}
              transition={varTranHover()}
            >
              <Tooltip title={t('QR code used for check in')}>
                <QRCode value={checkInQrCode ?? ''} />
              </Tooltip>
            </m.div>
          </Box>
        </Box>
      )}
      {slides.length > 0 && (
        <Lightbox
          index={selectedImage}
          slides={slides}
          open={openLightbox}
          close={handleCloseLightbox}
        />
      )}
    </>
  );
  const renderHead = (
    <>
      <Stack direction="row" sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {name}
        </Typography>
      </Stack>

      <Stack spacing={3} direction="row" flexWrap="wrap" alignItems="center">
        <Stack direction="row" alignItems="center" spacing={0.5} sx={{ typography: 'body2' }}>
          <Iconify icon="mingcute:location-fill" sx={{ color: 'error.main' }} />
          {`${address?.addressLine}, ${address?.postalNumber} ${address?.city}, ${address?.country}`}
        </Stack>
      </Stack>
    </>
  );

  const renderMap =
    address != null ? (
      <StyledMapContainer>
        <GymMapMarkersPopups
          {...baseSettings}
          data={[address!]}
          places={places}
          mapStyle="mapbox://styles/mapbox/light-v10"
        />
      </StyledMapContainer>
    ) : null;

  return (
    <>
      <Stack>
        {renderHead}

        <Divider sx={{ borderStyle: 'dashed', my: 5 }} />

        {renderMap}
      </Stack>
    </>
  );
}
