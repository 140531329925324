import { useEffect } from 'react';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import { getActiveMembers } from '../../../redux/slices/insight';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import { IInsightTableFilters } from '../../../types/insight';

// ----------------------------------------------------------------------

export default function ActiveMemberView({
  fromDate,
  toDate,
  membershipTypeIds,
  minimumLengthOfMembershipInDays,
}: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const report = useSelector((state) => state.insight.activeMemberReport);

  useEffect(() => {
    dispatch(
      getActiveMembers({
        fromDate,
        toDate,
        membershipTypeIds,
        minimumLengthOfMembershipInDays,
      })
    );
  }, [dispatch, fromDate, toDate, membershipTypeIds, minimumLengthOfMembershipInDays]);

  const chart = {
    series: [
      {
        data:
          report.series?.map((p) => ({
            x: p.x,
            y: [p.open, p.high, p.low, p.close],
          })) ?? [],
      },
    ],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      labels: {
        formatter: (value) => DateTime.fromISO(value).toFormat('MMMM yyyy'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fNumber(value)}`,
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.median ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.average ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  return (
    <Card>
      <CardHeader
        title={t('Active members')}
        subheader={t(
          'Shows the number of active members over time and the high/low for each month. Active members are those that have at least one active membership in the given month. Included membership types are contingent, campaign, punch cards, and personal training.'
        )}
      />
      <CardContent>
        <Chart dir="ltr" type="candlestick" series={chart.series} options={chartOptions} />
      </CardContent>
    </Card>
  );
}
