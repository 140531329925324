import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// types
import { IRetentionTableFilters, IRetentionTableFilterValue } from 'src/types/retention';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  filters: IRetentionTableFilters;
  onFilters: (name: string, value: IRetentionTableFilterValue) => void;
  onExport?: VoidFunction;
};

export default function RetentionTableToolbar({ filters, onFilters, onExport }: Props) {
  const popover = usePopover();
  const { t } = useLocales();

  const handleFilterName = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('name', event.target.value);
    },
    [onFilters]
  );

  const handleFilterMin = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('min', event.target.value);
    },
    [onFilters]
  );

  const handleFilterMax = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onFilters('max', event.target.value);
    },
    [onFilters]
  );

  const handleFilterPeriodFrom = useCallback(
    (newValue: DateTime | null) => {
      onFilters('periodFrom', newValue);
    },
    [onFilters]
  );

  const handleFilterPeriodTo = useCallback(
    (newValue: DateTime | null) => {
      onFilters('periodTo', newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1}>
          <TextField
            value={filters.name}
            onChange={handleFilterName}
            label={t('Search')}
            placeholder={t('Search...')}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
                </InputAdornment>
              ),
            }}
            sx={{
              flexGrow: 1,
            }}
          />

          <TextField
            value={filters.min}
            onChange={handleFilterMin}
            label={t('Minimum participations')}
            type="number"
            sx={{
              flexGrow: 1,
            }}
          />

          <TextField
            value={filters.max}
            onChange={handleFilterMax}
            label={t('Maximum participations')}
            type="number"
            sx={{
              flexGrow: 1,
            }}
          />

          <FormControl
            sx={{
              flexGrow: 1,
            }}
          >
            <DatePicker
              label={t('Period from')}
              value={filters.periodFrom}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterPeriodFrom}
            />
          </FormControl>

          <FormControl
            sx={{
              flexGrow: 1,
            }}
          >
            <DatePicker
              label={t('Period to')}
              value={filters.periodTo}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterPeriodTo}
            />
          </FormControl>
          {onExport !== undefined && (
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          )}
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onExport!();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
