import { useState } from 'react';
// @mui
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
//
import FaqsHero from '../faqs-hero';
import FaqsList from '../faqs-list';
import FaqsForm from '../faqs-form';
import { useLocales } from '../../../locales';
import FaqsCategory from '../faqs-category';

// ----------------------------------------------------------------------

export default function FaqsView() {
  const { t } = useLocales();

  const faq = t('faq', { returnObjects: true }) as any[];

  const [category, setCategory] = useState<any>(faq[0]);

  return (
    <>
      <FaqsHero />

      <Container sx={{ pt: 10, pb: 10, position: 'relative' }}>
        <FaqsCategory onSelect={(el) => setCategory(el)} />

        <Typography
          variant="h3"
          sx={{
            mt: { xs: 5, md: 10 },
          }}
        >
          {t('Frequently asked questions')}
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            mb: { xs: 5, md: 10 },
          }}
        >
          {category.category}
        </Typography>

        <Box
          gap={10}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
        >
          <FaqsList questionAndAnswers={category.questionAndAnswers} />

          <FaqsForm />
        </Box>
      </Container>
    </>
  );
}
