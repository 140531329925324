import { DateTime } from 'luxon';
import { BillableType, MembershipDto } from '../api';

export function calculateValidTo(membership: MembershipDto) {
  const renewalDate = membership?.renewalDate ?? DateTime.now();
  const frequency = membership?.billable ?? BillableType.OneTime;
  switch (frequency) {
    case BillableType.Monthly:
      return renewalDate
        .plus({
          month: 1,
        })
        .minus({
          day: 1,
        });
    case BillableType.Quarterly:
      return renewalDate
        .plus({
          month: 1,
        })
        .minus({
          day: 1,
        });
    case BillableType.HalfYearly:
      return renewalDate
        .plus({
          month: 1,
        })
        .minus({
          day: 1,
        });
    case BillableType.Yearly:
      return renewalDate
        .plus({
          month: 1,
        })
        .minus({
          day: 1,
        });
    case BillableType.Weekly:
      return renewalDate
        .plus({
          weeks: 1,
        })
        .minus({
          day: 1,
        });
    case BillableType.BiWeekly:
      return renewalDate
        .plus({
          weeks: 2,
        })
        .minus({
          day: 1,
        });
    case BillableType.TriWeekly:
      return renewalDate
        .plus({
          weeks: 3,
        })
        .minus({
          day: 1,
        });
    case BillableType.EveryFourthWeek:
      return renewalDate
        .plus({
          weeks: 4,
        })
        .minus({
          day: 1,
        });
    case BillableType.EveryTwelfthWeek:
      return renewalDate
        .plus({
          weeks: 4,
        })
        .minus({
          day: 1,
        });
    default:
      return renewalDate.minus({
        day: 1,
      });
  }
}
