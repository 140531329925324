import { Helmet } from 'react-helmet-async';
// sections
import { ClassTemplateListView } from 'src/sections/class-description/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassDescriptionListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Class Description List')}</title>
      </Helmet>

      <ClassTemplateListView />
    </>
  );
}
