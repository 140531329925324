import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Settings } from 'luxon';
import {
  Direction,
  DoorSystemLockService,
  ExternalLockDto,
  getConfigs,
  GymDto,
  GymsService,
  LocationDto,
  LocationsService,
  serviceOptions,
} from '../../api';
import { setError } from './error';
import { KEYS } from '../../utils/constants';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  gyms: [] as GymDto[],
  locks: [] as ExternalLockDto[],
  currentGym: {} as GymDto,
  globalGym: {} as GymDto,
  locations: [] as LocationDto[],
  currentLocation: {} as LocationDto,
  totalNumberOfLocations: 0,
  locationStats: {} as any,
};

const slice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getGymsSuccess(state, action) {
      state.gyms = action.payload.gyms;
    },
    getLocationsSuccess(state, action) {
      state.locations = action.payload.locations;
      state.totalNumberOfLocations = action.payload.total;
    },
    getLocationSuccess(state, action) {
      state.currentLocation = action.payload;
    },
    getGymSuccess(state, action) {
      state.currentGym = action.payload;
    },
    getLocksSuccess(state, action) {
      state.locks = action.payload;
    },
    setGlobalGymSuccess(state, action) {
      state.globalGym = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getGyms(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/gyms';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getGymsSuccess({
          gyms: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getLocations(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/locations';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getLocationsSuccess({
          locations: resp.data,
          total: parseInt(resp.headers['x-total-count'], 10),
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getGym(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await GymsService.details(params);

      dispatch(slice.actions.getGymSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getLocation(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await LocationsService.getById(params);

      dispatch(slice.actions.getLocationSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getLocks(params: {} = {} as any) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await DoorSystemLockService.findAll(params);

      dispatch(slice.actions.getLocksSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function setGlobalGym(gym: GymDto) {
  return async (dispatch: Dispatch) => {
    Settings.defaultZone = gym?.timeZone ?? 'system';
    localStorage.setItem(KEYS.selectedGymIdKey, `${gym.id}`);
    dispatch(slice.actions.setGlobalGymSuccess(gym));
  };
}

// ----------------------------------------------------------------------
