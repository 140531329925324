import { useCallback, useEffect, useState } from 'react';
// @mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { alpha } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import isEqual from 'lodash/isEqual';
import { useRouter } from 'src/routes/hook';
//
import { Direction, ParticipationStatus } from '../../../api';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { ConfirmDialog } from '../../../components/custom-dialog';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { useSettingsContext } from '../../../components/settings';
import {
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  emptyRows,
  useTable,
} from '../../../components/table';
import { useBoolean } from '../../../hooks/use-boolean';
import { useLocales } from '../../../locales';
import { getClassRegistrations } from '../../../redux/slices/classes';
import { useDispatch, useSelector } from '../../../redux/store';
import { IUserTableFilterValue, IUserTableFilters } from '../../../types/user';
import MemberTableFiltersResult from '../../member/member-table-filters-result';
import MemberTableToolbar from '../../member/member-table-toolbar';
import ClassRegistrationTableRow from '../class-registration-table-row';

// ----------------------------------------------------------------------

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function ClassRegistrationList() {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'className', label: t('Class') },
    { id: 'classBegins', label: t('Begins') },
    { id: 'firstName', label: t('Member') },
    { id: 'SignedUpAt', label: t('Signed up') },
    { id: 'CheckedInAt', label: t('Checked in') },
    { id: 'OptedOutAt', label: t('Opted out') },
    { id: 'status', label: 'Status' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[4].id,
    defaultOrder: 'desc',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.clazz.classRegistrations);

  const totalNumberOfMemberships = useSelector(
    (state) => state.clazz.totalNumberOfClassRegistrations
  );

  const tableStats = useSelector((state) => state.clazz.classRegistrationStats);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getClassRegistrations({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        status: filters.status === 'all' ? null : filters.status,
        search: filters.name,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableStats.total },
    {
      value: ParticipationStatus.OnTime,
      label: t(ParticipationStatus.OnTime),
      color: 'success',
      count: tableStats.onTime,
    },
    {
      value: ParticipationStatus.TooLate,
      label: t(ParticipationStatus.TooLate),
      color: 'warning',
      count: tableStats.tooLate,
    },
    {
      value: ParticipationStatus.OptedOut,
      label: t(ParticipationStatus.OptedOut),
      color: 'error',
      count: tableStats.optedOut,
    },
    {
      value: ParticipationStatus.OptedOutTooLate,
      label: t(ParticipationStatus.OptedOutTooLate),
      color: 'error',
      count: tableStats.optedOutTooLate,
    },
    {
      value: ParticipationStatus.OnWaitingList,
      label: t(ParticipationStatus.OnWaitingList),
      color: 'info',
      count: tableStats.onWaitingList,
    },
    {
      value: ParticipationStatus.SignedUp,
      label: t(ParticipationStatus.SignedUp),
      color: 'success',
      count: tableStats.signedUp,
    },
    {
      value: ParticipationStatus.NeverMet,
      label: t(ParticipationStatus.NeverMet),
      color: 'error',
      count: tableStats.neverMet,
    },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback((id: number) => {}, []);

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.classTemplates.edit(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Class Registration List')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Class Registration')}`,
              href: paths.classRegistration.root,
            },
            { name: `${t('List')}` },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card>
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <MemberTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <MemberTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfMemberships}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={() => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <ClassRegistrationTableRow
                      key={row.id}
                      row={row}
                      onEditRow={() => handleEditRow(row.id!)}
                      onDeleteRow={() => handleDeleteRow(row.id!)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMemberships)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfMemberships}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
