import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import { Direction, getConfigs, serviceOptions, ShiftDto, ShiftsService } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  shifts: [] as ShiftDto[],
  total: 0,
  currentShift: {} as ShiftDto,
};

const slice = createSlice({
  name: 'shift',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassesSuccess(state, action) {
      state.shifts = action.payload.shifts;
      state.total = action.payload.total;
    },
    getClassSuccess(state, action) {
      state.currentShift = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getShifts(
  params: {
    fromDate?: DateTime | null;
    toDate?: DateTime | null;
    searchString?: string;
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
    employeeId?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/shifts';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);
      dispatch(
        slice.actions.getClassesSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          shifts: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getShift(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const clazz = await ShiftsService.getById({ id });

      dispatch(slice.actions.getClassSuccess(clazz));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
    dispatch(slice.actions.stopLoading());
  };
}
