import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { Label } from '@mui/icons-material';
// locales
import { updateLanguage } from 'src/utils/axios';
import { useLocales } from 'src/locales';
// components
import Iconify from 'src/components/iconify';
import { varHover } from 'src/components/animate';

// ----------------------------------------------------------------------

export default function Help() {
  return (
    <>
      <IconButton
        onClick={() => window.open('https://help.bookingboard.dk')}
        sx={{
          width: 40,
          height: 40,
        }}
      >
        <Iconify icon="eva:question-mark-circle-outline" sx={{ borderRadius: 0.65, width: 28 }} />
      </IconButton>
    </>
  );
}
