// @mui
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// types
import { MembersWithRetentionIssuesDto } from '../../api';
//
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: MembersWithRetentionIssuesDto;
  onSelectRow: VoidFunction;
};

export default function RetentionTableRow({ row, onSelectRow }: Props) {
  const {
    firstName,
    lastName,
    emailAddress,
    profileImageUrl,
    numberOfAttendances,
    membershipType,
    phoneNumber,
  } = row;

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={`${firstName} ${lastName}`} src={profileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={`${firstName} ${lastName}`}
            secondary={emailAddress}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {numberOfAttendances}
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {membershipType}
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {phoneNumber}
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
