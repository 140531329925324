// @mui
import { daDK, deDE, enUS, esES, fiFI, nbNO, svSE } from '@mui/material/locale';

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: 'flagpack:gb-nir',
    dateLocale: 'en-gb',
  },
  {
    label: 'Dansk',
    value: 'da',
    systemValue: daDK,
    icon: 'flagpack:dk',
    dateLocale: 'da',
  },
  {
    label: 'Norsk',
    value: 'nb',
    systemValue: nbNO,
    icon: 'flagpack:no',
    dateLocale: 'nb',
  },
  {
    label: 'Svenska',
    value: 'sv',
    systemValue: svSE,
    icon: 'flagpack:se',
    dateLocale: 'sv',
  },
  {
    label: 'Suomalainen',
    value: 'fi',
    systemValue: fiFI,
    icon: 'flagpack:fi',
    dateLocale: 'fi',
  },
  {
    label: 'Español',
    value: 'es',
    systemValue: esES,
    icon: 'flagpack:es',
    dateLocale: 'es',
  },
  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: 'flagpack:de',
    dateLocale: 'de',
  },
];

export const defaultLang = allLangs[0]; // English

// GET MORE COUNTRY FLAGS
// https://icon-sets.iconify.design/flagpack/
// https://www.dropbox.com/sh/nec1vwswr9lqbh9/AAB9ufC8iccxvtWi3rzZvndLa?dl=0
