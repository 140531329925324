import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProgramTemplateNewEditForm from '../personal-program-template-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getWorkoutTypes } from '../../../redux/slices/program-template';
import { getMember } from '../../../redux/slices/members';
import { getPersonalPrograms } from '../../../redux/slices/personal-program';
import { getExercises } from '../../../redux/slices/exercise';

// ----------------------------------------------------------------------

export default function ProgramTemplateCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const { search } = useLocation();

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const memberId = query.get('memberId');

  const member = useSelector((state) => state.member.currentMember);

  const programTemplates = useSelector((state) => state.personalProgram.templates);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorkoutTypes());
    dispatch(getMember(parseInt(memberId ?? '-1', 10)));
    dispatch(
      getPersonalPrograms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
    dispatch(getExercises());
  }, [dispatch, memberId]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Personal Program')}`,
            href: paths.programTemplates.root,
          },
          { name: t('New personal program') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProgramTemplateNewEditForm
        programTemplates={programTemplates}
        workoutTypes={workoutTypes}
        member={member}
      />
    </Container>
  );
}
