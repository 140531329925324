import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { setError } from 'src/redux/slices/error';
import EmailEditor, { EditorRef, EmailEditorProps } from 'react-email-editor';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// _mock
import { _tags } from 'src/_mock';
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFAutocomplete,
  RHFEditor,
  RHFMultiSelect,
  RHFSelect,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
//
import { useDispatch, useSelector } from '../../redux/store';
import ArticleDetailsPreview from './article-details-preview';
import { EmailTemplatesService, ImageService, NewsDto, NewsService, NewsStatus } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentArticle?: NewsDto;
};

export default function ArticleNewEditForm({ currentArticle }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const preview = useBoolean();

  const gyms = useSelector((state) => state.gym.gyms);

  const globalGym = useSelector((state) => state.gym.globalGym);

  const emailEditorRef = useRef<EditorRef>(null);

  const newSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    subtitle: Yup.string(),
    teaser: Yup.string().required(t('Teaser is required')),
    body: Yup.string(),
    imageUrl: Yup.mixed<any>().nullable().required('Cover is required'),
    tags: Yup.array(),
    metaKeywords: Yup.array(),
    // not required
    json: Yup.mixed().nullable(),
    metaTitle: Yup.string(),
    metaDescription: Yup.string(),
    publishedOn: Yup.mixed<any>(),
    availableInGyms: Yup.array().min(1, t('Please select at least one gym')),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentArticle?.title || '',
      subtitle: currentArticle?.subtitle || '',
      teaser: currentArticle?.teaser || '',
      body: currentArticle?.body || '',
      json: currentArticle?.json || '',
      imageUrl: currentArticle?.imageUrl || '',
      status: currentArticle?.status || NewsStatus.Planned,
      tags: currentArticle?.tags || [],
      metaKeywords: currentArticle?.metaKeywords || [],
      metaTitle: currentArticle?.metaTitle || '',
      metaDescription: currentArticle?.metaDescription || '',
      publishedOn: currentArticle?.publishedOn || DateTime.now().plus({ hour: 1 }).startOf('hour'),
      availableInGyms: currentArticle?.availableInGyms?.length
        ? currentArticle?.availableInGyms?.map((e) => `${e}`)
        : [`${globalGym.id}`],
    }),
    [currentArticle, globalGym]
  );

  const params = useParams();

  const { id } = params;

  const onReady: EmailEditorProps['onReady'] = (unlayer) => {
    unlayer.setBodyValues({
      backgroundColor: '#fff',
    });
    unlayer.registerCallback('image', (d: any, x: any) => {
      ImageService.create({
        files: d.attachments,
      }).then((resp) => x({ url: resp.url }));
    });

    NewsService.getById({ id: parseInt(id!, 10) }).then((resp) => {
      if (resp?.json !== undefined && resp?.json !== null && resp?.json !== '') {
        unlayer.loadDesign(resp!.json! as any);
      } else if (resp?.body !== undefined && resp?.body !== null && resp?.body !== '') {
        unlayer.loadDesign({
          classic: true,
          html: resp?.body,
        } as any);
      }
    });
  };

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    control,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentArticle) {
      reset(defaultValues);
    }
    if (globalGym) {
      reset(defaultValues);
    }
  }, [currentArticle, defaultValues, reset, globalGym]);
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(
    async (data) =>
      new Promise((resolve) => {
        const unlayer = emailEditorRef.current?.editor;
        unlayer?.exportHtml(async (d) => {
          try {
            const { design, html } = d;
            // Check if is edit mode
            if (currentArticle) {
              await NewsService.update({
                id: currentArticle!.id!,
                body: {
                  id: currentArticle!.id!,
                  ...data,
                  body: html,
                  json: JSON.stringify(design),
                } as any,
              });
              if (data.imageUrl !== currentArticle.imageUrl) {
                await NewsService.addImage({ id: currentArticle!.id!, files: [data.imageUrl] });
              }
            } else {
              const newEvent = await NewsService.create({
                body: {
                  ...data,
                  body: html,
                  json: JSON.stringify(design),
                } as any,
              });
              await NewsService.addImage({ id: newEvent.id!, files: [data.imageUrl] });
            }

            reset();
            preview.onFalse();
            enqueueSnackbar(currentArticle ? t('Update success!') : t('Create success!'));
            router.push(paths.news.root);
          } catch (error) {
            dispatch(setError(error));
          }
          resolve(null);
        });
      })
  );

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        setValue('imageUrl', newFile, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleRemoveFile = useCallback(() => {
    setValue('imageUrl', null);
  }, [setValue]);

  const renderDetails = (
    <Grid xs={12}>
      <Card>
        <CardHeader title={t('Details')} />

        <Stack spacing={3} sx={{ p: 3 }}>
          <RHFTextField name="title" label={t('Title')} />

          <RHFTextField name="subtitle" label={t('Subtitle')} />

          <RHFSelect
            fullWidth
            name="status"
            label={t('Status')}
            InputLabelProps={{ shrink: true }}
            PaperPropsSx={{ textTransform: 'capitalize' }}
          >
            {[NewsStatus.Draft, NewsStatus.Planned, NewsStatus.Published].map((option) => (
              <MenuItem key={option} value={option}>
                {t(option)}
              </MenuItem>
            ))}
          </RHFSelect>

          <RHFTextField name="teaser" label={t('Teaser')} multiline rows={3} />

          <Stack spacing={1.5}>
            <Typography variant="subtitle2">{t('Content')}</Typography>
            <EmailEditor ref={emailEditorRef} projectId={223858} onReady={onReady} />
          </Stack>

          <Stack spacing={1.5}>
            <Typography variant="subtitle2">{t('Cover Image')}</Typography>
            <RHFUpload
              name="imageUrl"
              maxSize={3145728}
              onDrop={handleDrop}
              onDelete={handleRemoveFile}
            />
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {t('Please use af format of 4:3. Recommended size 2048 × 1536 pixels.')}
            </Typography>
          </Stack>
        </Stack>
      </Card>
    </Grid>
  );

  const renderProperties = (
    <Grid xs={12}>
      <Card>
        <CardHeader title={t('Properties')} />

        <Stack spacing={3} sx={{ p: 3 }}>
          <Controller
            name="publishedOn"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                {...field}
                label={t('Published On')}
                value={field.value}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
              />
            )}
          />

          <RHFMultiSelect
            name="availableInGyms"
            label={t('Available in gyms')}
            options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
            checkbox
          />

          <RHFAutocomplete
            name="tags"
            label="Tags"
            placeholder="+ Tags"
            multiple
            freeSolo
            options={_tags.map((option) => option)}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  color="info"
                  variant="soft"
                />
              ))
            }
          />

          <RHFTextField name="metaTitle" label={t('Meta title')} />

          <RHFTextField
            name="metaDescription"
            label={t('Meta description')}
            fullWidth
            multiline
            rows={3}
          />

          <RHFAutocomplete
            name="metaKeywords"
            label={t('Meta keywords')}
            placeholder={t('+ Keywords')}
            multiple
            freeSolo
            disableCloseOnSelect
            options={_tags.map((option) => option)}
            getOptionLabel={(option) => option}
            renderOption={(props, option) => (
              <li {...props} key={option}>
                {option}
              </li>
            )}
            renderTags={(selected, getTagProps) =>
              selected.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option}
                  label={option}
                  size="small"
                  color="info"
                  variant="soft"
                />
              ))
            }
          />
        </Stack>
      </Card>
    </Grid>
  );

  const renderActions = (
    <Grid xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ flexGrow: 1, pl: 3 }} />

      <LoadingButton
        type="submit"
        variant="contained"
        size="large"
        loading={isSubmitting}
        sx={{ ml: 2 }}
      >
        {!currentArticle ? t('Add news') : t('Save Changes')}
      </LoadingButton>
    </Grid>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderProperties}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
