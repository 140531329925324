import { useCallback, useEffect, useState } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import { DateTime } from 'luxon';
import isEqual from 'lodash/isEqual';
import { differenceInSeconds } from 'date-fns';
import { groupBy } from 'lodash';
import { saveAs } from 'file-saver';
//
import { Direction, EmployeesService, ShiftType, WagesService } from '../../../api';
import { useLocales } from '../../../locales';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
import { useSettingsContext } from '../../../components/settings';
import { useBoolean } from '../../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import SalarySettlementItemTableRow from '../salary-settlement-table-row';
import SalarySettlementTableToolbar from '../salary-settlement-table-toolbar';
import SalarySettlementTableFiltersResult from '../salary-settlement-table-filters-result';
import { getShifts } from '../../../redux/slices/shift';
import {
  ISalarySettlementTableFilters,
  ISalarySettlementTableFilterValue,
  SalarySettlement,
} from '../../../types/salary-settlement';

// ----------------------------------------------------------------------
const defaultFilters: ISalarySettlementTableFilters = {
  status: 'all',
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function SalarySettlementListView() {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'employeeName', label: t('Name'), align: 'left' },
    { id: 'clazz', label: t('Class'), align: 'left' },
    { id: 'personalTraining', label: t('Personal training'), align: 'left' },
    { id: 'unspecified', label: t('Unspecified'), align: 'left' },
    { id: 'total', label: t('Total'), align: 'left' },
    { id: '', label: t(''), align: 'left' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    setSelected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
    defaultOrder: 'asc',
    defaultRowsPerPage: 25,
  });

  const settings = useSettingsContext();

  const confirmDeletion = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.shift.shifts);

  const queryParams = new URLSearchParams(window.location.search);
  const fromDate = queryParams.get('fromDate')
    ? decodeURIComponent(queryParams.get('fromDate')!)
    : null;
  const toDate = queryParams.get('toDate') ? decodeURIComponent(queryParams.get('toDate')!) : null;

  const [filters, setFilters] = useState({
    ...defaultFilters,
    fromDate: fromDate ? DateTime.fromISO(fromDate) : DateTime.now().startOf('month'),
    toDate: toDate ? DateTime.fromISO(toDate) : DateTime.now().endOf('month'),
  });

  const employees = Object.entries(groupBy(tableData, (x) => x.employeeId)).map((group) => {
    const shifts = group[1];
    const firstShift = shifts[0];
    const total = shifts
      .map((y) => differenceInSeconds(y.ends!.toJSDate(), y.begins!.toJSDate()))
      .reduce((y, z) => y + z, 0);
    const clazz = shifts
      .filter((x) => x.type === ShiftType.Clazz)
      .map((y) => differenceInSeconds(y.ends!.toJSDate(), y.begins!.toJSDate()))
      .reduce((y, z) => y + z, 0);
    const personalTraining = shifts
      .filter((x) => x.type === ShiftType.PersonalTraining)
      .map((y) => differenceInSeconds(y.ends!.toJSDate(), y.begins!.toJSDate()))
      .reduce((y, z) => y + z, 0);
    const unspecified = shifts
      .filter((x) => x.type !== ShiftType.Clazz && x.type !== ShiftType.PersonalTraining)
      .map((y) => differenceInSeconds(y.ends!.toJSDate(), y.begins!.toJSDate()))
      .reduce((y, z) => y + z, 0);

    return {
      id: firstShift.employeeId,
      employeeName: firstShift.employeeName,
      employeeProfileImageUrl: firstShift.employeeProfileImageUrl,
      employeeWageRateId: firstShift.employeeWageRateId,
      isApproved: true,
      shifts: shifts.map((x) => ({
        ...x,
        begins: x.begins,
        ends: x.ends,
      })),
      totalHours: {
        hours: Math.trunc(total / 60 / 60),
        minutes: Math.trunc((total / 60) % 60),
        seconds: total % 60,
      },
      clazzHours: {
        hours: Math.trunc(clazz / 60 / 60),
        minutes: Math.trunc((clazz / 60) % 60),
        seconds: clazz % 60,
      },
      personalTrainingHours: {
        hours: Math.trunc(personalTraining / 60 / 60),
        minutes: Math.trunc((personalTraining / 60) % 60),
        seconds: personalTraining % 60,
      },
      unspecifiedHours: {
        hours: Math.trunc(unspecified / 60 / 60),
        minutes: Math.trunc((unspecified / 60) % 60),
        seconds: unspecified % 60,
      },
    } as SalarySettlement;
  });

  const totalNumberOfShifts = employees.length;

  useEffect(() => {
    const newurl =
      `${window.location.protocol}//${window.location.host}${window.location.pathname}` +
      `?fromDate=${
        filters.fromDate
          ? encodeURIComponent(filters.fromDate.toISO({ includeOffset: false })!)
          : ''
      }&toDate=${
        filters.toDate ? encodeURIComponent(filters.toDate.toISO({ includeOffset: false })!) : ''
      }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [filters]);

  useEffect(() => {
    dispatch(
      getShifts({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: totalNumberOfShifts },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length;

  const handleFilters = useCallback(
    (name: string, value: ISalarySettlementTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleExport = async () => {
    const result = await WagesService.get({
      fromDate: filters.fromDate ?? DateTime.now(),
      toDate: filters.toDate ?? DateTime.now(),
      employeeIds: selected.map((x) => parseInt(x, 10)),
    });

    const blob = new Blob([result], {
      type: 'text/csv',
    });
    saveAs(
      blob,
      `shifts_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );

    setSelected([]);
  };

  const handleApprove = () => {
    const selectedIds = selected.map((x) => parseInt(x, 10));
    const shifts = employees.filter((x) => selectedIds.includes(x.id!));

    Promise.all(
      shifts.map((emp) =>
        EmployeesService.updateShifts({
          id: emp.id,
          body: emp.shifts.map((x) => ({
            employeeId: emp.id,
            shiftId: x.id,
            isApproved: true,
          })),
        })
      )
    ).then(() => {
      setSelected([]);
      dispatch(
        getShifts({
          pageSize: 2 ** 31 - 1,
          pageNumber: 0,
          sortBy: [orderBy],
          direction: order === 'asc' ? Direction.Asc : Direction.Desc,
          fromDate: filters.fromDate,
          toDate: filters.toDate,
        })
      );
    });
  };

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Salary Settlement')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Salary Settlement')}`,
              href: paths.salarySettlement.root,
            },
            { name: `${t('List')}` },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card id="printarea">
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <SalarySettlementTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <SalarySettlementTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfShifts}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={employees.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  employees.map((row) => `${row.id}`)
                )
              }
              action={
                <>
                  <Tooltip title={t('Approve')}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleApprove();
                      }}
                    >
                      <Iconify icon="mdi:check-bold" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('Export')}>
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handleExport();
                      }}
                    >
                      <Iconify icon="solar:export-bold" />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={employees.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      employees.map((row) => `${row.id}`)
                    )
                  }
                />

                <TableBody>
                  {employees.map((row) => (
                    <SalarySettlementItemTableRow
                      key={row.id}
                      row={row}
                      selected={selected.includes(`${row.id}`)}
                      onSelectRow={() => onSelectRow(`${row.id}`)}
                    />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfShifts)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfShifts}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirmDeletion.value}
        onClose={confirmDeletion.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirmDeletion.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
