import { Helmet } from 'react-helmet-async';
// sections
import { VoucherCreateView } from '../../../sections/voucher/view';

// ----------------------------------------------------------------------

export default function VoucherCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new product</title>
      </Helmet>

      <VoucherCreateView />
    </>
  );
}
