import { useCallback, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// components
import Iconify from 'src/components/iconify';
import Link from '@mui/material/Link';
import { TicketDetailsDto } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  tickets: TicketDetailsDto[];
};

export default function EventDetailsTickets({ tickets }: Props) {
  const [approved, setApproved] = useState<number[]>([]);

  const handleClick = useCallback(
    (item: number) => {
      const selected = approved.includes(item)
        ? approved.filter((value) => value !== item)
        : [...approved, item];

      setApproved(selected);
    },
    [approved]
  );

  return (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
      }}
    >
      {tickets.map((ticket) => (
        <TicketItem
          key={ticket.id}
          ticket={ticket}
          selected={approved.includes(ticket.id!)}
          onSelected={() => handleClick(ticket.id!)}
        />
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type TicketItemProps = {
  selected: boolean;
  ticket: TicketDetailsDto;
  onSelected: VoidFunction;
};

function TicketItem({ ticket, selected, onSelected }: TicketItemProps) {
  return (
    <Stack component={Card} direction="row" spacing={2} key={ticket.id} sx={{ p: 3 }}>
      <Avatar
        alt={ticket.participantName}
        src={ticket.participantProfileImageUrl}
        sx={{ width: 48, height: 48 }}
      />

      <Stack spacing={2} flexGrow={1}>
        <ListItemText
          primary={ticket.participantName}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />

        <Stack spacing={1} direction="row">
          <Link href={`tel:${ticket.participantPhoneNumber}`}>
            <IconButton
              size="small"
              color="error"
              sx={{
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                },
              }}
            >
              <Iconify width={18} icon="solar:phone-bold" />
            </IconButton>
          </Link>

          <Link href={`mailto:${ticket.participantEmail}`}>
            <IconButton
              size="small"
              color="primary"
              sx={{
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                },
              }}
            >
              <Iconify width={18} icon="fluent:mail-24-filled" />
            </IconButton>
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
}
