import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ClassDescriptionNewEditForm from '../class-description-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getClassDescription } from '../../../redux/slices/class-description';

// ----------------------------------------------------------------------

export default function ClassDescriptionEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const current = useSelector((state) => state.classDescription.currentDescription);

  const chain = useSelector((state) => state.chain.currentChain);

  useEffect(() => {
    dispatch(
      getClassDescription({
        id: parseInt(id!, 10),
      })
    );
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Class description'),
            href: paths.classDescriptions.root,
          },
          { name: current?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ClassDescriptionNewEditForm currentDescription={current} chain={chain} />
    </Container>
  );
}
