import { useCallback, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
// components
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/iconify';
import Link from '@mui/material/Link';
import { InvoiceStatuses, TicketDetailsDto, TicketsService } from '../../api';
import { useLocales } from '../../locales';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  tickets: TicketDetailsDto[];
  onDelete: VoidFunction;
};

export default function EventDetailsTickets({ tickets, onDelete }: Props) {
  const [approved, setApproved] = useState<number[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const handleClick = useCallback(
    (item: number) => {
      const selected = approved.includes(item)
        ? approved.filter((value) => value !== item)
        : [...approved, item];

      setApproved(selected);
    },
    [approved]
  );

  const handleDelete = useCallback(
    (item: number) => {
      const request = async () => {
        await TicketsService.cancelTicket({
          ticketId: item,
        });

        enqueueSnackbar(t('Ticket cancelled!'));

        onDelete();
      };
      request();
    },
    [onDelete, enqueueSnackbar, t]
  );

  return (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        sm: 'repeat(2, 1fr)',
        md: 'repeat(3, 1fr)',
      }}
    >
      {tickets.map((ticket) => (
        <TicketItem
          key={ticket.id}
          ticket={ticket}
          selected={approved.includes(ticket.id!)}
          onSelected={() => handleClick(ticket.id!)}
          onDelete={() => handleDelete(ticket.id!)}
        />
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type TicketItemProps = {
  selected: boolean;
  ticket: TicketDetailsDto;
  onSelected: VoidFunction;
  onDelete: VoidFunction;
};

function TicketItem({ ticket, selected, onSelected, onDelete }: TicketItemProps) {
  const { t } = useLocales();

  const router = useRouter();

  return (
    <Stack component={Card} direction="row" spacing={2} key={ticket.id} sx={{ p: 3 }}>
      <Avatar
        alt={ticket.participantName}
        src={ticket.participantProfileImageUrl}
        sx={{ width: 48, height: 48 }}
        onClick={() => router.push(paths.members.view(ticket.participantId!))}
      />

      <Stack spacing={2} flexGrow={1}>
        <ListItemText
          primary={ticket.participantName}
          secondary={ticket.participantEmail}
          onClick={() => router.push(paths.members.view(ticket.participantId!))}
          secondaryTypographyProps={{
            mt: 0.5,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
        <Stack spacing={1} direction="column">
          <Stack direction="row" sx={{ typography: 'body2' }}>
            <Iconify icon="solar:ticket-bold-duotone" width={24} sx={{ mr: 1 }} />
            <Stack spacing={1} direction="column">
              <Typography variant="subtitle2">{ticket.ticketTypeName}</Typography>
              {ticket.options?.map((x) => (
                <Stack key={x.name} direction="row">
                  <Typography variant="body2">
                    {x.name}: <b>{t(x.value ?? '')}</b>
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
        <Stack spacing={1} direction="row">
          <Link href={`tel:${ticket.participantPhoneNumber}`}>
            <IconButton
              size="small"
              color="error"
              sx={{
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                },
              }}
            >
              <Iconify width={18} icon="solar:phone-bold" />
            </IconButton>
          </Link>

          <Link href={`mailto:${ticket.participantEmail}`}>
            <IconButton
              size="small"
              color="primary"
              sx={{
                borderRadius: 1,
                bgcolor: (theme) => alpha(theme.palette.primary.main, 0.08),
                '&:hover': {
                  bgcolor: (theme) => alpha(theme.palette.primary.main, 0.16),
                },
              }}
            >
              <Iconify width={18} icon="fluent:mail-24-filled" />
            </IconButton>
          </Link>
          {ticket.paymentStatus === InvoiceStatuses.Paid && (
            <>
              <Link href={paths.members.view(ticket.participantId!)}>
                <Button
                  size="small"
                  color="success"
                  sx={{
                    borderRadius: 1,
                    bgcolor: (theme) => alpha(theme.palette.success.main, 0.08),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.success.main, 0.16),
                    },
                  }}
                >
                  {t(ticket.paymentStatus!)}
                </Button>
              </Link>
            </>
          )}
          {ticket.paymentStatus !== InvoiceStatuses.Paid && (
            <>
              <Link href={paths.members.view(ticket.participantId!)}>
                <Button
                  size="small"
                  color="error"
                  sx={{
                    borderRadius: 1,
                    bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
                    '&:hover': {
                      bgcolor: (theme) => alpha(theme.palette.error.main, 0.16),
                    },
                  }}
                >
                  {t(ticket.paymentStatus!)}
                </Button>
              </Link>
            </>
          )}
        </Stack>
      </Stack>
      <Tooltip title={t('Cancels the ticket')}>
        <IconButton color="error" onClick={onDelete} sx={{ width: 36, height: 36 }}>
          <Iconify icon="solar:trash-bin-trash-bold" />
        </IconButton>
      </Tooltip>
    </Stack>
  );
}
