import { Helmet } from 'react-helmet-async';
// sections
import { EmailTemplateListView } from 'src/sections/email-template/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function EmailTemplateListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Email Template List')}</title>
      </Helmet>

      <EmailTemplateListView />
    </>
  );
}
