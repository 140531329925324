import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import PunchCardNewEditForm from '../punch-card-new-edit-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getPunchCard } from '../../../../redux/slices/membership-type';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

export default function PunchCardEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { t } = useLocales();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const currentType = useSelector((state) => state.membershipType.currentPunchCard);

  useEffect(() => {
    dispatch(getPunchCard(parseInt(id, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Punch card type'),
            href: paths.punchCardType.root,
          },
          { name: currentType?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <PunchCardNewEditForm currentType={currentType} />
    </Container>
  );
}
