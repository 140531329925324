import numeral from 'numeral';

// ----------------------------------------------------------------------

type InputValue = string | number | null;

export function fNumber(number: InputValue) {
  return numeral(number).format();
}

export function fCurrency(number: InputValue | number | undefined, currency = 'DKK') {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  });
  return formatter.format(number ? Number(number) : 0);
}

export function fCurrencyWithoutSymbol(number: InputValue | number | undefined, currency = 'DKK') {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  });
  return formatter
    .format(number ? Number(number) : 0)
    .replace(/[^\d.,-]/g, '')
    .trim();
}

export function fCurrencySymbol(currency = 'DKK') {
  return (0)
    .toLocaleString(navigator.language, {
      style: 'currency',
      currency,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
    .replace(/\d/g, '')
    .trim();
}

export function fPercent(number: InputValue) {
  const format = (() => {
    if (number) {
      return numeral(Number(number) / 100).format('0.0%');
    }

    if (number === 0) {
      return numeral(Number(number) / 100).format('0.0%');
    }

    return '';
  })();

  return result(format, '.0');
}

export function fShortenNumber(number: InputValue) {
  const format = (() => {
    if (number) {
      return numeral(number).format('0.00a');
    }

    if (number === 0) {
      return numeral(number).format('0.00a');
    }

    return '';
  })();

  return result(format, '.00');
}

export function fData(number: InputValue) {
  const format = number ? numeral(number).format('0.0 b') : '';

  return result(format, '.0');
}

function result(format: string, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}
