// @mui
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
// hooks
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// types
import { LeadDto, LeadStatuses, ParticipationStatus } from '../../api';
//
import { useLocales } from '../../locales';
import { fDate } from '../../utils/format-time';
import Label from '../../components/label';

// ----------------------------------------------------------------------

type Props = {
  row: LeadDto;
  onSelectRow: VoidFunction;
};

export default function LeadTableRow({ row, onSelectRow }: Props) {
  const {
    memberName,
    memberEmail,
    memberProfileImageUrl,
    referredByName,
    referredByEmail,
    referredByProfileImageUrl,
    signedUpAt,
    participatedOnDate,
    participatedInClassName,
    status,
    convertedToMembershipName,
    participationStatus,
  } = row;

  const theme = useTheme();

  const { t } = useLocales();

  const isLight = theme.palette.mode === 'light';

  const popover = usePopover();

  const color = (() => {
    if (status === LeadStatuses.Won) return 'success';
    if (status === LeadStatuses.Lost) return 'error';
    return 'default';
  })();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={memberName} src={memberProfileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={memberName}
            secondary={memberEmail}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {fDate(signedUpAt!)}
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <ListItemText
            primary={participatedInClassName}
            secondary={fDate(participatedOnDate!)}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
          {participationStatus && (
            <Label
              variant={isLight ? 'soft' : 'filled'}
              color={
                (participationStatus === ParticipationStatus.OnTime && 'success') ||
                (participationStatus === ParticipationStatus.SignedUp && 'default') ||
                (participationStatus === ParticipationStatus.OnWaitingList && 'warning') ||
                'error'
              }
            >
              {t(`${participationStatus}`)}
            </Label>
          )}
        </TableCell>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          {referredByProfileImageUrl && (
            <Avatar alt={referredByName} src={referredByProfileImageUrl} sx={{ mr: 2 }} />
          )}

          <ListItemText
            primary={referredByName}
            secondary={referredByEmail}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {convertedToMembershipName}
        </TableCell>
        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <Chip label={t(`${status}`)} color={color} />
        </TableCell>
        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
