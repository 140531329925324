import { IFile, IFileManager, IFolderManager } from '../types/file';
import { ProgramTemplateDto } from '../api';

export function arrangeIntoTree(templates: ProgramTemplateDto[], currentPath: string) {
  const items = [] as IFile[];
  templates = templates
    .filter((x) => x.path !== undefined && x.path !== null)
    .filter((x) => x.path?.includes(currentPath))
    .map((x) => ({
      ...x,
      path: x.path?.replace(currentPath, ''),
    }));
  for (let i = 0; i < templates.length; i += 1) {
    const template = templates[i];
    const path = template.path ? template.path.split('/') : [];
    iterateBranch(template, path, items, null);
  }
  return items;
}

function iterateBranch(
  template: ProgramTemplateDto,
  path: string[],
  branch: IFile[],
  parent: IFile | null
) {
  // If path has only one item then it is the file
  if (path.length <= 0 || path[0].trim() === '') {
    const file = {
      id: `${template.id}`,
      name: template.name,
      size: 0,
      type: 'doc',
      url: '',
      tags: [],
      totalFiles: undefined,
      isFavorite: false,
      shared: null,
      createdAt: template.createdDate,
      modifiedAt: template.lastModifiedDate,
      parent,
    } as IFileManager;
    branch.push(file);
  } else {
    // Check if branch already contains sub path otherwise create new branch
    const sub = path[0];
    const filtered = branch.filter((x) => x.name === sub);
    if (filtered.length > 0) {
      const leaf = filtered[0] as IFolderManager;
      iterateBranch(template, path.slice(1), leaf.children!, leaf);
      leaf.totalFiles = leaf.children?.length ?? 0;
    } else {
      const leaf = {
        id: path[0],
        name: path[0],
        size: 0,
        type: 'folder',
        url: '',
        tags: [],
        totalFiles: undefined,
        isFavorite: false,
        shared: null,
        createdAt: template.createdDate,
        modifiedAt: template.lastModifiedDate,
        children: [],
        parent,
      } as IFolderManager;
      branch.push(leaf);
      iterateBranch(template, path.slice(1), leaf.children!, leaf);
      leaf.totalFiles = leaf.children?.length ?? 0;
    }
  }
}
