import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import PersonalTrainingNewEditForm from '../personal-training-new-edit-form';
import { useDispatch, useSelector } from '../../../../redux/store';
import { getPersonalTraining } from '../../../../redux/slices/membership-type';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

export default function PersonalTrainingEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { t } = useLocales();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const currentType = useSelector((state) => state.membershipType.currentPersonalTraining);

  useEffect(() => {
    dispatch(getPersonalTraining(parseInt(id, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Personal training type'),
            href: paths.personalTrainingMembershipType.root,
          },
          { name: currentType?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <PersonalTrainingNewEditForm currentType={currentType} />
    </Container>
  );
}
