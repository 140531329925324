import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// utils
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import NotificationNewEditForm from '../notification-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocales } from '../../../locales';
import { getPushNotification } from '../../../redux/slices/push-notification';

// ----------------------------------------------------------------------

export default function NotificationEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const dispatch = useDispatch();

  const { id } = params;

  const article = useSelector((state) => state.pushNotification.currentNotification);

  useEffect(() => {
    dispatch(getPushNotification({ id: parseInt(id!, 10) }));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Notification'),
            href: paths.notification.root,
          },
          {
            name: article?.subject,
          },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <NotificationNewEditForm currentNotification={article} />
    </Container>
  );
}
