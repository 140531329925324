// @mui
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Unstable_Grid2';
// types
import { IUserProfileFollower } from 'src/types/user';
// components
import Iconify from 'src/components/iconify';
import { AccountDto, ExerciseResultDto } from '../../api';
import TrainingResults from './training-results';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  member: AccountDto;
  exerciseResults: ExerciseResultDto[];
};

export default function ProfileHealth({ member, exerciseResults }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Grid container spacing={5} disableEqualOverflow>
        <Grid xs={12}>
          <TrainingResults
            title={t('Newest exercise results')}
            subheader={t('{{count}} results', { count: exerciseResults.length })}
            list={exerciseResults}
          />
        </Grid>
      </Grid>
    </>
  );
}

// ----------------------------------------------------------------------

type FollowerItemProps = {
  follower: IUserProfileFollower;
  selected: boolean;
  onSelected: VoidFunction;
};

function FollowerItem({ follower, selected, onSelected }: FollowerItemProps) {
  const { name, country, avatarUrl } = follower;

  return (
    <Card
      sx={{
        display: 'flex',
        alignItems: 'center',
        p: (theme) => theme.spacing(3, 2, 3, 3),
      }}
    >
      <Avatar alt={name} src={avatarUrl} sx={{ width: 48, height: 48, mr: 2 }} />

      <ListItemText
        primary={name}
        secondary={
          <>
            <Iconify icon="mingcute:location-fill" width={16} sx={{ flexShrink: 0, mr: 0.5 }} />
            {country} country country country country country country country country country
          </>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle2',
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          noWrap: true,
          display: 'flex',
          component: 'span',
          alignItems: 'center',
          typography: 'caption',
          color: 'text.disabled',
        }}
      />

      <Button
        size="small"
        variant={selected ? 'text' : 'outlined'}
        color={selected ? 'success' : 'inherit'}
        startIcon={
          selected ? <Iconify width={18} icon="eva:checkmark-fill" sx={{ mr: -0.75 }} /> : null
        }
        onClick={onSelected}
        sx={{ flexShrink: 0, ml: 1.5 }}
      >
        {selected ? 'Followed' : 'Follow'}
      </Button>
    </Card>
  );
}
