import { Helmet } from 'react-helmet-async';
import { useLocales } from '../../../locales';
import SaleInsightsView from '../../../sections/insights/sales/view/view';

export default function SaleInsightsPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Insights: Sale')}</title>
      </Helmet>

      <SaleInsightsView />
    </>
  );
}
