import { useCallback, useEffect, useState } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { alpha, useTheme } from '@mui/material/styles';
// routes
import { paths } from 'src/routes/paths';
// components
import { DateTime } from 'luxon';
import { useRouter } from 'src/routes/hook';
import isEqual from 'lodash/isEqual';
//
import { Direction } from '../../../api';
import { useLocales } from '../../../locales';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../../components/table';
import { useSettingsContext } from '../../../components/settings';
import { useBoolean } from '../../../hooks/use-boolean';
import { useDispatch, useSelector } from '../../../redux/store';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import Iconify from '../../../components/iconify';
import Label from '../../../components/label';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import { ConfirmDialog } from '../../../components/custom-dialog';
import HourBankItemTableRow from '../hourbank-table-row';
import HourBankTableToolbar from '../hour-bank-table-toolbar';
import LedgerTableFiltersResult from '../hour-bank-table-filters-result';
import { getHourBank } from '../../../redux/slices/wage';
import { IHourBankTableFilters, IHourBankTableFilterValue } from '../../../types/hour-bank';

// ----------------------------------------------------------------------

const defaultFilters: IHourBankTableFilters = {
  name: '',
  role: [],
  status: 'all',
  fromDate: DateTime.now().startOf('month'),
  toDate: DateTime.now().endOf('month'),
};

// ----------------------------------------------------------------------

export default function EmailTemplateList() {
  const theme = useTheme();

  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'employeeName', label: t('ID'), align: 'left' },
    { id: 'hoursEarned', label: t('Hours earned'), align: 'left' },
    { id: 'hoursUsed', label: t('Hours used'), align: 'left' },
    { id: 'HoursRemaining', label: t('Hours remaining'), align: 'left' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[4].id,
    defaultOrder: 'desc',
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.wage.hourBank);

  const totalNumberOfMemberships = useSelector((state) => state.wage.totalEntriesHourBank);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getHourBank({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        fromDate: filters.fromDate,
        toDate: filters.toDate,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: totalNumberOfMemberships },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IHourBankTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Hour Bank')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Hour Bank')}`,
              href: paths.hourBank.root,
            },
            { name: `${t('List')}` },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Card id="printarea">
          <Tabs
            value={filters.status}
            onChange={handleFilterStatus}
            sx={{
              px: 2.5,
              boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
            }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab
                key={tab.value}
                iconPosition="end"
                value={tab.value}
                label={tab.label}
                icon={
                  <Label
                    variant={
                      ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                    }
                    color={tab.color}
                  >
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>

          <HourBankTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <LedgerTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              canReset={canReset}
              onResetFilters={handleResetFilters}
              //
              results={totalNumberOfMemberships}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData?.map((row) => (
                    <HourBankItemTableRow key={`${row.employeeId}`} row={row} />
                  ))}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMemberships)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={totalNumberOfMemberships}
            page={page}
            rowsPerPage={totalNumberOfMemberships}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
