import { Helmet } from 'react-helmet-async';
// sections
import ProductCategoryListView from '../../../sections/product-categories/view/product-categories-list-view';

// ----------------------------------------------------------------------

export default function ProductCategoryListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product List</title>
      </Helmet>

      <ProductCategoryListView />
    </>
  );
}
