import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProductNewEditForm from '../product-categories-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProductCategory } from '../../../redux/slices/product-categories';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ProductCategoryEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const { t } = useLocales();

  const currentCategory = useSelector((state) => state.productCategory.currentCategory);

  useEffect(() => {
    dispatch(getProductCategory(parseInt(id, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          { name: 'Dashboard', href: paths.dashboard.root },
          {
            name: 'Product categories',
            href: paths.productCategory.root,
          },
          { name: currentCategory?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProductNewEditForm currentCategory={currentCategory} />
    </Container>
  );
}
