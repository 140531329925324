import { Helmet } from 'react-helmet-async';
// sections
import { ContingentListView } from 'src/sections/membership-types/contingent/view';

// ----------------------------------------------------------------------

export default function ContingentTypeListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product List</title>
      </Helmet>

      <ContingentListView />
    </>
  );
}
