import { useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Alert from '@mui/material/Alert';
// routes
import { RouterLink } from 'src/routes/components';
// utils
import { fDateTime } from 'src/utils/format-time';
// components
import { enqueueSnackbar } from 'notistack';
import { DateTime } from 'luxon';
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import {
  InvoicesService,
  InvoiceStatuses,
  OrderDetailsDto,
  OrdersService,
  OrderStatuses,
} from '../../api';
import { useLocales } from '../../locales';
import { paths } from '../../routes/paths';
import { ConfirmDialog } from '../../components/custom-dialog';
import { useBoolean } from '../../hooks/use-boolean';
import { useRouter } from '../../routes/hook';
import { useDispatch } from '../../redux/store';
import { getOrder } from '../../redux/slices/order';

// ----------------------------------------------------------------------

type Props = {
  status: OrderStatuses;
  backLink: string;
  orderNumber: string;
  createdAt: DateTime;
  order: OrderDetailsDto;
};

export default function OrderDetailsToolbar({
  status,
  backLink,
  createdAt,
  orderNumber,
  order,
}: Props) {
  const { t } = useLocales();

  const [errorMessage, setErrorMessage] = useState('');

  const confirm = useBoolean();

  const router = useRouter();

  const dispatch = useDispatch();

  const handlePrintPage: VoidFunction = () => window.print();

  const handleCancelInvoice = async () => {
    setErrorMessage('');
    try {
      await OrdersService.remove({
        orderId: order.id!,
      });
      confirm.onFalse();
      enqueueSnackbar(t('Invoice cancelled!'));
      router.back();
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  };

  const handleSendInvoice = async () => {
    setErrorMessage('');
    try {
      await InvoicesService.sendReceipt({
        invoiceId: order.invoiceId!,
      });
      enqueueSnackbar(t('Invoice sent!'));
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  };

  const handleMarkAsPaid = async () => {
    setErrorMessage('');
    try {
      await InvoicesService.retryPayment({
        id: order.invoiceId!,
        body: {
          id: order.invoiceId!,
          status: InvoiceStatuses.Paid,
        },
      });
      dispatch(getOrder(order.id!));
      enqueueSnackbar(t('Invoice mark as paid!'));
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  };

  const handleRetryPayment = async () => {
    setErrorMessage('');
    try {
      await InvoicesService.retryPayment({
        id: order.invoiceId!,
        body: {
          id: order.invoiceId!,
        },
      });
      dispatch(getOrder(order.id!));
      enqueueSnackbar(t('Payment retried!'));
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  };

  const handleSendReminder = async () => {
    setErrorMessage('');
    try {
      await OrdersService.sendReminder({
        id: order.id!,
        body: {
          orderId: order.id!,
        },
      });
      enqueueSnackbar(t('Reminder sent!'));
    } catch (error) {
      setErrorMessage(error.response.data.detail);
    }
  };

  return (
    <>
      <Stack
        spacing={3}
        direction={{ xs: 'column', md: 'row' }}
        sx={{
          mb: { xs: 1, md: 3 },
        }}
      >
        <Stack spacing={1} direction="row" alignItems="flex-start">
          <IconButton component={RouterLink} href={backLink}>
            <Iconify icon="eva:arrow-ios-back-fill" />
          </IconButton>

          <Stack spacing={0.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Typography variant="h4"> Order {orderNumber} </Typography>
              <Label
                variant="soft"
                color={
                  (status === OrderStatuses.Paid && 'success') ||
                  (status === OrderStatuses.Open && 'warning') ||
                  (status === OrderStatuses.Cancelled && 'error') ||
                  'default'
                }
              >
                {t(status)}
              </Label>
            </Stack>

            <Typography variant="body2" sx={{ color: 'text.disabled' }}>
              {fDateTime(createdAt)}
            </Typography>
          </Stack>
        </Stack>

        <Stack
          flexGrow={1}
          spacing={1.5}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            color="inherit"
            variant="outlined"
            onClick={handlePrintPage}
            startIcon={<Iconify icon="solar:printer-minimalistic-bold" />}
          >
            {t('Print')}
          </Button>
        </Stack>
      </Stack>

      <Stack
        spacing={3}
        direction={{ xs: 'column', sm: 'row' }}
        alignItems={{ xs: 'flex-end', sm: 'center' }}
        sx={{
          mb: { xs: 1, md: 3 },
        }}
      >
        <Stack direction="row" spacing={1} flexGrow={1} sx={{ width: 1 }}>
          <IconButton component={RouterLink} href={paths.invoice.details(order.invoiceId!)}>
            <Iconify icon="iconamoon:invoice-duotone" />
          </IconButton>
          <IconButton component={RouterLink} href={paths.members.view(order.customerId!)}>
            <Iconify icon="solar:user-bold-duotone" />
          </IconButton>

          {status !== OrderStatuses.SentToInkasso && status !== OrderStatuses.Cancelled && (
            <Tooltip title={t('Send')}>
              <IconButton onClick={handleSendInvoice}>
                <Iconify icon="iconamoon:send-fill" />
              </IconButton>
            </Tooltip>
          )}
          {status !== OrderStatuses.Paid && status !== OrderStatuses.Cancelled && (
            <Tooltip title={t('Mark as paid')}>
              <IconButton onClick={handleMarkAsPaid}>
                <Iconify icon="wpf:paid" />
              </IconButton>
            </Tooltip>
          )}
          {status !== OrderStatuses.SentToInkasso &&
            status !== OrderStatuses.Paid &&
            status !== OrderStatuses.Cancelled && (
              <Tooltip title={t('Retry payment')}>
                <IconButton onClick={handleRetryPayment}>
                  <Iconify icon="grommet-icons:update" />
                </IconButton>
              </Tooltip>
            )}
          {status !== OrderStatuses.SentToInkasso &&
            status !== OrderStatuses.Paid &&
            status !== OrderStatuses.Cancelled && (
              <Tooltip title={t('Send payment reminder')}>
                <IconButton onClick={handleSendReminder}>
                  <Iconify icon="mdi:reminder" />
                </IconButton>
              </Tooltip>
            )}
          {status !== OrderStatuses.SentToInkasso &&
            status !== OrderStatuses.Paid &&
            status !== OrderStatuses.Cancelled && (
              <Tooltip title={t('Mark as void')}>
                <IconButton
                  color="error"
                  onClick={() => {
                    confirm.onTrue();
                  }}
                >
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            )}
        </Stack>
      </Stack>

      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            mb: { xs: 1, md: 3 },
          }}
        >
          {errorMessage}
        </Alert>
      )}

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Void invoice')}
        content={t('Are you sure want to cancel this invoice?')}
        action={
          <Button variant="contained" color="error" onClick={handleCancelInvoice}>
            {t('Void')}
          </Button>
        }
      />
    </>
  );
}
