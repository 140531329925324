import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// _mock
// components
import { DateTime } from 'luxon';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFMultiSelect, RHFTextField } from 'src/components/hook-form';
//
import { useSelector, useDispatch } from '../../redux/store';
import ArticleDetailsPreview from './article-details-preview';
import { PushNotificationDto, PushNotificationService } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentNotification?: PushNotificationDto;
};

export default function NotificationNewEditForm({ currentNotification }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const preview = useBoolean();

  const gyms = useSelector((state) => state.gym.gyms);

  const globalGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    subject: Yup.string()
      .max(30, 'Subject must be at most 30 characters')
      .required('Subject is required'),
    body: Yup.string().max(200, 'Body must be at most 200 characters').required('Body is required'),
    sendingTime: Yup.date().required('Sending time is required'),
    availableInGyms: Yup.array().min(1, t('Please select at least one gym')),
  });

  const defaultValues = useMemo(
    () =>
      ({
        subject: currentNotification?.subject || '',
        body: currentNotification?.body || '',
        sendingTime: currentNotification?.sendingTime || DateTime.now(),
        availableInGyms: currentNotification?.availableInGyms?.length
          ? currentNotification?.availableInGyms?.map((e) => `${e}`)
          : [`${globalGym.id}`],
      } as any),
    [currentNotification, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentNotification) {
      reset(defaultValues);
    }

    if (globalGym) {
      reset(defaultValues);
    }
  }, [currentNotification, defaultValues, reset, globalGym]);

  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentNotification) {
        await PushNotificationService.edit({
          id: currentNotification!.id!,
          body: { id: currentNotification!.id!, ...data } as any,
        });
      } else {
        await PushNotificationService.create({ body: { ...data } as any });
      }

      reset();
      preview.onFalse();
      enqueueSnackbar(currentNotification ? t('Update success!') : t('Create success!'));
      router.push(paths.notification.root);
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Subject, content...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="subject" label={t('Subject')} />

            <RHFTextField label={t('Content')} name="body" multiline minRows={4} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Properties')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Additional functions and attributes...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Properties')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Controller
              name="sendingTime"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <DateTimePicker
                  ampm={false}
                  {...field}
                  label={t('Release time')}
                  value={field.value}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      error: !!error,
                      helperText: error?.message,
                    },
                  }}
                />
              )}
            />

            <RHFMultiSelect
              name="availableInGyms"
              label={t('Available in gyms')}
              options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
              checkbox
            />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentNotification ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderProperties}

        {renderActions}
      </Grid>

      <ArticleDetailsPreview
        title={values.subject}
        content={values.body}
        description=""
        coverUrl=""
        //
        open={preview.value}
        isValid={isValid}
        isSubmitting={isSubmitting}
        onClose={preview.onFalse}
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}
