import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import FormControl from '@mui/material/FormControl';
// components
import { DateTime } from 'luxon';
import { saveAs } from 'file-saver';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from '../../locales';
// types
import { ILedgerTableFilters, ILedgerTableFilterValue } from '../../types/accounting';
import { BookkeepingService } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  filters: ILedgerTableFilters;
  onFilters: (name: string, value: ILedgerTableFilterValue) => void;
};

export default function LedgerTableToolbar({ filters, onFilters }: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const handlePrintPage: VoidFunction = () => window.print();

  const handleFilterFrom = useCallback(
    (newValue: DateTime | null) => {
      onFilters('fromDate', newValue);
    },
    [onFilters]
  );

  const handleFilterTo = useCallback(
    (newValue: DateTime | null) => {
      onFilters('toDate', newValue);
    },
    [onFilters]
  );

  const downloadForPeriod = async () => {
    const result = await BookkeepingService.export({
      fromDate: filters.fromDate ?? DateTime.now(),
      toDate: filters.toDate ?? DateTime.now(),
    });

    const blob = new Blob([result], {
      type: 'text/csv',
    });
    saveAs(
      blob,
      `ledger_${filters.fromDate!.toFormat('yyyy-MM-dd')}__${filters.toDate!.toFormat(
        'yyyy-MM-dd'
      )}.csv`
    );
  };

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <DatePicker
              label={t('From')}
              value={filters.fromDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterFrom}
            />
          </FormControl>

          <FormControl
            sx={{
              flexShrink: 0,
              width: { xs: 1, md: 200 },
            }}
          >
            <DatePicker
              label={t('To')}
              value={filters.toDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterTo}
            />
          </FormControl>
          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            handlePrintPage();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          {t('Print')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            downloadForPeriod();
          }}
        >
          <Iconify icon="solar:export-bold" />
          {t('Export')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
