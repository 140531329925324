import { setError } from 'src/redux/slices/error';
import { useLocales } from 'src/locales';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from '../../redux/store';

export default function GlobalErrorDialog() {
  const currentError = useSelector((state) => state.errorMessage.currentError);

  const dispatch = useDispatch();

  const resetError = () => {
    dispatch(setError(null));
  };

  const { t } = useLocales();

  return (
    <>
      {currentError && (
        <Dialog
          open={currentError != null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{t(currentError.title)}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {t(currentError.message)}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={resetError} autoFocus>
              {t('Close')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}
