import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
// components
import { EmployeeDto } from '../../api';
import { useLocales } from '../../locales';
import CustomPopover, { usePopover } from '../../components/custom-popover';
import Iconify from '../../components/iconify';
import { useDispatch, useSelector } from '../../redux/store';
import { getEmployeeFeedbackStats } from '../../redux/slices/employees';
import { RouterLink } from '../../routes/components';
import { PATH_AFTER_LOGIN } from '../../config-global';
import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

interface Props {
  employee: EmployeeDto;
}

export default function FeedbackStats({ employee }: Props) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const periodPopover = usePopover();

  const monthSelectionOptions = [
    {
      value: 1,
      text: t('{{count}} month', { count: 1 }),
    },
    {
      value: 3,
      text: t('{{count}} months', { count: 3 }),
    },
    {
      value: 6,
      text: t('{{count}} months', { count: 6 }),
    },
    {
      value: 12,
      text: t('{{count}} months', { count: 12 }),
    },
  ];

  const handleSelectedOption = useCallback(
    (months: number) => {
      dispatch(getEmployeeFeedbackStats(employee.id!, months));
    },
    [dispatch, employee]
  );

  const [selectedMonth, setSelectedMonth] = useState(3);

  useEffect(() => {
    if (employee.id) {
      dispatch(getEmployeeFeedbackStats(employee.id!, selectedMonth));
    }
  }, [dispatch, selectedMonth, employee]);

  const handleChangeSeries = useCallback(
    (newValue: number) => {
      periodPopover.onClose();
      setSelectedMonth(newValue);
      handleSelectedOption(newValue);
    },
    [periodPopover, handleSelectedOption]
  );

  const feedback = useSelector((state) => state.employee.currentEmployeeFeedbackStats);

  const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
      color: employee?.tint ?? '#ff6d75',
    },
    '& .MuiRating-iconHover': {
      color: employee?.tint ?? '#ff3d47',
    },
  });

  const renderHead = (
    <CardHeader
      title={t('Feedback')}
      subheader={t(
        'Average feedback given for the selected period. Gold is the average of the gym.'
      )}
      action={
        <>
          <ButtonBase
            onClick={periodPopover.onOpen}
            sx={{
              pl: 1,
              py: 0.5,
              pr: 0.5,
              borderRadius: 1,
              typography: 'subtitle2',
              bgcolor: 'background.neutral',
            }}
          >
            {t('{{count}} months', { count: selectedMonth })}

            <Iconify
              width={16}
              icon={
                periodPopover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'
              }
              sx={{ ml: 0.5 }}
            />
          </ButtonBase>
        </>
      }
    />
  );

  return (
    <>
      <Card>
        {renderHead}
        <Stack
          spacing={2}
          sx={{
            p: 3,
            position: 'relative',
          }}
        >
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Overall')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeOverallRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating value={feedback.overallRating ?? 0} size="small" readOnly precision={0.1} />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Coach preparation')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeCoachPreparationRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating
              value={feedback.coachPreparationRating ?? 0}
              size="small"
              readOnly
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Coach observation')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeCoachObservationRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating
              value={feedback.coachObservationRating ?? 0}
              size="small"
              readOnly
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Clarity of instructions')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeClarityOfInstructions ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating
              value={feedback.clarityOfInstructions ?? 0}
              size="small"
              readOnly
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Logistical')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeLogisticalRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating value={feedback.logisticalRating ?? 0} size="small" readOnly precision={0.5} />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Feedback helpfulness')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeFeedbackHelpfulnessRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating
              value={feedback.feedbackHelpfulnessRating ?? 0}
              size="small"
              readOnly
              precision={0.5}
            />
          </Box>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                paddingBottom: 1,
              }}
            >
              {t('Varm-up effectiveness')}
            </Typography>
            <Box>
              <StyledRating
                value={feedback.employeeWarmUpEffectivenessRating ?? 0}
                size="small"
                readOnly
                precision={0.1}
              />
            </Box>
            <Rating
              value={feedback.warmUpEffectivenessRating ?? 0}
              size="small"
              readOnly
              precision={0.5}
            />
          </Box>
          <Button
            component={RouterLink}
            href={`${paths.classFeedback.root}?employeeId=${employee.id}`}
          >
            {t('See more')}
          </Button>
        </Stack>
      </Card>
      <CustomPopover open={periodPopover.open} onClose={periodPopover.onClose} sx={{ width: 140 }}>
        {monthSelectionOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === selectedMonth}
            onClick={() => handleChangeSeries(option.value)}
          >
            {option.text}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
