import { Helmet } from 'react-helmet-async';
// sections
import { ProgramTemplateCreateView } from '../../../sections/personal-program-template/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function PersonalProgramNewPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Program Template Create')}</title>
      </Helmet>

      <ProgramTemplateCreateView />
    </>
  );
}
