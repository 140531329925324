import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFEditor, RHFTextField, RHFUpload } from 'src/components/hook-form';
import { MuiColorInput } from 'mui-color-input';
// types
import {
  ChainDto,
  ClassDescriptionDto,
  ClassDescriptionsService,
  SubscriptionType,
} from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  currentDescription?: ClassDescriptionDto;
  chain: ChainDto;
};

export default function ClassDescriptionNewEditForm({ currentDescription, chain }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [alertMessage, setAlertMessage] = useState('');

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Title is required')),
    description: Yup.string().required(t('Description is required')),
    images: Yup.array().min(1, t('Image is required')),
    externalProgrammingTrack: Yup.string(),
    tint: Yup.string().required(t('Tint is required')),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentDescription?.name || '',
      subtitle: currentDescription?.subtitle || '',
      description: currentDescription?.description || '',
      tint: currentDescription?.tint || '#e1e1e1',
      images: currentDescription?.images?.map((e) => e.url) ?? [],
      externalProgrammingTrack: currentDescription?.externalProgrammingTrack || '',
    }),
    [currentDescription]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentDescription) {
      reset(defaultValues);
    }
  }, [currentDescription, defaultValues, reset]);

  const handleTintChange = (newValue: string) => {
    setValue('tint', newValue);
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentDescription) {
        await ClassDescriptionsService.edit({
          id: currentDescription!.id!,
          body: { id: currentDescription!.id!, ...data } as any,
        });
        await ClassDescriptionsService.setBackgroundImage({
          id: currentDescription!.id!,
          files: data.images ?? [],
        });
      } else {
        const newDescription = await ClassDescriptionsService.create({ body: { ...data } as any });
        await ClassDescriptionsService.setBackgroundImage({
          id: newDescription.id!,
          files: data.images ?? [],
        });
      }

      reset();
      enqueueSnackbar(currentDescription ? t('Update success!') : t('Create success!'));
      router.push(paths.classDescriptions.root);
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });
    },
    [setValue, values.images]
  );

  const handleRemoveFile = useCallback(
    (inputFile: File | string) => {
      const deleted = currentDescription?.images?.filter((file) => file.url === inputFile) ?? [];
      for (let i = 0; i < deleted.length; i += 1) {
        const image = deleted[i];
        if (image) {
          ClassDescriptionsService.removeImage({
            descriptionId: currentDescription!.id!,
            imageId: image?.id!,
          });
        }
      }

      const filtered = values.images?.filter((file) => file !== inputFile) ?? [];
      setValue('images', filtered);
    },
    [setValue, currentDescription, values.images]
  );

  const handleRemoveAllFiles = useCallback(() => {
    const deleted = currentDescription?.images ?? [];
    for (let i = 0; i < deleted.length; i += 1) {
      const image = deleted[i];
      if (image) {
        ClassDescriptionsService.removeImage({
          descriptionId: currentDescription!.id!,
          imageId: image?.id!,
        });
      }
    }
    setValue('images', []);
  }, [setValue, currentDescription]);

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Title, short description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Name')}</Typography>
              <RHFTextField name="name" placeholder={t('Ex: Endurance WOD')} />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Description')}</Typography>
              <RHFEditor simple name="description" />
            </Stack>

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Images')}</Typography>
              <RHFUpload
                multiple
                thumbnail
                name="images"
                maxSize={3145728}
                onDrop={handleDrop}
                onRemove={handleRemoveFile}
                onRemoveAll={handleRemoveAllFiles}
              />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {t('Please use af format of 4:3. Recommended size 2048 × 1536 pixels.')}
              </Typography>
            </Stack>

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('External programming track')}</Typography>
                <RHFTextField name="externalProgrammingTrack" placeholder={t('Ex: WOD Elite')} />
              </Stack>
            )}
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Tint')}</Typography>
              <MuiColorInput value={values.tint} onChange={handleTintChange} format="hex" />
            </Stack>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentDescription ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
