import { useCallback, useEffect, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import Stack, { StackProps } from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
// routes
import { paths } from 'src/routes/paths';
// components
import { RouterLink } from 'src/routes/components';
import { useSettingsContext } from 'src/components/settings';
//
import { useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getGym } from '../../../redux/slices/gym';
import Iconify from '../../../components/iconify';
import GymSetup from '../gym-setup';

// ----------------------------------------------------------------------

export default function GymSetupView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const params = useParams();

  const { id = '' } = params;

  useEffect(() => {
    dispatch(
      getGym({
        id: parseInt(id, 10),
      })
    );
  }, [dispatch, id]);

  const currentGym = useSelector((state) => state.gym.currentGym);

  const gymTabs = [
    { value: 'overview', label: `${t('Overview')}` },
    { value: 'setup', label: `${t('Setup')}` },
  ];

  const [currentTab, setCurrentTab] = useState('overview');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{
        width: 1,
        bottom: 0,
        zIndex: 9,
        position: 'absolute',
        bgcolor: 'background.paper',
        [`& .${tabsClasses.flexContainer}`]: {
          pr: { md: 3 },
          justifyContent: {
            sm: 'center',
            md: 'flex-end',
          },
        },
      }}
    >
      {gymTabs.map((tab) => (
        <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} icon="" />
      ))}
    </Tabs>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <Stack
        spacing={1.5}
        direction="row"
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        <Button
          component={RouterLink}
          href={paths.gyms.view(currentGym?.id ?? -1)}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
        >
          {t('Back')}
        </Button>

        <Box sx={{ flexGrow: 1 }} />
      </Stack>

      <GymSetup currentGym={currentGym} />
    </Container>
  );
}
