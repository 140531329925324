import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Settings } from 'luxon';
import {
  Direction,
  DoorSystemLockService,
  getConfigs,
  GymDto,
  GymsService,
  LocationDto,
  LocationsService,
  LockDto,
  LocksService,
  serviceOptions,
} from '../../api';
import { setError } from './error';
import { KEYS } from '../../utils/constants';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  currentLock: {} as LockDto,
};

const slice = createSlice({
  name: 'gym',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getLockSuccess(state, action) {
      state.currentLock = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLock(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await LocksService.details(params);

      dispatch(slice.actions.getLockSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
