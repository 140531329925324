// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import WageRateNewEditForm from '../wage-rates-new-edit-form';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function WageRateCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new wage rate')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Wage Rate'),
            href: paths.wageRates.root,
          },
          { name: t('New wage rate') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <WageRateNewEditForm />
    </Container>
  );
}
