import { Helmet } from 'react-helmet-async';
// sections
import MemberEditView from 'src/sections/member/view/member-edit-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function MemberEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Member Edit')}</title>
      </Helmet>

      <MemberEditView />
    </>
  );
}
