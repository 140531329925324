import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  ClassTemplateDto,
  ClassTemplatesService,
  Direction,
  getConfigs,
  serviceOptions,
} from '../../api';
import { IClassTemplateStats } from '../../types/class-templates';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  templates: [] as ClassTemplateDto[],
  totalNumberOfTemplates: 0,
  stats: {
    total: 0,
    active: 0,
    inactive: 0,
  } as IClassTemplateStats,
  currentTemplate: {} as ClassTemplateDto,
};

const slice = createSlice({
  name: 'class-template',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassTemplatesSuccess(state, action) {
      state.templates = action.payload.templates;
      state.totalNumberOfTemplates = action.payload.totalNumberOfTemplates;
      state.stats = action.payload.stats;
    },
    getClassTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getClassTemplates(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    isActive?: boolean | null;
    search?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/class-templates';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getClassTemplatesSuccess({
          totalNumberOfTemplates: parseInt(resp.headers['x-total-count'], 10),
          templates: resp.data,
          stats,
        })
      );

      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getClassTemplate(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ClassTemplatesService.getTemplateById(params);

      dispatch(slice.actions.getClassTemplateSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
