import { Helmet } from 'react-helmet-async';
// sections
import { ContingentCreateView } from 'src/sections/membership-types/contingent/view';

// ----------------------------------------------------------------------

export default function ContingentTypeCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new product</title>
      </Helmet>

      <ContingentCreateView />
    </>
  );
}
