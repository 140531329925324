import { useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
// types
// assets
// components
import { setError } from 'src/redux/slices/error';
import FormProvider from 'src/components/hook-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { AccountDto, EmployeeDto, MembershipDto, MembersService } from '../../../api';
import { getPersonalTrainers } from '../../../redux/slices/employees';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onCreate: VoidFunction;
  member: AccountDto;
  membership: MembershipDto;
  personalTrainer: EmployeeDto | null;
};

export default function AddPersonalTrainerForm({
  open,
  onClose,
  onCreate,
  member,
  membership,
  personalTrainer,
}: Props) {
  const { t } = useLocales();

  const settings = useSelector((state) => state.chain.currentSettings);

  const personalTrainers = useSelector((state) => state.employee.personalTrainers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getPersonalTrainers({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
  }, [dispatch]);

  const newSchema = Yup.object().shape({
    personalTrainerId: Yup.number().required('Personal trainer is required'),
    membershipId: Yup.number().required('Membership is required'),
  });

  const defaultValues = useMemo(
    () => ({
      personalTrainerId: personalTrainer?.id ?? -1,
      membershipId: membership.id ?? -1,
    }),
    [membership, personalTrainer]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    control,
    setValue,
    reset,
  } = methods;

  const values = watch();

  useEffect(() => {
    if (membership) {
      reset(defaultValues);
    }
    if (personalTrainer) {
      reset(defaultValues);
    }
  }, [membership, personalTrainer, defaultValues, reset]);

  const handlePersonalTrainerChange = (event: SelectChangeEvent<number>) => {
    const result = parseInt(`${event.target.value}`, 10);
    setValue('personalTrainerId', result, { shouldValidate: true });
  };

  const onSubmit = handleSubmit(async (data) => {
    try {
      await MembersService.addPersonalTrainer({
        memberId: member.id!,
        body: {
          ...data,
        } as any,
      });

      onCreate();
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Add personal trainer')}</DialogTitle>

        <DialogContent dividers>
          <Stack spacing={3}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
              }}
            >
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Instructors')}</Typography>
                <Controller
                  name="personalTrainerId"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <FormControl fullWidth error={!!error}>
                      <Select
                        value={values.personalTrainerId}
                        onChange={handlePersonalTrainerChange}
                        autoWidth={false}
                      >
                        {personalTrainers.map((option) => (
                          <MenuItem
                            key={`membership-type-${option.id}`}
                            value={option.id}
                            sx={{
                              justifyContent: 'space-between',
                            }}
                          >
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                      {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                    </FormControl>
                  )}
                />
              </Stack>
            </Box>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {personalTrainer !== null ? t('Change') : t('Add')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
