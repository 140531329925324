import orderBy from 'lodash/orderBy';
import { useCallback, useEffect, useState } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// utils
import { DateTime } from 'luxon';
import { fTimestamp } from 'src/utils/format-time';
// _mock
import { _tourGuides, _tours, TOUR_SERVICE_OPTIONS } from 'src/_mock';
// assets
import { countries } from 'src/assets/data';
// components
import EmptyContent from 'src/components/empty-content';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { ITourFilterValue, ITourItem } from 'src/types/tour';
//
import ClassFeedbackList from '../class-feedback-list';
import EventSort from '../feedback-sort';
import EventSearch from '../feedback-search';
import EventFilters from '../feedback-filters';
import EventFiltersResult from '../feedback-filters-result';
import { useDispatch, useSelector } from '../../../redux/store';
import { ClassFeedbackDto, Direction } from '../../../api';
import { useLocales } from '../../../locales';
import { getFeedback } from '../../../redux/slices/class-feedback';
import { IFeedbackFilters } from '../../../types/feedback';

// ----------------------------------------------------------------------

const defaultFilters: IFeedbackFilters = {
  title: '',
  startDate: null,
  endDate: null,
  employeeId: null,
};

// ----------------------------------------------------------------------

export default function EventListView() {
  const pageSize = 9;

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const openFilters = useBoolean();

  const { t } = useLocales();

  const [sortBy, setSortBy] = useState('latest');

  const [search, setSearch] = useState<{ query: string; results: ITourItem[] }>({
    query: '',
    results: [],
  });

  const [filters, setFilters] = useState(defaultFilters);

  const dateError =
    filters.startDate && filters.endDate ? filters.startDate > filters.endDate : false;

  const classFeedback = useSelector((state) => state.classFeedback.feedback);

  const totalFeedback = useSelector((state) => state.classFeedback.totalFeedback);

  useEffect(() => {
    dispatch(
      getFeedback({
        pageSize,
        from: filters.startDate,
        to: filters.endDate,
        employeeId: filters.employeeId,
        sortBy: ['createdDate'],
        direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
      })
    );
  }, [dispatch, filters, sortBy]);

  const dataFiltered = applyFilter({
    inputData: classFeedback,
    filters,
    sortBy,
    dateError,
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedSortBy = queryParams.get('sortBy');
    const storedSearch = queryParams.get('search');
    const storedStartDate = queryParams.get('startDate')
      ? decodeURIComponent(queryParams.get('startDate')!)
      : null;
    const storedEndDate = queryParams.get('endDate')
      ? decodeURIComponent(queryParams.get('endDate')!)
      : null;
    const employeeId = queryParams.get('employeeId')
      ? parseInt(queryParams.get('employeeId')!, 10)
      : null;

    setSortBy(storedSortBy ?? 'latest');
    setSearch({ query: storedSearch ?? '', results: [] });
    setFilters({
      title: '',
      startDate:
        storedStartDate !== null
          ? DateTime.fromISO(storedStartDate, {
              zone: 'local',
            })
          : null,
      endDate:
        storedEndDate !== null
          ? DateTime.fromISO(storedEndDate, {
              zone: 'local',
            })
          : null,
      employeeId,
    });
  }, [setSortBy]);

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?sortBy=${sortBy}&search=${search.query ?? ''}&employeeId=${
      filters.employeeId ?? ''
    }&startDate=${
      filters.startDate
        ? encodeURIComponent(filters.startDate.toISO({ includeOffset: false })!)
        : ''
    }&endDate=${
      filters.endDate ? encodeURIComponent(filters.endDate.toISO({ includeOffset: false })!) : ''
    }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [filters, sortBy, search]);

  const canReset = !!filters.title.length || (!!filters.startDate && !!filters.endDate);

  const notFound = !dataFiltered.length && canReset;

  const handleFilters = useCallback((name: string, value: ITourFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const handleSortBy = useCallback((newValue: string) => {
    setSortBy(newValue);
  }, []);

  const handleSearch = useCallback(
    (inputValue: string) => {
      setSearch((prevState) => ({
        ...prevState,
        query: inputValue,
      }));

      if (inputValue) {
        const results = _tours.filter(
          (tour) => tour.name.toLowerCase().indexOf(search.query.toLowerCase()) !== -1
        );

        setSearch((prevState) => ({
          ...prevState,
          results,
        }));
      }
    },
    [search.query]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handlePageChange = useCallback(
    (value: number) => {
      dispatch(
        getFeedback({
          pageNumber: value - 1,
          pageSize,
          from: filters.startDate,
          to: filters.endDate,
          sortBy: ['createdDate'],
          direction: sortBy === 'latest' ? Direction.Desc : Direction.Asc,
        })
      );
    },
    [dispatch, filters, sortBy]
  );

  const FEEDBACK_SORT_OPTIONS = [
    { value: 'latest', label: t('Latest') },
    { value: 'oldest', label: t('Oldest') },
  ];

  const renderFilters = (
    <Stack
      spacing={3}
      justifyContent="space-between"
      alignItems={{ xs: 'flex-end', sm: 'center' }}
      direction={{ xs: 'column', sm: 'row' }}
    >
      <EventSearch
        query={search.query}
        results={search.results}
        onSearch={handleSearch}
        hrefItem={(id: string) => paths.dashboard.tour.details(id)}
      />

      <Stack direction="row" spacing={1} flexShrink={0}>
        <EventFilters
          open={openFilters.value}
          onOpen={openFilters.onTrue}
          onClose={openFilters.onFalse}
          //
          filters={filters}
          onFilters={handleFilters}
          //
          canReset={canReset}
          onResetFilters={handleResetFilters}
          //
          serviceOptions={TOUR_SERVICE_OPTIONS.map((option) => option.label)}
          tourGuideOptions={_tourGuides}
          destinationOptions={countries}
          //
          dateError={dateError}
        />

        <EventSort sort={sortBy} onSort={handleSortBy} sortOptions={FEEDBACK_SORT_OPTIONS} />
      </Stack>
    </Stack>
  );

  const renderResults = (
    <EventFiltersResult
      filters={filters}
      onResetFilters={handleResetFilters}
      //
      canReset={canReset}
      onFilters={handleFilters}
      //
      results={dataFiltered.length}
    />
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading="Feedback List"
        links={[
          { name: `${t('Dashboard')}`, href: paths.dashboard.root },
          {
            name: `${t('Feedback')}`,
            href: paths.classFeedback.root,
          },
          { name: `${t('List')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <Stack
        spacing={2.5}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      >
        {renderFilters}

        {canReset && renderResults}
      </Stack>

      {notFound && <EmptyContent title={t('No Data')} filled sx={{ py: 10 }} />}

      <ClassFeedbackList
        classFeedback={classFeedback}
        totalEvents={totalFeedback}
        pageSize={pageSize}
        handlePageChange={handlePageChange}
      />
    </Container>
  );
}

// ----------------------------------------------------------------------

const applyFilter = ({
  inputData,
  filters,
  sortBy,
  dateError,
}: {
  inputData: ClassFeedbackDto[];
  filters: IFeedbackFilters;
  sortBy: string;
  dateError: boolean;
}) => {
  const { title, startDate, endDate } = filters;

  // SORT BY
  if (sortBy === 'latest') {
    inputData = orderBy(inputData, ['createdAt'], ['desc']);
  }

  if (sortBy === 'oldest') {
    inputData = orderBy(inputData, ['createdAt'], ['asc']);
  }

  // FILTERS
  if (!dateError) {
    if (startDate && endDate) {
      inputData = inputData.filter(
        (event) =>
          fTimestamp(event.createdDate) >= fTimestamp(startDate) &&
          fTimestamp(event.createdDate) <= fTimestamp(endDate)
      );
    }
  }

  if (title.length) {
    inputData = inputData.filter((event) => title.includes(event.classTitle ?? ''));
  }

  return inputData;
};
