import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// routes
import { useNavigate } from 'react-router-dom';
import { paths } from '../../routes/paths';
//
import GymItem from './gym-item';
import { GymDto } from '../../api';
// ----------------------------------------------------------------------

type Props = {
  gyms: GymDto[];
};

export default function GymList({ gyms }: Props) {
  const navigate = useNavigate();

  const handleView = (id: number) => {
    navigate(paths.gyms.view(id));
  };

  const handleEdit = (id: number) => {
    navigate(paths.gyms.edit(id));
  };

  const handleDelete = useCallback((id: number) => {
    console.info('DELETE', id);
  }, []);

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {gyms.map((gym) => (
          <GymItem
            key={gym.id}
            gym={gym}
            onView={() => handleView(gym.id!)}
            onEdit={() => handleEdit(gym.id!)}
            onDelete={() => handleDelete(gym.id!)}
          />
        ))}
      </Box>

      {gyms.length > 8 && (
        <Pagination
          count={8}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
