import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProgramTemplateNewEditForm from '../program-template-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getWorkoutTypes } from '../../../redux/slices/program-template';

// ----------------------------------------------------------------------

export default function ProgramTemplateCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const workoutTypes = useSelector((state) => state.programTemplate.workoutTypes);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWorkoutTypes());
  }, [dispatch]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Program Template')}`,
            href: paths.programTemplates.root,
          },
          { name: t('New program template') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProgramTemplateNewEditForm workoutTypes={workoutTypes} />
    </Container>
  );
}
