// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { usePopover } from 'src/components/custom-popover';
// types
import { LedgerEntryDto } from '../../api';
//
import { useLocales } from '../../locales';
import { fDate } from '../../utils/format-time';
import { fNumber } from '../../utils/format-number';

// ----------------------------------------------------------------------

type Props = {
  row: LedgerEntryDto;
};

export default function LedgerItemTableRow({ row }: Props) {
  const { date, description, accountNumber, againstAccountNumber, amount, invoiceId } = row;

  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            primary={invoiceId}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left">{description}</TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          {accountNumber}
        </TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          {againstAccountNumber}
        </TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          {fNumber(amount ?? 0)}
        </TableCell>

        <TableCell align="right" sx={{ textTransform: 'capitalize' }}>
          {fDate(date)}
        </TableCell>
      </TableRow>
    </>
  );
}
