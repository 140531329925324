import { useRef } from 'react';
// @mui
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { TableProps } from 'src/components/table';
//
import ReportsItem from './reports-item';
import { useLocales } from '../../locales';
import { IReport } from '../../types/report';
import ReportsPanel from './reports-panel';

// ----------------------------------------------------------------------

type Props = {
  table: TableProps;
  data: IReport[];
  dataFiltered: IReport[];
  onOpenConfirm: VoidFunction;
  onDeleteItem: (id: string) => void;
};

export default function ReportsGridView({
  table,
  data,
  dataFiltered,
  onDeleteItem,
  onOpenConfirm,
}: Props) {
  const { selected, onSelectRow: onSelectItem, onSelectAllRows: onSelectAllItems } = table;
  const { t } = useLocales();
  const containerRef = useRef(null);

  const upload = useBoolean();

  const members = useBoolean();

  const memberships = useBoolean();

  return (
    <Box ref={containerRef}>
      <ReportsPanel
        title={t('Members')}
        subTitle={t(`{{count}} reports`, {
          count: data.filter((item) => item.type === 'member').length,
        })}
        onOpen={upload.onTrue}
        collapse={members.value}
        onCollapse={members.onToggle}
      />

      <Collapse in={!members.value} unmountOnExit>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={3}
        >
          {dataFiltered
            .filter((i) => i.type === 'member')
            .map((file) => (
              <ReportsItem
                key={file.id}
                file={file}
                selected={selected.includes(file.id)}
                onSelect={() => onSelectItem(file.id)}
                onDelete={() => onDeleteItem(file.id)}
                sx={{ maxWidth: 'auto' }}
              />
            ))}
        </Box>
      </Collapse>
      <ReportsPanel
        title={t('Memberships')}
        subTitle={t(`{{count}} reports`, {
          count: data.filter((item) => item.type === 'membership').length,
        })}
        sx={{ mt: 5 }}
        onOpen={upload.onTrue}
        collapse={memberships.value}
        onCollapse={memberships.onToggle}
      />

      <Collapse in={!memberships.value} unmountOnExit>
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
          }}
          gap={3}
        >
          {dataFiltered
            .filter((i) => i.type === 'membership')
            .map((file) => (
              <ReportsItem
                key={file.id}
                file={file}
                selected={selected.includes(file.id)}
                onSelect={() => onSelectItem(file.id)}
                onDelete={() => onDeleteItem(file.id)}
                sx={{ maxWidth: 'auto' }}
              />
            ))}
        </Box>
      </Collapse>
    </Box>
  );
}
