// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
// components
import Image from 'src/components/image';
import { FileDto, InstructorDto } from '../../api';
import { useLocales } from '../../locales';
import { AvatarShape } from '../../assets/illustrations';

// ----------------------------------------------------------------------

type Props = {
  instructors: InstructorDto[];
  coverImages: FileDto[];
};

export default function ClassInstructors({ instructors, coverImages }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Typography variant="h4" sx={{ my: 5 }}>
        {t('Instructors')}
      </Typography>

      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {instructors.map((p) => (
          <InstructorItem
            key={p.id}
            instructor={p}
            coverImage={coverImages[Math.floor(Math.random() * coverImages.length)]}
          />
        ))}
      </Box>
    </>
  );
}

// ----------------------------------------------------------------------

type InstructorItemProps = {
  instructor: InstructorDto;
  coverImage?: FileDto;
};

function InstructorItem({ instructor, coverImage }: InstructorItemProps) {
  const theme = useTheme();

  const { name, title, profileImageUrl } = instructor;

  return (
    <Card sx={{ textAlign: 'center' }}>
      <Box sx={{ position: 'relative' }}>
        <AvatarShape
          sx={{
            left: 0,
            right: 0,
            zIndex: 10,
            mx: 'auto',
            bottom: -26,
            position: 'absolute',
          }}
        />

        <Avatar
          alt={name}
          src={profileImageUrl}
          sx={{
            width: 64,
            height: 64,
            zIndex: 11,
            left: 0,
            right: 0,
            bottom: -32,
            mx: 'auto',
            position: 'absolute',
          }}
        />

        <Image
          src={profileImageUrl ?? coverImage?.url ?? ''}
          alt={name ?? ''}
          ratio="16/9"
          overlay={alpha(theme.palette.grey[900], 0.48)}
        />
      </Box>

      <ListItemText
        sx={{ mt: 7, mb: 1 }}
        primary={name}
        secondary={title}
        primaryTypographyProps={{ typography: 'subtitle1' }}
        secondaryTypographyProps={{ component: 'span', mt: 0.5 }}
      />

      <Divider sx={{ borderStyle: 'dashed' }} />
    </Card>
  );
}
