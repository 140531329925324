import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  Direction,
  getConfigs,
  InvoiceDto,
  InvoicesService,
  InvoiceStatuses,
  serviceOptions,
  TransactionDto,
} from '../../api';
import { IInvoiceStats } from '../../types/invoice';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  invoices: [] as InvoiceDto[],
  invoicesMissingPayments: [] as InvoiceDto[],
  currentInvoice: {} as InvoiceDto,
  currentInvoiceTransactions: [] as TransactionDto[],
  stats: {
    totalNumberOfInvoices: 0,
  } as IInvoiceStats,
};

const slice = createSlice({
  name: 'invoice',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getInvoicesSuccess(state, action) {
      state.invoices = action.payload.invoices;
      state.stats = action.payload.stats;
    },
    getInvoiceSuccess(state, action) {
      state.currentInvoice = action.payload;
    },
    getInvoiceTransactionsSuccess(state, action) {
      state.currentInvoiceTransactions = action.payload;
    },
    getInvoicesWithMissingPaymentSuccess(state, action) {
      state.invoicesMissingPayments = action.payload.invoices;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getInvoices(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    statuses?: InvoiceStatuses[];
    startDate?: DateTime | null;
    endDate?: DateTime | null;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/invoices';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getInvoicesSuccess({
          stats: {
            totalNumberOfInvoices: parseInt(resp.headers['x-total-count'], 10),
            totalAllCount: stats.all,
            totalDraftCount: stats.draft,
            totalOpenCount: stats.open,
            totalPaidCount: stats.paid,
            totalUncollectibleCount: stats.uncollectible,
            totalVoidCount: stats.void,
            totalInkassoCount: stats.inkasso,
            totalExpiredCount: stats.expired,
            totalAuthorizedCount: stats.authorized,
            totalFailedCount: stats.failed,
          },
          invoices: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getInvoice(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await InvoicesService.getById({
        id,
      });
      dispatch(slice.actions.getInvoiceSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getInvoiceTransactions(invoiceId: number) {
  return async (dispatch: Dispatch) => {
    try {
      const response = await InvoicesService.getTransactions({
        id: invoiceId,
      });
      dispatch(slice.actions.getInvoiceTransactionsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getInvoicesWithMissingPayments(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    statuses?: InvoiceStatuses[];
    startDate?: Date | null;
    endDate?: Date | null;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/invoices';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getInvoicesWithMissingPaymentSuccess({
          invoices: resp.data,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
