import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { ContractDto, ContractsService, Direction, getConfigs, serviceOptions } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  contracts: [] as ContractDto[],
  currentContract: {} as ContractDto,
};

const slice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getContractsSuccess(state, action) {
      state.contracts = action.payload.gyms;
    },
    getContractSuccess(state, action) {
      state.currentContract = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContracts(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    statuses?: string[] | null[];
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // throw new Error('Parameter is not a number!-contract');

      const url = '/api/contracts';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getContractsSuccess({
          gyms: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getContract(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ContractsService.getById(params);

      dispatch(slice.actions.getContractSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

// ----------------------------------------------------------------------
