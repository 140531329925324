import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// _mock
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import MemberNewEditForm from '../member-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getMember } from '../../../redux/slices/members';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function MemberEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const { id } = params;

  const dispatch = useDispatch();

  const currentMember = useSelector((state) => state.member.currentMember);

  useEffect(() => {
    dispatch(getMember(parseInt(id!, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Customer'),
            href: paths.members.root,
          },
          { name: currentMember?.fullName },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <MemberNewEditForm currentMember={currentMember} />
    </Container>
  );
}
