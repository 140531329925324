import QRCode from 'react-qr-code';
// @mui
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { CardActions } from '@mui/material';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Unstable_Grid2';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
// components
// types
import { GymDto } from '../../api';
import { useLocales } from '../../locales';
import Iconify from '../../components/iconify';
import { RouterLink } from '../../routes/components';
import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  gym: GymDto;
};

export default function GymAccess({ gym }: Props) {
  const { t } = useLocales();

  const onImageDownload = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg!);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx!.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = 'QRCode';
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const renderHead = (
    <>
      <Stack direction="row">
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {t('Locks')}
        </Typography>
      </Stack>
    </>
  );

  return (
    <>
      <Stack>
        {renderHead}

        <Divider sx={{ borderStyle: 'dashed', my: 5 }} />

        <Grid container spacing={3}>
          <Grid xs={12} md={6} lg={8}>
            <Stack spacing={3}>
              {gym.locks?.map((item) => (
                <Card key={`lock-${item.id}`}>
                  <CardHeader
                    title={item.name}
                    subheader={`${item.externalId}`}
                    action={
                      <IconButton component={RouterLink} href={paths.locks.edit(item?.id!)}>
                        <Iconify icon="solar:settings-outline" />
                      </IconButton>
                    }
                  />
                  <CardContent>
                    <Stack spacing={3}>
                      <Stack spacing={1.5}>
                        <Typography variant="h6">{t('Settings')} </Typography>
                        <FormGroup>
                          <Tooltip
                            title={t('Locks the door automatically after 15 seconds af opening')}
                          >
                            <FormControlLabel
                              control={<Switch defaultChecked={item.useAutoLock} disabled />}
                              label={t('Use auto lock')}
                            />
                          </Tooltip>
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={item.automaticallyLockDoorsOutsideOpeningHours}
                                disabled
                              />
                            }
                            label={t('Automatically lock doors outside opening hours')}
                          />
                          <FormControlLabel
                            control={
                              <Switch
                                defaultChecked={item.automaticallyUnlockDoorsInsideOpeningHours}
                                disabled
                              />
                            }
                            label={t('Automatically unlock doors inside opening hours')}
                          />
                        </FormGroup>
                      </Stack>
                      <Stack spacing={1.5}>
                        {item.openingHours && item.openingHours!.length > 0 && (
                          <Typography variant="h6">{t('Opening hours')} </Typography>
                        )}
                        {item.openingHours?.map((oh, index) => (
                          <Stack spacing={2} key={`${item.id}-${oh.dayOfWeek}-${oh.from}-${oh.to}`}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                textTransform: 'capitalize',
                              }}
                            >
                              {t(`${oh.dayOfWeek}`)}
                            </Typography>
                            <Stack direction="row" spacing={1.5}>
                              <TimePicker label={t('Open')} value={oh.from} ampm={false} disabled />

                              <TimePicker label={t('Closed')} value={oh.to} ampm={false} disabled />
                            </Stack>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            <Card>
              <CardHeader title={t('QR code')} subheader={t('QR code used for class check in')} />
              <CardContent>
                <QRCode
                  id="QRCode"
                  value={gym.checkInQrCode ?? ''}
                  size={128}
                  style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                />
              </CardContent>
              <CardActions>
                <Button onClick={onImageDownload}>{t('Download')}</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
