import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventClickArg, EventDropArg } from '@fullcalendar/core';
import { EventResizeDoneArg } from '@fullcalendar/interaction';
import { useCallback, useEffect, useRef, useState } from 'react';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { DateTime } from 'luxon';
import { fTimestamp } from 'src/utils/format-time';
// types
import { ICalendarEvent, ICalendarRange, ICalendarView } from 'src/types/calendar';
import {
  addDays,
  addMonths,
  addWeeks,
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfWeek,
} from 'date-fns';
import { useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export type UseCalendarProps = {
  defaultDate?: DateTime;
  defaultView?: ICalendarView;
};

export default function useCalendar(props?: UseCalendarProps) {
  const selectedGym = useSelector((state) => state.gym.globalGym);

  const calendarRef = useRef<FullCalendar>(null);

  const calendarEl = calendarRef.current;

  useEffect(() => {
    calendarEl?.getApi().setOption('timeZone', selectedGym?.timeZone ?? 'UTC');
  }, [selectedGym, calendarEl]);

  // Start of week is monday
  calendarEl?.getApi()?.setOption('firstDay', 1);

  const smUp = useResponsive('up', 'sm');

  const [date, setDate] = useState((props?.defaultDate ?? DateTime.now()).startOf('week'));

  const [startDate, setStartDate] = useState(
    (props?.defaultDate ?? DateTime.now()).startOf('week')
  );

  const [endDate, setEndDate] = useState((props?.defaultDate ?? DateTime.now()).endOf('week'));

  const [openForm, setOpenForm] = useState(false);

  const [selectEventId, setSelectEventId] = useState('');

  const [selectedRange, setSelectedRange] = useState<ICalendarRange>(null);

  const [view, setView] = useState<ICalendarView>(
    props?.defaultView ?? (smUp ? 'timeGridWeek' : 'timeGridDay')
  );

  const onOpenForm = useCallback(() => {
    setOpenForm(true);
  }, []);

  const onCloseForm = useCallback(() => {
    setOpenForm(false);
    setSelectedRange(null);
    setSelectEventId('');
  }, []);

  const onInitialView = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      const newView = smUp ? 'timeGridWeek' : 'listWeek';
      calendarApi.changeView(newView);
      setView(newView);
    }
  }, [calendarEl, smUp]);

  const onChangeView = useCallback(
    (newView: ICalendarView) => {
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();

        calendarApi.changeView(newView);
        setView(newView);

        const currentDate = calendarApi.getDate();

        if (newView === 'timeGridWeek' || newView === 'listWeek' || newView === 'dayGridWeek') {
          setStartDate(DateTime.fromJSDate(currentDate).startOf('week'));
          setEndDate(DateTime.fromJSDate(currentDate).endOf('week'));
        } else if (newView === 'dayGridMonth') {
          setStartDate(DateTime.fromJSDate(currentDate).startOf('month'));
          setEndDate(DateTime.fromJSDate(currentDate).endOf('month'));
        } else if (newView === 'timeGridDay' || newView === 'dayGridDay') {
          setStartDate(DateTime.fromJSDate(currentDate).startOf('day'));
          setEndDate(DateTime.fromJSDate(currentDate).endOf('day'));
        }
      }
    },
    [calendarEl]
  );

  const onDateToday = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.today();
      setDate(DateTime.fromJSDate(calendarApi.getDate()));
    }
  }, [calendarEl]);

  const onDatePrev = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.prev();
      const currentDate = calendarApi.getDate();
      setDate(DateTime.fromJSDate(currentDate));

      if (view === 'timeGridWeek' || view === 'listWeek' || view === 'dayGridWeek') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('week'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('week'));
      } else if (view === 'dayGridMonth') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('month'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('month'));
      } else if (view === 'timeGridDay' || view === 'dayGridDay') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('day'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('day'));
      }
    }
  }, [calendarEl, view]);

  const onDateNext = useCallback(() => {
    if (calendarEl) {
      const calendarApi = calendarEl.getApi();

      calendarApi.next();
      const currentDate = calendarApi.getDate();

      setDate(DateTime.fromJSDate(currentDate));

      if (view === 'timeGridWeek' || view === 'listWeek' || view === 'dayGridWeek') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('week'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('week'));
      } else if (view === 'dayGridMonth') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('month'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('month'));
      } else if (view === 'timeGridDay' || view === 'dayGridDay') {
        setStartDate(DateTime.fromJSDate(currentDate).startOf('day'));
        setEndDate(DateTime.fromJSDate(currentDate).endOf('day'));
      }
    }
  }, [calendarEl, view]);

  const onSelectRange = useCallback(
    (arg: DateSelectArg) => {
      if (calendarEl) {
        const calendarApi = calendarEl.getApi();

        calendarApi.unselect();
      }
      onOpenForm();
      setSelectedRange({
        start: fTimestamp(arg.start),
        end: fTimestamp(arg.end),
      });
    },
    [calendarEl, onOpenForm]
  );

  const onClickEvent = useCallback(
    (arg: EventClickArg) => {
      const { event } = arg;

      onOpenForm();
      setSelectEventId(event.id);
    },
    [onOpenForm]
  );

  const onResizeEvent = useCallback(
    (arg: EventResizeDoneArg, updateEvent: (eventData: Partial<ICalendarEvent>) => void) => {
      const { event } = arg;

      updateEvent({
        id: event.id,
        allDay: event.allDay,
        start: fTimestamp(event.start),
        end: fTimestamp(event.end),
      });
    },
    []
  );

  const onDropEvent = useCallback(
    (arg: EventDropArg, updateEvent: (eventData: Partial<ICalendarEvent>) => void) => {
      const { event } = arg;

      updateEvent({
        id: event.id,
        allDay: event.allDay,
        start: fTimestamp(event.start),
        end: fTimestamp(event.end),
      });
    },
    []
  );

  const onClickEventInFilters = useCallback(
    (eventId: string) => {
      if (eventId) {
        onOpenForm();
        setSelectEventId(eventId);
      }
    },
    [onOpenForm]
  );

  return {
    calendarRef,
    //
    view,
    date,
    startDate,
    endDate,
    //
    onDatePrev,
    onDateNext,
    onDateToday,
    onDropEvent,
    onClickEvent,
    onChangeView,
    onSelectRange,
    onResizeEvent,
    onInitialView,
    //
    openForm,
    onOpenForm,
    onCloseForm,
    //
    selectEventId,
    setSelectEventId,
    selectedRange,
    //
    onClickEventInFilters,
  };
}
