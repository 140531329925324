import { useCallback } from 'react';
import { m } from 'framer-motion';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// utils
import { fDate } from 'src/utils/format-time';
import { enqueueSnackbar } from 'notistack';
// components
import { DateTime } from 'luxon';
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import { varTranHover } from 'src/components/animate';
import Lightbox, { useLightBox } from 'src/components/lightbox';
import { EventDetailsDto, EventService, NewsService } from '../../api';
import { useLocales } from '../../locales';
import { useSelector } from '../../redux/store';
import { useBoolean } from '../../hooks/use-boolean';
import { ConfirmDialog } from '../../components/custom-dialog';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  event: EventDetailsDto;
};

export default function EventDetailsContent({ event }: Props) {
  const { t } = useLocales();

  const router = useRouter();

  const confirmCreateNews = useBoolean();

  const { title, description, closedForSignUpAt, openForSignUpAt, begins, ends, images } = event;

  const slides = images?.map((slide) => ({
    src: slide.url ?? '',
  })) ?? [{ src: '' }];

  const chain = useSelector((state) => state.chain.currentChain);

  const confirm = useBoolean();

  const {
    selected: selectedImage,
    open: openLightbox,
    onOpen: handleOpenLightbox,
    onClose: handleCloseLightbox,
  } = useLightBox(slides);

  const handleShare = () =>
    navigator.share({
      url: `https://${chain.domain}/Ticket/Register?eventid=${event.id}`,
      title: event.title,
      text: event.shortDescription,
    });

  const handleDeletion = useCallback(async () => {
    await EventService.cancel({
      id: event.id!,
    });

    enqueueSnackbar(t('Event cancelled!'));
    router.push(paths.events.root);
  }, [event, router, t]);

  const handleCreateNews = async () => {
    await NewsService.create({
      body: {
        body: event.description,
        title: event.title,
        publishedOn: DateTime.now(),
        imageId: (event.images?.length ?? 0) > 0 ? event.images![0].id : undefined,
        availableInGyms: event.gymId !== undefined ? [event.gymId!] : [],
      },
    });

    enqueueSnackbar(t('News article created!'));
  };

  const renderGallery = (
    <>
      {slides.length > 0 && (
        <Box
          gap={1}
          display="grid"
          gridTemplateColumns={{
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
          }}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        >
          <m.div
            key={slides[0].src}
            whileHover="hover"
            variants={{
              hover: { opacity: 0.8 },
            }}
            transition={varTranHover()}
          >
            <Image
              alt={slides[0].src}
              src={slides[0].src}
              ratio="1/1"
              onClick={() => handleOpenLightbox(slides[0].src)}
              sx={{ borderRadius: 2, cursor: 'pointer' }}
            />
          </m.div>

          <Box gap={1} display="grid" gridTemplateColumns="repeat(2, 1fr)">
            {slides.slice(1, 5).map((slide) => (
              <m.div
                key={slide.src}
                whileHover="hover"
                variants={{
                  hover: { opacity: 0.8 },
                }}
                transition={varTranHover()}
              >
                <Image
                  alt={slide.src}
                  src={slide.src}
                  ratio="1/1"
                  onClick={() => handleOpenLightbox(slide.src)}
                  sx={{ borderRadius: 2, cursor: 'pointer' }}
                />
              </m.div>
            ))}
          </Box>
        </Box>
      )}

      <Lightbox
        index={selectedImage}
        slides={slides}
        open={openLightbox}
        close={handleCloseLightbox}
      />
    </>
  );

  const renderHead = (
    <>
      <Stack direction="row" sx={{ mb: 3 }}>
        <Typography variant="h4" sx={{ flexGrow: 1 }}>
          {title}
        </Typography>

        <Tooltip title={t('Create event news article')}>
          <IconButton onClick={confirmCreateNews.onTrue}>
            <Iconify icon="ion:newspaper" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('Share event link')}>
          <IconButton onClick={handleShare}>
            <Iconify icon="solar:share-bold" />
          </IconButton>
        </Tooltip>

        <Tooltip title={t('Cancel event')}>
          <IconButton color="error" onClick={confirm.onTrue}>
            <Iconify icon="solar:trash-bin-trash-bold" />
          </IconButton>
        </Tooltip>
      </Stack>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Cancel event')}
        content={
          <>
            Are you sure want to cancel <strong>{event.title}</strong>?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleDeletion();
              confirm.onFalse();
            }}
          >
            {t('Cancel event')}
          </Button>
        }
      />

      <ConfirmDialog
        open={confirmCreateNews.value}
        onClose={confirmCreateNews.onFalse}
        title={t('Create event news article')}
        content={<>{t('Are you sure you want to create a news article for this event?')}</>}
        action={
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              confirmCreateNews.onFalse();
              handleCreateNews();
            }}
          >
            {t('Create')}
          </Button>
        }
      />
    </>
  );

  const renderOverview = (
    <Box
      gap={3}
      display="grid"
      gridTemplateColumns={{
        xs: 'repeat(1, 1fr)',
        md: 'repeat(2, 1fr)',
      }}
    >
      {[
        {
          label: t('Available'),
          value: `${fDate(openForSignUpAt)} - ${fDate(closedForSignUpAt)}`,
          icon: <Iconify icon="solar:calendar-date-bold" />,
        },
        {
          label: t('Durations'),
          value: `${fDate(begins)} - ${fDate(ends)}`,
          icon: <Iconify icon="solar:clock-circle-bold" />,
        },
      ].map((item) => (
        <Stack key={item.label} spacing={1.5} direction="row">
          {item.icon}
          <ListItemText
            primary={item.label}
            secondary={item.value}
            primaryTypographyProps={{
              typography: 'body2',
              color: 'text.secondary',
              mb: 0.5,
            }}
            secondaryTypographyProps={{
              typography: 'subtitle2',
              color: 'text.primary',
              component: 'span',
            }}
          />
        </Stack>
      ))}
    </Box>
  );

  const renderContent = (
    <>
      <Markdown children={description ?? ''} />
    </>
  );

  return (
    <>
      {renderGallery}

      <Stack sx={{ maxWidth: 720, mx: 'auto' }}>
        {renderHead}

        <Divider sx={{ borderStyle: 'dashed', my: 5 }} />

        {renderOverview}

        <Divider sx={{ borderStyle: 'dashed', my: 5 }} />

        {renderContent}
      </Stack>
    </>
  );
}
