export const possibleReps = [
  '1 RM',
  '2 RM',
  '3 RM',
  '4 RM',
  '5 RM',
  '6 RM',
  '7 RM',
  '8 RM',
  '9 RM',
  '10 RM',
  '11 RM',
  '12 RM',
  '13 RM',
  '14 RM',
  '15 RM',
  '16 RM',
  '17 RM',
  '18 RM',
  '19 RM',
  '20 RM',
];

export const units = ['KM', 'METERS', 'REPS', 'TIMES', 'CAL'];
