// @mui
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';
// types
//
import AccountMemberships from './account-memberships';
import AccountBillingPayment from './account-billing-payment';
import AccountBillingHistory from './account-billing-history';
import AccountBillingAddress from './account-billing-address';
import {
  AccountCreditBalanceEntryDto,
  AccountDto,
  AddressDto,
  InvoiceDto,
  MembershipDto,
  PaymentMethodDto,
} from '../../api';
import AccountBalanceHistory from './account-balance-history';

// ----------------------------------------------------------------------

type Props = {
  plans: MembershipDto[];
  cards: PaymentMethodDto[];
  invoices: InvoiceDto[];
  addressBook: AddressDto[];
  member: AccountDto;
  entries: AccountCreditBalanceEntryDto[];
  handleAddNewMembership: VoidFunction;
};

export default function AccountBilling({
  cards,
  plans,
  invoices,
  addressBook,
  member,
  entries,
  handleAddNewMembership,
}: Props) {
  return (
    <Grid container spacing={5} disableEqualOverflow>
      <Grid xs={12} md={8}>
        <AccountMemberships
          plans={plans}
          cardList={cards}
          member={member}
          handleAddNewMembership={handleAddNewMembership}
        />

        <AccountBillingPayment cards={cards} member={member} />

        <AccountBillingAddress addressBook={addressBook} member={member} />
      </Grid>

      <Grid xs={12} md={4}>
        <Stack spacing={3}>
          <AccountBalanceHistory entries={entries} member={member} />

          <AccountBillingHistory invoices={invoices} />
        </Stack>
      </Grid>
    </Grid>
  );
}
