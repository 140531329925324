import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { useSettingsContext } from 'src/components/settings';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  TableSkeleton,
  useTable,
} from 'src/components/table';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
// types
import { IProductTableFilters, IProductTableFilterValue } from 'src/types/product';
import { enqueueSnackbar } from 'notistack';
//
import VoucherTableRow from '../voucher-table-row';
import VoucherTableToolbar from '../voucher-table-toolbar';
import VoucherTableFiltersResult from '../voucher-table-filters-result';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocales } from '../../../locales';
import { getVouchers } from '../../../redux/slices/voucher';
import { Direction, DiscountVoucherService } from '../../../api';

// ----------------------------------------------------------------------

const defaultFilters: IProductTableFilters = {
  name: '',
  publish: [],
  stock: [],
};

// ----------------------------------------------------------------------

export default function VoucherListView() {
  const router = useRouter();

  const { t } = useLocales();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const TABLE_HEAD = [
    { id: 'name', label: t('Name') },
    { id: 'discountAmount', label: t('Discount amount') },
    { id: 'discountPercentage', label: t('Discount percentage') },
    { id: 'clips', label: t('Count') },
    { id: 'isOneTimeUse', label: t('Single-use') },
    { id: 'validFrom', label: t('Valid from') },
    { id: 'validTo', label: t('Valid to') },
    { id: '', width: 88 },
  ];

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.voucher.vouchers);

  const vouchersLoading = useSelector((state) => state.voucher.isLoading);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getVouchers({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
      })
    );
  }, [dispatch, rowsPerPage, page, orderBy, order, filters]);

  const confirm = useBoolean();

  const denseHeight = dense ? 60 : 80;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = !tableData.length && canReset;

  const handleFilters = useCallback(
    (name: string, value: IProductTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      const request = async () => {
        await DiscountVoucherService.remove({ id });

        enqueueSnackbar(t('Deleted!'));

        dispatch(
          getVouchers({
            pageSize: rowsPerPage,
            pageNumber: page,
            sortBy: [orderBy],
            direction: order === 'asc' ? Direction.Asc : Direction.Desc,
            search: filters.name,
          })
        );
      };

      request();
    },
    [dispatch, rowsPerPage, page, orderBy, order, filters, t]
  );

  const handleEditRow = useCallback(
    (id: number) => {
      router.push(paths.vouchers.edit(id));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.vouchers.details(id));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Voucher list')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            {
              name: t('Vouchers'),
              href: paths.vouchers.root,
            },
            { name: t('List') },
          ]}
          action={
            <Button
              component={RouterLink}
              href={paths.vouchers.new}
              variant="contained"
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {t('New voucher')}
            </Button>
          }
          sx={{ mb: { xs: 3, md: 5 } }}
        />

        <Card>
          <VoucherTableToolbar filters={filters} onFilters={handleFilters} />

          {canReset && (
            <VoucherTableFiltersResult
              filters={filters}
              onFilters={handleFilters}
              //
              onResetFilters={handleResetFilters}
              //
              results={tableData.length}
              sx={{ p: 2.5, pt: 0 }}
            />
          )}

          <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected.length}
              rowCount={tableData.length}
              onSelectAllRows={(checked) => {}}
              action={
                <Tooltip title={t('Delete')}>
                  <IconButton color="primary" onClick={confirm.onTrue}>
                    <Iconify icon="solar:trash-bin-trash-bold" />
                  </IconButton>
                </Tooltip>
              }
            />

            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {vouchersLoading ? (
                    [...Array(rowsPerPage)].map((i, index) => (
                      <TableSkeleton key={index} sx={{ height: denseHeight }} />
                    ))
                  ) : (
                    <>
                      {tableData?.map((row) => (
                        <VoucherTableRow
                          key={row.id}
                          row={row}
                          onViewRow={() => handleViewRow(row.id!)}
                          onDeleteRow={() => handleDeleteRow(row.id!)}
                          onEditRow={() => handleEditRow(row.id!)}
                        />
                      ))}
                    </>
                  )}

                  <TableEmptyRows
                    height={denseHeight}
                    emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                  />

                  <TableNoData notFound={notFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={tableData.length}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------
