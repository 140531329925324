import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import checkoutReducer from './slices/checkout';
import chainReducer from './slices/chain';
import memberReducer from './slices/members';
import employeeReducer from './slices/employees';
import gymReducer from './slices/gym';
import lockReducer from './slices/lock';
import newsReducer from './slices/news';
import newsletterReducer from './slices/newsletter';
import contractReducer from './slices/contract';
import eventReducer from './slices/event';
import membershipReducer from './slices/membership';
import invoiceReducer from './slices/invoice';
import productReducer from './slices/product';
import productCategoryReducer from './slices/product-categories';
import orderReducer from './slices/order';
import classFeedbackReducer from './slices/class-feedback';
import membershipTypeReducer from './slices/membership-type';
import emailTemplateReducer from './slices/email-template';
import classDescriptionReducer from './slices/class-description';
import voucherReducer from './slices/voucher';
import classesReducer from './slices/classes';
import classTemplateReducer from './slices/class-template';
import leadReducer from './slices/leads';
import retentionReducer from './slices/retention';
import pushNotificationReducer from './slices/push-notification';
import programTemplateReducer from './slices/program-template';
import programReducer from './slices/program';
import personalProgramReducer from './slices/personal-program';
import accountingReducer from './slices/accounting';
import statisticsReducer from './slices/statistics';
import shiftReducer from './slices/shift';
import wageReducer from './slices/wage';
import exerciseReducer from './slices/exercise';
import reportReducer from './slices/report';
import errorMessageReducer from './slices/error';
import quickLinkReducer from './slices/quick-links';
import chatReducer from './slices/chat';
import insightReducer from './slices/insight';
import searchReducer from './slices/search';

// ----------------------------------------------------------------------

const checkoutPersistConfig = {
  key: 'checkout',
  storage,
  keyPrefix: 'redux-',
};

export const rootReducer = combineReducers({
  checkout: persistReducer(checkoutPersistConfig, checkoutReducer),
  chain: chainReducer,
  chat: chatReducer,
  member: memberReducer,
  employee: employeeReducer,
  membership: membershipReducer,
  report: reportReducer,
  news: newsReducer,
  newsletter: newsletterReducer,
  gym: gymReducer,
  lock: lockReducer,
  contract: contractReducer,
  event: eventReducer,
  invoice: invoiceReducer,
  order: orderReducer,
  product: productReducer,
  classFeedback: classFeedbackReducer,
  productCategory: productCategoryReducer,
  emailTemplate: emailTemplateReducer,
  membershipType: membershipTypeReducer,
  classDescription: classDescriptionReducer,
  voucher: voucherReducer,
  clazz: classesReducer,
  classTemplate: classTemplateReducer,
  lead: leadReducer,
  retention: retentionReducer,
  pushNotification: pushNotificationReducer,
  programTemplate: programTemplateReducer,
  program: programReducer,
  quickLinks: quickLinkReducer,
  personalProgram: personalProgramReducer,
  accounting: accountingReducer,
  statistics: statisticsReducer,
  shift: shiftReducer,
  wage: wageReducer,
  exercise: exerciseReducer,
  errorMessage: errorMessageReducer,
  insight: insightReducer,
  search: searchReducer,
});
