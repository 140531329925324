// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import ListItemText from '@mui/material/ListItemText';
import Chip from '@mui/material/Chip';
import { alpha } from '@mui/material/styles';
// routes
import { RouterLink } from 'src/routes/components';
//
import Iconify from '../../iconify';
//
import { NavConfigProps, NavItemProps } from '../types';
import { StyledDotIcon, StyledIcon, StyledItem } from './styles';
import { SubscriptionType } from '../../../api';

// ----------------------------------------------------------------------

type Props = NavItemProps & {
  config: NavConfigProps;
};

export default function NavItem({
  item,
  open,
  depth,
  active,
  config,
  externalLink,
  ...other
}: Props) {
  const { title, path, icon, info, children, disabled, caption, roles, subscriptionPlan } = item;

  const subItem = depth !== 1;

  const renderContent = (
    <StyledItem
      disableGutters
      disabled={disabled}
      active={active}
      depth={depth}
      config={config}
      {...other}
    >
      <>
        {icon && <StyledIcon size={config.iconSize}>{icon}</StyledIcon>}

        {subItem && (
          <StyledIcon size={config.iconSize}>
            <StyledDotIcon active={active} />
          </StyledIcon>
        )}
      </>

      {!(config.hiddenLabel && !subItem) && (
        <ListItemText
          primary={title}
          secondary={
            caption ? (
              <Tooltip title={caption} placement="top-start">
                <span>{caption}</span>
              </Tooltip>
            ) : null
          }
          primaryTypographyProps={{
            noWrap: true,
            typography: 'body2',
            textTransform: 'capitalize',
            fontWeight: active ? 'fontWeightSemiBold' : 'fontWeightMedium',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            component: 'span',
            typography: 'caption',
            color: 'text.disabled',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ ml: 1, lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}

      {!children &&
        subscriptionPlan === SubscriptionType.Premium &&
        config.currentChain?.subscriptionType !== SubscriptionType.Premium &&
        config.currentChain?.subscriptionType !== SubscriptionType.Platinum && (
          <Chip
            label={subscriptionPlan}
            size="small"
            variant="outlined"
            sx={{
              color: '#ffbd1b',
              borderColor: '#ffbd1b',
              bgcolor: (theme) => alpha('#ffbd1b', 0.08),
            }}
          />
        )}

      {!children &&
        subscriptionPlan === SubscriptionType.Platinum &&
        config.currentChain?.subscriptionType !== SubscriptionType.Platinum && (
          <Chip
            label={subscriptionPlan}
            size="small"
            variant="outlined"
            sx={{
              color: '#E5E4E2',
              borderColor: '#E5E4E2',
              bgcolor: (theme) => alpha('#E5E4E2', 0.08),
            }}
          />
        )}
    </StyledItem>
  );

  // Hidden item by role
  if (roles && !(roles!.filter((x) => config.currentRoles?.includes(x)).length > 0)) {
    return null;
  }

  const hasAccess =
    subscriptionPlan === undefined ||
    subscriptionPlan === SubscriptionType.Standard ||
    (subscriptionPlan === SubscriptionType.Premium &&
      (config.currentChain?.subscriptionType === SubscriptionType.Premium ||
        config.currentChain?.subscriptionType === SubscriptionType.Platinum)) ||
    (subscriptionPlan === SubscriptionType.Platinum &&
      config.currentChain?.subscriptionType === SubscriptionType.Platinum);

  // External link
  if (externalLink)
    return (
      <Link
        href={hasAccess ? path : '#'}
        target="_blank"
        rel="noopener"
        underline="none"
        color="inherit"
        sx={{
          ...(disabled && {
            cursor: 'default',
          }),
        }}
      >
        {renderContent}
      </Link>
    );

  // Has child
  if (children) {
    return renderContent;
  }

  // Default
  return (
    <Link
      component={RouterLink}
      href={hasAccess ? path : '#'}
      underline="none"
      color="inherit"
      sx={{
        ...(disabled && {
          cursor: 'default',
        }),
      }}
    >
      {renderContent}
    </Link>
  );
}
