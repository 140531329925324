import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Grid from '@mui/material/Unstable_Grid2';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
import { saveAs } from 'file-saver';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { Direction, getConfigs, serviceOptions } from '../../../api';
import Label from '../../../components/label';
import { getMembersWithRetentionIssuesDto } from '../../../redux/slices/retention';
import RetentionTableRow from '../retention-table-row';
import RetentionTableFiltersResult from '../retention-table-filters-result';
import RetentionTableToolbar from '../retention-table-toolbar';
import { IRetentionTableFilters, IRetentionTableFilterValue } from '../../../types/retention';

// ----------------------------------------------------------------------

const defaultFilters: IRetentionTableFilters = {
  name: '',
  periodFrom: DateTime.now().minus({
    month: 3,
  }),
  periodTo: null,
  status: 'all',
  min: 0,
  max: 3,
};

// ----------------------------------------------------------------------

export default function RetentionListView() {
  const { t } = useLocales();

  const TABLE_HEAD = [
    { id: 'firstName', label: t('Name'), align: 'left' },
    { id: 'numberOfAttendances', label: t('Number of attendances'), align: 'left' },
    { id: 'membershipType', label: t('Membership'), align: 'left' },
    { id: 'phoneNumber', label: t('Phone number'), align: 'left' },
    { id: '' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.retention.members);

  const totalNumberOfMembers = useSelector((state) => state.retention.totalNumberOfMembers);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(
      getMembersWithRetentionIssuesDto({
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        minNumberOfAttendances: filters.min,
        maxNumberOfAttendances: filters.max,
        periodTo: filters.periodTo,
        periodFrom: filters.periodFrom,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order]);

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'default', count: totalNumberOfMembers },
  ] as const;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || !tableData.length;

  const handleFilters = useCallback(
    (name: string, value: IRetentionTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  const handleExport = useCallback(() => {
    const action = async () => {
      const url = '/api/retention-issues';

      const configs = getConfigs('get', 'application/json', url, {
        headers: {
          Accept: 'text/csv',
        },
      });

      configs.params = {
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        minNumberOfAttendances: filters.min,
        maxNumberOfAttendances: filters.max,
        periodTo: filters.periodTo,
        periodFrom: filters.periodFrom,
      };
      const resp = await serviceOptions.axios!.request(configs);

      // Ignore type, because API can return multiple types
      const blob = new Blob([resp.data as any], {
        type: 'text/csv',
      });
      saveAs(blob, `result.csv`);
    };
    action();
  }, [filters, orderBy, order]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={`${t('Retention Issues')}`}
          links={[
            {
              name: `${t('Dashboard')}`,
              href: paths.dashboard.root,
            },
            {
              name: `${t('Retention')}`,
              href: paths.leads.root,
            },
            { name: `${t('List')}` },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
        />

        <Grid container spacing={3}>
          <Grid xs={12}>
            <Card>
              <Tabs
                value={filters.status}
                onChange={handleFilterStatus}
                sx={{
                  px: 2.5,
                  boxShadow: (theme) => `inset 0 -2px 0 0 ${alpha(theme.palette.grey[500], 0.08)}`,
                }}
              >
                {STATUS_OPTIONS.map((tab) => (
                  <Tab
                    key={tab.value}
                    iconPosition="end"
                    value={tab.value}
                    label={tab.label}
                    icon={
                      <Label
                        variant={
                          ((tab.value === 'all' || tab.value === filters.status) && 'filled') ||
                          'soft'
                        }
                        color={tab.color}
                      >
                        {tab.count}
                      </Label>
                    }
                  />
                ))}
              </Tabs>

              <RetentionTableToolbar
                filters={filters}
                onFilters={handleFilters}
                onExport={handleExport}
              />
              {canReset && (
                <RetentionTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  //
                  onResetFilters={handleResetFilters}
                  //
                  results={totalNumberOfMembers}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  dense={dense}
                  numSelected={selected.length}
                  rowCount={tableData.length}
                  onSelectAllRows={(checked) => {}}
                  action={
                    <Tooltip title={t('Delete')}>
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar>
                  <Table size={dense ? 'small' : 'medium'}>
                    <TableHeadCustom
                      order={order}
                      orderBy={orderBy}
                      headLabel={TABLE_HEAD}
                      rowCount={tableData.length}
                      numSelected={selected.length}
                      onSort={onSort}
                    />

                    <TableBody>
                      {tableData?.map((row) => (
                        <RetentionTableRow
                          key={row.id}
                          row={row}
                          onSelectRow={() => handleViewRow(row.id!)}
                        />
                      ))}

                      <TableEmptyRows
                        height={denseHeight}
                        emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMembers)}
                      />

                      <TableNoData notFound={notFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={totalNumberOfMembers}
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                //
                dense={dense}
                onChangeDense={onChangeDense}
              />
            </Card>
          </Grid>
        </Grid>
      </Container>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={
          <>
            Are you sure want to delete <strong> {selected.length} </strong> items?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}

// ----------------------------------------------------------------------
