// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// utils
import { fDate } from 'src/utils/format-time';
import { fShortenNumber } from 'src/utils/format-number';
// types
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { PushNotificationDto, PushNotificationStatus } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  notification: PushNotificationDto;
};

export default function NotificationItemHorizontal({ notification }: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const {
    id,
    subject,
    body,
    sendingTime,
    status,
    lastModifiedDate,
    authorProfileImageUrl,
    authorName,
    totalViews,
  } = notification;

  return (
    <>
      <Stack component={Card} direction="row">
        <Stack
          sx={{
            p: (theme) => theme.spacing(3, 3, 2, 3),
          }}
          flexGrow={1}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ mb: 2 }}>
            <Label
              variant="soft"
              color={status === PushNotificationStatus.Delivered ? 'success' : 'default'}
            >
              {t(`${status}`)}
            </Label>

            <Stack direction="row">
              <Box
                component="span"
                sx={{ typography: 'caption', color: 'text.disabled', mt: '8px' }}
              >
                {fDate(lastModifiedDate)}
              </Box>
              <Avatar alt={authorName} src={authorProfileImageUrl} sx={{ mt: '-3px', ml: 1 }} />
            </Stack>
          </Stack>

          <Stack spacing={1} flexGrow={1} sx={{ mb: 2 }}>
            <Link color="inherit" component={RouterLink} href={paths.notification.edit(id!)}>
              <TextMaxLine variant="subtitle2" line={2}>
                {subject}
              </TextMaxLine>
            </Link>

            <TextMaxLine variant="body2" sx={{ color: 'text.secondary', width: '100%' }}>
              {body}
            </TextMaxLine>
          </Stack>

          <Stack direction="row" alignItems="center">
            <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
              <Iconify icon="eva:more-horizontal-fill" />
            </IconButton>

            <Stack
              spacing={1.5}
              flexGrow={1}
              direction="row"
              justifyContent="flex-end"
              sx={{
                typography: 'caption',
                color: 'text.disabled',
              }}
            >
              <Stack direction="row" alignItems="center">
                <Iconify icon="solar:eye-bold" width={16} sx={{ mr: 0.5 }} />
                {fShortenNumber(totalViews ?? 0)}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="bottom-center"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            router.push(paths.notification.edit(id!));
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
