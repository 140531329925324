import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  EmployeeDto,
  EmployeeFeedbackStatsDto,
  EmployeesService,
  EmployeeWageRateDto,
  getConfigs,
  RoleDto,
  RolesService,
  serviceOptions,
} from '../../api';
import { IEmployeeStats } from '../../types/employee';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  employees: [] as EmployeeDto[],
  totalNumberOfEmployees: 0,
  currentEmployee: {} as EmployeeDto,
  currentEmployeeFeedbackStats: {} as EmployeeFeedbackStatsDto,
  currentEmployeeWageRates: [] as EmployeeWageRateDto[],
  personalTrainers: [] as EmployeeDto[],
  stats: {
    totalActive: 0,
    totalInactive: 0,
    total: 0,
  } as IEmployeeStats,
  roles: [] as RoleDto[],
};

const slice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getEmployeesSuccess(state, action) {
      state.employees = action.payload.employees;
      state.totalNumberOfEmployees = action.payload.totalNumberOfEmployees;
      state.stats = action.payload.stats;
    },
    getEmployeeSuccess(state, action) {
      state.currentEmployee = action.payload;
    },
    getEmployeeWageRateSuccess(state, action) {
      state.currentEmployeeWageRates = action.payload;
    },
    getEmployeeFeedbackStatsSuccess(state, action) {
      state.currentEmployeeFeedbackStats = action.payload;
    },
    getPersonalTrainersSuccess(state, action) {
      state.personalTrainers = action.payload.employees;
    },
    getRolesSuccess(state, action) {
      state.roles = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getStaff(
  params: {
    /**  */
    searchString?: string;
    /**  */
    roles?: any | null[];
    /**  */
    onlyActive?: boolean;
    /**  */
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    isActive?: boolean | null;
    /**  */
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/employees';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getEmployeesSuccess({
          totalNumberOfEmployees: parseInt(resp.headers['x-total-count'], 10),
          employees: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getEmployee(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await EmployeesService.details({
        id,
      });
      dispatch(slice.actions.getEmployeeSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getEmployeeWageRates(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await EmployeesService.getWages({
        id,
      });
      dispatch(slice.actions.getEmployeeWageRateSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getEmployeeFeedbackStats(id: number, numberOfMonths: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await EmployeesService.getFeedbackStats({
        employeeId: id,
        numberOfMonths,
      });
      dispatch(slice.actions.getEmployeeFeedbackStatsSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getRoles() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await RolesService.index();
      dispatch(slice.actions.getRolesSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getPersonalTrainers(
  params: {
    /**  */
    searchString?: string;
    /**  */
    onlyActive?: boolean;
    /**  */
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    isActive?: boolean | null;
    /**  */
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/employees';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = {
        ...params,
        roles: ['PERSONAL_TRAINER'],
        isActive: true,
      };
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getPersonalTrainersSuccess({
          totalNumberOfEmployees: parseInt(resp.headers['x-total-count'], 10),
          employees: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
