import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import GymListView from '../../../sections/gyms/view/gym-list-view';

// ----------------------------------------------------------------------

export default function GymListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${t('Gym: List | Booking Board')}`}</title>
      </Helmet>

      <GymListView />
    </>
  );
}

// ----------------------------------------------------------------------
