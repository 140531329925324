import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { ExerciseTypeDto, ExerciseTypesService } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  exercises: [] as ExerciseTypeDto[],
};

const slice = createSlice({
  name: 'exercise',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getExercisesSuccess(state, action) {
      state.exercises = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getExercises() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ExerciseTypesService.findAll();

      dispatch(slice.actions.getExercisesSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
