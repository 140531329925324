import { Helmet } from 'react-helmet-async';
// sections
import { NewsletterEditView } from '../../../sections/newsletter/view';

// ----------------------------------------------------------------------

export default function NewsletterEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Post Edit</title>
      </Helmet>

      <NewsletterEditView />
    </>
  );
}
