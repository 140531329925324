import { Helmet } from 'react-helmet-async';
// sections
import ChainEditView from '../../../sections/chain/view/chain-edit-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ChainEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: System settings')}</title>
      </Helmet>

      <ChainEditView />
    </>
  );
}
