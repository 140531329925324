import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { setError } from 'src/redux/slices/error';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
// routes
import { paths } from 'src/routes/paths';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// _mock
import { _tags } from 'src/_mock';
// components
import { useSnackbar } from 'src/components/snackbar';
import { useRouter } from 'src/routes/hook';
import FormProvider, { RHFMultiSelect, RHFTextField } from 'src/components/hook-form';
// types
import { QuickLinkDto, QuickLinksService } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getChainSettings } from '../../redux/slices/chain';
import { getGyms } from '../../redux/slices/gym';

// ----------------------------------------------------------------------

type Props = {
  currentQuickLink?: QuickLinkDto;
};

export default function QuickLinkNewEditForm({ currentQuickLink }: Props) {
  const router = useRouter();

  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const settings = useSelector((state) => state.chain.currentSettings);

  const gyms = useSelector((state) => state.gym.gyms);

  const globalGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    url: Yup.string().required(t('Url is required')),
    availableInGyms: Yup.array().min(currentQuickLink ? 0 : 1, t('Please select at least one gym')),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentQuickLink?.name || '',
      url: currentQuickLink?.url || '',
      gymId: currentQuickLink?.gym?.id || null,
      availableInGyms: [`${globalGym.id}`],
    }),
    [currentQuickLink, globalGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentQuickLink) {
      reset(defaultValues);
    }
  }, [currentQuickLink, defaultValues, reset]);

  useEffect(() => {
    dispatch(getChainSettings());
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentQuickLink) {
        await QuickLinksService.edit({
          id: currentQuickLink!.id!,
          body: { id: currentQuickLink!.id!, ...data },
        });
      } else {
        await QuickLinksService.create({ body: { ...data } });
      }

      reset();
      enqueueSnackbar(currentQuickLink ? t('Update success!') : t('Create success!'));
      router.back();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name, link...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Name')}</Typography>
              <RHFTextField name="name" />
            </Stack>
            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Url')}</Typography>
              <RHFTextField name="url" />
            </Stack>
            {!currentQuickLink && (
              <Stack spacing={1.5}>
                <Typography variant="subtitle2">{t('Available in gyms')}</Typography>
                <RHFMultiSelect
                  name="availableInGyms"
                  options={gyms.map((e) => ({ value: `${e.id}`, label: e.name! }))}
                  checkbox
                />
              </Stack>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {!currentQuickLink ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
