import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useParams } from 'src/routes/hook';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import WageRateNewEditForm from '../wage-rates-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getWageRate } from '../../../redux/slices/wage';

// ----------------------------------------------------------------------

export default function WageRateEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const params = useParams();

  const { id } = params;

  const current = useSelector((state) => state.wage.currentWageRate);

  useEffect(() => {
    dispatch(getWageRate(parseInt(id!, 10)));
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Wage Rate'),
            href: paths.wageRates.root,
          },
          { name: current?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <WageRateNewEditForm currentWageRate={current} />
    </Container>
  );
}
