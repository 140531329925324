import { Helmet } from 'react-helmet-async';
// sections
import { DropInDetailsView } from 'src/sections/membership-types/drop-in/view';

// ----------------------------------------------------------------------

export default function DropInTypeDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Details</title>
      </Helmet>

      <DropInDetailsView />
    </>
  );
}
