// @mui
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
// routes
import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';
// utils
import { fDateTime } from 'src/utils/format-time';
// types
// components
import Image from 'src/components/image';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { shortDateLabel } from 'src/components/custom-date-range-picker';
import { EventDto } from '../../api';
import TextMaxLine from '../../components/text-max-line';
import { useLocales } from '../../locales';
import { ConfirmDialog } from '../../components/custom-dialog';
import { useBoolean } from '../../hooks/use-boolean';

// ----------------------------------------------------------------------

type Props = {
  event: EventDto;
  onView: VoidFunction;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
};

export default function EventItem({ event, onView, onEdit, onDelete }: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const {
    id,
    title,
    begins,
    ends,
    openForSignUpAt,
    closedForSignUpAt,
    images,
    createdDate,
    numberOfParticipants,
    shortDescription,
  } = event;

  let slides = [] as string[];

  if (!images) {
    slides = ['', '', ''];
  } else if (images?.length === 1) {
    slides = [images[0].url ?? '', images[0].url ?? '', images[0].url ?? ''];
  } else if (images?.length === 2) {
    slides = [images[0].url ?? '', images[1].url ?? '', images[1].url ?? ''];
  } else if (images?.length === 3) {
    slides = [images[0].url ?? '', images[1].url ?? '', images[2].url ?? ''];
  } else {
    slides = images!.map((e) => e.url ?? '');
  }

  const shortLabel = shortDateLabel(begins!, ends!);

  const confirm = useBoolean();

  const renderImages = (
    <Stack
      spacing={0.5}
      direction="row"
      sx={{
        p: (theme) => theme.spacing(1, 1, 0, 1),
      }}
    >
      <Stack flexGrow={1} sx={{ position: 'relative' }}>
        <Image alt={slides[0]} src={slides[0]} sx={{ borderRadius: 1, height: 164, width: 1 }} />
      </Stack>
      <Stack spacing={0.5}>
        <Image alt={slides[1]} src={slides[1]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
        <Image alt={slides[2]} src={slides[2]} ratio="1/1" sx={{ borderRadius: 1, width: 80 }} />
      </Stack>
    </Stack>
  );

  const renderTexts = (
    <Stack
      spacing={1.5}
      sx={{
        p: (theme) => theme.spacing(2.5, 2.5, 2, 2.5),
      }}
    >
      <ListItemText
        primary={t(`Posted date: {{createdDate}}`, { createdDate: fDateTime(createdDate) })}
        secondary={
          <Link component={RouterLink} href={paths.events.view(id!)} color="inherit">
            {title}
          </Link>
        }
        primaryTypographyProps={{
          typography: 'caption',
          color: 'text.disabled',
        }}
        secondaryTypographyProps={{
          mt: 1,
          noWrap: true,
          component: 'span',
          color: 'text.primary',
          typography: 'subtitle1',
        }}
      />

      <TextMaxLine
        variant="body2"
        sx={{
          color: 'text.secondary',
        }}
      >
        {shortDescription ?? ''}
      </TextMaxLine>
    </Stack>
  );

  const renderInfo = (
    <Stack
      spacing={1.5}
      sx={{
        position: 'relative',
        p: (theme) => theme.spacing(0, 2.5, 2.5, 2.5),
      }}
    >
      <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', bottom: 20, right: 8 }}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      {[
        {
          label: shortLabel,
          icon: <Iconify icon="solar:clock-circle-bold" sx={{ color: 'info.main' }} />,
        },
        {
          label: t(`{{numberOfParticipants}} Booked`, { numberOfParticipants }),
          icon: <Iconify icon="solar:users-group-rounded-bold" sx={{ color: 'primary.main' }} />,
        },
      ].map((item) => (
        <Stack
          key={item.label}
          spacing={1}
          direction="row"
          alignItems="center"
          sx={{ typography: 'body2' }}
        >
          {item.icon}
          {item.label}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <>
      <Card>
        {renderImages}

        {renderTexts}

        {renderInfo}
      </Card>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            confirm.onTrue();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Cancel')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Cancel event')}
        content={
          <>
            Are you sure want to cancel <strong>{event.title}</strong>?
          </>
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onDelete();
              confirm.onFalse();
            }}
          >
            {t('Cancel event')}
          </Button>
        }
      />
    </>
  );
}
