import { useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fShortenNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { ChartSegments } from '../../../api';
import { IInsightTableFilters } from '../../../types/insight';
import { getContingentDistribution } from '../../../redux/slices/insight';

// ----------------------------------------------------------------------

export default function MembershipDistributionView({
  fromDate,
  toDate,
  membershipTypeIds,
  minimumLengthOfMembershipInDays,
}: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const popover = usePopover();

  const report = useSelector((state) => state.insight.contingentDistribution);

  const [segment, setSegment] = useState(ChartSegments.All);

  useEffect(() => {
    dispatch(
      getContingentDistribution({
        fromDate,
        toDate,
        segment,
        membershipTypeIds,
        minimumLengthOfMembershipInDays,
      })
    );
  }, [dispatch, fromDate, toDate, segment, membershipTypeIds, minimumLengthOfMembershipInDays]);

  const charts = report.map((r) => ({
    chart: {
      name: r.name ?? 'All',
      series:
        r.series?.map((p) => ({
          name: p.name,
          type: 'column',
          data: p.data?.map((d: any) => d.y) ?? [],
        })) ?? [],
    },
  }));

  charts.sort((a, b) => a.chart.name.localeCompare(b.chart.name));

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '95%',
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories:
        report.length && report[0].series && report[0].series!.length
          ? report[0].series![0].data?.map((d: any) => d.x.toFormat('MMMM yyyy'))
          : [],
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fShortenNumber(value)}`,
      },
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Contingent distribution')}
          subheader={t('Shows contingent distribution for the selected period')}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {t(segment)}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />
        <CardContent>
          {charts.map(({ chart }) => (
            <Chart
              dir="ltr"
              type="bar"
              series={chart.series}
              options={{
                ...chartOptions,
                title: {
                  text: chart.name,
                },
              }}
              key={chart.name}
              height="600"
            />
          ))}
        </CardContent>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        <MenuItem
          key={ChartSegments.All}
          selected={ChartSegments.All === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.All);
          }}
        >
          {t(ChartSegments.All)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Gender}
          selected={ChartSegments.Gender === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Gender);
          }}
        >
          {t(ChartSegments.Gender)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Age}
          selected={ChartSegments.Age === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Age);
          }}
        >
          {t(ChartSegments.Age)}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
