import { memo } from 'react';
import Stack from '@mui/material/Stack';
//
import { NavConfigProps, NavListProps, NavSectionProps } from '../types';
import { navMiniConfig } from '../config';
import NavList from './nav-list';
import { SubscriptionType } from '../../../api';

// ----------------------------------------------------------------------

function NavSectionMini({ data, config, sx, ...other }: NavSectionProps) {
  return (
    <Stack sx={sx} {...other}>
      {data.map((group, index) => (
        <Group key={group.subheader || index} items={group.items} config={navMiniConfig(config)} />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

type GroupProps = {
  items: NavListProps[];
  roles?: string[];
  config: NavConfigProps;
  subscriptionPlan?: SubscriptionType;
};

function Group({ items, roles, subscriptionPlan, config }: GroupProps) {
  // Hidden item by role
  if (roles && !(roles!.filter((x) => config.currentRoles?.includes(x)).length > 0)) {
    return null;
  }

  // Hidden item by subscription
  if (subscriptionPlan && subscriptionPlan !== SubscriptionType.Standard) {
    if (
      subscriptionPlan === SubscriptionType.Premium &&
      config.currentChain?.subscriptionType !== SubscriptionType.Premium &&
      config.currentChain?.subscriptionType !== SubscriptionType.Platinum
    ) {
      return null;
    }

    if (
      subscriptionPlan === SubscriptionType.Platinum &&
      config.currentChain?.subscriptionType !== SubscriptionType.Platinum
    ) {
      return null;
    }
  }

  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          config={config}
        />
      ))}
    </>
  );
}
