import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { ProductCategoriesService, ProductCategoryDto } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  categories: [] as ProductCategoryDto[],
  currentCategory: {} as ProductCategoryDto,
};

const slice = createSlice({
  name: 'product-categories',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProductCategoriesSuccess(state, action) {
      state.categories = action.payload;
    },
    getProductCategorySuccess(state, action) {
      state.currentCategory = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProductCategories() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductCategoriesService.index();
      dispatch(slice.actions.getProductCategoriesSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getProductCategory(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductCategoriesService.details({ id });
      dispatch(slice.actions.getProductCategorySuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
