import { format, formatDistanceToNow, getTime } from 'date-fns';
import { DateTime } from 'luxon';

// ----------------------------------------------------------------------

type InputValue = DateTime | Date | string | number | null | undefined;

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy';

  if (date instanceof DateTime) {
    return date ? date.toLocaleString(DateTime.DATE_SHORT) : '';
  }

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM yyyy HH:mm';
  if (date instanceof DateTime) {
    return date ? date.toLocaleString(DateTime.DATETIME_SHORT) : '';
  }
  return date ? format(new Date(date), fm) : '';
}

export function fTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'HH:mm';
  if (date instanceof DateTime) {
    return date ? date.toLocaleString(DateTime.TIME_SIMPLE) : '';
  }
  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  if (date instanceof DateTime) {
    return date ? date.toLocaleString(DateTime.DATETIME_SHORT) : '';
  }
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  if (date instanceof DateTime) {
    return date
      ? formatDistanceToNow(date.toJSDate(), {
          addSuffix: true,
        })
      : '';
  }
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}
