import { useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fCurrency, fNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { IInsightTableFilters } from '../../../types/insight';
import { ChartSegments } from '../../../api';
import { getProductMonthlyRevenue } from '../../../redux/slices/insight';

// ----------------------------------------------------------------------

export default function ProductMonthlyRevenueView({ fromDate, toDate }: IInsightTableFilters) {
  const { t } = useLocales();

  const popoverSegment = usePopover();

  const dispatch = useDispatch();

  const [segment, setSegment] = useState(ChartSegments.All);

  const report = useSelector((state) => state.insight.productMonthlyRevenue);

  const settings = useSelector((state) => state.chain.currentSettings);

  useEffect(() => {
    dispatch(
      getProductMonthlyRevenue({
        fromDate,
        toDate,
        segment,
      })
    );
  }, [dispatch, fromDate, toDate, segment]);

  const chart = {
    series:
      report.series?.map((p) => ({
        name: p.name,
        data:
          p.data?.map((d: any) => ({
            x: d.x,
            y: d.y,
          })) ?? [],
      })) ?? [],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      labels: {
        formatter: (value) => DateTime.fromISO(value).toFormat('MMMM yyyy'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fCurrency(value, settings.defaultCurrency)}`,
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.median ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.average ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Product monthly revenue')}
          action={
            <>
              <ButtonBase
                onClick={popoverSegment.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                {t(segment)}

                <Iconify
                  width={16}
                  icon={
                    popoverSegment.open
                      ? 'eva:arrow-ios-upward-fill'
                      : 'eva:arrow-ios-downward-fill'
                  }
                  sx={{ ml: 0.5 }}
                />
              </ButtonBase>
            </>
          }
        />
        <CardContent>
          <Chart dir="ltr" type="area" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
      <CustomPopover
        open={popoverSegment.open}
        onClose={popoverSegment.onClose}
        sx={{ width: 140 }}
      >
        <MenuItem
          key={ChartSegments.All}
          selected={ChartSegments.All === segment}
          onClick={() => {
            popoverSegment.onClose();
            setSegment(ChartSegments.All);
          }}
        >
          {t(ChartSegments.All)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Gender}
          selected={ChartSegments.Gender === segment}
          onClick={() => {
            popoverSegment.onClose();
            setSegment(ChartSegments.Gender);
          }}
        >
          {t(ChartSegments.Gender)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Age}
          selected={ChartSegments.Age === segment}
          onClick={() => {
            popoverSegment.onClose();
            setSegment(ChartSegments.Age);
          }}
        >
          {t(ChartSegments.Age)}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
