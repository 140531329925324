import { useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { useTheme } from '@mui/material/styles';
//
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { ChartSegments } from '../../../api';
import { IInsightTableFilters } from '../../../types/insight';
import { getMembershipDistribution } from '../../../redux/slices/insight';

// ----------------------------------------------------------------------

export default function MembershipDistributionView({
  fromDate,
  toDate,
  membershipTypeIds,
  minimumLengthOfMembershipInDays,
}: IInsightTableFilters) {
  const { t } = useLocales();

  const dispatch = useDispatch();

  const popover = usePopover();

  const report = useSelector((state) => state.insight.membershipDistribution);

  const [segment, setSegment] = useState(ChartSegments.All);

  useEffect(() => {
    dispatch(
      getMembershipDistribution({
        fromDate,
        toDate,
        segment,
        membershipTypeIds,
        minimumLengthOfMembershipInDays,
      })
    );
  }, [dispatch, fromDate, toDate, segment, membershipTypeIds, minimumLengthOfMembershipInDays]);

  const charts = report.map((r) => ({
    chart: {
      name: r.name ?? 'All',
      series: r.series ?? [],
      labels: r.labels ?? [],
    },
  }));

  charts.sort((a, b) => a.chart.name.localeCompare(b.chart.name));

  const theme = useTheme();

  const chartOptions = useChart({
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    labels: [],
    stroke: {
      colors: [theme.palette.background.paper],
    },
    tooltip: {
      fillSeriesColor: false,
      y: {
        formatter: (value: number) => fNumber(value),
        title: {
          formatter: (seriesName: string) => `${seriesName}`,
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
          },
        },
      },
    },
    legend: {
      position: 'bottom',
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Membership distribution')}
          subheader={t('Shows membership distribution for the selected period')}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {t(segment)}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />
        <CardContent>
          {charts.map(({ chart }) => (
            <Chart
              type="polarArea"
              series={chart.series}
              options={{
                ...chartOptions,
                labels: chart.labels ?? [],
                title: {
                  text: chart.name,
                },
              }}
              key={chart.name}
            />
          ))}
        </CardContent>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        <MenuItem
          key={ChartSegments.All}
          selected={ChartSegments.All === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.All);
          }}
        >
          {t(ChartSegments.All)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Gender}
          selected={ChartSegments.Gender === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Gender);
          }}
        >
          {t(ChartSegments.Gender)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Age}
          selected={ChartSegments.Age === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Age);
          }}
        >
          {t(ChartSegments.Age)}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
