// @mui
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { ConfirmDialog } from 'src/components/custom-dialog';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
// utils
import { MembershipTypePriceDto } from '../../../api';
import { fCurrency } from '../../../utils/format-number';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: MembershipTypePriceDto;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function PersonalTrainingPricingTableRow({ row, onDeleteRow, onEditRow }: Props) {
  const {
    priceAmount,
    priceCurrency,
    validFrom,
    validTo,
    taxRate,
    taxRateInclusive,
    updatePriceOnExistingMemberships,
  } = row;

  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <ListItemText
            disableTypography
            primary={
              <Link noWrap color="inherit" variant="subtitle2" sx={{ cursor: 'pointer' }}>
                {fCurrency(priceAmount, priceCurrency)}
              </Link>
            }
          />
        </TableCell>

        <TableCell>
          <ListItemText
            primary={validFrom!.toFormat('dd MMM yyyy')}
            secondary={validFrom!.toFormat('HH:mm')}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>
        <TableCell>
          {validTo ? (
            <ListItemText
              primary={validTo!.toFormat('dd MMM yyyy')}
              secondary={validTo!.toFormat('HH:mm')}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>

        <TableCell>
          {taxRate ? `${taxRateInclusive ? t('Incl.') : t('Excl.')} ${taxRate}%` : '-'}
        </TableCell>

        <TableCell>{updatePriceOnExistingMemberships ? t('Yes') : t('No')}</TableCell>

        <TableCell align="right">
          <IconButton color={popover.open ? 'primary' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
