import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import QuickLinkNewEditForm from '../quick-link-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { getProductCategories } from '../../../redux/slices/product-categories';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function QuickLinkCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new quick link')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Quick Link'),
            href: paths.quickLinks.root,
          },
          { name: t('New') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <QuickLinkNewEditForm />
    </Container>
  );
}
