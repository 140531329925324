import { Helmet } from 'react-helmet-async';
// sections
import GymCreateView from '../../../sections/gyms/view/gym-create-view';

// ----------------------------------------------------------------------

export default function GymCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new gym</title>
      </Helmet>

      <GymCreateView />
    </>
  );
}
