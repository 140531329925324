// @mui
import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// routes
// components
//
import { ClassFeedbackDto } from '../../api';
import FeedbackItem from './feedback-item';

// ----------------------------------------------------------------------

type Props = {
  classFeedback: ClassFeedbackDto[];
  totalEvents: number;
  pageSize: number;
  handlePageChange: (value: number) => void;
};

export default function ClassFeedbackList({
  classFeedback,
  totalEvents,
  handlePageChange,
  pageSize,
}: Props) {
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePageChange(value);
  };

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {classFeedback.map((event) => (
          <FeedbackItem key={event.id} feedback={event} />
        ))}
      </Box>

      {totalEvents > pageSize && (
        <Pagination
          count={Math.ceil(totalEvents / pageSize)}
          onChange={handleChange}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
