import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import { Direction, getConfigs, LedgerEntryDto, serviceOptions } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  ledgerEntries: [] as LedgerEntryDto[],
  totalNumberOfEntries: 0,
};

const slice = createSlice({
  name: 'accounting',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getEmailTemplatesSuccess(state, action) {
      state.ledgerEntries = action.payload.ledgerEntries;
      state.totalNumberOfEntries = action.payload.totalNumberOfEntries;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLedgerEntries(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    fromDate?: DateTime | null;
    toDate?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // throw new Error('Parameter is not a number!');

      const url = '/api/ledgers';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getEmailTemplatesSuccess({
          ledgerEntries: resp.data,
          totalNumberOfEntries: parseInt(resp.headers['x-total-count'], 10),
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}
