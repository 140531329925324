// utils
import { paramCase } from 'src/utils/change-case';
import { _id, _postTitles } from 'src/_mock/assets';

// ----------------------------------------------------------------------

const MOCK_ID = _id[1];

const MOCK_TITLE = _postTitles[2];

const ROOTS = {
  AUTH: '/auth',
  AUTH_DEMO: '/auth-demo',
  DASHBOARD: '/',
};

// ----------------------------------------------------------------------

export const paths = {
  refresh: '/blank',
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
  post: {
    root: `/post`,
    details: (title: string) => `/post/${paramCase(title)}`,
    demo: {
      details: `/post/${paramCase(MOCK_TITLE)}`,
    },
  },
  // AUTH
  auth: {
    jwt: {
      login: `${ROOTS.AUTH}/jwt/login`,
      register: `${ROOTS.AUTH}/jwt/register`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: ROOTS.DASHBOARD,
    mail: `${ROOTS.DASHBOARD}/mail`,
    chat: `${ROOTS.DASHBOARD}/chat`,
    blank: `${ROOTS.DASHBOARD}/blank`,
    kanban: `${ROOTS.DASHBOARD}/kanban`,
    calendar: `${ROOTS.DASHBOARD}/calendar`,
    fileManager: `${ROOTS.DASHBOARD}/file-manager`,
    permission: `${ROOTS.DASHBOARD}/permission`,
    general: {
      app: `${ROOTS.DASHBOARD}/app`,
      ecommerce: `${ROOTS.DASHBOARD}/ecommerce`,
      analytics: `${ROOTS.DASHBOARD}/analytics`,
      banking: `${ROOTS.DASHBOARD}/banking`,
      booking: `${ROOTS.DASHBOARD}/booking`,
      file: `${ROOTS.DASHBOARD}/file`,
    },
    user: {
      root: `${ROOTS.DASHBOARD}/user`,
      new: `${ROOTS.DASHBOARD}/user/new`,
      list: `${ROOTS.DASHBOARD}/user/list`,
      cards: `${ROOTS.DASHBOARD}/user/cards`,
      profile: `${ROOTS.DASHBOARD}/user/profile`,
      account: `${ROOTS.DASHBOARD}/user/account`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/user/${id}/edit`,
      demo: {
        edit: `${ROOTS.DASHBOARD}/user/${MOCK_ID}/edit`,
      },
    },
    product: {
      root: `${ROOTS.DASHBOARD}/product`,
      new: `${ROOTS.DASHBOARD}/product/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/product/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/product/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/product/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/product/${MOCK_ID}/edit`,
      },
    },
    invoice: {
      root: `${ROOTS.DASHBOARD}/invoice`,
      new: `${ROOTS.DASHBOARD}/invoice/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/invoice/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/invoice/${MOCK_ID}/edit`,
      },
    },
    post: {
      root: `${ROOTS.DASHBOARD}/post`,
      new: `${ROOTS.DASHBOARD}/post/new`,
      details: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}`,
      edit: (title: string) => `${ROOTS.DASHBOARD}/post/${paramCase(title)}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}`,
        edit: `${ROOTS.DASHBOARD}/post/${paramCase(MOCK_TITLE)}/edit`,
      },
    },
    order: {
      root: `${ROOTS.DASHBOARD}/order`,
      details: (id: string) => `${ROOTS.DASHBOARD}/order/${id}`,
      demo: {
        details: `${ROOTS.DASHBOARD}/order/${MOCK_ID}`,
      },
    },
    job: {
      root: `${ROOTS.DASHBOARD}/job`,
      new: `${ROOTS.DASHBOARD}/job/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/job/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/job/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/job/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/job/${MOCK_ID}/edit`,
      },
    },
    tour: {
      root: `${ROOTS.DASHBOARD}/tour`,
      new: `${ROOTS.DASHBOARD}/tour/new`,
      details: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}`,
      edit: (id: string) => `${ROOTS.DASHBOARD}/tour/${id}/edit`,
      demo: {
        details: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}`,
        edit: `${ROOTS.DASHBOARD}/tour/${MOCK_ID}/edit`,
      },
    },
  },
  general: {
    management: '/management',
    sales: '/sales',
    training: '/training',
    economics: '/economics',
    booking: '/booking',
    file: '/file',
  },
  contracts: {
    root: '/contracts/list',
    edit: (id: number) => `/contracts/${id}/edit`,
    new: `/contracts/new`,
  },
  classes: {
    root: '/classes',
    new: '/classes/new',
    edit: (id: number) => `/classes/${id}/edit`,
    view: (id: number) => `/classes/${id}`,
  },
  classTemplates: {
    root: '/class-templates',
    new: '/class-templates/new',
    edit: (id: number) => `/class-templates/${id}/edit`,
    view: (id: number) => `/class-templates/${id}`,
  },
  classDescriptions: {
    root: '/class-descriptions',
    new: '/class-descriptions/new',
    edit: (id: number) => `/class-descriptions/${id}/edit`,
    view: (id: number) => `/class-descriptions/${id}`,
  },
  classFeedback: {
    root: '/feedback/list',
    new: '/feedback/new',
    edit: (id: number) => `/feedback/${id}/edit`,
    view: (id: number) => `/feedback/${id}`,
  },
  events: {
    root: '/events/list',
    new: '/events/new',
    edit: (id: number) => `/events/${id}/edit`,
    view: (id: number) => `/events/${id}`,
  },
  classRegistration: {
    root: '/class-registrations',
  },
  chat: {
    root: '/chat',
    view: (id: number) => `/chat/${id}`,
  },
  mail: {
    root: '/mail',
  },
  news: {
    root: '/news',
    new: '/news/new',
    edit: (id: number) => `/news/${id}/edit`,
    view: (id: number) => `/news/${id}`,
  },
  newsletter: {
    root: '/newsletters',
    new: '/newsletters/new',
    edit: (id: number) => `/newsletters/${id}/edit`,
    view: (id: number) => `/newsletters/${id}`,
  },
  notification: {
    root: '/notifications',
    new: '/notifications/new',
    edit: (id: number) => `/notifications/${id}/edit`,
    view: (id: number) => `/notifications/${id}`,
  },
  report: {
    root: '/reports',
    list: '/reports/list',
    view: (slug: string) => `/reports/${slug}`,
  },
  insight: {
    memberships: '/insights/memberships',
    sales: '/insights/sales',
  },
  emailTemplates: {
    root: '/email-templates',
    new: '/email-templates/new',
    edit: (id: number) => `/email-templates/${id}/edit`,
    view: (id: number) => `/email-templates/${id}`,
  },
  bookkeeping: {
    root: '/bookkeeping',
  },
  programTemplates: {
    root: '/program-templates',
    new: '/program-templates/new',
    edit: (id: number) => `/program-templates/${id}/edit`,
    view: (id: number) => `/program-templates/${id}`,
  },
  personalPrograms: {
    root: '/personal-programs',
    new: '/personal-programs/new',
    edit: (id: number) => `/personal-programs/${id}/edit`,
    view: (id: number) => `/personal-programs/${id}`,
  },
  programs: {
    root: '/programs',
    new: '/programs/new',
    edit: (id: number) => `/programs/${id}/edit`,
  },
  members: {
    root: '/members/list',
    new: '/members/new',
    edit: (id: number) => `/members/${id}/edit`,
    view: (id: number) => `/members/${id}`,
  },
  memberships: {
    root: '/memberships',
    new: '/memberships/new',
    edit: (id: number) => `/memberships/${id}/edit`,
    view: (id: number) => `/memberships/${id}`,
  },
  wageRates: {
    root: '/wage-rates',
    new: '/wage-rates/new',
    edit: (id: number) => `/wage-rates/${id}/edit`,
    view: (id: number) => `/wage-rates/${id}`,
  },
  chains: {
    edit: (id: number) => `/chains/${id}/edit`,
  },
  gyms: {
    root: '/gyms/list',
    new: '/gyms/new',
    edit: (id: number) => `/gyms/${id}/edit`,
    setup: (id: number) => `/gyms/${id}/setup`,
    view: (id: number) => `/gyms/${id}`,
  },
  locks: {
    edit: (id: number) => `/locks/${id}/edit`,
  },
  locations: {
    root: '/locations/list',
    new: '/locations/new',
    edit: (id: number) => `/locations/${id}/edit`,
    view: (id: number) => `/locations/${id}`,
  },
  user: {
    root: '/user',
    new: '/user/new',
    list: '/user/list',
    cards: '/user/cards',
    profile: '/user/profile',
    account: '/user/account',
    edit: (name: string) => `/user/${name}/edit`,
    demoEdit: `/user/reece-chung/edit`,
  },
  guests: {
    root: '/guests',
    new: '/guests/new',
  },
  leads: {
    root: '/leads',
  },
  salarySettlement: {
    root: '/salary-settlement',
  },
  dutySchedule: {
    root: '/shifts',
  },
  retention: {
    root: '/retention',
  },
  hourBank: {
    root: '/hour-bank',
  },
  invoice: {
    root: '/invoices',
    list: '/invoices/list',
    new: `/invoices/new`,
    details: (id: number) => `/invoices/${id}`,
    edit: (id: number) => `/invoices/${id}/edit`,
  },
  employee: {
    root: '/employees',
    list: '/employees/list',
    new: `/employees/new`,
    details: (id: number) => `/employees/${id}`,
    edit: (id: number) => `/employees/${id}/edit`,
  },
  order: {
    root: `/orders/list`,
    details: (id: number) => `/orders/${id}`,
  },
  product: {
    root: `/products`,
    new: `/products/new`,
    checkout: `/products/new`,
    details: (id: string | number) => `/products/${id}`,
    edit: (id: string | number) => `/products/${id}/edit`,
  },
  quickLinks: {
    root: `/quick-links`,
    new: `/quick-links/new`,
    checkout: `/quick-links/new`,
    edit: (id: string | number) => `/quick-links/${id}/edit`,
  },
  productCategory: {
    root: `/product-categories`,
    new: `/product-categories/new`,
    details: (id: string | number) => `/product-categories/${id}`,
    edit: (id: string | number) => `/product-categories/${id}/edit`,
  },
  vouchers: {
    root: `/vouchers`,
    new: `/vouchers/new`,
    details: (id: string | number) => `/vouchers/${id}`,
    edit: (id: string | number) => `/vouchers/${id}/edit`,
  },
  campaignType: {
    root: `/campaign-types`,
    new: `/campaign-types/new`,
    details: (id: number) => `/campaign-types/${id}`,
    edit: (id: number) => `/campaign-types/${id}/edit`,
  },
  contingentType: {
    root: `/contingent-types`,
    new: `/contingent-types/new`,
    details: (id: number) => `/contingent-types/${id}`,
    edit: (id: number) => `/contingent-types/${id}/edit`,
  },
  personalTrainingMembershipType: {
    root: `/personal-training-membership-types`,
    new: `/personal-training-membership-types/new`,
    details: (id: number) => `/personal-training-membership-types/${id}`,
    edit: (id: number) => `/personal-training-membership-types/${id}/edit`,
  },
  punchCardType: {
    root: `/punch-card-types`,
    new: `/punch-card-types/new`,
    details: (id: number) => `/punch-card-types/${id}`,
    edit: (id: number) => `/punch-card-types/${id}/edit`,
  },
  dropInType: {
    root: `/drop-in-types`,
    new: `/drop-in-types/new`,
    details: (id: number) => `/drop-in-types/${id}`,
    edit: (id: number) => `/drop-in-types/${id}/edit`,
  },
};
