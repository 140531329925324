import { AnyAction, createSlice, Dispatch } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import { useLocales } from 'src/locales';
import { InternalError } from 'src/types/error';

// ----------------------------------------------------------------------

type State = {
  currentError: InternalError | null;
};

const initialState = {
  currentError: null,
} as State;

const slice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    hasError(state, action) {
      state.currentError = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function setError(error: Error | AxiosError | null): any {
  const action = (dispatch: Dispatch) => {
    if (!error) {
      dispatch(slice.actions.hasError(null));
    } else if (axios.isAxiosError(error)) {
      const message = error?.response?.data?.detail ?? 'Unknown error';
      dispatch(
        slice.actions.hasError({
          title: 'Error',
          message,
        })
      );
    } else {
      const message = error?.message ?? 'Unknown error';
      dispatch(
        slice.actions.hasError({
          title: 'Error',
          message,
        })
      );
    }
  };
  return action;
}
