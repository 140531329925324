// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ProductNewEditForm from '../voucher-new-edit-form';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function VoucherCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new voucher')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Vouchers'),
            href: paths.vouchers.root,
          },
          { name: t('New voucher') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ProductNewEditForm />
    </Container>
  );
}
