import { useCallback, useRef, useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import { blue, green, grey, orange, purple } from '@mui/material/colors';
import Chip from '@mui/material/Chip';
// types
// hooks
import { useMockedUser } from 'src/hooks/use-mocked-user';
// utils
import { fDate } from 'src/utils/format-time';
// components
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';
import {
  AccountDto,
  CommentService,
  EmailStatus,
  MemberEventDto,
  MemberEventType,
} from '../../api';
import { getMemberComments, getMemberEvents } from '../../redux/slices/members';
import { useDispatch } from '../../redux/store';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

interface Props {
  member: AccountDto;
  event: MemberEventDto;
}

export default function EventItem({ event, member }: Props) {
  const { t } = useLocales();

  const { user } = useMockedUser();

  const commentRef = useRef<HTMLInputElement>(null);

  const fileRef = useRef<HTMLInputElement>(null);

  const [message, setMessage] = useState('');

  const handleChangeMessage = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(e.target.value);
  }, []);

  const dispatch = useDispatch();

  const handleAttach = useCallback(() => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  }, []);

  const handleClickComment = useCallback(() => {
    if (commentRef.current) {
      commentRef.current.focus();
    }
  }, []);

  const handleDeleteComment = useCallback(async () => {
    await CommentService.remove({
      id: event.id!,
    });
    dispatch(getMemberEvents(member.id!));
    dispatch(getMemberComments(member.id!));

    enqueueSnackbar(t('Comment removed!'));
  }, [dispatch, event, member, t]);

  const renderAvatar = () => {
    if (event?.type === MemberEventType.DoorAccess) {
      return (
        <Avatar sx={{ bgcolor: green[500] }}>
          <Iconify icon="mingcute:unlock-line" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.Email) {
      return (
        <Avatar sx={{ bgcolor: purple[500] }}>
          {' '}
          <Iconify icon="ic:outline-email" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.Notification) {
      return (
        <Avatar sx={{ bgcolor: blue[500] }}>
          {' '}
          <Iconify icon="mdi:bell-ring" width={24} />
        </Avatar>
      );
    }
    if (event?.type === MemberEventType.ClassRegistration) {
      return (
        <Avatar sx={{ bgcolor: grey[900] }}>
          {' '}
          <Iconify icon="fluent:dumbbell-20-filled" width={24} />
        </Avatar>
      );
    }
    return <Avatar src={event?.createdByProfileImage} alt={event?.createdBy} />;
  };

  const renderActions = () => {
    if (event?.type === MemberEventType.Email) {
      if (event!.status === EmailStatus.Failed) {
        return <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="error" />;
      }
      if (event!.status === EmailStatus.Delivered) {
        return (
          <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="success" />
        );
      }
      if (event!.status === EmailStatus.Rejected) {
        return (
          <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="warning" />
        );
      }
      return <Chip label={t(`${event?.status}`)} variant="outlined" size="small" color="info" />;
    }

    if (event.type === MemberEventType.Comment) {
      return (
        <IconButton color="error" onClick={handleDeleteComment}>
          <Iconify icon="eva:trash-2-outline" />
        </IconButton>
      );
    }

    return <></>;
  };

  const renderHead = (
    <CardHeader
      disableTypography
      avatar={renderAvatar()}
      title={event?.title ?? event?.createdBy}
      subheader={
        <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
          {fDate(event.timestamp)}
        </Box>
      }
      action={renderActions()}
    />
  );

  const renderInput = (
    <Stack
      spacing={2}
      direction="row"
      alignItems="center"
      sx={{
        p: (theme) => theme.spacing(0, 3, 3, 3),
      }}
    >
      <Avatar src={user?.photoURL} alt={user?.displayName} />

      <InputBase
        fullWidth
        value={message}
        inputRef={commentRef}
        placeholder="Write a comment…"
        onChange={handleChangeMessage}
        endAdornment={
          <InputAdornment position="end" sx={{ mr: 1 }}>
            <IconButton size="small" onClick={handleAttach}>
              <Iconify icon="solar:gallery-add-bold" />
            </IconButton>

            <IconButton size="small">
              <Iconify icon="eva:smiling-face-fill" />
            </IconButton>
          </InputAdornment>
        }
        sx={{
          pl: 1.5,
          height: 40,
          borderRadius: 1,
          border: (theme) => `solid 1px ${alpha(theme.palette.grey[500], 0.32)}`,
        }}
      />

      <input type="file" ref={fileRef} style={{ display: 'none' }} />
    </Stack>
  );

  return (
    <Card>
      {renderHead}

      <Typography
        variant="body2"
        sx={{
          p: (theme) => theme.spacing(3, 3, 2, 3),
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: event.content! }} />
      </Typography>
    </Card>
  );
}
