import { useRef } from 'react';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
// utils
// types
// components
import Iconify from 'src/components/iconify';
//
import { EmployeeDto } from '../../api';
import { useLocales } from '../../locales';
import FeedbackStats from './feedback-stats';
import { useAuthContext } from '../../auth/hooks';

// ----------------------------------------------------------------------

type Props = {
  employee: EmployeeDto;
};

export default function ProfileHome({ employee }: Props) {
  const { user } = useAuthContext();

  const canViewStats = ['Owner', 'Box_manager']!.filter((x) => user?.roles?.includes(x)).length > 0;

  const { t } = useLocales();

  const renderAbout = (
    <Card>
      <CardHeader title={t('About')} />

      <Stack spacing={2} sx={{ p: 3 }}>
        {employee.address != null ? (
          <Stack direction="row" spacing={2}>
            <Iconify icon="mingcute:location-fill" width={24} />

            <Box sx={{ typography: 'body2' }}>
              {t('Live at ')}
              <Link variant="subtitle2" color="inherit">
                {`${employee.address?.addressLine}, ${employee.address?.postalNumber} ${employee.address?.city}, ${employee.address?.country}`}
              </Link>
            </Box>
          </Stack>
        ) : null}

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:mail-24-filled" width={24} sx={{ mr: 2 }} />
          {employee.email}
        </Stack>

        <Stack direction="row" sx={{ typography: 'body2' }}>
          <Iconify icon="fluent:phone-24-filled" width={24} sx={{ mr: 2 }} />
          {employee.phoneNumber}
        </Stack>
      </Stack>
    </Card>
  );

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={8} lg={9}>
        <Stack spacing={3}>{renderAbout}</Stack>
      </Grid>
      {canViewStats && (
        <Grid xs={12} md={4} lg={3}>
          <FeedbackStats employee={employee} />
        </Grid>
      )}
    </Grid>
  );
}
