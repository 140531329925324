import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
import { useDispatch } from 'src/redux/store';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import { DateTime } from 'luxon';
import FormProvider, { RHFSwitch } from 'src/components/hook-form';
import { useLocales } from '../../../locales';
import {
  CampaignService,
  ContingentService,
  DropInsService,
  MembershipDto,
  MembershipUpdateTypes,
  PersonalTrainingService,
  VouchersService,
} from '../../../api';

// ----------------------------------------------------------------------

type Props = {
  open: boolean;
  onClose: VoidFunction;
  onComplete: VoidFunction;
  membership?: MembershipDto;
};

export default function MembershipCancelOnHoldForm({
  open,
  onClose,
  onComplete,
  membership,
}: Props) {
  const { t } = useLocales();

  const newSchema = Yup.object().shape({
    membershipId: Yup.number().required('Fullname is required'),
    membershipTypeId: Yup.number().required('Fullname is required'),
    validFrom: Yup.lazy((value) =>
      value === '' ? Yup.string().default('') : Yup.date().required(t('Valid to is required'))
    ),
  });

  const defaultValues = useMemo(
    () =>
      ({
        validFrom: DateTime.now(),
        membershipId: membership?.id || -1,
        membershipTypeId: membership?.membershipTypeId || -1,
      } as any),
    [membership]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    handleSubmit,
    formState: { isSubmitting },
    control,
    reset,
  } = methods;

  const values = watch();

  const isDropIn = membership?.typeOfMembership === 'DropIn';
  const isPunchCard = membership?.typeOfMembership === 'Voucher';
  const isContingent = membership?.typeOfMembership === 'Contingent';
  const isCampaign = membership?.typeOfMembership === 'Campaign';
  const isPersonalTraining = membership?.typeOfMembership === 'PersonalTrainingMembership';
  const dispatch = useDispatch();

  const onSubmit = handleSubmit(async (data) => {
    const action = async () => {
      try {
        if (isDropIn) {
          await DropInsService.update({
            id: membership!.id!,
            body: {
              id: membership!.id!,
              type: MembershipUpdateTypes.CancelOnHold,
            } as any,
          });
        } else if (isContingent) {
          await ContingentService.update({
            id: membership!.id!,
            body: {
              id: membership!.id!,
              type: MembershipUpdateTypes.CancelOnHold,
            } as any,
          });
        } else if (isCampaign) {
          await CampaignService.update({
            id: membership!.id!,
            body: {
              id: membership!.id!,
              type: MembershipUpdateTypes.CancelOnHold,
            } as any,
          });
        } else if (isPunchCard) {
          await VouchersService.update({
            id: membership!.id!,
            body: {
              id: membership!.id!,
              type: MembershipUpdateTypes.CancelOnHold,
            } as any,
          });
        } else if (isPersonalTraining) {
          await PersonalTrainingService.update({
            id: membership!.id!,
            body: {
              id: membership!.id!,
              type: MembershipUpdateTypes.CancelOnHold,
            } as any,
          });
        }

        onComplete();
      } catch (err) {
        dispatch(setError(err));
      }
    };

    action();
  });

  useEffect(() => {
    if (membership) {
      reset(defaultValues);
    }
  }, [membership, reset, defaultValues]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <DialogTitle>{t('Resume membership')}</DialogTitle>
        <DialogContent dividers>
          <Typography
            variant="body2"
            sx={{
              mb: 3,
            }}
          >
            {t('Here you can resume the given membership.')}
          </Typography>

          <Stack spacing={3}>
            <Stack spacing={1.5}>
              <Controller
                name="validFrom"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <DatePicker
                    {...field}
                    readOnly
                    label={t('Valid from')}
                    value={field.value}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            </Stack>
          </Stack>
        </DialogContent>

        <DialogActions>
          <Button color="inherit" variant="outlined" onClick={onClose}>
            {t('Cancel')}
          </Button>

          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Resume')}
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}
