import * as Yup from 'yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import LoadingButton from '@mui/lab/LoadingButton';
// utils
import { fNumber } from 'src/utils/format-number';
// types
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
//
import { setError } from 'src/redux/slices/error';
import ReviewItem from './review-item';
import MessageItem from './message-item';
import { useLocales } from '../../locales';
import {
  ClassesService,
  ClassFeedbackDto,
  ClazzDto,
  MessageDto,
  ParticipationStatus,
} from '../../api';
import { useDispatch } from '../../redux/store';
import { getClassMessages } from '../../redux/slices/classes';
import { fDate, fTime } from '../../utils/format-time';

// ----------------------------------------------------------------------

type Props = {
  clazz: ClazzDto;
  messages: MessageDto[];
  feedback: ClassFeedbackDto[];
};

export default function ClassHome({ clazz, messages, feedback }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const { t } = useLocales();

  const newSchema = Yup.object().shape({
    body: Yup.string().required('Body is required'),
    sendAsEmail: Yup.boolean(),
    sendAsPushNotification: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      body: '',
      sendAsEmail: false,
      sendAsPushNotification: true,
    }),
    []
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await ClassesService.sendMessage({
        id: clazz.id!,
        body: {
          subject: `${clazz.title} - ${clazz.begins}`,
          body: data.body,
          sendAsEmail: data.sendAsEmail,
          sendAsPushNotification: data.sendAsPushNotification,
        },
      });

      reset();
      enqueueSnackbar(t('Message sent!'));

      dispatch(getClassMessages(clazz.id!));
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const posts = ([] as any[]).concat(messages).concat(feedback);
  posts.sort((a, b) => b.createdDate!.toMillis() - a.createdDate!.toMillis());

  const renderStats = (
    <Card sx={{ py: 3, textAlign: 'center', typography: 'h4' }}>
      <Stack
        direction="row"
        divider={<Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />}
      >
        <Stack width={1}>
          {fNumber(clazz.capacity ?? 0)}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {t('Seats')}
          </Box>
        </Stack>

        <Stack width={1}>
          {fNumber(
            clazz.participants?.filter(
              (x) =>
                x.status === ParticipationStatus.OnTime || x.status === ParticipationStatus.SignedUp
            ).length ?? 0
          )}
          <Box component="span" sx={{ color: 'text.secondary', typography: 'body2' }}>
            {t('Participants')}
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderAbout = (
    <Card>
      <CardHeader title={t('Time and place')} />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Box sx={{ typography: 'body2' }}>{clazz.gym?.name}</Box>

        <Stack direction="row" spacing={2}>
          <Iconify icon="mdi:calendar-range" width={24} />

          <Box sx={{ typography: 'body2' }}>
            <Link variant="subtitle2" color="inherit">
              {`${fDate(clazz.begins)}`}
            </Link>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify icon="mdi:clock" width={24} />

          <Box sx={{ typography: 'body2' }}>
            <Link variant="subtitle2" color="inherit">
              {t(`{{begins}} to {{ends}}`, {
                begins: fTime(clazz.begins),
                ends: fTime(clazz.ends),
              })}
            </Link>
          </Box>
        </Stack>

        <Stack direction="row" spacing={2}>
          <Iconify icon="mingcute:location-fill" width={24} />

          <Box sx={{ typography: 'body2' }}>
            <Link variant="subtitle2" color="inherit">
              {`${clazz.gym?.address?.addressLine}, ${clazz.gym?.address?.postalNumber} ${clazz.gym?.address?.city}, ${clazz.gym?.address?.country}`}
            </Link>
          </Box>
        </Stack>
      </Stack>
    </Card>
  );

  const renderPostInput = (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Card sx={{ p: 3 }}>
        <RHFTextField
          name="body"
          label={t('Body')}
          placeholder={t('Send a message to participants...')}
          multiline
          rows={4}
          sx={{
            mb: 3,
          }}
        />

        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box
            columnGap={2}
            rowGap={3}
            display="grid"
            gridTemplateColumns={{
              xs: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
            }}
          >
            <RHFSwitch name="sendAsPushNotification" label={t('Push notification')} />

            <RHFSwitch name="sendAsEmail" label={t('Email')} />
          </Box>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('Send')}
          </LoadingButton>
        </Stack>
      </Card>
    </FormProvider>
  );

  function isFeedback(object: any): object is ClassFeedbackDto {
    return 'rating' in object;
  }

  const renderReviewItem = (r: ClassFeedbackDto) => <ReviewItem key={r.id} feedback={r} />;

  const renderMessageItem = (r: MessageDto) => <MessageItem key={r.id} message={r} />;

  return (
    <Grid container spacing={3}>
      <Grid xs={12} md={4}>
        <Stack spacing={3}>
          {renderStats}

          {renderAbout}
        </Stack>
      </Grid>

      <Grid xs={12} md={8}>
        <Stack spacing={3}>
          {renderPostInput}

          {posts.map((r) => (isFeedback(r) ? renderReviewItem(r) : renderMessageItem(r)))}
        </Stack>
      </Grid>
    </Grid>
  );
}
