import { Helmet } from 'react-helmet-async';
// sections
import MembershipListView from '../../../sections/membership/view/membership-list-view';

// ----------------------------------------------------------------------

export default function MembershipListPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: User List</title>
      </Helmet>

      <MembershipListView />
    </>
  );
}
