import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// utils
// components
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// types
import { setError } from 'src/redux/slices/error';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { EmployeeDto, EmployeesService, EmployeeWageRateDto } from '../../api';
import { getWageRates } from '../../redux/slices/wage';

// ----------------------------------------------------------------------

type Props = {
  colorOptions: string[];
  onClose: VoidFunction;
  currentRate?: EmployeeWageRateDto;
  currentEmployee: EmployeeDto;
};

export default function EditAddWageRateForm({
  currentEmployee,
  currentRate,
  colorOptions,
  onClose,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const wageRates = useSelector((state) => state.wage.wageRates);

  useEffect(() => {
    dispatch(
      getWageRates({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const EventSchema = Yup.object().shape({
    wageRateId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Wage rate is required'))
    ),
    employeeId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Employee is required'))
    ),
    validFrom: Yup.date().required(t('Valid to is required')),
    validTo: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Valid to must be later than valid from'),
        (value, { parent }) => !value || value > parent.validFrom
      ),
    payrollEmployeeId: Yup.string().required(t('Payroll ID is required')),
  });

  const defaultValues = useMemo(
    () =>
      ({
        wageRateId: currentRate?.wageRateId || '',
        employeeId: currentEmployee.id || '',
        validFrom: currentRate?.validFrom,
        validTo: currentRate?.validTo,
        payrollEmployeeId: currentRate?.payrollEmployeeId || '',
      } as any),
    [currentRate, currentEmployee]
  );

  const methods = useForm({
    resolver: yupResolver(EventSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentRate) {
        await EmployeesService.editWage({
          id: currentEmployee.id!,
          wageRateId: currentRate!.id!,
          body: { id: currentRate!.id!, ...data } as any,
        });
      } else {
        await EmployeesService.addWage({
          id: currentEmployee!.id!,
          body: { ...data } as any,
        });
      }

      enqueueSnackbar(currentRate ? t('Update success!') : t('Create success!'));

      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const onDelete = useCallback(async () => {
    try {
      await EmployeesService.deleteWageRate({
        id: currentEmployee.id!,
        wageRateId: currentRate!.id!,
      });
      enqueueSnackbar(t('Delete success!'));
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  }, [currentEmployee, currentRate, enqueueSnackbar, onClose, t, dispatch]);

  const handleWageRateChange = (event: ChangeEvent<any>) => {
    setValue('wageRateId', event.target.value, { shouldValidate: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <TextField
          select
          label={t('Wage Rate')}
          value={values.wageRateId}
          onChange={handleWageRateChange}
        >
          {wageRates.map((option) => (
            <MenuItem key={`in-${option.id}`} value={option.id!}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <Controller
          name="validFrom"
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              value={field.value}
              label={t('Valid from')}
              format="dd/MM/yyyy"
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
            />
          )}
        />

        <Controller
          name="validTo"
          control={control}
          render={({ field, fieldState: { error } }) => (
            <DatePicker
              {...field}
              value={field.value}
              label={t('Valid to')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: !!error,
                  helperText: error?.message,
                },
              }}
              format="dd/MM/yyyy"
            />
          )}
        />

        <RHFTextField name="payrollEmployeeId" label={t('Payroll employee ID')} />
      </Stack>

      <DialogActions>
        {!!currentRate?.id && (
          <Tooltip title="Delete Event">
            <IconButton onClick={onDelete} color="error">
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {currentRate ? t('Save Changes') : t('Add')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
