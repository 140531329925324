import { Helmet } from 'react-helmet-async';
// sections
import ProductCategoryEditView from '../../../sections/product-categories/view/product-categories-edit-view';

// ----------------------------------------------------------------------

export default function ProductCategoryEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Edit</title>
      </Helmet>

      <ProductCategoryEditView />
    </>
  );
}
