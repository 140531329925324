import { Helmet } from 'react-helmet-async';
// sections
import RetentionListView from '../../../sections/retention/view/retention-list-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function RetentionListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Retention List')}</title>
      </Helmet>

      <RetentionListView />
    </>
  );
}
