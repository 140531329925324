import { useCallback, useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import Container from '@mui/material/Container';
import { Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useDispatch, useSelector } from 'src/redux/store';
import {
  getCampaigns,
  getContingents,
  getDropIns,
  getPersonalTrainings,
  getPunchCards,
} from 'src/redux/slices/membership-type';
import { useLocales } from '../../../../locales';
import { useSettingsContext } from '../../../../components/settings';
import { paths } from '../../../../routes/paths';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import MemberLifetimeValueView from '../member-lifetime-value-view';
import MemberMonthlyRecurringRevenueView from '../member-monthly-recurring-revenue-view';
import MemberMonthlyAverageRevenueView from '../member-monthly-average-revenue-view';
import RevenueContingentView from '../revenue-contingent-view';
import RevenueCampaignView from '../revenue-campaign-view';
import RevenuePunchCardsView from '../revenue-punch-cards-view';
import RevenueDropInView from '../revenue-drop-in-view';
import RevenuePersonalTrainingView from '../revenue-personal-training-view';
import ProductMonthlyRevenueView from '../product-monthly-revenue-view';
import RevenueFinesView from '../revenue-fines-view';
import { IInsightFilterValue, IInsightTableFilters } from '../../../../types/insight';
import ProductMonthlyRevenuePerCategoryView from '../product-monthly-revenue-per-category-view';
import MonthlyRecurringRevenueView from '../monthly-recurring-revenue-view';
import MemberMonthlyRevenueView from '../member-monthly-revenue-view';
import InsightsToolbar from '../insights-toolbar';

const defaultFilters: IInsightTableFilters = {
  fromDate: DateTime.now().startOf('year'),
  toDate: DateTime.now().endOf('year'),
  membershipTypeIds: [],
  minimumLengthOfMembershipInDays: null,
};

export default function SaleInsightsView() {
  const { t } = useLocales();

  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const [filters, setFilters] = useState(defaultFilters);

  const handleFilters = useCallback((name: string, value: IInsightFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const campaigns = useSelector((state) => state.membershipType.campaigns);
  const contingents = useSelector((state) => state.membershipType.contingents);
  const punchCards = useSelector((state) => state.membershipType.punchCards);
  const dropIns = useSelector((state) => state.membershipType.dropIns);
  const personalTrainings = useSelector((state) => state.membershipType.personalTrainings);

  useEffect(() => {
    dispatch(
      getCampaigns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getDropIns({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPersonalTrainings({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
    dispatch(
      getPunchCards({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        isActive: true,
        validOn: new Date(),
        sortBy: ['name'],
      })
    );
  }, [dispatch]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Insights')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Insights'),
          },
          { name: t('Sales') },
        ]}
        sx={{ mb: 3 }}
      />
      <Typography variant="body2" sx={{ mb: 3 }}>
        {t(
          "Here you can get insights about your sales. You can see how much revenue you've made, how much revenue you've made from members, how much revenue you've made from products, and more. All amounts are in your default currency and excluding VAT."
        )}
      </Typography>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <Card>
            <InsightsToolbar
              filters={filters}
              onFilters={handleFilters}
              dropIns={dropIns}
              punchCards={punchCards}
              contingents={contingents}
              campaigns={campaigns}
              personalTrainings={personalTrainings}
            />
          </Card>
        </Grid>
        <Grid xs={12} xl={6}>
          <MemberMonthlyRevenueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} xl={6}>
          <MonthlyRecurringRevenueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} xl={6}>
          <MemberMonthlyRecurringRevenueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} xl={6}>
          <MemberLifetimeValueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} xl={6}>
          <MemberMonthlyAverageRevenueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          mt: 2,
        }}
      >
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenueContingentView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenueCampaignView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenuePunchCardsView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenueDropInView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenuePersonalTrainingView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} md={12} lg={6} xl={4}>
          <RevenueFinesView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={3}
        sx={{
          mt: 2,
        }}
      >
        <Grid xs={12} xl={6}>
          <ProductMonthlyRevenueView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
        <Grid xs={12} xl={6}>
          <ProductMonthlyRevenuePerCategoryView
            fromDate={filters.fromDate}
            toDate={filters.toDate}
            membershipTypeIds={filters.membershipTypeIds}
            minimumLengthOfMembershipInDays={filters.minimumLengthOfMembershipInDays}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
