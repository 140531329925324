import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  EmailTemplateDto,
  EmailTemplatesService,
  getConfigs,
  serviceOptions,
} from '../../api';
import { IEmailTemplateStats } from '../../types/email-template';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  templates: [] as EmailTemplateDto[],
  currentTemplate: {} as EmailTemplateDto,
  stats: {} as IEmailTemplateStats,
};

const slice = createSlice({
  name: 'email-templates',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getEmailTemplatesSuccess(state, action) {
      state.templates = action.payload.templates;
      state.stats = action.payload.stats;
    },
    getEmailTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEmailTemplates(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    type?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      // throw new Error('Parameter is not a number!-Email');

      const url = '/api/email-templates';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);
      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getEmailTemplatesSuccess({
          templates: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getEmailTemplate(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await EmailTemplatesService.getById(params);

      dispatch(slice.actions.getEmailTemplateSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
