import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// components
import Iconify from 'src/components/iconify';
import Markdown from '../../components/markdown';
import { ClazzDto, ProgramDto } from '../../api';
import { useLocales } from '../../locales';
import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  program?: ProgramDto;
  clazz: ClazzDto;
};

export default function ClassProgram({ program, clazz }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: 2,
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {program?.name ?? t('Program')}
          </Typography>
        </Box>

        <Box sx={{ flexShrink: 0 }}>
          {program === undefined ? (
            <Button
              component={RouterLink}
              to={`${paths.programs.new}?clazzId=${clazz.id}&date=${encodeURIComponent(
                clazz.begins!.toString()
              )}`}
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              {t('New program')}
            </Button>
          ) : (
            <Button
              component={RouterLink}
              to={`${paths.programs.edit(program!.id!)}?clazzId=${
                clazz.id
              }&date=${encodeURIComponent(clazz.begins!.toString())}`}
              variant="contained"
              startIcon={<Iconify icon="solar:pen-bold" />}
            >
              {t('Edit program')}
            </Button>
          )}
        </Box>
      </Stack>

      <Stack spacing={3}>
        {program?.workouts?.map((w) => (
          <Card key={`workout-${w.id}`}>
            <CardHeader
              disableTypography
              title={
                <Link color="inherit" variant="subtitle1">
                  {w.title}
                </Link>
              }
              subheader={w.workoutTypeAbbreviation}
            />
            <Stack
              spacing={2}
              sx={{
                p: 3,
                position: 'relative',
              }}
            >
              <Markdown children={w.description ?? ''} />
            </Stack>
          </Card>
        ))}
      </Stack>
    </>
  );
}
