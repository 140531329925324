// @mui
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// types
import { ClassDescriptionDto } from '../../api';
//
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  row: ClassDescriptionDto;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function ClassTemplateTableRow({ row, onEditRow, onDeleteRow }: Props) {
  const { name, subtitle, tint, isOpenGym, isPersonalTraining } = row;

  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  const type = (() => {
    if (isOpenGym) return <Chip label={t('Open gym')} />;
    if (isPersonalTraining) return <Chip label={t('Personal training')} />;
    return <Typography />;
  })();

  return (
    <>
      <TableRow hover>
        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar alt={name} src={row.backgroundImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={name}
            secondary={subtitle}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {tint}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {type}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          {t('Edit')}
        </MenuItem>
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Delete')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              confirm.onFalse();
              onDeleteRow();
            }}
          >
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
