import { Helmet } from 'react-helmet-async';
// sections
import ClassesCreateView from '../../../sections/classes/view/classes-create-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassesCreatePage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Create a new class')}</title>
      </Helmet>

      <ClassesCreateView />
    </>
  );
}
