import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  ChainDto,
  ChainSearchesService,
  ChainSettingsDto,
  ChainSettingsService,
  ChainsService,
} from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  chains: [] as ChainDto[],
  currentChain: {} as ChainDto,
  currentSettings: {} as ChainSettingsDto,
};

const slice = createSlice({
  name: 'chain',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET MAILS
    getChainsSuccess(state, action) {
      state.chains = action.payload;
    },
    getChainSuccess(state, action) {
      state.currentChain = action.payload;
    },
    getChainSettingSuccess(state, action) {
      state.currentSettings = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getChains(query: string) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ChainSearchesService.search({
        query,
      });
      dispatch(slice.actions.getChainsSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------

export function getChain() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ChainsService.getChains();
      dispatch(slice.actions.getChainSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

// ----------------------------------------------------------------------

export function getChainSettings() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ChainSettingsService.get();
      dispatch(slice.actions.getChainSettingSuccess(response));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
