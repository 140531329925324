import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  getConfigs,
  NewsletterDto,
  NewslettersService,
  NewsStatus,
  serviceOptions,
} from '../../api';
import { INewsletterStats } from '../../types/newsletter';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  letters: [] as NewsletterDto[],
  currentLetter: {} as NewsletterDto,
  stats: {} as INewsletterStats,
};

const slice = createSlice({
  name: 'newsletter',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getNewsSuccess(state, action) {
      state.letters = action.payload.letters;
      state.stats = action.payload.stats;
    },
    getCurrentNewsSuccess(state, action) {
      state.currentLetter = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNewsletters(
  params: {
    /**  */
    pageNumber?: number;
    /**  */
    pageSize?: number;
    /**  */
    sortBy?: any | null[];
    /**  */
    direction?: Direction;
    /**  */
    status?: string | null | NewsStatus;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/newsletters';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);
      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getNewsSuccess({
          letters: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getNewsletter(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await NewslettersService.getById(params);

      dispatch(slice.actions.getCurrentNewsSuccess(resp));
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------
