// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// types
import { MembershipDto, MembershipStatuses } from '../../../api';
//
import { useLocales } from '../../../locales';
import { fCurrency } from '../../../utils/format-number';
import Label from '../../../components/label';
import { paths } from '../../../routes/paths';
import { useRouter } from '../../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  onEditRow: VoidFunction;
  row: MembershipDto;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function CampaignMemberTableRow({
  row,
  onEditRow,
  onSelectRow,
  onDeleteRow,
}: Props) {
  const {
    ownerName,
    name,
    renewalDate,
    priceAmount,
    priceCurrency,
    billable,
    ownerProfileImageUrl,
    ownerEmail,
    status,
    ownerId,
  } = row;
  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  const router = useRouter();

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => router.push(paths.members.view(ownerId!))}
        >
          <Avatar alt={ownerName} src={ownerProfileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={ownerName}
            secondary={ownerEmail}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {name}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {fCurrency(priceAmount, priceCurrency)}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {t(`${billable}`)}
        </TableCell>

        <TableCell>
          {renewalDate ? (
            <ListItemText
              primary={renewalDate!.toFormat('dd MMM yyyy')}
              secondary={renewalDate!.toFormat('HH:mm')}
              primaryTypographyProps={{ typography: 'body2', noWrap: true }}
              secondaryTypographyProps={{
                mt: 0.5,
                component: 'span',
                typography: 'caption',
              }}
            />
          ) : (
            '-'
          )}
        </TableCell>

        <TableCell>
          <Label
            variant="soft"
            color={
              (status === MembershipStatuses.Active && 'success') ||
              (status === MembershipStatuses.OnHold && 'info') ||
              (status === MembershipStatuses.MissingPayment && 'error') ||
              (status === MembershipStatuses.Cancelled && 'warning') ||
              'default'
            }
          >
            {t(`${status}`)}
          </Label>
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
