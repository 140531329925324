import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Direction, getConfigs, LeadDto, LeadStatuses, serviceOptions } from '../../api';
import { ILeadStats } from '../../types/lead';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  leads: [] as LeadDto[],
  totalNumberOfLeads: 0,
  stats: {
    won: 0,
    lost: 0,
    potential: 0,
    total: 0,
  } as ILeadStats,
};

const slice = createSlice({
  name: 'lead',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMembersSuccess(state, action) {
      state.leads = action.payload.leads;
      state.totalNumberOfLeads = action.payload.totalNumberOfLeads;
      state.stats = action.payload.stats;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getLeads(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    status?: LeadStatuses;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/leads';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getMembersSuccess({
          totalNumberOfLeads: parseInt(resp.headers['x-total-count'], 10),
          leads: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
