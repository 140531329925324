import { Helmet } from 'react-helmet-async';
// sections
import LocationCreateView from '../../../sections/location/view/location-create-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function LocationCreatePage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${t('Location: New | Booking Board')}`}</title>
      </Helmet>

      <LocationCreateView />
    </>
  );
}
