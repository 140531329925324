// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import PunchCardNewEditForm from '../punch-card-new-edit-form';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

export default function PunchCardCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create new punch card type')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Punch card type'),
            href: paths.punchCardType.root,
          },
          { name: t('New punch card type') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <PunchCardNewEditForm />
    </Container>
  );
}
