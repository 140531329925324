import { Helmet } from 'react-helmet-async';
// sections
import GymSetupView from '../../../sections/gyms/view/gym-setup-view';

// ----------------------------------------------------------------------

export default function GymSetupPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Gym Setup</title>
      </Helmet>

      <GymSetupView />
    </>
  );
}
