import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  getConfigs,
  OrderItemDto,
  ProductDto,
  ProductsService,
  serviceOptions,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  products: [] as ProductDto[],
  totalNumberOfProducts: 0,
  currentProduct: {} as ProductDto,
  currentProductPurchases: [] as OrderItemDto[],
  totalCurrentProductPurchases: 0,
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getProductsSuccess(state, action) {
      state.products = action.payload.products;
      state.totalNumberOfProducts = action.payload.totalNumberOfProducts;
    },
    getProductPurchasesSuccess(state, action) {
      state.currentProductPurchases = action.payload.purchases;
      state.totalCurrentProductPurchases = action.payload.totalPurchases;
    },
    getProductSuccess(state, action) {
      state.currentProduct = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getProducts(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    searchString?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/products';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getProductsSuccess({
          totalNumberOfProducts: parseInt(resp.headers['x-total-count'], 10),
          products: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getProduct(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await ProductsService.getProduct({
        id,
      });
      dispatch(slice.actions.getProductSuccess(response));
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getProductPurchases(
  params: {
    id: number;
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = `/api/products/${params.id}/purchases`;

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getProductPurchasesSuccess({
          totalPurchases: parseInt(resp.headers['x-total-count'], 10),
          purchases: resp.data,
        })
      );
      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
