import { Helmet } from 'react-helmet-async';
// sections
import LocationDetailsView from '../../../sections/location/view/location-details-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function LocationDetailsPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${t('Location: Details | Booking Board')}`}</title>
      </Helmet>

      <LocationDetailsView />
    </>
  );
}
