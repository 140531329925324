import { Helmet } from 'react-helmet-async';
// sections
import { PersonalTrainingEditView } from 'src/sections/membership-types/personal-training/view';

// ----------------------------------------------------------------------

export default function PersonalTrainingMembershipTypeEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Edit</title>
      </Helmet>

      <PersonalTrainingEditView />
    </>
  );
}
