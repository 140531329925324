// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// types
import { ParticipationStatus, SignUpDto } from '../../api';
//
import { useLocales } from '../../locales';
import { fDateTime } from '../../utils/format-time';
import Label from '../../components/label';
import { stringAvatar } from '../../utils/avatar';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  row: SignUpDto;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function ClassRegistrationTableRow({ row, onEditRow, onDeleteRow }: Props) {
  const confirm = useBoolean();

  const router = useRouter();

  const { t } = useLocales();

  const popover = usePopover();

  const theme = useTheme();

  const isLight = theme.palette.mode === 'light';

  return (
    <>
      <TableRow hover>
        <TableCell
          sx={{ display: 'flex', alignItems: 'center' }}
          onClick={() => router.push(paths.classes.view(row.id!))}
        >
          {row.coverImageUrl === undefined && (
            <Avatar {...stringAvatar(row.className!, { mr: 2, width: 48, height: 48 })} />
          )}
          {row.coverImageUrl !== undefined && (
            <Avatar
              variant="rounded"
              alt={row.className}
              src={row.coverImageUrl}
              sx={{ mr: 2, width: 48, height: 48 }}
            />
          )}

          {row.className}
        </TableCell>

        <TableCell>{fDateTime(row.classBegins)}</TableCell>

        <TableCell onClick={() => router.push(paths.members.view(row.accountId!))}>
          <Stack direction="row" sx={{ display: 'flex', alignItems: 'center' }}>
            {row.profileImageUrl === undefined && (
              <Avatar
                {...stringAvatar(`${row.firstName} ${row.lastName}`, {
                  mr: 2,
                  width: 48,
                  height: 48,
                })}
              />
            )}
            {row.profileImageUrl !== undefined && (
              <Avatar
                alt={`${row.firstName} ${row.lastName}`}
                src={row.profileImageUrl}
                sx={{ mr: 2 }}
              />
            )}

            <ListItemText
              primary={`${row.firstName} ${row.lastName}`}
              secondary={row.accountEmailAddress}
              primaryTypographyProps={{ typography: 'body2' }}
              secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
            />
          </Stack>
        </TableCell>

        <TableCell>{fDateTime(row.signedUpAt)}</TableCell>

        <TableCell>{row.checkedInAt !== undefined && fDateTime(row.checkedInAt)}</TableCell>

        <TableCell>{row.optedOutAt !== undefined && fDateTime(row.optedOutAt)}</TableCell>

        <TableCell>
          <Label
            variant={isLight ? 'soft' : 'filled'}
            color={
              (row.status === ParticipationStatus.OnTime && 'success') ||
              (row.status === ParticipationStatus.SignedUp && 'default') ||
              (row.status === ParticipationStatus.OnWaitingList && 'warning') ||
              'error'
            }
          >
            {t(`${row.status}`)}
          </Label>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            confirm.onTrue();
            popover.onClose();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          {t('Cancel')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
