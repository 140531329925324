import React, { useCallback, useEffect, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
// routes
import { paths } from 'src/routes/paths';
// hooks
// components
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import { useSnackbar } from 'notistack';
import { RouterLink } from '../../../routes/components';
//
import ClassHome from '../class-home';
import ClassCover from '../class-cover';
import ClassProgram from '../class-program';
import ClassParticipants from '../class-participants';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getFeedback } from '../../../redux/slices/class-feedback';
import { useParams, useRouter } from '../../../routes/hook';
import { getClass, getClassMessages } from '../../../redux/slices/classes';
import ClassInstructors from '../class-instructors';
import { ClassesService } from '../../../api';
import { useAuthContext } from '../../../auth/hooks';
import { ConfirmDialog } from '../../../components/custom-dialog';
import { useBoolean } from '../../../hooks/use-boolean';

// ----------------------------------------------------------------------

export default function ClassesDetailsView() {
  const router = useRouter();

  const settings = useSettingsContext();

  const { enqueueSnackbar } = useSnackbar();

  const params = useParams();

  const { t } = useLocales();

  const { id = '' } = params;

  const clazz = useSelector((state) => state.clazz.currentClass);

  const chain = useSelector((state) => state.chain.currentChain);

  const feedback = useSelector((state) => state.classFeedback.feedback);

  const messages = useSelector((state) => state.clazz.messages);

  const dispatch = useDispatch();

  const confirmCancellation = useBoolean();

  const { user } = useAuthContext();

  const canViewFeedback =
    ['Owner', 'Box_manager']!.filter((x) => user?.roles?.includes(x)).length > 0;

  useEffect(() => {
    const classId = parseInt(id, 10);
    if (canViewFeedback) {
      dispatch(
        getFeedback({
          sortBy: ['createdDate'],
          classId,
        })
      );
    }
    dispatch(getClass(classId));
    dispatch(getClassMessages(classId));
  }, [dispatch, id, canViewFeedback]);

  const TABS = [
    {
      value: 'overview',
      label: t('Overview'),
      icon: <Iconify icon="material-symbols:dashboard-outline" width={24} />,
    },
    {
      value: 'participants',
      label: t('Participants'),
      icon: <Iconify icon="clarity:users-line" width={24} />,
    },
    {
      value: 'program',
      label: t('Program'),
      icon: <Iconify icon="fluent:dumbbell-16-regular" width={24} />,
    },
    {
      value: 'instructors',
      label: t('Instructors'),
      icon: <Iconify icon="healthicons:i-training-class" width={24} />,
    },
  ];

  const [currentTab, setCurrentTab] = useState('participants');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const handleCancelClicked = async () => {
    await ClassesService.delete({ id: clazz.id! });

    enqueueSnackbar(t('Class cancelled!'));
    router.back();
  };

  const generateBookingLink = useCallback(() => {
    navigator.clipboard.writeText(`https://${chain.domain}/Guest/New?classId=${clazz.id}`);

    enqueueSnackbar(t('Link copied to clipboard!'));
  }, [chain, clazz, t, enqueueSnackbar]);

  return (
    <>
      <Container maxWidth={settings.themeStretch ? false : 'xl'}>
        <CustomBreadcrumbs
          heading={t('Class')}
          links={[
            { name: t('Dashboard'), href: paths.dashboard.root },
            { name: t('Class'), href: paths.classes.root },
            { name: clazz.title },
          ]}
          sx={{
            mb: { xs: 3, md: 5 },
          }}
          action={
            <>
              {clazz.isGuestBookingPossible === true && (
                <Tooltip title={t('Booking link')}>
                  <IconButton
                    onClick={generateBookingLink}
                    sx={{
                      mr: 1,
                    }}
                  >
                    <Iconify icon="solar:share-bold-duotone" />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title={t('Edit')}>
                <IconButton
                  component={RouterLink}
                  href={paths.classes.edit(clazz.id!)}
                  sx={{
                    mr: 1,
                  }}
                >
                  <Iconify icon="solar:pen-bold" />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('Cancel class')}>
                <IconButton
                  color="error"
                  onClick={() => {
                    confirmCancellation.onTrue();
                  }}
                >
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            </>
          }
        />

        <Card
          sx={{
            mb: 3,
            height: 290,
          }}
        >
          <ClassCover
            subTitle={clazz.subtitle ?? ''}
            title={clazz.title ?? ''}
            avatarUrl={clazz.gym?.logoUrl ?? ''}
            coverUrl={clazz.backgroundImageUrl ?? ''}
          />

          <Tabs
            value={currentTab}
            onChange={handleChangeTab}
            sx={{
              width: 1,
              bottom: 0,
              zIndex: 9,
              position: 'absolute',
              bgcolor: 'background.paper',
              [`& .${tabsClasses.flexContainer}`]: {
                pr: { md: 3 },
                justifyContent: {
                  sm: 'center',
                  md: 'flex-end',
                },
              },
            }}
          >
            {TABS.map((tab) => (
              <Tab key={tab.value} value={tab.value} icon={tab.icon} label={tab.label} />
            ))}
          </Tabs>
        </Card>

        {currentTab === 'overview' && (
          <ClassHome clazz={clazz} messages={messages} feedback={feedback} />
        )}

        {currentTab === 'participants' && (
          <ClassParticipants clazz={clazz} participants={clazz.participants ?? []} />
        )}

        {currentTab === 'program' && <ClassProgram program={clazz.program} clazz={clazz} />}

        {currentTab === 'instructors' && (
          <ClassInstructors
            instructors={clazz.instructors ?? []}
            coverImages={clazz.gym?.images ?? []}
          />
        )}
      </Container>
      <ConfirmDialog
        open={confirmCancellation.value}
        onClose={confirmCancellation.onFalse}
        title={t('Confirm cancellation')}
        content={
          <Box
            dangerouslySetInnerHTML={{
              __html: t('Are you sure want to cancel <strong>{{clazzTitle}}</strong>?', {
                clazzTitle: clazz.title,
              }),
            }}
          />
        }
        action={
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              handleCancelClicked();
              confirmCancellation.onFalse();
            }}
          >
            {t('Cancel class')}
          </Button>
        }
      />
    </>
  );
}
