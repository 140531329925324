export function convertToCSV(arr: any[]) {
  const titleKeys = Object.keys(arr[0]).map((x) => x.charAt(0).toUpperCase() + x.slice(1));

  const refinedData = [];
  refinedData.push(titleKeys);

  arr.forEach((item) => {
    refinedData.push(Object.values(item));
  });

  return refinedData.map((row) => `${row.join(';')}\n`).join('');
}
