import { MemberChangeDto } from 'src/api';
import { useCallback, useMemo } from 'react';
// @mui
import TableContainer from '@mui/material/TableContainer';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import CardHeader from '@mui/material/CardHeader';
//
// components
//

import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import Scrollbar from '../../../components/scrollbar';
import { useRouter } from '../../../routes/hook';
import MemberChangeOnHoldTableRow from './member-change-on-hold-table-row';

// ----------------------------------------------------------------------

type Props = {
  rows: MemberChangeDto[];
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof MemberChangeDto>(
  order: Order,
  orderBy: Key
): (a: MemberChangeDto, b: MemberChangeDto) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// ----------------------------------------------------------------------

export default function MemberChangeOnHoldTableView({ rows }: Props) {
  const { t } = useLocales();

  const router = useRouter();

  const tableHeadOnHoldMembers = [
    { id: 'ownerName', label: t('Member'), align: 'left' },
    { id: 'name', label: t('Membership'), align: 'left' },
    { id: 'ownerEmail', label: t('Email'), align: 'left' },
    { id: 'ownerPhoneNumber', label: t('Phone number'), align: 'left' },
    { id: '' },
  ];

  const tableOnHoldMembers = useTable({
    defaultOrderBy: tableHeadOnHoldMembers[0].id,
    defaultRowsPerPage: 10,
  });

  const onHoldNotFound = !rows.length;

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const visibleRows = useMemo(
    () =>
      [...rows]
        .sort(getComparator(tableOnHoldMembers.order, tableOnHoldMembers.orderBy as any))
        .slice(
          tableOnHoldMembers.page * tableOnHoldMembers.rowsPerPage,
          tableOnHoldMembers.page * tableOnHoldMembers.rowsPerPage + tableOnHoldMembers.rowsPerPage
        ),
    [tableOnHoldMembers, rows]
  );

  const denseHeight = tableOnHoldMembers.dense ? 52 : 72;

  return (
    <Card>
      <CardHeader title={t('Members on hold')} />
      <TableContainer
        sx={{
          position: 'relative',
          overflow: 'unset',
          pt: 2.5,
        }}
      >
        <Scrollbar>
          <Table size={tableOnHoldMembers.dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={tableOnHoldMembers.order}
              orderBy={tableOnHoldMembers.orderBy}
              headLabel={tableHeadOnHoldMembers}
              rowCount={rows.length}
              numSelected={tableOnHoldMembers.selected.length}
              onSort={tableOnHoldMembers.onSort}
            />

            <TableBody>
              {visibleRows?.map((row) => (
                <MemberChangeOnHoldTableRow
                  key={row.id}
                  row={row}
                  onViewRow={() => handleViewRow(row.ownerId!)}
                  onSelectRow={() => handleViewRow(row.ownerId!)}
                />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(
                  tableOnHoldMembers.page,
                  tableOnHoldMembers.rowsPerPage,
                  rows.length
                )}
              />

              <TableNoData notFound={onHoldNotFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>
      <TablePaginationCustom
        count={rows.length}
        page={tableOnHoldMembers.page}
        rowsPerPage={tableOnHoldMembers.rowsPerPage}
        onPageChange={tableOnHoldMembers.onChangePage}
        onRowsPerPageChange={tableOnHoldMembers.onChangeRowsPerPage}
      />
    </Card>
  );
}
