import { Helmet } from 'react-helmet-async';
// sections
import ClassesEditView from '../../../sections/classes/view/classes-edit-view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassesEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Class Edit')}</title>
      </Helmet>

      <ClassesEditView />
    </>
  );
}
