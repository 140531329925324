import { useCallback, useEffect, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import Label from 'src/components/label';
import { useSettingsContext } from 'src/components/settings';
//
import EventDetailsToolbar from '../class-template-details-toolbar';
import EventDetailsContent from '../class-template-details-content';
import EventDetailsTickets from '../class-template-details-tickets';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEvent, getParticipants } from '../../../redux/slices/event';

// ----------------------------------------------------------------------

export default function ClassTemplateDetailsView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const { id } = params;

  const currentEvent = useSelector((state) => state.event.currentEvent);

  const currentEventParticipants = useSelector((state) => state.event.currentEventParticipants);

  useEffect(() => {
    dispatch(
      getEvent({
        id: parseInt(id!, 10),
      })
    );
    dispatch(
      getParticipants({
        id: parseInt(id!, 10),
      })
    );
  }, [dispatch, id]);

  const [currentTab, setCurrentTab] = useState('content');

  const EVENT_DETAILS_TABS = [
    { value: 'content', label: `${t('Overview')}` },
    { value: 'participants', label: `${t('Participants')}` },
  ];

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{
        mb: { xs: 3, md: 5 },
      }}
    >
      {EVENT_DETAILS_TABS.map((tab) => (
        <Tab
          key={tab.value}
          iconPosition="end"
          value={tab.value}
          label={tab.label}
          icon={
            tab.value === 'participants' ? (
              <Label variant="filled">{currentEventParticipants?.length}</Label>
            ) : (
              ''
            )
          }
        />
      ))}
    </Tabs>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <EventDetailsToolbar
        backLink={paths.events.root}
        editLink={paths.events.edit(currentEvent?.id ?? -1)}
        liveLink="#"
      />
      {renderTabs}

      {currentTab === 'content' && <EventDetailsContent event={currentEvent} />}

      {currentTab === 'participants' && <EventDetailsTickets tickets={currentEventParticipants} />}
    </Container>
  );
}
