import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { Direction, getConfigs, NewsDto, NewsService, NewsStatus, serviceOptions } from '../../api';
import { INewsStats } from '../../types/news';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  articles: [] as NewsDto[],
  currentArticle: {} as NewsDto,
  stats: {} as INewsStats,
};

const slice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.error = action.payload;
    },
    getNewsSuccess(state, action) {
      state.articles = action.payload.articles;
      state.stats = action.payload.stats;
    },
    getCurrentNewsSuccess(state, action) {
      state.currentArticle = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getNews(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    search?: string;
    status?: NewsStatus | string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/news';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getNewsSuccess({
          articles: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

export function getSingleNews(
  params: {
    /**  */
    id: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await NewsService.getById(params);

      dispatch(slice.actions.getCurrentNewsSuccess(resp));
    } finally {
      dispatch(slice.actions.stopLoading());
    }
  };
}

// ----------------------------------------------------------------------
