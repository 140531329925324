import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  ChartStatisticsDto,
  MemberStatisticsDto,
  ParticipationStatus,
  StatisticsService,
  TopSalesmanDto,
  WidgetStatisticsDto,
  YearlySalesDto,
} from '../../api';
import { ILeadStats } from '../../types/lead';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  memberLifeTime: {} as WidgetStatisticsDto,
  memberLeg: {} as WidgetStatisticsDto,
  income: {} as WidgetStatisticsDto,
  mrr: {} as WidgetStatisticsDto,
  arm: {} as WidgetStatisticsDto,
  clv: {} as WidgetStatisticsDto,
  rms: {} as WidgetStatisticsDto,
  rpcs: {} as WidgetStatisticsDto,
  rpts: {} as WidgetStatisticsDto,
  rps: {} as WidgetStatisticsDto,
  rf: {} as WidgetStatisticsDto,
  lrmp: {} as WidgetStatisticsDto,
  comparisonMembershipPrices: {} as WidgetStatisticsDto,
  comparisonArm: {} as WidgetStatisticsDto,
  comparisonClv: {} as WidgetStatisticsDto,
  grossExpense: {} as WidgetStatisticsDto,
  expenseCategories: {} as WidgetStatisticsDto,
  membershipCategories: {} as WidgetStatisticsDto,
  revenuePerMembershipTypes: [] as ChartStatisticsDto[],
  revenuePerProduct: [] as ChartStatisticsDto[],
  revenueMembershipCategories: {} as WidgetStatisticsDto,
  topSalesmen: [] as TopSalesmanDto[],
  productSold: {} as WidgetStatisticsDto,
  totalBalance: {} as WidgetStatisticsDto,
  salesProfit: {} as WidgetStatisticsDto,
  yearlySales: {} as YearlySalesDto,
  salesByGender: {} as any,
  classAvailability: {} as any,
  currentRegistrations: {} as any,
  classTotal: {} as any,
  totalNumberOfLeads: 0,
  stats: {
    won: 0,
    lost: 0,
    potential: 0,
    total: 0,
  } as ILeadStats,
  classRegistrationStats: {
    Total: {} as MemberStatisticsDto,
    OnTime: {} as MemberStatisticsDto,
    TooLate: {} as MemberStatisticsDto,
    OptedOutTooLate: {} as MemberStatisticsDto,
    OptedOut: {} as MemberStatisticsDto,
    NeverMet: {} as MemberStatisticsDto,
    OnWaitingList: {} as MemberStatisticsDto,
    SignedUp: {} as MemberStatisticsDto,
  },
};

const slice = createSlice({
  name: 'statistics',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMemberLifeTimeSuccess(state, action) {
      state.memberLifeTime = action.payload;
    },
    getMemberLegSuccess(state, action) {
      state.memberLeg = action.payload;
    },
    getIncomeSuccess(state, action) {
      state.income = action.payload;
    },
    getMrrSuccess(state, action) {
      state.mrr = action.payload;
    },
    getArmSuccess(state, action) {
      state.arm = action.payload;
    },
    getClvSuccess(state, action) {
      state.clv = action.payload;
    },
    getRptsSuccess(state, action) {
      state.rpts = action.payload;
    },
    getRpcsSuccess(state, action) {
      state.rpcs = action.payload;
    },
    getRmsSuccess(state, action) {
      state.rms = action.payload;
    },
    getRpsSuccess(state, action) {
      state.rps = action.payload;
    },
    getRfSuccess(state, action) {
      state.rf = action.payload;
    },
    getLrmpSuccess(state, action) {
      state.lrmp = action.payload;
    },
    getExpenseSuccess(state, action) {
      state.grossExpense = action.payload;
    },
    getExpenseCategoriesSuccess(state, action) {
      state.expenseCategories = action.payload;
    },
    getMembershipCategoriesSuccess(state, action) {
      state.membershipCategories = action.payload;
    },
    getRevenuePerMembershipTypesSuccess(state, action) {
      state.revenuePerMembershipTypes = action.payload;
    },
    getRevenuePerProductSuccess(state, action) {
      state.revenuePerProduct = action.payload;
    },
    getRevenueMembershipCategoriesSuccess(state, action) {
      state.revenueMembershipCategories = action.payload;
    },
    getClassAvailabilitySuccess(state, action) {
      state.classAvailability = action.payload;
    },
    getClassTotalSuccess(state, action) {
      state.classTotal = action.payload;
    },
    getCurrentRegistrationsSuccess(state, action) {
      state.currentRegistrations = action.payload;
    },
    getClassRegistrationStatsSuccess(state, action) {
      if (action.payload.status) {
        const status = action.payload.status as ParticipationStatus;
        state.classRegistrationStats[status] = action.payload.data;
      } else {
        state.classRegistrationStats.Total = action.payload.data;
      }
    },
    getProductSoldStatsSuccess(state, action) {
      state.productSold = action.payload;
    },
    getTotalBalanceStatsSuccess(state, action) {
      state.totalBalance = action.payload;
    },
    getSalesProfitStatsSuccess(state, action) {
      state.salesProfit = action.payload;
    },
    getSalesByGenderSuccess(state, action) {
      state.salesByGender = action.payload;
    },
    getTopSalesmenSuccess(state, action) {
      state.topSalesmen = action.payload;
    },
    getYearlySalesSuccess(state, action) {
      state.yearlySales = action.payload;
    },
    getComparisonMembershipPricesSuccess(state, action) {
      state.comparisonMembershipPrices = action.payload;
    },
    getComparisonArmSuccess(state, action) {
      state.comparisonArm = action.payload;
    },
    getComparisonClvSuccess(state, action) {
      state.comparisonClv = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMemberLifeTimeStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.lifeTime();

      dispatch(slice.actions.getMemberLifeTimeSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMemberLegStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.leg();

      dispatch(slice.actions.getMemberLegSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getIncomeStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.gross();

      dispatch(slice.actions.getIncomeSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getGrossExpenseStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.grossExpense();

      dispatch(slice.actions.getExpenseSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMrrStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.mrr();

      dispatch(slice.actions.getMrrSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getArmStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.arm();

      dispatch(slice.actions.getArmSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getClvStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.clv();

      dispatch(slice.actions.getClvSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRptsStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.rpts();

      dispatch(slice.actions.getRptsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRpcsStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.rpcs();

      dispatch(slice.actions.getRpcsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRmsStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.rms();

      dispatch(slice.actions.getRmsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRpsStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.rps();

      dispatch(slice.actions.getRpsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRfStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.rf();

      dispatch(slice.actions.getRfSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getLrmpStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.lrmp();

      dispatch(slice.actions.getLrmpSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getExpenseCategoriesStatistic(
  params: {
    numberOfMonths?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.expenseCategories(params);

      dispatch(slice.actions.getExpenseCategoriesSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getMembershipCategoriesStatistic(
  params: {
    numberOfMonths?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.membershipCategories(params);

      dispatch(slice.actions.getMembershipCategoriesSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRevenuePerMembershipTypesStatistic(
  params: {
    numberOfMonths?: number;
    membershipTypeIds?: number[];
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.revenueMembershipTypes(params);

      dispatch(slice.actions.getRevenuePerMembershipTypesSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRevenuePerProductStatistic(
  params: {
    numberOfMonths?: number;
    productCategoryIds?: number[];
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.revenueProducts(params);

      dispatch(slice.actions.getRevenuePerProductSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getRevenueMembershipCategoriesStatistic(
  params: {
    numberOfMonths?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.revenueMembershipCategories(params);

      dispatch(slice.actions.getRevenueMembershipCategoriesSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getClassAvailabilityStatistic(
  params: {
    numberOfMonths: number;
    classDescriptionId?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.classAvailabilityStatistics(params);

      dispatch(slice.actions.getClassAvailabilitySuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getClassTotalStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.trainingTotalStatistics();

      dispatch(slice.actions.getClassTotalSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getCurrentRegistrationsStatistic() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await StatisticsService.currentRegistrationsStatistics();

      dispatch(slice.actions.getCurrentRegistrationsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getClassRegistrationStats(
  params: {
    status?: ParticipationStatus;
    fromDate?: DateTime;
    toDate?: DateTime;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.signUpStatistics(params);

      dispatch(
        slice.actions.getClassRegistrationStatsSuccess({
          status: params.status,
          data: result,
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getProductSoldStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.productSoldStatistics();

      dispatch(slice.actions.getProductSoldStatsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getTotalBalanceStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.salesTotalBalanceStatistics();

      dispatch(slice.actions.getTotalBalanceStatsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getSalesProfitStats() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.salesProfitStatistics();

      dispatch(slice.actions.getSalesProfitStatsSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getSalesByGender() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.saleByGenderStatistics();

      dispatch(slice.actions.getSalesByGenderSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getTopSalesmen() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.bestSalesManStatistics();

      dispatch(slice.actions.getTopSalesmenSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getYearlySales(
  params: {
    /**  */
    year?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.salesYearlyStatistics(params);

      dispatch(slice.actions.getYearlySalesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getComparisonMembershipPrices(
  params: {
    /**  */
    year?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.membershipPriceComparison();

      dispatch(slice.actions.getComparisonMembershipPricesSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getComparisonClv(
  params: {
    /**  */
    year?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.clvComparison();

      dispatch(slice.actions.getComparisonClvSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getComparisonArm(
  params: {
    /**  */
    year?: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const result = await StatisticsService.armComparison();

      dispatch(slice.actions.getComparisonArmSuccess(result));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
