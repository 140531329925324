import { useCallback } from 'react';
// @mui
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { useSettingsContext } from 'src/components/settings';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from 'src/components/table';
// types
//
import SubAccountTableRow from './sub-account-table-row';
import { useLocales } from '../../../locales';
import { AccountDto } from '../../../api';

// ----------------------------------------------------------------------

type Props = {
  subAccounts: AccountDto[];
};

// ----------------------------------------------------------------------

export default function SubAccountList({ subAccounts }: Props) {
  const { t } = useLocales();

  const TABLE_HEAD = [{ id: 'fullName', label: t('Name'), align: 'left' }, { id: '' }];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[0].id,
  });

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const tableData = subAccounts;

  const totalNumberOfMembers = subAccounts.length;

  const notFound = !tableData.length;

  const handleViewRow = useCallback(
    (id: number) => {
      router.push(paths.members.view(id));
    },
    [router]
  );

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {t('Sub accounts')}
          </Typography>
        </Box>
      </Stack>

      <Card
        sx={{
          mt: 1.5,
        }}
      >
        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) => {}}
            action={
              <Tooltip title={t('Delete')}>
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
              />

              <TableBody>
                {tableData?.map((row) => (
                  <SubAccountTableRow
                    key={row.id}
                    row={row}
                    onViewRow={() => handleViewRow(row.id!)}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMembers)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={totalNumberOfMembers}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------
