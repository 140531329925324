import { useCallback } from 'react';
// @mui
import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// routes
import { paths } from 'src/routes/paths';
// components
import { useRouter } from 'src/routes/hook';
import { enqueueSnackbar } from 'notistack';
//
import EventItem from './event-item';
import { EventDto, EventService } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  events: EventDto[];
  totalEvents: number;
  pageSize: number;
  onDelete: VoidFunction;
  handlePageChange: (value: number) => void;
};

export default function EventList({
  events,
  totalEvents,
  onDelete,
  handlePageChange,
  pageSize,
}: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const handleView = useCallback(
    (id: number) => {
      router.push(paths.events.view(id));
    },
    [router]
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePageChange(value);
  };

  const handleEdit = useCallback(
    (id: number) => {
      router.push(paths.events.edit(id));
    },
    [router]
  );

  const handleDelete = useCallback(
    async (id: number) => {
      await EventService.cancel({
        id,
      });

      enqueueSnackbar(t('Event cancelled!'));
      onDelete();
      router.push(paths.events.root);
      console.info('DELETE', id);
    },
    [t, router, onDelete]
  );

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(3, 1fr)',
        }}
      >
        {events.map((event) => (
          <EventItem
            key={event.id}
            event={event}
            onView={() => handleView(event.id!)}
            onEdit={() => handleEdit(event.id!)}
            onDelete={() => handleDelete(event.id!)}
          />
        ))}
      </Box>

      {totalEvents > pageSize && (
        <Pagination
          count={Math.ceil(totalEvents / pageSize)}
          onChange={handleChange}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
