import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import { BookkeepingListView } from '../../../sections/bookkeeping/view';

// ----------------------------------------------------------------------

export default function BookkeepingListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Class Template List')}</title>
      </Helmet>

      <BookkeepingListView />
    </>
  );
}
