import * as Yup from 'yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFTextField } from 'src/components/hook-form';
// types
import { ChainDto, LocationDto, LocationsService } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getGyms } from '../../redux/slices/gym';

// ----------------------------------------------------------------------

type Props = {
  currentLocation?: LocationDto;
  chain: ChainDto;
};

export default function LocationNewEditForm({ currentLocation, chain }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const mdUp = useResponsive('up', 'md');

  const { enqueueSnackbar } = useSnackbar();

  const [alertMessage, setAlertMessage] = useState('');

  const gyms = useSelector((state) => state.gym.gyms);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  const newSchema = Yup.object().shape({
    name: Yup.string().required(t('Name is required')),
    gymId: Yup.number().min(1, t('Gym is required')),
  });

  const defaultValues = useMemo(
    () => ({
      name: currentLocation?.name || '',
      gymId: currentLocation?.gymId || selectedGym?.id || (gyms.length > 0 ? gyms[0].id : -1),
    }),
    [currentLocation, selectedGym, gyms]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (currentLocation) {
      reset(defaultValues);
    }
  }, [currentLocation, defaultValues, reset]);

  useEffect(() => {
    dispatch(
      getGyms({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
      })
    );
  }, [dispatch]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentLocation) {
        await LocationsService.edit({
          id: currentLocation!.id!,
          body: { id: currentLocation!.id!, ...data } as any,
        });
      } else {
        await LocationsService.create({ body: { ...data } as any });
      }

      reset();
      enqueueSnackbar(currentLocation ? t('Update success!') : t('Create success!'));
      router.push(paths.locations.root);
    } catch (error) {
      setAlertMessage(`${error.response?.data?.detail}`);
    }
  });

  const handleGymChange = (event: SelectChangeEvent<any>) => {
    setValue('gymId', event.target.value, { shouldValidate: true });
  };

  const renderAlert = (
    <>
      {alertMessage && (
        <Grid xs={12}>
          <Alert severity="error">{alertMessage}</Alert>
        </Grid>
      )}
    </>
  );

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Details')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('Name, short description, image...')}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title={t('Details')} />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="name" label={t('Name')} placeholder={t('Ex: Hall #1')} />
            {!currentLocation && (
              <Controller
                name="gymId"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <FormControl fullWidth error={!!error}>
                    <InputLabel>{t('Gym')}</InputLabel>
                    <Select
                      value={values.gymId}
                      label={t('Gym')}
                      onChange={handleGymChange}
                      autoWidth={false}
                    >
                      {gyms.map((option) => (
                        <MenuItem key={`gym-${option.id}`} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                    {error?.message && <FormHelperText>{error?.message}</FormHelperText>}
                  </FormControl>
                )}
              />
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton
          type="submit"
          variant="contained"
          size="large"
          loading={isSubmitting}
          sx={{ ml: 2 }}
        >
          {!currentLocation ? t('Create') : t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderAlert}

        {renderDetails}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
