import { Helmet } from 'react-helmet-async';
// sections
import ContractCreateView from '../../../sections/contracts/view/contract-create-view';

// ----------------------------------------------------------------------

export default function ContractNewPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Tour Edit</title>
      </Helmet>

      <ContractCreateView />
    </>
  );
}
