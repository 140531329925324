import { memo, useState } from 'react';
import Map, { Layer, LayerProps, Source } from 'react-map-gl';
// @mui
// components
import { MapBoxProps, MapControl, MapMarker } from 'src/components/map';
import { AddressDto, PlaceDto } from '../../api';

// ----------------------------------------------------------------------

interface Props extends MapBoxProps {
  data: AddressDto[];
  places: PlaceDto[];
}

function GymMapMarkersPopups({ data, places, ...other }: Props) {
  const [popupInfo, setPopupInfo] = useState<AddressDto | null>(null);

  const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    source: 'members',
    filter: ['has', 'point_count'],
    paint: {
      'circle-color': ['step', ['get', 'point_count'], '#51bbd6', 100, '#f1f075', 750, '#f28cb1'],
      'circle-radius': ['step', ['get', 'point_count'], 20, 100, 30, 750, 40],
    },
  };

  const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    source: 'members',
    filter: ['has', 'point_count'],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'text-size': 12,
    },
  };

  const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    source: 'members',
    filter: ['!', ['has', 'point_count']],
    paint: {
      'circle-color': '#11b4da',
      'circle-radius': 4,
      'circle-stroke-width': 1,
      'circle-stroke-color': '#fff',
    },
  };

  return (
    <Map
      initialViewState={{ latitude: data[0].latitude, longitude: data[0].longitude, zoom: 12 }}
      {...other}
    >
      <MapControl
        hideScaleControl
        hideGeolocateControl
        hideFullscreenControl
        hideNavigationnControl
      />

      <Source
        id="members"
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: places.map((x) => ({
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [x.longitude ?? 0, x.latitude ?? 0],
            },
            properties: {},
          })),
        }}
        cluster
        clusterMaxZoom={14}
        clusterRadius={100}
      >
        <Layer {...clusterLayer} />
        <Layer {...clusterCountLayer} />
        <Layer {...unclusteredPointLayer} />
      </Source>

      {data.map((city, index) => (
        <MapMarker
          key={`marker-${index}`}
          latitude={city.latitude ?? 0}
          longitude={city.longitude ?? 0}
          onClick={(event) => {
            event.originalEvent.stopPropagation();
            setPopupInfo(city);
          }}
        />
      ))}
    </Map>
  );
}

export default memo(GymMapMarkersPopups);
