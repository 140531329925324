// @mui
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';
import Box from '@mui/material/Box';
// routes
import { paths } from 'src/routes/paths';
// utils
import { fDate } from 'src/utils/format-time';
import { fCurrency } from 'src/utils/format-number';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import { InvoiceDto, InvoiceStatuses } from '../../api';
import { useLocales } from '../../locales';
import Label from '../../components/label';
import { useRouter } from '../../routes/hook';

// ----------------------------------------------------------------------

type Props = {
  invoices: InvoiceDto[];
};

export default function AccountBillingHistory({ invoices }: Props) {
  const showMore = useBoolean();

  const { t } = useLocales();

  const router = useRouter();

  const orderedInvoices = ([] as InvoiceDto[]).concat(invoices);
  orderedInvoices.sort((x, y) => {
    const first = x.createdDate!.toJSDate();
    const second = y.createdDate!.toJSDate();
    return second.getTime() - first.getTime();
  });

  const getInvoiceStatus = (invoice: InvoiceDto) => {
    if (invoice.totalAmount! === (invoice.totalRefund ?? 0) && invoice.totalAmount! > 0) {
      return 'Refunded';
    }
    return invoice.status;
  };

  return (
    <Card>
      <CardHeader title={t('Invoice History')} />

      <Stack spacing={1.5} sx={{ px: 3, pt: 3 }}>
        {(showMore.value ? orderedInvoices : orderedInvoices.slice(0, 8)).map((invoice) => (
          <Stack
            key={invoice.id}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            onClick={() => router.push(paths.invoice.details(invoice.id!))}
          >
            <Box
              sx={{
                width: 1,
              }}
            >
              <ListItemText
                primary={`#${invoice.id!}`}
                secondary={fDate(invoice.createdDate)}
                primaryTypographyProps={{
                  typography: 'body2',
                }}
                secondaryTypographyProps={{
                  mt: 0.5,
                  component: 'span',
                  typography: 'caption',
                  color: 'text.disabled',
                }}
              />
            </Box>
            <Box sx={{ width: 1 }}>
              <Typography variant="body2">
                {fCurrency(invoice.totalAmount, invoice.priceCurrency)}
              </Typography>
            </Box>
            <Label
              variant="soft"
              color={
                (getInvoiceStatus(invoice) === InvoiceStatuses.Paid && 'success') ||
                (getInvoiceStatus(invoice) === InvoiceStatuses.Open && 'warning') ||
                (getInvoiceStatus(invoice) === InvoiceStatuses.Failed && 'error') ||
                'default'
              }
              sx={{ width: 1 }}
            >
              {invoice.status === InvoiceStatuses.Void
                ? t(`Voided`)
                : t(`${getInvoiceStatus(invoice)}`)}
            </Label>
          </Stack>
        ))}

        <Divider sx={{ borderStyle: 'dashed' }} />
      </Stack>

      <Stack alignItems="flex-start" sx={{ p: 2 }}>
        <Button
          size="small"
          color="inherit"
          startIcon={
            <Iconify
              icon={showMore.value ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
            />
          }
          onClick={showMore.onToggle}
        >
          {showMore.value ? t(`Show Less`) : t(`Show More`)}
        </Button>
      </Stack>
    </Card>
  );
}
