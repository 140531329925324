// routes
import { paths } from 'src/routes/paths';

// API
// ----------------------------------------------------------------------
export const HOST_API = process.env.REACT_APP_HOST_API;

export const HOST_HUB = process.env.REACT_APP_HOST_HUB;
export const ASSETS_API = process.env.REACT_APP_ASSETS_API;
export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;
export const SENTRY_DSN = process.env.SENTRY_DSN;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = paths.dashboard.root; // as '/dashboard'
