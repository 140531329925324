// @mui
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// types
import { IOrderCustomer } from 'src/types/order';
// components
import Iconify from 'src/components/iconify';
import { AddressDto, PaymentMethodDto } from '../../api';
import { useLocales } from '../../locales';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';

// ----------------------------------------------------------------------

type Props = {
  customer: IOrderCustomer;
  payment?: PaymentMethodDto;
  shippingAddress: AddressDto;
};

export default function OrderDetailsInfo({ customer, payment, shippingAddress }: Props) {
  const { t } = useLocales();

  const router = useRouter();

  const renderCustomer = (
    <>
      <CardHeader title={t('Customer Info')} />
      <Stack
        direction="row"
        sx={{ p: 3 }}
        onClick={() => router.push(paths.members.view(customer.id!))}
      >
        <Avatar
          alt={customer.name}
          src={customer.avatarUrl}
          sx={{ width: 48, height: 48, mr: 2 }}
        />

        <Stack
          spacing={0.5}
          alignItems="flex-start"
          sx={{
            typography: 'body2',
            overflow: 'hidden',
          }}
        >
          <Typography variant="subtitle2">{customer.name}</Typography>

          <Box
            sx={{
              color: 'text.secondary',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              maxWidth: '100%',
              textOverflow: 'ellipsis',
            }}
          >
            {customer.email}
          </Box>

          {customer.ipAddress ? (
            <Box>
              IP Address:
              <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
                {customer.ipAddress}
              </Box>
            </Box>
          ) : null}
        </Stack>
      </Stack>
    </>
  );

  const renderShipping = (
    <>
      <CardHeader title={t('Shipping')} />
      <Stack spacing={1.5} sx={{ p: 3, typography: 'body2' }}>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            {t('Address')}
          </Box>
          {`${shippingAddress.addressLine}, ${shippingAddress.postalNumber} ${shippingAddress.city}, ${shippingAddress.country}`}
        </Stack>
        <Stack direction="row" alignItems="center">
          <Box component="span" sx={{ color: 'text.secondary', width: 120, flexShrink: 0 }}>
            {t('Phone number')}
          </Box>
          {customer.phoneNumber}
        </Stack>
      </Stack>
    </>
  );

  const renderPayment = (
    <>
      <CardHeader title={t('Payment')} />
      <Stack direction="row" alignItems="center" sx={{ p: 3, typography: 'body2' }}>
        <Box component="span" sx={{ color: 'text.secondary', flexGrow: 1 }}>
          {t('Card number')}
        </Box>

        {payment?.maskedCard
          ?.replace(/X/g, '*')
          .match(/.{1,4}/g)
          ?.join(' ')}
        <Iconify
          icon={(payment?.cardType === 'visa' && 'logos:visa') || 'logos:mastercard'}
          width={36}
        />
      </Stack>
    </>
  );

  return (
    <Card>
      {renderCustomer}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderShipping}

      <Divider sx={{ borderStyle: 'dashed' }} />

      {renderPayment}
    </Card>
  );
}
