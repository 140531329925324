import { Helmet } from 'react-helmet-async';
// sections
import DropInCreateView from '../../../../sections/membership-types/drop-in/view/drop-in-create-view';

// ----------------------------------------------------------------------

export default function DropInTypeCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new product</title>
      </Helmet>

      <DropInCreateView />
    </>
  );
}
