import { useCallback, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import { CardProps } from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
import { useCopyToClipboard } from 'src/hooks/use-copy-to-clipboard';
// utils
import { fDateTime } from 'src/utils/format-time';
import { fData } from 'src/utils/format-number';
// types
import { IFileManager } from 'src/types/file';
// components
import Iconify from 'src/components/iconify';
import TextMaxLine from 'src/components/text-max-line';
import FileThumbnail from 'src/components/file-thumbnail';
//
import { IReport, IReportManager } from '../../types/report';
import { useRouter } from '../../routes/hook';
import { paths } from '../../routes/paths';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

interface Props extends CardProps {
  file: IReportManager;
  selected?: boolean;
  onSelect?: VoidFunction;
  onDelete: VoidFunction;
}

export default function ReportsItem({ file, selected, onSelect, onDelete, sx, ...other }: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const renderIcon = <FileThumbnail file={file.type} sx={{ width: 36, height: 36 }} />;

  const renderText = (
    <>
      <TextMaxLine persistent variant="subtitle2" sx={{ width: 1, mt: 2 }}>
        {t(file.name)}
      </TextMaxLine>
    </>
  );

  return (
    <>
      <Stack
        component={Paper}
        variant="outlined"
        alignItems="flex-start"
        onClick={() => router.push(paths.report.view(file.url))}
        sx={{
          pt: 2.5,
          pr: 2.5,
          pl: 2.5,
          pb: 2,
          borderRadius: 2,
          bgcolor: 'unset',
          cursor: 'pointer',
          position: 'relative',
          ...sx,
        }}
        {...other}
      >
        {renderIcon}

        {renderText}
      </Stack>
    </>
  );
}
