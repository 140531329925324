import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import GymNewEditForm from '../gym-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getChainSettings } from '../../../redux/slices/chain';
import { getLocks } from '../../../redux/slices/gym';

// ----------------------------------------------------------------------

export default function GymCreateView() {
  const settings = useSettingsContext();

  const dispatch = useDispatch();

  const { t } = useLocales();

  const chainSettings = useSelector((state) => state.chain.currentSettings);

  const locks = useSelector((state) => state.gym.locks);

  useEffect(() => {
    dispatch(getChainSettings());
    dispatch(getLocks({}));
  }, [dispatch]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new gym')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Gym'),
            href: paths.productCategory.root,
          },
          { name: t('New gym') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <GymNewEditForm chainSettings={chainSettings} locks={locks} />
    </Container>
  );
}
