// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import LocationNewEditForm from '../location-new-edit-form';
import { useLocales } from '../../../locales';
import { useSelector } from '../../../redux/store';

// ----------------------------------------------------------------------

export default function LocationCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const chain = useSelector((state) => state.chain.currentChain);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create a new location')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Location'),
            href: paths.locations.root,
          },
          { name: t('New') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <LocationNewEditForm chain={chain} />
    </Container>
  );
}
