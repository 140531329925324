import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  Direction,
  getConfigs,
  ProgramDto,
  ProgramsService,
  ProgramTemplateDto,
  serviceOptions,
  WorkoutTypeDto,
  WorkoutTypesService,
} from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  scheduledPrograms: [] as ProgramDto[],
  totalNumberOfTemplates: 0,
  currentTemplate: {} as ProgramTemplateDto,
  workoutTypes: [] as WorkoutTypeDto[],
};

const slice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stoppedLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassTemplatesSuccess(state, action) {
      state.scheduledPrograms = action.payload.scheduledPrograms;
      state.totalNumberOfTemplates = action.payload.totalNumberOfTemplates;
    },
    getClassTemplateSuccess(state, action) {
      state.currentTemplate = action.payload;
    },
    getWorkoutTypesSuccess(state, action) {
      state.workoutTypes = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPrograms(
  params: {
    from: DateTime | null;
    to: DateTime | null;
    searchString?: string;
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/programs';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getClassTemplatesSuccess({
          totalNumberOfTemplates: parseInt(resp.headers['x-total-count'], 10),
          scheduledPrograms: resp.data,
        })
      );

      dispatch(slice.actions.stoppedLoading());
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getProgram(
  params: {
    /**  */
    programId: number;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await ProgramsService.getById(params);

      dispatch(slice.actions.getClassTemplateSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}

export function getWorkoutTypes() {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const resp = await WorkoutTypesService.findAll();

      dispatch(slice.actions.getWorkoutTypesSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError({ ...error }));
    }
  };
}
