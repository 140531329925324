import { useCallback, useEffect, useState } from 'react';
// @mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import ListItemButton, { listItemButtonClasses } from '@mui/material/ListItemButton';
// types
// components
import Iconify from 'src/components/iconify';
import SearchNotFound from 'src/components/search-not-found';
import { getCustomers } from '../../redux/slices/members';
import { useDispatch, useSelector } from '../../redux/store';
import { CustomerDto, InvoiceAddressDto } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  action?: React.ReactNode;
  //
  open: boolean;
  onClose: VoidFunction;
  //
  selected: (selectedId: string) => boolean;
  onSelect: (address: InvoiceAddressDto | null) => void;
};

export default function AddressListDialog({
  title,
  action,
  //
  open,
  onClose,
  //
  selected,
  onSelect,
}: Props) {
  const { t } = useLocales();

  const [searchAddress, setSearchAddress] = useState('');

  const dataFiltered = useSelector((state) => state.member.customers);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      getCustomers({
        name: searchAddress,
        companyName: searchAddress,
        email: searchAddress,
        vatNo: searchAddress,
      })
    );
  }, [dispatch, searchAddress]);

  const notFound = !dataFiltered.length && !!searchAddress;

  const handleSearchAddress = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchAddress(event.target.value);
  }, []);

  const handleSelectAddress = useCallback(
    (customer: CustomerDto | null) => {
      onSelect({
        customerId: customer?.id,
        firstName: customer?.firstName,
        lastName: customer?.lastName,
        middleName: customer?.middleName,
        email: customer?.email,
        vatNo: customer?.vatNo,
        companyName: customer?.companyName,
        city: customer?.city,
        addressLine: customer?.addressLine,
        postalNumber: customer?.postalNumber,
        country: customer?.country,
        phoneNumber: customer?.phoneNumber,
      });
      setSearchAddress('');
      onClose();
    },
    [onClose, onSelect]
  );

  const renderList = (
    <Stack
      spacing={0.5}
      sx={{
        p: 0.5,
        maxHeight: 80 * 8,
        overflowX: 'hidden',
      }}
    >
      {dataFiltered.map((customer) => (
        <Stack
          key={customer.id}
          spacing={0.5}
          component={ListItemButton}
          selected={selected(`${customer.id}`)}
          onClick={() => handleSelectAddress(customer)}
          sx={{
            py: 1,
            px: 1.5,
            borderRadius: 1,
            flexDirection: 'column',
            alignItems: 'flex-start',
            [`&.${listItemButtonClasses.selected}`]: {
              bgcolor: 'action.selected',
              '&:hover': {
                bgcolor: 'action.selected',
              },
            },
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{customer.fullName}</Typography>
          </Stack>

          {customer.companyName && (
            <Box sx={{ color: 'primary.main', typography: 'caption' }}>{customer.companyName}</Box>
          )}

          {customer.addressLine && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {`${customer.addressLine}, ${customer.postalNumber} ${customer.city}, ${customer.country}`}
            </Typography>
          )}

          {customer.phoneNumber && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {customer.phoneNumber}
            </Typography>
          )}

          {customer.vatNo && (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {customer.vatNo}
            </Typography>
          )}
        </Stack>
      ))}
    </Stack>
  );

  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ p: 3, pr: 1.5 }}
      >
        <Typography variant="h6"> {t('Address Book')} </Typography>

        {action && action}
      </Stack>

      <Stack sx={{ p: 2, pt: 0 }}>
        <TextField
          value={searchAddress}
          onChange={handleSearchAddress}
          placeholder={t('Search...')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      {notFound ? (
        <SearchNotFound query={searchAddress} sx={{ px: 3, pt: 5, pb: 10 }} />
      ) : (
        renderList
      )}
    </Dialog>
  );
}
