import { useCallback } from 'react';
// @mui
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { useLocales } from '../../../locales';
import { IReportFilterValue, IReportTableFilters } from '../../../types/report';

// ----------------------------------------------------------------------

type Props = {
  filters: IReportTableFilters;
  onFilters: (name: string, value: IReportFilterValue) => void;
  onPrint: VoidFunction;
};

export default function MemberChurnReportToolbar({ filters, onFilters, onPrint }: Props) {
  const popover = usePopover();

  const { t } = useLocales();

  const handleFilterValidFrom = useCallback(
    (newValue: DateTime | null) => {
      onFilters('fromDate', newValue);
    },
    [onFilters]
  );

  const handleFilterValidTo = useCallback(
    (newValue: DateTime | null) => {
      onFilters('toDate', newValue);
    },
    [onFilters]
  );

  return (
    <>
      <Stack
        spacing={2}
        alignItems={{ xs: 'flex-end', md: 'center' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        sx={{
          p: 2.5,
          pr: { xs: 2.5, md: 1 },
        }}
      >
        <Stack direction="row" alignItems="center" spacing={2} flexGrow={1} sx={{ width: 1 }}>
          <FormControl fullWidth>
            <DatePicker
              label={t('From date')}
              value={filters.fromDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterValidFrom}
            />
          </FormControl>

          <FormControl fullWidth>
            <DatePicker
              label={t('To date')}
              value={filters.toDate}
              slotProps={{
                textField: {
                  fullWidth: true,
                },
              }}
              onChange={handleFilterValidTo}
            />
          </FormControl>

          <IconButton onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </Stack>
      </Stack>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onPrint();
          }}
        >
          <Iconify icon="solar:printer-minimalistic-bold" />
          {t('Print')}
        </MenuItem>
      </CustomPopover>
    </>
  );
}
