import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import EmployeeNewEditForm from '../employee-new-edit-form';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getEmployee, getRoles } from '../../../redux/slices/employees';

// ----------------------------------------------------------------------

export default function EmployeeEditView() {
  const settings = useSettingsContext();

  const params = useParams();

  const { t } = useLocales();

  const { id } = params;

  const dispatch = useDispatch();

  const currentEmployee = useSelector((state) => state.employee.currentEmployee);

  const roles = useSelector((state) => state.employee.roles);

  useEffect(() => {
    dispatch(getEmployee(parseInt(id!, 10)));
    dispatch(getRoles());
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[
          {
            name: t('Dashboard'),
            href: paths.dashboard.root,
          },
          {
            name: t('Employee'),
            href: paths.employee.root,
          },
          { name: currentEmployee?.name },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <EmployeeNewEditForm currentEmployee={currentEmployee} roles={roles} />
    </Container>
  );
}
