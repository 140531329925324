// @mui
import Typography from '@mui/material/Typography';
import Paper, { PaperProps } from '@mui/material/Paper';
import Stack, { StackProps } from '@mui/material/Stack';
// types
// components
import Label from 'src/components/label';
import { AccountDto, AddressDto } from '../../api';

// ----------------------------------------------------------------------

type Props = PaperProps &
  StackProps & {
    action?: React.ReactNode;
    address: AddressDto;
    member: AccountDto;
  };

export default function AddressItem({ address, member, action, sx, ...other }: Props) {
  const { addressLine, city, postalNumber, country } = address;
  const { fullName, phoneNumber } = member;

  return (
    <Stack
      component={Paper}
      spacing={2}
      alignItems={{ md: 'flex-end' }}
      direction={{ xs: 'column', md: 'row' }}
      sx={{
        position: 'relative',
        ...sx,
      }}
      {...other}
    >
      <Stack flexGrow={1} spacing={1}>
        <Stack direction="row" alignItems="center">
          <Typography variant="subtitle2">{fullName}</Typography>

          <Label color="info" sx={{ ml: 1 }}>
            Default
          </Label>
        </Stack>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${addressLine}, ${postalNumber} ${city}, ${country}`}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {phoneNumber}
        </Typography>
      </Stack>

      {action && action}
    </Stack>
  );
}
