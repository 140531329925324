import { useEffect } from 'react';
// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import ChainNewEditForm from '../chain-new-edit-form';
import { useDispatch, useSelector } from '../../../redux/store';
import { useLocales } from '../../../locales';
import { getChain, getChainSettings } from '../../../redux/slices/chain';
import { getEmailTemplates } from '../../../redux/slices/email-template';

// ----------------------------------------------------------------------

export default function ChainEditView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const params = useParams();

  const { id = '' } = params;

  const dispatch = useDispatch();

  const currentSettings = useSelector((state) => state.chain.currentSettings);
  const currentChain = useSelector((state) => state.chain.currentChain);
  const emailTemplates = useSelector((state) => state.emailTemplate.templates);

  useEffect(() => {
    dispatch(getChainSettings());
    dispatch(getChain());
    dispatch(
      getEmailTemplates({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch, id]);

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Edit')}
        links={[{ name: t('Dashboard'), href: paths.dashboard.root }, { name: t('Settings') }]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <ChainNewEditForm
        settings={currentSettings}
        chain={currentChain}
        emailTemplates={emailTemplates}
      />
    </Container>
  );
}
