import { useCallback, useEffect, useRef, useState } from 'react';
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TableContainer from '@mui/material/TableContainer';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
// components
import isEqual from 'lodash/isEqual';
import Iconify from 'src/components/iconify';
import { enqueueSnackbar } from 'notistack';
//
import { EmployeeDto, EmployeesService, EmployeeWageRateDto } from '../../api';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getEmployeeWageRates } from '../../redux/slices/employees';
import Label from '../../components/label';
import Scrollbar from '../../components/scrollbar/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  TableSelectedAction,
  useTable,
} from '../../components/table';
import { IUserTableFilters, IUserTableFilterValue } from '../../types/user';
import { useSettingsContext } from '../../components/settings';
import { useRouter } from '../../routes/hook';
import { useBoolean } from '../../hooks/use-boolean';
import WageRateTableRow from './wage-rates-table-row';
import { CALENDAR_COLOR_OPTIONS } from '../../_mock';
import EditAddWageRateForm from './edit-add-wage-rate-form';
import ClazzDetailsTableFiltersResult from './clazz-details-table-filters-result';
import ClazzDetailsTableToolbar from './clazz-details-table-toolbar';

// ----------------------------------------------------------------------

const defaultFilters: IUserTableFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

type Props = {
  member: EmployeeDto;
};

export default function ProfileSalary({ member }: Props) {
  const theme = useTheme();

  const fileRef = useRef<HTMLInputElement>(null);

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const addSalaryPopup = useBoolean();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const tableData = useSelector((state) => state.employee.currentEmployeeWageRates);

  const [currentRate, setCurrentRate] = useState<EmployeeWageRateDto | undefined>(undefined);

  useEffect(() => {
    dispatch(getEmployeeWageRates(member.id!));
  }, [dispatch, member]);

  const TABLE_HEAD = [
    { id: 'wageRateName', label: t('Name'), align: 'left' },
    { id: 'payrollEmployeeId', label: t('External ID'), align: 'left' },
    { id: 'validFrom', label: t('Valid from'), align: 'left' },
    { id: 'validTo', label: t('Valid to'), align: 'left' },
    { id: '' },
  ];

  const STATUS_OPTIONS = [
    { value: 'all', label: t('All'), color: 'info', count: tableData.length },
  ] as const;

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: TABLE_HEAD[2].id,
    defaultOrder: 'desc',
  });

  const [filters, setFilters] = useState(defaultFilters);

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IUserTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (id: number) => {
      EmployeesService.deleteWageRate({
        id: member.id!,
        wageRateId: id,
      }).then(() => {
        enqueueSnackbar(t('Delete success!'));
        dispatch(getEmployeeWageRates(member.id!));
      });
    },
    [dispatch, member, t]
  );

  const handleEditRow = useCallback(
    (rate: EmployeeWageRateDto) => {
      setCurrentRate(rate);
      addSalaryPopup.onTrue();
    },
    [addSalaryPopup]
  );

  const handleFilterStatus = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      handleFilters('status', newValue);
    },
    [handleFilters]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          pb: 2.5,
          pr: { xs: 2.5, md: 1 },
          pl: { xs: 2.5, md: 1 },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4" gutterBottom>
            {t('Wage Rate List')}
          </Typography>
        </Box>

        <Button
          onClick={() => {
            addSalaryPopup.onTrue();
          }}
          variant="contained"
          startIcon={<Iconify icon="mingcute:add-line" />}
        >
          {t('New wage rate')}
        </Button>
      </Stack>
      <Card>
        <Tabs
          value={filters.status}
          onChange={handleFilterStatus}
          sx={{
            px: 2.5,
            boxShadow: (bTheme) => `inset 0 -2px 0 0 ${alpha(bTheme.palette.grey[500], 0.08)}`,
          }}
        >
          {STATUS_OPTIONS.map((tab) => (
            <Tab
              key={tab.value}
              iconPosition="end"
              value={tab.value}
              label={tab.label}
              icon={
                <Label
                  variant={
                    ((tab.value === 'all' || tab.value === filters.status) && 'filled') || 'soft'
                  }
                  color={tab.color}
                >
                  {tab.count}
                </Label>
              }
            />
          ))}
        </Tabs>

        <ClazzDetailsTableToolbar filters={filters} onFilters={handleFilters} />

        {canReset && (
          <ClazzDetailsTableFiltersResult
            filters={filters}
            onFilters={handleFilters}
            //
            canReset={canReset}
            onResetFilters={handleResetFilters}
            //
            results={tableData.length}
            sx={{ p: 2.5, pt: 0 }}
          />
        )}

        <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
          <TableSelectedAction
            dense={dense}
            numSelected={selected.length}
            rowCount={tableData.length}
            onSelectAllRows={(checked) => {}}
            action={
              <Tooltip title={t('Delete')}>
                <IconButton color="primary" onClick={confirm.onTrue}>
                  <Iconify icon="solar:trash-bin-trash-bold" />
                </IconButton>
              </Tooltip>
            }
          />

          <Scrollbar>
            <Table size={dense ? 'small' : 'medium'}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData.length}
                numSelected={selected.length}
                onSort={onSort}
              />

              <TableBody>
                {tableData?.map((row) => (
                  <WageRateTableRow
                    key={row.id}
                    row={row}
                    onEditRow={() => handleEditRow(row)}
                    onDeleteRow={() => handleDeleteRow(row.id!)}
                  />
                ))}

                <TableEmptyRows
                  height={denseHeight}
                  emptyRows={emptyRows(page, rowsPerPage, tableData.length)}
                />

                <TableNoData notFound={notFound} />
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>

        <TablePaginationCustom
          count={tableData.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          //
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </Card>

      <Dialog
        fullWidth
        maxWidth="xs"
        open={addSalaryPopup.value}
        onClose={addSalaryPopup.onFalse}
        transitionDuration={{
          enter: theme.transitions.duration.shortest,
          exit: theme.transitions.duration.shortest - 80,
        }}
      >
        <DialogTitle sx={{ minHeight: 76 }}>{t('Add new salary')}</DialogTitle>

        <EditAddWageRateForm
          currentEmployee={member}
          currentRate={currentRate}
          colorOptions={CALENDAR_COLOR_OPTIONS}
          onClose={() => {
            addSalaryPopup.onFalse();
            setCurrentRate(undefined);
            dispatch(getEmployeeWageRates(member.id!));
          }}
        />
      </Dialog>
    </>
  );
}
