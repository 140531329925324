import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router-dom';
// auth
import { GuestGuard } from 'src/auth/guard';
// layouts
import AuthClassicLayout from 'src/layouts/auth/classic';
// components
import { SplashScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// JWT
const JwtLoginPage = lazy(() => import('src/pages/auth/jwt/login'));
const JwtRegisterPage = lazy(() => import('src/pages/auth/jwt/register'));

// ----------------------------------------------------------------------

const authJwt = (
  <Route
    path="jwt"
    element={
      <GuestGuard>
        <Suspense fallback={<SplashScreen />}>
          <Outlet />
        </Suspense>
      </GuestGuard>
    }
    children={[
      <Route
        path="login"
        element={
          <AuthClassicLayout>
            <JwtLoginPage />
          </AuthClassicLayout>
        }
      />,
      <Route
        path="register"
        element={
          <AuthClassicLayout title="Manage your training center more effectively with Booking Board">
            <JwtRegisterPage />
          </AuthClassicLayout>
        }
      />,
    ]}
  />
);

export const authRoutes = [<Route path="auth" children={authJwt} />];
