import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import { GuestCreateView } from '../../../sections/guest/view';

// ----------------------------------------------------------------------

export default function GuestCreatePage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Create a new guest')}</title>
      </Helmet>

      <GuestCreateView />
    </>
  );
}
