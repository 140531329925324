// @mui
import Container from '@mui/material/Container';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
//
import CampaignNewEditForm from '../campaign-new-edit-form';
import { useLocales } from '../../../../locales';

// ----------------------------------------------------------------------

export default function CampaignCreateView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Create new campaign type')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          {
            name: t('Campaign type'),
            href: paths.campaignType.root,
          },
          { name: t('New campaign type') },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />

      <CampaignNewEditForm />
    </Container>
  );
}
