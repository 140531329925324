import { useEffect, useState } from 'react';
// @mui
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import { useLocales } from '../../../locales';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fCurrency, fNumber } from '../../../utils/format-number';
import Iconify from '../../../components/iconify';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import { IInsightTableFilters } from '../../../types/insight';
import { ChartSegments } from '../../../api';
import { getContingentMonthlyRevenue } from '../../../redux/slices/insight';
import { getContingents } from '../../../redux/slices/membership-type';

// ----------------------------------------------------------------------

export default function RevenueContingentView({
  fromDate,
  toDate,
  membershipTypeIds,
}: IInsightTableFilters) {
  const { t } = useLocales();

  const popover = usePopover();

  const popoverMembershipType = usePopover();

  const dispatch = useDispatch();

  const [segment, setSegment] = useState(ChartSegments.All);

  const report = useSelector((state) => state.insight.contingentMonthlyRevenue);

  const settings = useSelector((state) => state.chain.currentSettings);

  const membershipTypes = useSelector((state) => state.membershipType.contingents);

  const [membershipTypeId, setMembershipTypeId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(
      getContingents({
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        validFrom: toDate,
        availableFrom: fromDate,
        sortBy: ['name'],
      })
    );
  }, [dispatch, fromDate, toDate]);

  useEffect(() => {
    dispatch(
      getContingentMonthlyRevenue({
        fromDate,
        toDate,
        segment,
        membershipTypeIds,
      })
    );
  }, [dispatch, fromDate, toDate, segment, membershipTypeIds]);

  const chart = {
    series:
      report.series?.map((p) => ({
        name: p.name,
        data:
          p.data?.map((d: any) => ({
            x: d.x,
            y: d.y,
          })) ?? [],
      })) ?? [],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      labels: {
        formatter: (value) => DateTime.fromISO(value).toFormat('MMMM yyyy'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: false,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fCurrency(value, settings.defaultCurrency)}`,
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.median ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.average ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  return (
    <>
      <Card>
        <CardHeader
          title={t('Contingent monthly revenue')}
          action={
            <ButtonBase
              onClick={popover.onOpen}
              sx={{
                pl: 1,
                py: 0.5,
                pr: 0.5,
                borderRadius: 1,
                typography: 'subtitle2',
                bgcolor: 'background.neutral',
              }}
            >
              {t(segment)}

              <Iconify
                width={16}
                icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                sx={{ ml: 0.5 }}
              />
            </ButtonBase>
          }
        />
        <CardContent>
          <Chart dir="ltr" type="area" series={chart.series} options={chartOptions} />
        </CardContent>
      </Card>
      <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        <MenuItem
          key={ChartSegments.All}
          selected={ChartSegments.All === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.All);
          }}
        >
          {t(ChartSegments.All)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Gender}
          selected={ChartSegments.Gender === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Gender);
          }}
        >
          {t(ChartSegments.Gender)}
        </MenuItem>
        <MenuItem
          key={ChartSegments.Age}
          selected={ChartSegments.Age === segment}
          onClick={() => {
            popover.onClose();
            setSegment(ChartSegments.Age);
          }}
        >
          {t(ChartSegments.Age)}
        </MenuItem>
      </CustomPopover>
      <CustomPopover open={popoverMembershipType.open} onClose={popoverMembershipType.onClose}>
        <MenuItem
          key={null}
          selected={membershipTypeId === null}
          onClick={() => {
            popoverMembershipType.onClose();
            setMembershipTypeId(null);
          }}
        >
          {t('All')}
        </MenuItem>
        {(membershipTypes ?? []).map((membershipType) => (
          <MenuItem
            key={membershipType.id}
            selected={membershipType.id === membershipTypeId}
            onClick={() => {
              popoverMembershipType.onClose();
              setMembershipTypeId(membershipType.id!);
            }}
          >
            {membershipType.name}
          </MenuItem>
        ))}
      </CustomPopover>
    </>
  );
}
