import * as Yup from 'yup';
import { useCallback, useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Tooltip from '@mui/material/Tooltip';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSwitch,
  RHFTextField,
  RHFUpload,
} from 'src/components/hook-form';
// types
import { setError } from 'src/redux/slices/error';
import {
  AccessSystemProviders,
  BookkeepingProviders,
  ChainDto,
  ChainSettingsDto,
  ChainSettingsService,
  ChainsService,
  DoorSystemIntegrationService,
  EmailTemplateDto,
  EmailTemplateType,
  ExternalProgramProviders,
  ExternalSystemProviders,
  PayrollSystem,
  StripeConnectService,
  SubscriptionType,
  TrackingProviders,
} from '../../api';
import { useLocales } from '../../locales';
import { useDispatch } from '../../redux/store';
import { getChain } from '../../redux/slices/chain';
import { currencies } from '../../utils/currency';
import { fCurrencySymbol } from '../../utils/format-number';

// ----------------------------------------------------------------------

type Props = {
  settings: ChainSettingsDto;
  chain: ChainDto;
  emailTemplates: EmailTemplateDto[];
};

export default function ChainNewEditForm({ settings, chain, emailTemplates }: Props) {
  const mdUp = useResponsive('up', 'md');

  const { t } = useLocales();

  const { enqueueSnackbar } = useSnackbar();

  const newSchema = Yup.object().shape({
    chainName: Yup.string().required(t('Name is required')),
    isFineEnabledWhenTooLate: Yup.boolean(),
    fineWhenTooLateAmount: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    isFineEnabledWhenNeverMet: Yup.boolean(),
    fineWhenNeverMetAmount: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    setupFeeAmount: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    defaultTaxRate: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    isCheckInTooLateIntervalEnabled: Yup.boolean(),
    checkInTooLateInterval: Yup.mixed(),
    isCheckInIntervalEnabled: Yup.boolean(),
    checkInInterval: Yup.mixed(),
    isOptOutTooLateIntervalEnabled: Yup.boolean(),
    optOutTooLateInvalidInterval: Yup.mixed(),
    optOutCutoffInterval: Yup.mixed(),
    isReservationInvalidIntervalEnabled: Yup.boolean(),
    reservationInvalidInterval: Yup.mixed(),
    isLateCheckInAllowed: Yup.boolean(),
    lateCheckInInterval: Yup.mixed(),
    defaultCurrency: Yup.string().required(t('Currency is required')),
    noReplyEmailAddress: Yup.string().required(t('No-reply email is required')),
    mailProviderDomain: Yup.string(),
    mailProviderApiKey: Yup.string(),
    maximumNumberOfActiveClassRegistrations: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    maximumNumberOfWaitListPerDay: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    limitClassesPerDay: Yup.boolean(),
    maximumNumberOfClassesPerDay: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    isClassAdditionToEnabled: Yup.boolean(),
    checkinMessage: Yup.string(),
    personalTrainingDescriptionId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    putOnHoldFeeAmount: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    defaultPayrollSystem: Yup.mixed(),
    defaultPaymentProvider: Yup.mixed(),
    useVoucherClipsForFines: Yup.boolean(),
    sendSmsWhenCancellingClasses: Yup.boolean(),
    retentionEmailTemplateId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    needsSignatureOnContracts: Yup.boolean(),
    salesWithVat: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    salesWithoutVat: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    paymentProvider: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    bank: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    fees: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    cash: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    check: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    mobilePay: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    paymentFeeAmount: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    isFineEnabledWhenOptOutTooLate: Yup.boolean(),
    fineWhenOptOutTooLateAmount: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    automaticCancellingOfClasses: Yup.boolean(),
    minimumNumberOfRegistrationsPerClass: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1)
    ),
    timeBeforeAutomaticCancellationCheck: Yup.mixed(),
    bookingInAdvanceAllowedForDays: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1)
    ),
    maximumNumberOfActiveMemberships: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    allowedNumberOfVisibleCalendarDaysForMembers: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1)
    ),
    isTrailTrainingFollowUpEnabled: Yup.boolean(),
    trailTrainingFollowUpEmailId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    isTrailTrainingWelcomeEnabled: Yup.boolean(),
    trailTrainingWelcomeEmailId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    defaultBookkeepingProvider: Yup.mixed(),
    externalProgramProvider: Yup.mixed(),
    externalProgramProviderApiKey: Yup.string(),
    isShowingEmployeePhoneNumberAllowed: Yup.boolean(),
    colorPrimary: Yup.string().required(t('Name is required')),
    colorSecondary: Yup.string().required(t('Name is required')),
    trackingCode: Yup.string(),
    trackingProvider: Yup.mixed(),
    accessSystemProvider: Yup.mixed(),
    accessSystemProviderEndpoint: Yup.string(),
    accessSystemProviderUsername: Yup.string(),
    accessSystemProviderPassword: Yup.string(),
    accessSystemAccessGroupId: Yup.string(),
    timeZone: Yup.string().required(t('Name is required')),
    showAllClassesInApp: Yup.boolean(),
    hasReferralBonus: Yup.boolean(),
    isPaymentsActive: Yup.boolean(),
    deleteShiftsWhenCancellingClasses: Yup.boolean(),
    referralBonus: Yup.lazy((value) => (value === '' ? Yup.string() : Yup.number().min(0))),
    referralCandidateEmailId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    referralRefererEmailId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
    transferPaymentProviderFeesToCustomers: Yup.boolean(),
    defaultPaymentGateway: Yup.mixed(),
    images: Yup.array().min(1, t('Image is required')),
    programAccessKey: Yup.string(),
    bankAccount: Yup.string(),
    bankAccountIban: Yup.string(),
    bankAccountSwift: Yup.string(),
    appStoreLink: Yup.string(),
    playStoreLink: Yup.string(),
    oneSignalAppId: Yup.string(),
    oneSignalApiKey: Yup.string(),
    domain: Yup.string(),
    notifyAboutNewMembers: Yup.boolean(),
    notifyAboutFines: Yup.boolean(),
    disableLeaderboard: Yup.boolean(),
    isFeedbackAllowed: Yup.boolean(),
    googleTagManagerTrackingCode: Yup.string().max(12, t('Needs to be in format GTM-XXXXXXXX')),
    requirePaymentMethodOnAccounts: Yup.boolean(),
  });

  const defaultValues = useMemo(
    () => ({
      chainName: settings?.chainName ?? '',
      isFineEnabledWhenTooLate: settings?.isFineEnabledWhenTooLate ?? false,
      fineWhenTooLateAmount: settings?.fineWhenTooLateAmount ?? 0,
      isFineEnabledWhenNeverMet: settings?.isFineEnabledWhenNeverMet ?? false,
      fineWhenNeverMetAmount: settings?.fineWhenNeverMetAmount ?? 0,
      setupFeeAmount: settings?.setupFeeAmount ?? 0,
      defaultTaxRate: settings?.defaultTaxRate ?? 0,
      isCheckInTooLateIntervalEnabled: settings?.isCheckInTooLateIntervalEnabled ?? false,
      checkInTooLateInterval: settings?.checkInTooLateInterval
        ? settings?.checkInTooLateInterval
        : null,
      isCheckInIntervalEnabled: settings?.isCheckInIntervalEnabled ?? false,
      checkInInterval: settings?.checkInInterval ? settings?.checkInInterval : null,
      isOptOutTooLateIntervalEnabled: settings?.isOptOutTooLateIntervalEnabled ?? false,
      optOutTooLateInvalidInterval: settings?.optOutTooLateInvalidInterval
        ? settings?.optOutTooLateInvalidInterval
        : null,
      optOutCutoffInterval: settings?.optOutCutoffInterval ? settings?.optOutCutoffInterval : null,
      isReservationInvalidIntervalEnabled: settings?.isReservationInvalidIntervalEnabled ?? false,
      reservationInvalidInterval: settings?.reservationInvalidInterval
        ? settings?.reservationInvalidInterval
        : null,
      isLateCheckInAllowed: settings?.isLateCheckInAllowed ?? false,
      lateCheckInInterval: settings?.lateCheckInInterval ? settings?.lateCheckInInterval : null,
      defaultCurrency: settings?.defaultCurrency || 'DKK',
      noReplyEmailAddress: settings?.noReplyEmailAddress ?? '',
      mailProviderDomain: settings?.mailProviderDomain ?? '',
      mailProviderApiKey: settings?.mailProviderApiKey ?? '',
      maximumNumberOfActiveClassRegistrations:
        settings?.maximumNumberOfActiveClassRegistrations ?? '',
      maximumNumberOfWaitListPerDay: settings?.maximumNumberOfWaitListPerDay ?? '',
      limitClassesPerDay: settings?.limitClassesPerDay ?? false,
      maximumNumberOfClassesPerDay: settings?.maximumNumberOfClassesPerDay ?? '',
      isClassAdditionToEnabled: settings?.isClassAdditionToEnabled ?? false,
      checkinMessage: settings?.checkinMessage ?? '',
      personalTrainingDescriptionId: settings?.personalTrainingDescriptionId ?? '',
      putOnHoldFeeAmount: settings?.putOnHoldFeeAmount ?? 0,
      defaultPayrollSystem: settings?.defaultPayrollSystem ?? '',
      defaultPaymentProvider: settings?.defaultPaymentProvider ?? ExternalSystemProviders.Reepay,
      useVoucherClipsForFines: settings?.useVoucherClipsForFines ?? false,
      retentionEmailTemplateId: settings?.retentionEmailTemplateId ?? '',
      needsSignatureOnContracts: settings?.needsSignatureOnContracts ?? false,
      salesWithVat: settings?.salesWithVat ?? 1000,
      salesWithoutVat: settings?.salesWithoutVat ?? 1100,
      paymentProvider: settings?.paymentProvider ?? 5050,
      bank: settings?.bank ?? 5000,
      fees: settings?.fees ?? 2400,
      cash: settings?.cash ?? 5000,
      check: settings?.check ?? 5000,
      mobilePay: settings?.mobilePay ?? 5000,
      paymentFeeAmount: settings?.paymentFeeAmount ?? 0,
      isFineEnabledWhenOptOutTooLate: settings?.isFineEnabledWhenOptOutTooLate ?? false,
      fineWhenOptOutTooLateAmount: settings?.fineWhenOptOutTooLateAmount ?? 0,
      automaticCancellingOfClasses: settings?.automaticCancellingOfClasses ?? false,
      minimumNumberOfRegistrationsPerClass: settings?.minimumNumberOfRegistrationsPerClass ?? '',
      timeBeforeAutomaticCancellationCheck: settings?.timeBeforeAutomaticCancellationCheck
        ? settings?.timeBeforeAutomaticCancellationCheck
        : null,
      bookingInAdvanceAllowedForDays: settings?.bookingInAdvanceAllowedForDays ?? '',
      maximumNumberOfActiveMemberships: settings?.maximumNumberOfActiveMemberships ?? '',
      allowedNumberOfVisibleCalendarDaysForMembers:
        settings?.allowedNumberOfVisibleCalendarDaysForMembers ?? '',
      isTrailTrainingFollowUpEnabled: settings?.isTrailTrainingFollowUpEnabled ?? false,
      trailTrainingFollowUpEmailId: settings?.trailTrainingFollowUpEmailId ?? '',
      isTrailTrainingWelcomeEnabled: settings?.isTrailTrainingWelcomeEnabled ?? false,
      trailTrainingWelcomeEmailId: settings?.trailTrainingWelcomeEmailId ?? '',
      defaultBookkeepingProvider: settings?.defaultBookkeepingProvider ?? '',
      externalProgramProvider: settings?.externalProgramProvider ?? '',
      externalProgramProviderApiKey: settings?.externalProgramProviderApiKey ?? '',
      isShowingEmployeePhoneNumberAllowed: settings?.isShowingEmployeePhoneNumberAllowed ?? false,
      colorPrimary: settings?.colorPrimary ?? '',
      colorSecondary: settings?.colorSecondary ?? '',
      trackingCode: settings?.trackingCode ?? '',
      trackingProvider: settings?.trackingProvider ?? '',
      accessSystemProvider: settings?.accessSystemProvider ?? '',
      accessSystemProviderEndpoint: settings?.accessSystemProviderEndpoint ?? '',
      accessSystemProviderUsername: settings?.accessSystemProviderUsername ?? '',
      accessSystemProviderPassword: settings?.accessSystemProviderPassword ?? '',
      accessSystemAccessGroupId: settings?.accessSystemAccessGroupId ?? '',
      timeZone: settings?.timeZone ?? '',
      showAllClassesInApp: settings?.showAllClassesInApp ?? false,
      hasReferralBonus: settings?.hasReferralBonus ?? false,
      referralBonus: settings?.referralBonus ?? '',
      referralCandidateEmailId: settings?.referralCandidateEmailId ?? '',
      referralRefererEmailId: settings?.referralRefererEmailId ?? '',
      transferPaymentProviderFeesToCustomers:
        settings?.transferPaymentProviderFeesToCustomers ?? false,
      defaultPaymentGateway: settings?.defaultPaymentGateway || ExternalSystemProviders.Clearhaus,
      images: chain?.logoUrl !== undefined ? [chain?.logoUrl!] : [],
      vatNo: settings?.vatNo ?? '',
      deleteShiftsWhenCancellingClasses: settings?.deleteShiftsWhenCancellingClasses ?? true,
      programAccessKey: settings?.programAccessKey ?? '',
      bankAccount: settings?.bankAccount ?? '',
      bankAccountIban: settings?.bankAccountIban ?? '',
      bankAccountSwift: settings?.bankAccountSwift ?? '',
      appStoreLink: settings?.appStoreLink ?? '',
      playStoreLink: settings?.playStoreLink ?? '',
      oneSignalAppId: settings?.oneSignalAppId ?? '',
      oneSignalApiKey: settings?.oneSignalApiKey ?? '',
      domain: settings?.domain ?? '',
      notifyAboutNewMembers: settings?.notifyAboutNewMembers ?? true,
      notifyAboutFines: settings?.notifyAboutFines ?? true,
      disableLeaderboard: settings?.disableLeaderboard ?? false,
      isFeedbackAllowed: settings?.isFeedbackAllowed ?? false,
      googleTagManagerTrackingCode: settings?.googleTagManagerTrackingCode ?? '',
      requirePaymentMethodOnAccounts: settings?.requirePaymentMethodOnAccounts ?? false,
      sendSmsWhenCancellingClasses: settings?.sendSmsWhenCancellingClasses ?? false,
      isPaymentsActive: settings?.isPaymentsActive ?? true,
    }),
    [settings, chain]
  );

  const purchaseDomain = chain.domain ?? '';
  const presentationDomain = chain.domainOfPresentation ?? '';
  const guestEmails = emailTemplates
    .filter((x) => x.type === EmailTemplateType.Guest)
    .map((x) => ({
      value: x.id,
      text: x.name,
    }));

  const retentionEmails = emailTemplates.map((x) => ({
    value: x.id,
    text: x.name,
  }));

  const referralEmails = emailTemplates
    .filter((x) => x.type === EmailTemplateType.Referral)
    .map((x) => ({
      value: x.id,
      text: x.name,
    }));

  const calendarIntegrationCode = `<div id="bookingboard-container"></div>
        <script src="https://${purchaseDomain}/js/calendar-integration.js"></script>
        <script>BookingBoard.init("https://${purchaseDomain}");</script>`;

  const payrollSystems = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: PayrollSystem.Danloen,
      text: t(PayrollSystem.Danloen),
    },
    {
      value: PayrollSystem.Generic,
      text: t(PayrollSystem.Generic),
    },
  ];
  const accountingSystems = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: BookkeepingProviders.Dinero,
      text: t(BookkeepingProviders.Dinero),
    },
    {
      value: BookkeepingProviders.Billy,
      text: t(BookkeepingProviders.Billy),
    },
    {
      value: BookkeepingProviders.Economics,
      text: t('E-conomics'),
    },
  ];
  const workoutSystems = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: ExternalProgramProviders.SugarWod,
      text: t(ExternalProgramProviders.SugarWod),
    },
    {
      value: ExternalProgramProviders.IceFit,
      text: t(ExternalProgramProviders.IceFit),
    },
  ];
  const analyticsSystems = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: TrackingProviders.Facebook,
      text: t(TrackingProviders.Facebook),
    },
  ];
  const accessSystems = [
    {
      value: '',
      text: t('None'),
    },
    {
      value: AccessSystemProviders.Danalock,
      text: t(AccessSystemProviders.Danalock),
    },
    {
      value: AccessSystemProviders.JustFace,
      text: t(AccessSystemProviders.JustFace),
    },
    {
      value: AccessSystemProviders.Exivo,
      text: t(AccessSystemProviders.Exivo),
    },
    {
      value: AccessSystemProviders.OutScale,
      text: t(AccessSystemProviders.OutScale),
    },
  ];

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    reset,
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const onSubmit = handleSubmit(async (data) => {
    try {
      await ChainSettingsService.update({
        id: settings!.id!,
        body: {
          ...data,
          timeBeforeAutomaticCancellationCheck:
            data.timeBeforeAutomaticCancellationCheck.toFormat('HH:mm:ss'),
          checkInTooLateInterval: data.checkInTooLateInterval.toFormat('HH:mm:ss'),
          checkInInterval: data.checkInInterval.toFormat('HH:mm:ss'),
          optOutTooLateInvalidInterval: data.optOutTooLateInvalidInterval.toFormat('HH:mm:ss'),
          optOutCutoffInterval: data.optOutCutoffInterval.toFormat('HH:mm:ss'),
          reservationInvalidInterval: data.reservationInvalidInterval.toFormat('HH:mm:ss'),
          lateCheckInInterval: data.lateCheckInInterval.toFormat('HH:mm:ss'),
        } as any,
      });

      enqueueSnackbar(t('Update success!'));
    } catch (error) {
      dispatch(setError(error));
    }
  });

  const dispatch = useDispatch();

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const files = values.images || [];

      const newFiles = acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );

      setValue('images', [...files, ...newFiles], { shouldValidate: true });

      const uploadLogo = async () => {
        await ChainsService.uploadLogo({ id: chain!.id!, files: newFiles });

        dispatch(getChain());
      };

      uploadLogo();
    },
    [chain, dispatch, setValue, values.images]
  );

  const setupDanalock = () => {
    DoorSystemIntegrationService.create({
      body: {
        system: ExternalSystemProviders.Danalock,
      },
    }).then((resp) => window.open(resp.authUrl));
  };
  const setupStripeConnect = () => {
    StripeConnectService.create().then((resp) => window.open(resp.link));
  };

  const renderDetails = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Basic info')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              "Here, you can configure and manage various aspects of your chain's profile and operational details to tailor your platform experience. From specifying the chain's name, VAT number, and subscription plan type to uploading a logo and setting primary and secondary colors for branding, these settings ensure your brand identity is well-represented. You can also manage technical integrations like calendar integration codes and domain settings, as well as provide links to your mobile app stores. Dive into these settings to personalize and optimize your chain management experience effectively."
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Basic info" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="chainName" label={t('Name')} />

            <RHFTextField name="vatNo" label={t('VAT number')} disabled />

            <TextField
              value={`${chain.subscriptionType}`}
              label={t('Subscription plan')}
              disabled
            />

            <Stack spacing={1.5}>
              <Typography variant="subtitle2">{t('Logo')}</Typography>
              <RHFUpload multiple thumbnail name="images" maxSize={3145728} onDrop={handleDrop} />
            </Stack>

            <RHFTextField name="checkinMessage" label={t('Check in message')} />

            <RHFTextField
              name="maximumNumberOfActiveMemberships"
              label={t('Global maximum for active memberships')}
            />

            <RHFTextField name="colorPrimary" label={t('Primary color')} />

            <RHFTextField name="colorSecondary" label={t('Secondary color')} />

            <RHFTextField name="domain" label={t('Subdomain')} />

            <TextField value={presentationDomain} label={t('Presentation domain')} disabled />

            <RHFTextField name="appStoreLink" label={t('App Store Link')} />

            <RHFTextField name="playStoreLink" label={t('Play Store Link')} />

            <TextField
              value={calendarIntegrationCode}
              label={t('Calendar integration code')}
              multiline
              rows={3}
              disabled
            />

            <RHFSwitch name="isPaymentsActive" label={t('Is payments active')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderProperties = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('General')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here, you can customize various features to enhance your experience and streamline your interactions with our platform. General settings include enabling fines for late arrivals and no-shows, managing check-in intervals, and controlling class reservations. Communication preferences allow notifications about new members, fines, and feedback options. Member engagement settings cover follow-up emails for trial lessons, displaying all classes in the app, and enabling referral bonuses. Premium features, exclusive to certain subscriptions, provide tailored workout programs. Adjust these settings to suit your needs, and your preferences will be saved instantly.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="General" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <Box
              columnGap={2}
              rowGap={3}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                md: 'repeat(2, 1fr)',
              }}
            >
              <RHFSwitch
                name="isFineEnabledWhenTooLate"
                label={t('Fine when showing up too late')}
              />

              <RHFSwitch name="isFineEnabledWhenNeverMet" label={t('Fine if no show')} />

              <RHFSwitch
                name="isCheckInTooLateIntervalEnabled"
                label={t('Enable too late check in interval')}
              />

              <RHFSwitch name="isCheckInIntervalEnabled" label={t('Enable check in interval')} />

              <RHFSwitch
                name="isOptOutTooLateIntervalEnabled"
                label={t('Enable latest opt out check')}
              />

              <RHFSwitch
                name="isLateCheckInAllowed"
                label={t('Enable check in after class has begun')}
              />

              <RHFSwitch
                name="isReservationInvalidIntervalEnabled"
                label={t('Enable class reservation validity')}
              />

              <RHFSwitch
                name="limitClassesPerDay"
                label={t('Enable limit on classes per day for members')}
              />

              <RHFSwitch
                name="isClassAdditionToEnabled"
                label={t('Enable limit for which classes can be trained extension of each other')}
              />

              <RHFSwitch
                name="useVoucherClipsForFines"
                label={t('Enable use of punch card clips for fines')}
              />

              <RHFSwitch
                name="needsSignatureOnContracts"
                label={t('Enable signature requirement on contracts')}
              />

              <RHFSwitch
                name="isFineEnabledWhenOptOutTooLate"
                label={t('Enable fines when opting out too late for class')}
              />
              <RHFSwitch
                name="automaticCancellingOfClasses"
                label={t('Enable automatic cancelling of classes')}
              />

              <RHFSwitch
                name="isTrailTrainingFollowUpEnabled"
                label={t('Enable follow up emails on trail lessons')}
              />

              <RHFSwitch
                name="isTrailTrainingWelcomeEnabled"
                label={t('Enable welcome email for trail lesson')}
              />

              <RHFSwitch
                name="showAllClassesInApp"
                label={t('Enable showing all classes in app')}
              />

              <RHFSwitch name="hasReferralBonus" label={t('Enable referral bonus for leads')} />

              <RHFSwitch
                name="transferPaymentProviderFeesToCustomers"
                label={t('Enable administration fee on invoices')}
              />

              <RHFSwitch
                name="deleteShiftsWhenCancellingClasses"
                label={t('Delete shifts when cancelling classes')}
              />

              {(chain.subscriptionType === SubscriptionType.Premium ||
                chain.subscriptionType === SubscriptionType.Platinum) && (
                <RHFSwitch name="providesWorkoutPrograms" label={t('Workout program provider')} />
              )}

              <RHFSwitch name="notifyAboutNewMembers" label={t('Notify about new members')} />

              <RHFSwitch name="notifyAboutFines" label={t('Notify about fines')} />

              <RHFSwitch name="disableLeaderboard" label={t('Disable leaderboard')} />

              <RHFSwitch name="isFeedbackAllowed" label={t('Is feedback allowed')} />

              <RHFSwitch
                name="sendSmsWhenCancellingClasses"
                label={t('Send SMS when cancelling classes')}
              />

              <RHFSwitch
                name="requirePaymentMethodOnAccounts"
                label={t('Require payment method on accounts')}
              />
            </Box>
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderIntegrations = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Integrations')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here, you can configure various integration details to enhance functionality and streamline operations. You can input your OneSignal App ID and API Key for push notifications. Depending on your subscription type (Premium or Platinum), you may also manage a Program Access Key for specific features.\n' +
                '\n' +
                "Additionally, you can select default systems for payroll, accounting, workout platforms, analytics, and access control. These selections optimize your platform's capabilities and provide seamless integration with external services. For advanced configurations, options like API keys, tracking codes, and endpoint credentials are available to ensure comprehensive system setup based on your organizational needs."
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Integrations" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="oneSignalAppId" label={t('OneSignal App Id')} />

            <RHFTextField name="oneSignalApiKey" label={t('OneSignal API Key')} />

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) && (
              <RHFTextField name="programAccessKey" label={t('Program Access Key')} />
            )}

            <RHFSelect
              name="defaultPayrollSystem"
              disabled={chain.subscriptionType === SubscriptionType.Standard}
              label={t('Payroll system')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {payrollSystems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              name="defaultBookkeepingProvider"
              disabled={chain.subscriptionType === SubscriptionType.Standard}
              label={t('Accounting system')}
              InputLabelProps={{ shrink: true }}
            >
              {accountingSystems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>
            <RHFSelect
              name="externalProgramProvider"
              disabled={chain.subscriptionType === SubscriptionType.Standard}
              label={t('Workout platform')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {workoutSystems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>

            {values.externalProgramProvider !== '' && (
              <RHFTextField
                name="externalProgramProviderApiKey"
                label={t('API key for workout platform')}
              />
            )}

            <RHFSelect
              name="trackingProvider"
              disabled={chain.subscriptionType === SubscriptionType.Standard}
              label={t('Analytics platform')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
              hidden
            >
              {analyticsSystems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>

            {values.trackingProvider !== '' && (
              <RHFTextField name="trackingCode" label={t('Analytics tracking code')} hidden />
            )}

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) && (
              <RHFTextField
                name="googleTagManagerTrackingCode"
                label={t('Google Tag Manager')}
                placeholder="GTM-XXXXXXXX"
              />
            )}

            <RHFSelect
              name="accessSystemProvider"
              disabled={chain.subscriptionType === SubscriptionType.Standard}
              label={t('Access system')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {accessSystems.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) &&
              values.accessSystemProvider !== '' &&
              values.accessSystemProvider !== AccessSystemProviders.Danalock && (
                <>
                  <RHFTextField
                    name="accessSystemProviderEndpoint"
                    label={t('Access system endpoint')}
                  />
                  <RHFTextField
                    name="accessSystemProviderUsername"
                    label={t('Access system username')}
                  />
                  <RHFTextField
                    name="accessSystemProviderPassword"
                    label={t('Access system password')}
                  />
                  <RHFTextField
                    name="accessSystemAccessGroupId"
                    label={t('Access system group ID')}
                  />
                </>
              )}

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) &&
              values.accessSystemProvider === AccessSystemProviders.Danalock && (
                <>
                  <Button variant="outlined" color="primary" onClick={setupDanalock}>
                    {t('Setup Danalock')}
                  </Button>
                </>
              )}

            <RHFSelect
              disabled
              name="defaultPaymentProvider"
              label={t('Payment provider')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              <MenuItem key={ExternalSystemProviders.Stripe} value={ExternalSystemProviders.Stripe}>
                {ExternalSystemProviders.Stripe}
              </MenuItem>
              <MenuItem key={ExternalSystemProviders.Reepay} value={ExternalSystemProviders.Reepay}>
                {ExternalSystemProviders.Reepay}
              </MenuItem>
            </RHFSelect>

            {values.defaultPaymentProvider === ExternalSystemProviders.Stripe && (
              <>
                <Button variant="outlined" color="primary" onClick={setupStripeConnect}>
                  {t('Setup Stripe Connect')}
                </Button>
              </>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderCommunication = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Communication')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here, you can configure important email-related details to ensure effective communication and proper handling of emails. You can set a "no-reply" email address for system messages and choose the email provider\'s domain and API key if you have a Platinum subscription type. Additionally, you can select templates for emails related to member retention, welcome and follow-up emails for trial lessons, and confirmation emails for referrals, depending on your subscription type. These settings help ensure professional and efficient communication with your members and customers.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Communication" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="noReplyEmailAddress" label={t('No-reply email address')} />

            {chain.subscriptionType === SubscriptionType.Platinum && (
              <RHFTextField name="mailProviderDomain" label={t('Mail provider domain')} />
            )}

            {chain.subscriptionType === SubscriptionType.Platinum && (
              <RHFTextField name="mailProviderApiKey" label={t('Mail provider API key')} />
            )}

            <RHFSelect
              name="retentionEmailTemplateId"
              label={t('Email for retention issues')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
              disabled={retentionEmails.length === 0}
            >
              {retentionEmails.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.text}
                </MenuItem>
              ))}
            </RHFSelect>

            {values.isTrailTrainingWelcomeEnabled && (
              <RHFSelect
                name="trailTrainingWelcomeEmailId"
                label={t('Welcome email for trial lessons')}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
                disabled={guestEmails.length === 0}
              >
                {guestEmails.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}

            {values.isTrailTrainingFollowUpEnabled && (
              <RHFSelect
                name="trailTrainingFollowUpEmailId"
                label={t('Follow up email for trial lessons')}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
                disabled={guestEmails.length === 0}
              >
                {guestEmails.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) && (
              <RHFSelect
                name="referralCandidateEmailId"
                label={t('Confirmation email to candidate')}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
                disabled={referralEmails.length === 0}
              >
                {referralEmails.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}

            {(chain.subscriptionType === SubscriptionType.Premium ||
              chain.subscriptionType === SubscriptionType.Platinum) && (
              <RHFSelect
                name="referralRefererEmailId"
                label={t('Confirmation email to referrer')}
                InputLabelProps={{ shrink: true }}
                PaperPropsSx={{ textTransform: 'capitalize' }}
                disabled={referralEmails.length === 0}
              >
                {referralEmails.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </RHFSelect>
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderPayment = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Billing')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here, you can configure essential financial details to ensure smooth transactions and accurate billing. You can select your default currency, set the standard VAT rate, and provide your bank account information, including IBAN and SWIFT codes. These settings help streamline financial operations and maintain accurate financial records.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Billing" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFSelect
              name="defaultCurrency"
              label={t('Currency')}
              InputLabelProps={{ shrink: true }}
              PaperPropsSx={{ textTransform: 'capitalize' }}
            >
              {currencies.map((option) => (
                <MenuItem key={`cur-${option.abbreviation}`} value={option.abbreviation}>
                  {`${option.abbreviation} (${option.currency})`}
                </MenuItem>
              ))}
            </RHFSelect>

            <RHFTextField name="defaultTaxRate" label={t('VAT (%)')} />

            <RHFTextField name="bankAccount" label={t('Bank Account')} placeholder="XXXX-XXXXXX" />

            <RHFTextField name="bankAccountIban" label={t('Bank Account Iban')} />

            <RHFTextField name="bankAccountSwift" label={t('Bank Account Swift')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderAccounting = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Accounting')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Manage your financial accounts efficiently with these settings. Enter account numbers for different payment methods to streamline transactions and accounting processes. Specify accounts for sales with VAT, sales without VAT, fees, bank transactions, credit card payments, cash payments, check payments, and MobilePay transactions as needed.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Accounting" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField name="salesWithVat" label={t('Account number for sales w/ VAT')} />

            <RHFTextField name="salesWithoutVat" label={t('Account number for sales w/o VAT')} />

            <RHFTextField name="fees" label={t('Account number for fees')} />

            <RHFTextField name="bank" label={t('Account number for bank')} />

            <RHFTextField
              name="paymentProvider"
              label={t('Account number for credit card payment')}
            />

            <RHFTextField name="cash" label={t('Account number for cash payment')} />

            <RHFTextField name="check" label={t('Account number for check payment')} />

            <RHFTextField name="mobilePay" label={t('Account number for MobilePay payment')} />
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderClasses = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Classes')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here, you can customize various parameters to optimize class scheduling and member participation. Specify limits such as the maximum number of active class registrations, the maximum number of participations per day, and the maximum number of waitlist reservations per day. Adjust visibility settings with the number of days visible in the calendar and manage booking options with the number of days in advance bookings are allowed.\n' +
                '\n' +
                'For more advanced settings, configure automatic cancellation thresholds based on minimum participant numbers and specific time intervals before and after class starts. Fine-tune check-in intervals to ensure smooth class operations, manage opt-out deadlines, and define reservation validity periods. Enable late check-ins to accommodate members arriving after class has started. These settings help streamline operations and enhance member experience within your class management system.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Classes" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="maximumNumberOfActiveClassRegistrations"
              label={t('Maximum number of active class registrations')}
            />

            {values.limitClassesPerDay && (
              <RHFTextField
                name="maximumNumberOfClassesPerDay"
                label={t('Maximum number of participations per day')}
              />
            )}

            <RHFTextField
              name="maximumNumberOfWaitListPerDay"
              label={t('Maximum number of wait list reservations per day')}
            />

            <RHFTextField
              name="allowedNumberOfVisibleCalendarDaysForMembers"
              label={t('Number of visible days in calendar')}
            />

            <RHFTextField
              name="bookingInAdvanceAllowedForDays"
              label={t('Number of days in future where bookings are allowed')}
            />

            {values.automaticCancellingOfClasses && (
              <>
                <RHFTextField
                  name="minimumNumberOfRegistrationsPerClass"
                  label={t('Minimum number of participants per class')}
                />
                <Controller
                  name="timeBeforeAutomaticCancellationCheck"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TimePicker
                      {...field}
                      value={values.timeBeforeAutomaticCancellationCheck}
                      label={t('Time before automatic cancellation check')}
                      ampm={false}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          error: !!error,
                          helperText: error?.message,
                        },
                      }}
                    />
                  )}
                />
              </>
            )}

            {values.isCheckInIntervalEnabled && (
              <Controller
                name="checkInInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.checkInInterval}
                    label={t('Earliest check in interval before class starts')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
            {values.isCheckInTooLateIntervalEnabled && (
              <Controller
                name="checkInTooLateInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.checkInTooLateInterval}
                    label={t('Interval where check in is considered too late')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
            {values.isOptOutTooLateIntervalEnabled && (
              <Controller
                name="optOutTooLateInvalidInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.optOutTooLateInvalidInterval}
                    label={t('Interval where opt out is considered too late')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
            {values.isOptOutTooLateIntervalEnabled && (
              <Controller
                name="optOutCutoffInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.optOutCutoffInterval}
                    label={t('When is it no longer possible to opt out before class starts')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
            {values.isReservationInvalidIntervalEnabled && (
              <Controller
                name="reservationInvalidInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.reservationInvalidInterval}
                    label={t('Interval where class reservation is considered invalid')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
            {values.isLateCheckInAllowed && (
              <Controller
                name="lateCheckInInterval"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TimePicker
                    {...field}
                    value={values.lateCheckInInterval}
                    label={t('Interval after class start where it is still possible to check in')}
                    ampm={false}
                    slotProps={{
                      textField: {
                        fullWidth: true,
                        error: !!error,
                        helperText: error?.message,
                      },
                    }}
                  />
                )}
              />
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );

  const renderFinesAndFees = (
    <>
      {mdUp && (
        <Grid md={4}>
          <Typography variant="h6" sx={{ mb: 0.5 }}>
            {t('Fines and fees')}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t(
              'Here are the financial settings. Specify registration fee, membership pause fee, and any fines for arriving late or not attending. Enter the amounts in the desired currency.\n\nIf late arrival fine is enabled, specify the fine amount. If no-show fine is enabled, specify the relevant amount. If late cancellation fine is enabled, specify the fine amount. If there is a referral bonus, specify the bonus amount. If transferring payment provider fees to customers, specify the fee amount.'
            )}
          </Typography>
        </Grid>
      )}

      <Grid xs={12} md={8}>
        <Card>
          {!mdUp && <CardHeader title="Fines and fees" />}

          <Stack spacing={3} sx={{ p: 3 }}>
            <RHFTextField
              name="setupFeeAmount"
              label={t('Registration fee')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fCurrencySymbol(values.defaultCurrency)}
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="putOnHoldFeeAmount"
              label={t('Fee when pausing membership')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {fCurrencySymbol(values.defaultCurrency)}
                  </InputAdornment>
                ),
              }}
            />

            {values.isFineEnabledWhenTooLate && (
              <RHFTextField
                name="fineWhenTooLateAmount"
                label={t('Fine amount for too late')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fCurrencySymbol(values.defaultCurrency)}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {values.isFineEnabledWhenNeverMet && (
              <RHFTextField
                name="fineWhenNeverMetAmount"
                label={t('Fine amount for no show')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fCurrencySymbol(values.defaultCurrency)}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {values.isOptOutTooLateIntervalEnabled && (
              <RHFTextField
                name="fineWhenOptOutTooLateAmount"
                label={t('Fine amount for opt out too late')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fCurrencySymbol(values.defaultCurrency)}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {values.hasReferralBonus && (
              <RHFTextField
                name="referralBonus"
                label={t('Referral bonus')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fCurrencySymbol(values.defaultCurrency)}
                    </InputAdornment>
                  ),
                }}
              />
            )}

            {values.transferPaymentProviderFeesToCustomers && (
              <RHFTextField
                name="paymentFeeAmount"
                label={t('Administration fee')}
                helperText={t(
                  "This fee will be added to the invoice's total amount and is excl. VAT"
                )}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      {fCurrencySymbol(values.defaultCurrency)}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Stack>
        </Card>
      </Grid>
    </>
  );
  const renderActions = (
    <>
      {mdUp && <Grid md={4} />}
      <Grid xs={12} md={8} sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, pl: 3 }} />

        <LoadingButton type="submit" variant="contained" size="large" loading={isSubmitting}>
          {t('Save Changes')}
        </LoadingButton>
      </Grid>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        {renderDetails}

        {renderProperties}

        {renderIntegrations}

        {renderCommunication}

        {renderPayment}

        {renderAccounting}

        {renderClasses}

        {renderFinesAndFees}

        {renderActions}
      </Grid>
    </FormProvider>
  );
}
