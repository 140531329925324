import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import { Direction, getConfigs, MembersWithRetentionIssuesDto, serviceOptions } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  members: [] as MembersWithRetentionIssuesDto[],
  totalNumberOfMembers: 0,
};

const slice = createSlice({
  name: 'retention',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMembersSuccess(state, action) {
      state.members = action.payload.members;
      state.totalNumberOfMembers = action.payload.totalNumberOfMembers;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMembersWithRetentionIssuesDto(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    search?: string | null;
    periodFrom?: DateTime | null;
    periodTo?: DateTime | null;
    maxNumberOfAttendances?: number | null;
    minNumberOfAttendances?: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/retention-issues';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getMembersSuccess({
          totalNumberOfMembers: parseInt(resp.headers['x-total-count'], 10),
          members: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
