import { Helmet } from 'react-helmet-async';
// sections
import { ClassTemplateDetailsView } from 'src/sections/class-template/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ClassTemplateDetailsPage() {
  const { t } = useLocales();
  return (
    <>
      <Helmet>
        <title> {t('Class Template Details')}</title>
      </Helmet>

      <ClassTemplateDetailsView />
    </>
  );
}
