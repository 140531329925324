import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useState } from 'react';
// @mui
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useParams, useRouter } from 'src/routes/hook';
// hooks
// components
import { saveAs } from 'file-saver';
import { DateTime } from 'luxon';
import Scrollbar from 'src/components/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from 'src/components/table';
//
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { ContingentTypeDto, Direction, getConfigs, serviceOptions } from '../../../api';
import ContingentMemberTableRow from './contingent-member-table-row';
import { getMemberships } from '../../../redux/slices/membership';
import MembershipTableToolbar from '../membership-table-toolbar';
import {
  IMembershipTypeTableFilters,
  IMembershipTypeTableFilterValue,
} from '../../../types/membership-type';
import MembershipTableFiltersResult from '../membership-table-filters-result';

// ----------------------------------------------------------------------

type Props = {
  membershipType: ContingentTypeDto;
};

const defaultFilters: IMembershipTypeTableFilters = {
  name: '',
  public: ['All'],
  stock: [],
  status: ['Active'],
  validOn: DateTime.now(),
};

export default function ContingentDetailsMembers({ membershipType }: Props) {
  const dispatch = useDispatch();

  const router = useRouter();

  const { t } = useLocales();

  const params = useParams();

  const { id = '' } = params;

  const tableData = useSelector((state) => state.membership.memberships);

  const totalNumberOfMembers = useSelector((state) => state.membership.totalNumberOfMemberships);

  const defaultValues = useMemo(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const storedRowsPerPage = queryParams.get('rowsPerPage');
    const storedPage = queryParams.get('page');
    const storedOrderBy = queryParams.get('orderBy');
    const storedOrder = queryParams.get('order');
    const storedStatus = queryParams.get('status');
    const storedValidOn = queryParams.get('validOn')
      ? decodeURIComponent(queryParams.get('validOn')!)
      : null;

    return {
      storedRowsPerPage: storedRowsPerPage !== null ? parseInt(storedRowsPerPage, 10) : undefined,
      storedPage: storedPage !== null ? parseInt(storedPage, 10) : undefined,
      storedOrderBy: storedOrderBy ?? undefined,
      storedOrder: storedOrder === 'asc' ? 'asc' : 'desc',
      storedStatus,
      storedValidOn,
    };
  }, []);

  const [filters, setFilters] = useState({
    name: '',
    public: ['All'],
    stock: [] as string[],
    status:
      defaultValues.storedStatus !== null ? [defaultValues.storedStatus ?? 'Active'] : ['Active'],
    validOn:
      defaultValues.storedValidOn !== null
        ? DateTime.fromISO(defaultValues.storedValidOn, {
            zone: 'local',
          })
        : undefined,
  });

  const {
    dense,
    page,
    order,
    setOrder,
    orderBy,
    setOrderBy,
    rowsPerPage,
    setRowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: defaultValues.storedOrderBy ?? undefined,
    defaultRowsPerPage: defaultValues.storedRowsPerPage,
    defaultCurrentPage: defaultValues.storedPage,
    defaultOrder: defaultValues.storedOrder === 'asc' ? 'asc' : 'desc',
  });

  useEffect(() => {
    const newurl = `${window.location.protocol}//${window.location.host}${
      window.location.pathname
    }?rowsPerPage=${rowsPerPage}&page=${page}&orderBy=${orderBy}&order=${order}&status=${
      filters.status
    }&search=${filters.name ?? ''}&validOn=${
      filters.validOn ? encodeURIComponent(filters.validOn.toISO({ includeOffset: false })!) : ''
    }`;
    window.history.replaceState({ path: newurl }, '', newurl);
  }, [rowsPerPage, page, filters, orderBy, order]);

  useEffect(() => {
    dispatch(
      getMemberships({
        membershipTypes: [parseInt(id, 10)],
        pageSize: rowsPerPage,
        pageNumber: page,
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        validOn: filters.validOn,
      })
    );
  }, [dispatch, rowsPerPage, page, filters, orderBy, order, id]);

  const TABLE_HEAD = [
    { id: 'ownerName', label: t('Member'), align: 'left' },
    { id: 'membershipTypeName', label: t('Membership'), align: 'left' },
    { id: 'priceAmount', label: t('Price'), align: 'left' },
    { id: 'billable', label: t('Frequency'), align: 'left' },
    { id: 'renewalDate', label: t('Renewal date'), align: 'left' },
    { id: 'status', label: t('Status'), align: 'left' },
    { id: '' },
  ];

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!tableData.length && !!filters.name) || (!tableData.length && !!filters.status);

  const handleFilters = useCallback(
    (name: string, value: IMembershipTypeTableFilterValue) => {
      onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [onResetPage]
  );

  const handleDeleteRow = useCallback(
    (membershipId: number) => {
      const deleteRow = tableData.filter((row) => row.id !== membershipId);
    },
    [tableData]
  );

  const handleEditRow = useCallback(
    (membershipId: number) => {
      router.push(paths.members.edit(membershipId));
    },
    [router]
  );

  const handleViewRow = useCallback(
    (membershipId: number) => {
      router.push(paths.members.view(membershipId));
    },
    [router]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);

  const handleExport = useCallback(() => {
    const action = async () => {
      const url = '/api/memberships';

      const configs = getConfigs('get', 'application/json', url, {
        headers: {
          Accept: 'text/csv',
        },
      });

      configs.params = {
        pageSize: 2 ** 31 - 1,
        pageNumber: 0,
        membershipTypes: [parseInt(id, 10)],
        sortBy: [orderBy],
        direction: order === 'asc' ? Direction.Asc : Direction.Desc,
        search: filters.name,
        validOn: filters.validOn,
      };
      const resp = await serviceOptions.axios!.request(configs);

      // Ignore type, because API can return multiple types
      const blob = new Blob([resp.data as any], {
        type: 'text/csv',
      });
      saveAs(blob, `result.csv`);
    };
    action();
  }, [filters, orderBy, order, id]);

  const denseHeight = dense ? 52 : 72;

  return (
    <>
      <MembershipTableToolbar filters={filters} onFilters={handleFilters} onExport={handleExport} />

      {canReset && (
        <MembershipTableFiltersResult
          filters={filters}
          onFilters={handleFilters}
          //
          onResetFilters={handleResetFilters}
          //
          results={totalNumberOfMembers}
          sx={{ p: 2.5, pt: 0 }}
        />
      )}

      <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
        <Scrollbar>
          <Table size={dense ? 'small' : 'medium'}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData.length}
              numSelected={selected.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData?.map((row) => (
                <ContingentMemberTableRow
                  key={row.id}
                  row={row}
                  onSelectRow={() => handleViewRow(row.ownerId!)}
                  onDeleteRow={() => handleDeleteRow(row.id!)}
                  onEditRow={() => handleEditRow(row.id!)}
                />
              ))}

              <TableEmptyRows
                height={denseHeight}
                emptyRows={emptyRows(page, rowsPerPage, totalNumberOfMembers)}
              />

              <TableNoData notFound={notFound} />
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={totalNumberOfMembers}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
      />
    </>
  );
}
