import { useEffect, useState } from 'react';
import { Settings } from 'luxon';
import { SplashScreen } from '../loading-screen';
import { getChain } from '../../redux/slices/chain';
import { getGyms } from '../../redux/slices/gym';
import { useDispatch } from '../../redux/store';
import { useAuthContext } from '../../auth/hooks';
import { useLocales } from '../../locales';

type Props = {
  children: React.ReactNode;
};

export default function Initialize({ children }: Props) {
  const { authenticated } = useAuthContext();

  const [isLoading, setIsLoading] = useState(true);

  const { currentLang } = useLocales();

  const dispatch = useDispatch();

  useEffect(() => {
    // Is done because adapterLocale doesn't work
    Settings.defaultLocale = currentLang.value;

    const initialize = async () => {
      await dispatch(getChain());
      await dispatch(getGyms());
      setIsLoading(false);
    };

    if (authenticated) {
      initialize();
    }
  }, [dispatch, authenticated, currentLang]);

  return <>{isLoading && authenticated ? <SplashScreen /> : children}</>;
}
