import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { SearchResultDto, SearchService } from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  entries: [] as SearchResultDto[],
};

const slice = createSlice({
  name: 'member',
  initialState,
  reducers: {
    getSearchSuccess(state, action) {
      state.entries = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function search(
  params: {
    searchString?: string;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    try {
      const result = await SearchService.search(params);

      dispatch(slice.actions.getSearchSuccess(result));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
