import { useCallback, useEffect, useState } from 'react';
// @mui
import Tab from '@mui/material/Tab';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import MenuItem from '@mui/material/MenuItem';
// routes
import { paths } from 'src/routes/paths';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useParams } from 'react-router-dom';
import { useLocales } from '../../../locales';
import { useDispatch, useSelector } from '../../../redux/store';
import { getGym } from '../../../redux/slices/gym';
import { RouterLink } from '../../../routes/components';
import GymDetailsContent from '../gym-details-content';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import Iconify from '../../../components/iconify';
import ProfileCover from '../profile-cover';
import CustomPopover, { usePopover } from '../../../components/custom-popover';
import GymAccess from '../gym-access';
import GymOpeningHours from '../gym-opening-hours';
import { getLocations } from '../../../redux/slices/members';

// ----------------------------------------------------------------------

export default function GymDetailsView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const params = useParams();

  const popover = usePopover();

  const { id = '' } = params;

  useEffect(() => {
    dispatch(
      getGym({
        id: parseInt(id, 10),
      })
    );
    dispatch(getLocations());
  }, [dispatch, id]);

  const currentGym = useSelector((state) => state.gym.currentGym);

  const places = useSelector((state) => state.member.locations);

  const gymTabs = [
    { value: 'overview', label: `${t('Overview')}` },
    { value: 'locks', label: `${t('Access')}` },
    { value: 'openingHours', label: `${t('Opening hours')}` },
  ];

  const [currentTab, setCurrentTab] = useState('overview');

  const handleChangeTab = useCallback((event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }, []);

  const renderTabs = (
    <Tabs
      value={currentTab}
      onChange={handleChangeTab}
      sx={{
        width: 1,
        bottom: 0,
        zIndex: 9,
        position: 'absolute',
        bgcolor: 'background.paper',
        [`& .${tabsClasses.flexContainer}`]: {
          pr: { md: 3 },
          justifyContent: {
            sm: 'center',
            md: 'flex-end',
          },
        },
      }}
    >
      {gymTabs.map((tab) => (
        <Tab key={tab.value} iconPosition="end" value={tab.value} label={tab.label} icon="" />
      ))}
    </Tabs>
  );

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={t('Gym')}
        links={[
          { name: t('Dashboard'), href: paths.dashboard.root },
          { name: t('Gyms'), href: paths.gyms.root },
          { name: `${currentGym?.name}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
        action={
          <>
            <Tooltip title={t('Edit')}>
              <IconButton component={RouterLink} href={paths.gyms.edit(currentGym?.id!)}>
                <Iconify icon="solar:pen-bold" />
              </IconButton>
            </Tooltip>
            <IconButton onClick={popover.onOpen}>
              <Iconify icon="eva:more-vertical-fill" />
            </IconButton>
          </>
        }
      />

      <Card
        sx={{
          mb: 3,
          height: 290,
        }}
      >
        <ProfileCover
          name={currentGym?.name!}
          avatarUrl={currentGym?.logoUrl ?? ''}
          coverUrl={currentGym?.images?.length ? currentGym!.images[0].url! : ''}
        />

        {renderTabs}
      </Card>

      {currentTab === 'overview' && <GymDetailsContent gym={currentGym} places={places} />}

      {currentTab === 'locks' && <GymAccess gym={currentGym} />}

      {currentTab === 'openingHours' && <GymOpeningHours gym={currentGym} />}

      <CustomPopover open={popover.open} onClose={popover.onClose}>
        <MenuItem component={RouterLink} href={paths.gyms.setup(currentGym?.id ?? -1)}>
          <Iconify icon="solar:cog" />
          {t('Setup')}
        </MenuItem>
      </CustomPopover>
    </Container>
  );
}
