import { Helmet } from 'react-helmet-async';
// sections
import { UserProfileView } from 'src/sections/member/view';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function MemberProfilePage() {
  const { t } = useLocales();
  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Member Profile')}</title>
      </Helmet>

      <UserProfileView />
    </>
  );
}
