import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import LocationListView from '../../../sections/location/view/location-list-view';

// ----------------------------------------------------------------------

export default function LocationListPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title>{`${t('Location: List | Booking Board')}`}</title>
      </Helmet>

      <LocationListView />
    </>
  );
}

// ----------------------------------------------------------------------
