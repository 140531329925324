import { createSlice, Dispatch } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';
// utils
import {
  Direction,
  getConfigs,
  MembershipDto,
  MembershipStatuses,
  serviceOptions,
} from '../../api';
import { IMembershipStats } from '../../types/membership';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  memberships: [] as MembershipDto[],
  totalNumberOfMemberships: 0,
  currentMemberMemberships: [] as MembershipDto[],
  stats: {
    total: 0,
    active: 0,
    onHold: 0,
    cancelled: 0,
    inactive: 0,
    missingPayment: 0,
  } as IMembershipStats,
};

const slice = createSlice({
  name: 'membership',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getMembershipsSuccess(state, action) {
      state.memberships = action.payload.memberships;
      state.totalNumberOfMemberships = action.payload.totalMemberships;
      state.stats = action.payload.stats;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMemberships(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: string[] | null[];
    direction?: Direction;
    statuses?: MembershipStatuses[] | null[];
    membershipTypes?: number[];
    search?: string;
    validOn?: DateTime | null;
    validTo?: DateTime | null;
    validFrom?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/memberships';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = {
        ...params,
      };
      const resp = await serviceOptions.axios!.request(configs);

      const stats = JSON.parse(resp.headers['x-page-stats']);

      dispatch(
        slice.actions.getMembershipsSuccess({
          totalMemberships: parseInt(resp.headers['x-total-count'], 10),
          memberships: resp.data,
          stats,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

// ----------------------------------------------------------------------
