import { Helmet } from 'react-helmet-async';
// sections
import GymDetailsView from '../../../sections/gyms/view/gym-details-view';

// ----------------------------------------------------------------------

export default function GymDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Gym</title>
      </Helmet>

      <GymDetailsView />
    </>
  );
}
