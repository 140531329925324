import React, { useCallback, useEffect, useState } from 'react';
import { saveAs } from 'file-saver';
// @mui
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { blue, orange } from '@mui/material/colors';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
//
import { DateTime } from 'luxon';
// components
import { useDispatch, useSelector } from '../../../redux/store';
//
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { useSettingsContext } from '../../../components/settings';
import MemberChurnReportToolbar from './member-churn-report-toolbar';

import { IReportFilterValue, IReportTableFilters } from '../../../types/report';
import { useLocales } from '../../../locales';
import { paths } from '../../../routes/paths';
import { getMemberChurnReport } from '../../../redux/slices/report';
import Chart from '../../../components/chart/chart';
import { useChart } from '../../../components/chart';
import { fNumber } from '../../../utils/format-number';
import Scrollbar from '../../../components/scrollbar/scrollbar';
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from '../../../components/table';
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const defaultFilters: IReportTableFilters = {
  fromDate: DateTime.now().minus({ months: 2 }).startOf('month'),
  toDate: DateTime.now().endOf('month'),
  membershipTypeIds: null,
  minimumLengthOfMembershipInDays: 0,
};

// ----------------------------------------------------------------------

export default function MemberChurnReportView() {
  const settings = useSettingsContext();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const report = useSelector((state) => state.report.memberChurnReport);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    dispatch(getMemberChurnReport(filters));
  }, [dispatch, filters]);

  const handleFilters = useCallback((name: string, value: IReportFilterValue) => {
    setFilters((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }, []);

  const chart = {
    categories: report?.churnRates?.map((x) => x.month!) ?? [],
    series: [
      {
        name: t('Churn'),
        data: report?.churnRates?.map((x) => x.count ?? 0) ?? [],
      },
    ],
  };

  const chartOptions = useChart({
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories: chart.categories,
      title: {
        text: t('Month'),
      },
    },
    dataLabels: {
      formatter: (val, opts) => fNumber(val as number),
      enabled: true,
    },
    yaxis: {
      labels: {
        formatter: (value) => `${fNumber(value)}%`,
      },
      title: {
        text: t('Percentage'),
      },
    },
    annotations: {
      yaxis: [
        {
          y: report.medianChurnRateForPeriod ?? 0,
          borderColor: blue['400'],
          label: {
            borderColor: blue['400'],
            style: {
              color: '#fff',
              background: blue['400'],
            },
            text: t('Median'),
          },
        },
        {
          y: report.averageChurnRateForPeriod ?? 0,
          borderColor: orange['400'],
          label: {
            borderColor: orange['400'],
            style: {
              color: '#fff',
              background: orange['400'],
            },
            text: t('Average'),
          },
        },
      ],
    },
  });

  const tableHeadLostMembers = [
    { id: 'month', label: t('Month'), align: 'left' },
    { id: 'count', label: t('Churn rate'), align: 'left' },
  ];

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    onResetPage,
    //
    selected,
    onSelectAllRows,
    onSelectRow,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({
    defaultOrderBy: tableHeadLostMembers[0].id,
    defaultOrder: 'desc',
  });

  const lostTableData = report.churnRates ?? [];
  const lostNotFound = !lostTableData.length;

  const handlePrintPage: VoidFunction = () => window.print();

  return (
    <Container maxWidth={settings.themeStretch ? false : 'xl'}>
      <CustomBreadcrumbs
        heading={`${t('Member churn report')}`}
        links={[
          {
            name: `${t('Dashboard')}`,
            href: paths.dashboard.root,
          },
          {
            name: `${t('Reports')}`,
            href: paths.report.root,
          },
          { name: `${t('Member churn report')}` },
        ]}
        sx={{
          mb: { xs: 3, md: 5 },
        }}
      />
      <Stack spacing={3} id="printarea">
        <Card>
          <MemberChurnReportToolbar
            filters={filters}
            onFilters={handleFilters}
            onPrint={handlePrintPage}
          />
        </Card>
        <Card>
          <CardHeader
            title={t('Churn rate')}
            subheader={t('Shows the churn rate in percentage per month for the selected period')}
          />
          <CardContent>
            <Chart dir="ltr" type="area" series={chart.series} options={chartOptions} />
          </CardContent>

          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              p: 1,
            }}
          >
            <Tooltip
              title={t(
                'Churn is calculated by taken the number of lost members at end of period divided by the number of members at start of month.'
              )}
            >
              <Iconify icon="solar:question-circle-outline" />
            </Tooltip>
          </Box>
        </Card>

        <Card>
          <CardHeader title={t('List')} />
          <TableContainer
            sx={{
              position: 'relative',
              overflow: 'unset',
              pt: 2.5,
            }}
          >
            <Scrollbar>
              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={tableHeadLostMembers}
                  rowCount={lostTableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                />

                <TableBody>
                  {lostTableData?.map((row) => (
                    <TableRow hover key={row.month}>
                      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                        {row.month}
                      </TableCell>

                      <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
                        {`${fNumber(row.count ?? 0)}%`}
                      </TableCell>
                    </TableRow>
                  ))}

                  <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, lostTableData.length)} />

                  <TableNoData notFound={lostNotFound} />
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={lostTableData.length}
            page={page}
            rowsPerPage={25}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Card>
      </Stack>
    </Container>
  );
}
