import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import {
  Direction,
  getConfigs,
  OrderDetailsDto,
  OrderDto,
  OrdersService,
  OrderStatuses,
  serviceOptions,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  orders: [] as OrderDto[],
  totalNumberOfOrders: 0,
  currentOrder: {} as OrderDetailsDto,
};

const slice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getOrdersSuccess(state, action) {
      state.orders = action.payload.orders;
      state.totalNumberOfOrders = action.payload.totalNumberOfOrders;
    },
    getOrderSuccess(state, action) {
      state.currentOrder = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getOrders(
  params: {
    pageNumber?: number;
    pageSize?: number;
    sortBy?: any | null[];
    direction?: Direction;
    statuses?: OrderStatuses[];
    startDate?: Date | null;
    endDate?: Date | null;
    search?: string | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/orders';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);

      dispatch(
        slice.actions.getOrdersSuccess({
          totalNumberOfOrders: parseInt(resp.headers['x-total-count'], 10),
          orders: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
  };
}

export function getOrder(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await OrdersService.details({
        id,
      });
      dispatch(slice.actions.getOrderSuccess(response));
    } catch (error) {
      dispatch(setError(error));
    }
  };
}
