import { Helmet } from 'react-helmet-async';
// sections
import { DropInEditView } from '../../../../sections/membership-types/drop-in/view';

// ----------------------------------------------------------------------

export default function DropInTypeEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Edit</title>
      </Helmet>

      <DropInEditView />
    </>
  );
}
