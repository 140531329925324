import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  ActiveMemberReportDto,
  ActiveMembershipReportDto,
  AverageRevenuePerMemberReportDto,
  MemberChangeReportDto,
  MemberChurnReportDto,
  MemberLengthOfEngagementReportDto,
  ReportsService,
} from '../../api';

// ----------------------------------------------------------------------

const initialState = {
  memberChangeReport: {} as MemberChangeReportDto,
  memberChurnReport: {} as MemberChurnReportDto,
  activeMemberReport: {} as ActiveMemberReportDto,
  activeMembershipReport: {} as ActiveMembershipReportDto,
  memberLengthOfEngagementReport: {} as MemberLengthOfEngagementReportDto,
  averageRevenuePerMemberReport: {} as AverageRevenuePerMemberReportDto,
};

const slice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getMemberChangeReportSuccess(state, action) {
      state.memberChangeReport = action.payload;
    },
    getMemberChurnReportSuccess(state, action) {
      state.memberChurnReport = action.payload;
    },
    getActiveMemberReportSuccess(state, action) {
      state.activeMemberReport = action.payload;
    },
    getActiveMembershipReportSuccess(state, action) {
      state.activeMembershipReport = action.payload;
    },
    getMemberLengthOfEngagementReportSuccess(state, action) {
      state.memberLengthOfEngagementReport = action.payload;
    },
    getAverageRevenuePerMemberReportSuccess(state, action) {
      state.averageRevenuePerMemberReport = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getMemberChangeReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.memberChangeReport(params as any);
    dispatch(slice.actions.getMemberChangeReportSuccess(response));
  };
}

export function getMemberChurnReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
    membershipTypeIds: number[] | null;
    minimumLengthOfMembershipInDays: number | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.memberChurnReport(params as any);
    dispatch(slice.actions.getMemberChurnReportSuccess(response));
  };
}

export function getActiveMemberReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.activeMemberReport(params as any);
    dispatch(slice.actions.getActiveMemberReportSuccess(response));
  };
}

export function getActiveMembershipReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.activeMembershipReport(params as any);
    dispatch(slice.actions.getActiveMembershipReportSuccess(response));
  };
}

export function getMemberLengthOfEngagementReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.memberLengthOfEngagementReport(params as any);
    dispatch(slice.actions.getMemberLengthOfEngagementReportSuccess(response));
  };
}

export function getAverageRevenuePerMemberReport(
  params: {
    fromDate: DateTime | null;
    toDate: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    const response = await ReportsService.averageRevenuePerMemberReport(params as any);
    dispatch(slice.actions.getAverageRevenuePerMemberReportSuccess(response));
  };
}
