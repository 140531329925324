// @mui
import Timeline from '@mui/lab/Timeline';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
// utils
import { fDateTime } from 'src/utils/format-time';
// types
import { OrderDetailsDto, OrderEventDto, OrderEventStatuses } from '../../api';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  history: OrderEventDto[];
  order: OrderDetailsDto;
};

export default function OrderDetailsHistory({ history, order }: Props) {
  const { t } = useLocales();

  const sortedHistory = [...history].sort(
    (x, y) => y.createdDate!.toMillis() - x.createdDate!.toMillis()
  );

  const paidEvent = history.find((x) => x.status === OrderEventStatuses.Paid);

  const renderSummary = (
    <Stack
      spacing={2}
      component={Paper}
      variant="outlined"
      sx={{
        p: 2.5,
        minWidth: 260,
        flexShrink: 0,
        borderRadius: 2,
        typography: 'body2',
        borderStyle: 'dashed',
      }}
    >
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>{t('Order time')}</Box>
        {fDateTime(order.createdDate)}
      </Stack>
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>{t('Payment time')}</Box>
        {paidEvent && fDateTime(paidEvent.createdDate)}
        {!paidEvent && '-'}
      </Stack>
      <Stack spacing={0.5}>
        <Box sx={{ color: 'text.disabled' }}>{t('Completion time')}</Box>
        {fDateTime(order.createdDate)}
      </Stack>
    </Stack>
  );

  const timeLineEventColor = (event: OrderEventDto) => {
    if (event.status === OrderEventStatuses.Paid) {
      return 'success';
    }

    if (event.status === OrderEventStatuses.PaymentFailed) {
      return 'error';
    }

    return 'grey';
  };

  const renderTimeline = (
    <Timeline
      sx={{
        p: 0,
        m: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {sortedHistory.map((item, index) => {
        const firstTimeline = index === 0;

        const lastTimeline = index === history.length - 1;

        return (
          <TimelineItem key={`transaction-${index}`}>
            <TimelineSeparator>
              <TimelineDot color={timeLineEventColor(item)} />
              {lastTimeline ? null : <TimelineConnector />}
            </TimelineSeparator>

            <TimelineContent>
              <Typography variant="subtitle2">{t(`${item.status}`)}</Typography>

              <Box sx={{ color: 'text.disabled', typography: 'caption', mt: 0.5 }}>
                {fDateTime(item.createdDate)}
              </Box>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );

  return (
    <Card>
      <CardHeader title={t('History')} />
      <Stack
        spacing={3}
        alignItems={{ md: 'flex-start' }}
        direction={{ xs: 'column-reverse', md: 'row' }}
        sx={{ p: 3 }}
      >
        {renderTimeline}

        {renderSummary}
      </Stack>
    </Card>
  );
}
