// @mui
import Container from '@mui/material/Container';
import { useParams } from '../../../routes/hook';
import MemberChangeReportView from '../member-change-report/member-change-report-view';
import MemberChurnReportView from '../member-churn-report/member-churn-report-view';
import MemberRetentionReportView from '../member-retention-report/member-retention-report-view';
import MemberLengthOfEngagementReportView from '../member-length-of-engagement-report/member-length-of-engagement-report-view';
import MemberAverageRevenueReportView from '../member-average-revenue-report/member-average-revenue-report-view';
import ActiveMemberReportView from '../active-member-report/active-member-report-view';
import ActiveMembershipReportView from '../active-membership-report/active-membership-report-view';
//

// ----------------------------------------------------------------------

export default function ReportDetailsView() {
  const params = useParams();

  const { slug = '' } = params;

  const renderView = () => {
    if (slug === 'member-churn-report') {
      return <MemberChurnReportView />;
    }
    if (slug === 'member-change-report') {
      return <MemberChangeReportView />;
    }
    if (slug === 'member-retention-report') {
      return <MemberRetentionReportView />;
    }
    if (slug === 'member-average-revenue-report') {
      return <MemberAverageRevenueReportView />;
    }
    if (slug === 'member-length-of-engagement-report') {
      return <MemberLengthOfEngagementReportView />;
    }
    if (slug === 'active-member-report') {
      return <ActiveMemberReportView />;
    }
    if (slug === 'active-membership-report') {
      return <ActiveMembershipReportView />;
    }
    return <></>;
  };

  return <Container>{renderView()}</Container>;
}
