import { Helmet } from 'react-helmet-async';
// sections
import { ClassTemplateCreateView } from 'src/sections/class-description/view';

// ----------------------------------------------------------------------

export default function ClassDescriptionCreatePage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Create a new tour</title>
      </Helmet>

      <ClassTemplateCreateView />
    </>
  );
}
