import axios, {
  AxiosRequestConfig,
  AxiosRequestTransformer,
  AxiosResponseTransformer,
  Method,
} from 'axios';
// config
import { DateTime } from 'luxon';
import { formatISO } from 'date-fns';
import { HOST_API } from '../config-global';
import { GymDto, serviceOptions } from '../api';
import { hasJsonStructure } from './string-utils';
import { localStorageGetItem } from './storage-available';

// ----------------------------------------------------------------------

// Convert Date to ISO
const dateTransformer = (data: any): any => {
  if (data instanceof Date) {
    return DateTime.fromJSDate(data).toISO();
  }
  if (data instanceof DateTime) {
    return data.toISO();
  }
  if (data instanceof FormData) {
    return data;
  }
  if (Array.isArray(data)) {
    return data.map(dateTransformer);
  }
  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateTransformer(value)])
    );
  }
  return data;
};

// Convert ISO to Date
const dateResponseTransformer = (data: any): any => {
  if (hasJsonStructure(data)) {
    data = JSON.parse(data);
  }

  // Check if date
  if (typeof data === 'string' && data !== '') {
    // Make sure it is a date or datetime
    const isTimespan = (data.match(/^\d{2}:\d{2}:\d{2}[.]{0,1}\d*/) || []).length === 1;
    if (isTimespan) {
      const timespan = DateTime.fromISO(`2000-01-01T${data.toString()}`);
      if (timespan.isValid) return timespan;
      return data;
    }

    // Make sure it is a date or datetime
    const isDate = (data.match(/^\d{4}[./-]\d{2}[./-]\d{2}.*/) || []).length === 1;
    if (isDate) {
      const dateTime = DateTime.fromISO(data.toString());
      if (dateTime.isValid) return dateTime;
      return data;
    }

    return data;
  }

  if (Array.isArray(data)) {
    const arr = data.map(dateResponseTransformer);
    return arr;
  }

  if (typeof data === 'object' && data !== null) {
    return Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, dateResponseTransformer(value)])
    );
  }

  return data;
};

const axiosInstance = axios.create({
  baseURL: HOST_API,
  paramsSerializer: {
    indexes: null, // Avoid [] on query params
  },
  transformRequest: [
    dateTransformer,
    ...(axios.defaults.transformRequest as AxiosRequestTransformer[]),
  ],
  transformResponse: [
    dateResponseTransformer,
    ...(axios.defaults.transformResponse as AxiosResponseTransformer[]),
  ],
});

axiosInstance.defaults.headers.common['Accept-Language'] = `${localStorageGetItem(
  'i18nextLng'
)},en;q=0.9`;

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response !== null && error.response.status === 401) {
      setSession(null);

      if (window.location.href.indexOf('/auth/jwt/login') === -1) {
        window.location.replace('/auth/jwt/login');
      }
    }

    // Assumes CORS issues due to 401
    if (error.response === null) {
      setSession(null);
      if (window.location.href.indexOf('/auth/jwt/login') === -1) {
        window.location.replace('/auth/jwt/login');
      }
    }

    return Promise.reject(error);
  }
);

axiosInstance.interceptors.request.use(
  (config) => {
    if ((config.method === 'GET' || config.method === 'get') && selectedGym) {
      config.params = { ...config.params, selectedGymId: selectedGym?.id };
    }

    return config;
  },
  (error) => Promise.reject(error)
);

serviceOptions.axios = axiosInstance;

export default axiosInstance;

// ----------------------------------------------------------------------

let selectedGym: GymDto | undefined;

export function updateSelectedGym(gym?: GymDto) {
  selectedGym = gym;
}

export function updateLanguage(newLang: string) {
  axiosInstance.defaults.headers.common['Accept-Language'] = `${newLang},en;q=0.9`;
}

// ----------------------------------------------------------------------

export const fetcher = async (args: string | [string, AxiosRequestConfig]) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {
  chat: '/api/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',
  auth: {
    me: '/api/auth/me',
    login: '/api/auth/login',
    register: '/api/auth/register',
  },
  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },
  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },
  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },
};

// ----------------------------------------------------------------------

// Is duplicate code of the function in Auth
// TODO move to separate file
export const setSession = (accessToken: string | null) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');

    delete axios.defaults.headers.common.Authorization;
  }
};
