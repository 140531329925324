export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */
  return color;
}

export function stringAvatar(name: string, sx: {}) {
  if (name.includes(' ')) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        ...sx,
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`.replace(/[^\w\s]/g, ''),
    };
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
      ...sx,
    },
    children: `${name[0]}`.replace(/[^\w\s]/g, ''),
  };
}
