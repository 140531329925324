// @mui
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Iconify from 'src/components/iconify';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { ConfirmDialog } from 'src/components/custom-dialog';
// types
import { MemberDto } from '../../api';
//
import { useLocales } from '../../locales';
import Label from '../../components/label';
import { fData } from '../../utils/format-number';
import { fDate } from '../../utils/format-time';

// ----------------------------------------------------------------------

type Props = {
  onEditRow: VoidFunction;
  row: MemberDto;
  onSelectRow: VoidFunction;
  onDeleteRow: VoidFunction;
  onViewRow: VoidFunction;
};

export default function MemberTableRow({
  row,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  onViewRow,
}: Props) {
  const {
    fullName,
    profileImageUrl,
    createdDate,
    numberOfAttendances,
    emailAddress,
    hasPaymentMethod,
  } = row;

  const confirm = useBoolean();

  const { t } = useLocales();

  const popover = usePopover();

  return (
    <>
      <TableRow hover>
        <TableCell
          onClick={onViewRow}
          sx={[
            {
              '&:hover': {
                cursor: 'pointer',
              },
            },
            { display: 'flex', alignItems: 'center' },
          ]}
        >
          <Avatar alt={fullName} src={profileImageUrl} sx={{ mr: 2 }} />

          <ListItemText
            primary={fullName}
            secondary={emailAddress}
            primaryTypographyProps={{ typography: 'body2' }}
            secondaryTypographyProps={{ component: 'span', color: 'text.disabled' }}
          />
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {fDate(createdDate)}
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          <Label variant="soft" color={(hasPaymentMethod === true && 'success') || 'error'}>
            {hasPaymentMethod ? `${t('Yes')}` : `${t('No')}`}
          </Label>
        </TableCell>

        <TableCell align="left" sx={{ textTransform: 'capitalize' }}>
          {numberOfAttendances}
        </TableCell>

        <TableCell align="right" sx={{ px: 1, whiteSpace: 'nowrap' }}>
          <IconButton color={popover.open ? 'inherit' : 'default'} onClick={popover.onOpen}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            onSelectRow();
            popover.onClose();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          {t('View')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            popover.onClose();
          }}
        >
          <Iconify icon="eva:edit-fill" />

          {t('Edit')}
        </MenuItem>
      </CustomPopover>

      <ConfirmDialog
        open={confirm.value}
        onClose={confirm.onFalse}
        title={t('Delete')}
        content={t('Are you sure want to delete?')}
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            {t('Delete')}
          </Button>
        }
      />
    </>
  );
}
