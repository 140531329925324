import { ChangeEvent, useCallback, useEffect, useMemo } from 'react';
import { setError } from 'src/redux/slices/error';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DialogActions from '@mui/material/DialogActions';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
// utils
// api
import { deleteEvent } from 'src/api/calendar';
// components
import { DateTime } from 'luxon';
import Iconify from 'src/components/iconify';
import { useSnackbar } from 'src/components/snackbar';
import FormProvider, { RHFSwitch, RHFTextField } from 'src/components/hook-form';
// types
import { ICalendarEvent } from 'src/types/calendar';
import { useLocales } from '../../locales';
import { useDispatch, useSelector } from '../../redux/store';
import { getStaff } from '../../redux/slices/employees';
import { getClassDescriptions } from '../../redux/slices/class-description';
import { getGyms } from '../../redux/slices/gym';
import { ClassesService, ClazzDto, EntityStatuses } from '../../api';

// ----------------------------------------------------------------------

type Props = {
  onClose: VoidFunction;
  currentEvent?: ICalendarEvent;
  currentClazz?: ClazzDto;
};

export default function CalendarClazzForm({ currentClazz, currentEvent, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const instructors = useSelector((state) => state.employee.employees);

  const clazzDescriptions = useSelector((state) => state.classDescription.descriptions);

  const gyms = useSelector((state) => state.gym.gyms);

  const selectedGym = useSelector((state) => state.gym.globalGym);

  useEffect(() => {
    dispatch(
      getStaff({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        isActive: true,
      })
    );
    dispatch(
      getClassDescriptions({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
        statuses: [EntityStatuses.Available],
      })
    );
    dispatch(
      getGyms({
        pageNumber: 0,
        pageSize: 2 ** 31 - 1,
      })
    );
  }, [dispatch]);

  const newSchema = Yup.object().shape({
    descriptionId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Type is required'))
    ),
    gymId: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Gym is required'))
    ),
    begins: Yup.mixed<any>()
      .nullable()
      .required(t('Begins is required'))
      .test(
        'is-set',
        t('Begins is required'),
        (value, { parent }) => value !== null && value !== undefined
      ),
    ends: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Ends must be later than begins'),
        (value, { parent }) => !value || value > parent.begins
      ),
    shiftBegins: Yup.mixed<any>()
      .nullable()
      .required(t('Begins is required'))
      .test(
        'is-set',
        t('Begins is required'),
        (value, { parent }) => value !== null && value !== undefined
      ),
    shiftEnds: Yup.mixed<any>()
      .nullable()
      .test(
        'date-min',
        t('Ends must be later than begins'),
        (value, { parent }) => !value || value > parent.shiftBegins
      ),
    // not required
    title: Yup.string(),
    subtitle: Yup.string(),
    coaches: Yup.array(),
    closedClass: Yup.boolean(),
    numberOfRepetitions: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(1, t('Repetitions needs to be at least 1'))
    ),
    maximumNumberOfParticipants: Yup.lazy((value) =>
      value === '' ? Yup.string() : Yup.number().min(0)
    ),
  });

  const defaultValues = useMemo(
    () => ({
      title: currentClazz?.title || '',
      subtitle: currentClazz?.subtitle || '',
      descriptionId: currentClazz?.descriptionId || -1,
      gymId: currentClazz?.gymId || selectedGym?.id,
      begins:
        currentClazz?.begins ||
        (currentEvent?.start
          ? DateTime.fromJSDate(new Date(currentEvent?.start)).startOf('hour')
          : DateTime.now().startOf('hour')),
      ends:
        currentClazz?.ends ||
        (currentEvent?.end
          ? DateTime.fromJSDate(new Date(currentEvent?.end)).endOf('hour').plus({ minute: 1 })
          : DateTime.now().endOf('hour').plus({ minute: 1 })),
      shiftBegins:
        currentClazz?.shiftBegins ||
        (currentEvent?.start
          ? DateTime.fromJSDate(new Date(currentEvent?.start)).startOf('hour')
          : DateTime.now().startOf('hour')),
      shiftEnds:
        currentClazz?.shiftEnds ||
        (currentEvent?.end
          ? DateTime.fromJSDate(new Date(currentEvent?.end)).endOf('hour').plus({ minute: 1 })
          : DateTime.now().endOf('hour').plus({ minute: 1 })),
      isOpenGym: currentClazz?.isOpenGym ?? false,
      closedClass: currentClazz?.closedClass ?? false,
      finesEnabled: currentClazz?.finesEnabled ?? false,
      isGuestBookingPossible: currentClazz?.isGuestBookingPossible ?? false,
      releaseProgram: currentClazz?.releaseProgram ?? false,
      minutesBeforeProgramRelease: currentClazz?.minutesBeforeProgramRelease,
      hoursBeforeProgramRelease: currentClazz?.hoursBeforeProgramRelease,
      shiftDurationHours: currentClazz?.shiftDurationHours,
      shiftDurationMinutes: currentClazz?.shiftDurationMinutes,
      optOutTimeHours: currentClazz?.optOutTimeHours,
      optOutTimeMinutes: currentClazz?.optOutTimeMinutes,
      classDurationHours: currentClazz?.classDurationHours,
      classDurationMinutes: currentClazz?.classDurationMinutes,
      changeOptOutTime: false,
      coaches: currentClazz?.instructors?.map((x) => x.id) || [-1],
      numberOfRepetitions: '',
      maximumNumberOfParticipants: currentClazz?.capacity || 16,
    }),
    [currentClazz, currentEvent, selectedGym]
  );

  const methods = useForm({
    resolver: yupResolver(newSchema),
    defaultValues,
  });

  const {
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = handleSubmit(async (data) => {
    try {
      // Check if is edit mode
      if (currentClazz) {
        await ClassesService.edit({
          id: currentClazz!.id!,
          body: {
            id: currentClazz!.id!,
            ...data,
          } as any,
        });
      } else {
        await ClassesService.create({
          body: {
            ...data,
          } as any,
        });
      }

      enqueueSnackbar(currentClazz ? t('Update success!') : t('Create success!'));

      onClose();
      reset();
    } catch (error) {
      dispatch(setError(error));
    }
  });

  useEffect(() => {
    if (currentClazz) {
      reset(defaultValues);
    }
  }, [currentClazz, defaultValues, reset]);

  const onDelete = useCallback(async () => {
    try {
      await deleteEvent(`${currentClazz?.id}`);
      enqueueSnackbar('Delete success!');
      onClose();
    } catch (error) {
      dispatch(setError(error));
    }
  }, [currentClazz?.id, enqueueSnackbar, onClose, dispatch]);

  const handleInstructorChange = (event: ChangeEvent<any>) => {
    setValue('coaches', [event.target.value], { shouldValidate: true });
  };

  const handleDescriptionChange = (event: ChangeEvent<any>) => {
    setValue('descriptionId', event.target.value, { shouldValidate: true });
  };

  const handleGymChange = (event: ChangeEvent<any>) => {
    setValue('gymId', event.target.value, { shouldValidate: true });
  };

  const handleBeginsChange = (newValue: any | null) => {
    setValue('begins', newValue, { shouldValidate: true });
    setValue('shiftBegins', newValue, { shouldValidate: true });
  };

  const handleEndsChange = (newValue: any | null) => {
    setValue('ends', newValue, { shouldValidate: true });
    setValue('shiftEnds', newValue, { shouldValidate: true });
  };

  const handleShiftBeginsChange = (newValue: any | null) => {
    setValue('shiftBegins', newValue, { shouldValidate: true });
  };

  const handleShiftEndsChange = (newValue: any | null) => {
    setValue('shiftEnds', newValue, { shouldValidate: true });
  };

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack spacing={3} sx={{ px: 3 }}>
        <TextField
          label={t('Class type')}
          select
          value={values.descriptionId}
          onChange={handleDescriptionChange}
        >
          <MenuItem key={`desc-${-1}`} value={-1} disabled>
            {t('None selected')}
          </MenuItem>
          {clazzDescriptions.map((option) => (
            <MenuItem key={`desc-${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField label={t('Gym')} select disabled value={values.gymId} onChange={handleGymChange}>
          <MenuItem key={`desc-${-1}`} value={-1} disabled>
            {t('None selected')}
          </MenuItem>
          {gyms.map((option) => (
            <MenuItem key={`gym-${option.id}`} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          select
          label={t('Coach')}
          value={values.coaches}
          onChange={handleInstructorChange}
        >
          <MenuItem key={`desc-${-1}`} value={-1} disabled>
            {t('None selected')}
          </MenuItem>
          {instructors.map((option) => (
            <MenuItem key={`in-${option.id}`} value={option.id!}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
        <Stack spacing={3} direction="row">
          <Controller
            name="begins"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                {...field}
                value={field.value}
                onChange={handleBeginsChange}
                label={t('Begins')}
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                minutesStep={5}
              />
            )}
          />

          <Controller
            name="ends"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                {...field}
                value={field.value}
                onChange={handleEndsChange}
                label={t('Ends')}
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                minutesStep={5}
              />
            )}
          />
        </Stack>

        <Stack spacing={3} direction="row">
          <Controller
            name="shiftBegins"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                {...field}
                value={field.value}
                onChange={handleShiftBeginsChange}
                label={t('Shift begins')}
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                minutesStep={5}
              />
            )}
          />

          <Controller
            name="shiftEnds"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                {...field}
                value={field.value}
                onChange={handleShiftEndsChange}
                label={t('Shift ends')}
                ampm={false}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    error: !!error,
                    helperText: error?.message,
                  },
                }}
                minutesStep={5}
              />
            )}
          />
        </Stack>
        <Stack spacing={3} direction="row">
          <RHFTextField name="numberOfRepetitions" label={t('Number of repetitions')} />

          <RHFTextField
            name="maximumNumberOfParticipants"
            label={t('Maximum number of participants')}
          />
        </Stack>
        <RHFSwitch name="closedClass" label={t('Is a closed class')} />
      </Stack>

      <DialogActions>
        {!!currentClazz?.id && (
          <Tooltip title={t('Delete Event')}>
            <IconButton onClick={onDelete}>
              <Iconify icon="solar:trash-bin-trash-bold" />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={{ flexGrow: 1 }} />

        <Button variant="outlined" color="inherit" onClick={onClose}>
          {t('Cancel')}
        </Button>

        <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
          {t('Save Changes')}
        </LoadingButton>
      </DialogActions>
    </FormProvider>
  );
}
