import { Helmet } from 'react-helmet-async';
// sections
import { VoucherEditView } from '../../../sections/voucher/view';

// ----------------------------------------------------------------------

export default function VoucherEditPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Product Edit</title>
      </Helmet>

      <VoucherEditView />
    </>
  );
}
