import { createSlice, Dispatch } from '@reduxjs/toolkit';
// utils
import { DateTime } from 'luxon';
import {
  Direction,
  getConfigs,
  HourlyWageRateDto,
  HoursDto,
  serviceOptions,
  ShiftDto,
  WageRatesService,
} from '../../api';
import { setError } from './error';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  shifts: [] as ShiftDto[],
  currentShift: {} as ShiftDto,
  hourBank: [] as HoursDto[],
  totalEntriesHourBank: 0,
  wageRates: [] as HourlyWageRateDto[],
  currentWageRate: {} as HourlyWageRateDto,
  totalEntries: 0,
};

const slice = createSlice({
  name: 'wage',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    getClassesSuccess(state, action) {
      state.shifts = action.payload;
    },
    getClassSuccess(state, action) {
      state.currentShift = action.payload;
    },
    getHourBankSuccess(state, action) {
      state.hourBank = action.payload.hourBank;
      state.totalEntriesHourBank = action.payload.total;
    },
    getWageRatesSuccess(state, action) {
      state.wageRates = action.payload.wageRates;
      state.totalEntries = action.payload.total;
    },
    getWageRateSuccess(state, action) {
      state.currentWageRate = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getHourBank(
  params: {
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
    fromDate?: DateTime | null;
    toDate?: DateTime | null;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/hour-bank';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);
      dispatch(
        slice.actions.getHourBankSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          hourBank: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

// ----------------------------------------------------------------------

export function getWageRates(
  params: {
    sortBy?: any | null[];
    pageNumber?: number;
    pageSize?: number;
    direction?: Direction;
  } = {} as any
) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const url = '/api/wage-rates';

      const configs = getConfigs('get', 'application/json', url, {});

      configs.params = params;
      const resp = await serviceOptions.axios!.request(configs);
      dispatch(
        slice.actions.getWageRatesSuccess({
          total: parseInt(resp.headers['x-total-count'], 10),
          wageRates: resp.data,
        })
      );
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}

export function getWageRate(id: number) {
  return async (dispatch: Dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const clazz = await WageRatesService.getById({ id });

      dispatch(slice.actions.getWageRateSuccess(clazz));
    } catch (error) {
      dispatch(setError(error));
    }
    dispatch(slice.actions.stopLoading());
  };
}
