import { DateTime } from 'luxon';

export const defaultOpeningHours = [
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Monday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Tuesday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Wednesday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Thursday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Friday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Saturday',
  },
  {
    from: '00:00:00',
    to: '23:59:59',
    dayOfWeek: 'Sunday',
  },
].map((x) => ({
  ...x,
  from: x.from ? DateTime.fromISO(`2000-01-01T${x.from}`) : null,
  to: x.to ? DateTime.fromISO(`2000-01-01T${x.to}`) : null,
}));
