import { Helmet } from 'react-helmet-async';
// sections
import { FeedbackDetailsView } from 'src/sections/feedback/view';

// ----------------------------------------------------------------------

export default function FeedbackDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Analytics</title>
      </Helmet>

      <FeedbackDetailsView />
    </>
  );
}
