// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stack, { StackProps } from '@mui/material/Stack';
// components
import Iconify from 'src/components/iconify';
// types
import {
  IMembershipTypeTableFilters,
  IMembershipTypeTableFilterValue,
} from '../../../types/membership-type';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

type Props = StackProps & {
  filters: IMembershipTypeTableFilters;
  onFilters: (name: string, value: IMembershipTypeTableFilterValue) => void;
  //
  onResetFilters: VoidFunction;
  //
  results: number;
};

export default function CampaignTableFiltersResult({
  filters,
  onFilters,
  //
  onResetFilters,
  //
  results,
  ...other
}: Props) {
  const { t } = useLocales();

  const handleRemovePublic = (inputValue: string) => {
    const newValue = filters.public.filter((item) => item !== inputValue);
    onFilters('public', newValue);
  };

  const handleRemoveStatus = (inputValue: string) => {
    const newValue = filters.status.filter((item) => item !== inputValue);
    onFilters('status', newValue);
  };

  return (
    <Stack spacing={1.5} {...other}>
      <Box sx={{ typography: 'body2' }}>
        <strong>{results}</strong>
        <Box component="span" sx={{ color: 'text.secondary', ml: 0.25 }}>
          results found
        </Box>
      </Box>

      <Stack flexGrow={1} spacing={1} direction="row" flexWrap="wrap" alignItems="center">
        {!!filters.public.length && (
          <Block label={t('Publicly available:')}>
            {filters.public.map((item) => (
              <Chip
                key={item}
                label={t(item)}
                size="small"
                onDelete={() => handleRemovePublic(item)}
              />
            ))}
          </Block>
        )}

        {!!filters.status.length && (
          <Block label="Status:">
            {filters.status.map((item) => (
              <Chip
                key={item}
                label={t(item)}
                size="small"
                onDelete={() => handleRemoveStatus(item)}
              />
            ))}
          </Block>
        )}

        <Button
          color="error"
          onClick={onResetFilters}
          startIcon={<Iconify icon="solar:trash-bin-trash-bold" />}
        >
          Clear
        </Button>
      </Stack>
    </Stack>
  );
}

// ----------------------------------------------------------------------

type BlockProps = StackProps & {
  label: string;
};

function Block({ label, children, sx, ...other }: BlockProps) {
  return (
    <Stack
      component={Paper}
      variant="outlined"
      spacing={1}
      direction="row"
      sx={{
        p: 1,
        borderRadius: 1,
        overflow: 'hidden',
        borderStyle: 'dashed',
        ...sx,
      }}
      {...other}
    >
      <Box component="span" sx={{ typography: 'subtitle2' }}>
        {label}
      </Box>

      <Stack spacing={1} direction="row" flexWrap="wrap">
        {children}
      </Stack>
    </Stack>
  );
}
