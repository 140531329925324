import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import ProgramCreateView from '../../../sections/program/view/program-create-view';

// ----------------------------------------------------------------------

export default function ProgramNewPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Program Create')}</title>
      </Helmet>

      <ProgramCreateView />
    </>
  );
}
