import { useCallback } from 'react';
import { enqueueSnackbar } from 'notistack';
// @mui
import Box from '@mui/material/Box';
import Pagination, { paginationClasses } from '@mui/material/Pagination';
// types
//
import { ArticleItemSkeleton } from './article-skeleton';
import NewsletterItemHorizontal from './newsletter-item-horizontal';
import { NewsletterDto, NewslettersService } from '../../api';
import { paths } from '../../routes/paths';
import { useRouter } from '../../routes/hook';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  letters: NewsletterDto[];
  loading?: boolean;
  totalEvents: number;
  pageSize: number;
  onDelete: VoidFunction;
  handlePageChange: (value: number) => void;
};

export default function NewsletterListHorizontal({
  letters,
  loading,
  onDelete,
  handlePageChange,
  pageSize,
  totalEvents,
}: Props) {
  const router = useRouter();

  const { t } = useLocales();

  const renderSkeleton = (
    <>
      {[...Array(16)].map((_, index) => (
        <ArticleItemSkeleton key={index} variant="horizontal" />
      ))}
    </>
  );

  const handleDelete = useCallback(
    async (id: number) => {
      await NewslettersService.remove({
        id,
      });

      enqueueSnackbar(t('Deleted!'));
      onDelete();
      router.push(paths.newsletter.root);
      console.info('DELETE', id);
    },
    [t, router, onDelete]
  );

  const renderList = (
    <>
      {letters.map((post) => (
        <NewsletterItemHorizontal
          key={post.id}
          article={post}
          onDelete={() => handleDelete(post.id!)}
        />
      ))}
    </>
  );

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    handlePageChange(value);
  };

  return (
    <>
      <Box
        gap={3}
        display="grid"
        gridTemplateColumns={{
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
        }}
      >
        {loading ? renderSkeleton : renderList}
      </Box>

      {totalEvents > pageSize && (
        <Pagination
          count={Math.ceil(totalEvents / pageSize)}
          onChange={handleChange}
          sx={{
            mt: 8,
            [`& .${paginationClasses.ul}`]: {
              justifyContent: 'center',
            },
          }}
        />
      )}
    </>
  );
}
