// @mui
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import Grid from '@mui/material/Unstable_Grid2';
// types
import { IUserProfileFollower } from 'src/types/user';
// components
import Iconify from 'src/components/iconify';
import { EmployeeDto } from '../../api';
import ClazzDetails from './clazz-details';
import { useLocales } from '../../locales';

// ----------------------------------------------------------------------

type Props = {
  employee: EmployeeDto;
};

export default function ProfileClazz({ employee }: Props) {
  const { t } = useLocales();

  return (
    <>
      <Grid container spacing={5} disableEqualOverflow>
        <Grid xs={12}>
          <ClazzDetails title={t('Classes')} member={employee} />
        </Grid>
      </Grid>
    </>
  );
}
