import { useCallback, useEffect, useState } from 'react';
import parse from 'html-react-parser';
// @mui
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import AvatarGroup, { avatarGroupClasses } from '@mui/material/AvatarGroup';
// routes
import { paths } from 'src/routes/paths';
import { useParams } from 'src/routes/hook';
import { RouterLink } from 'src/routes/components';
// _mock
import { POST_PUBLISH_OPTIONS } from 'src/_mock';
// api
// components
import Iconify from 'src/components/iconify';
import Markdown from 'src/components/markdown';
import EmptyContent from 'src/components/empty-content';
//
import ArticleDetailsHero from '../article-details-hero';
import { PostDetailsSkeleton } from '../article-skeleton';
import ArticleDetailsToolbar from '../article-details-toolbar';
import { useDispatch, useSelector } from '../../../redux/store';
import { getSingleNews } from '../../../redux/slices/news';
import { useLocales } from '../../../locales';

// ----------------------------------------------------------------------

export default function ArticleDetailsView() {
  const params = useParams();

  const { t } = useLocales();

  const dispatch = useDispatch();

  const { id } = params;

  const [publish, setPublish] = useState('');

  const article = useSelector((state) => state.news.currentArticle);

  const isLoading = useSelector((state) => state.news.isLoading);

  const errorMessage = useSelector((state) => state.news.error);

  useEffect(() => {
    dispatch(getSingleNews({ id: parseInt(id!, 10) }));
  }, [dispatch, id]);

  const handleChangePublish = useCallback((newValue: string) => {
    setPublish(newValue);
  }, []);

  useEffect(() => {
    if (article) {
      // setPublish(article?.publish);
    }
  }, [article]);

  const renderSkeleton = <PostDetailsSkeleton />;

  const renderError = (
    <EmptyContent
      filled
      title={`${errorMessage}`}
      action={
        <Button
          component={RouterLink}
          href={paths.dashboard.post.root}
          startIcon={<Iconify icon="eva:arrow-ios-back-fill" width={16} />}
          sx={{ mt: 3 }}
        >
          {t('Back to List')}
        </Button>
      }
      sx={{
        py: 20,
      }}
    />
  );

  const renderPost = article && (
    <>
      <ArticleDetailsToolbar
        backLink={paths.news.root}
        editLink={paths.news.edit(article.id!)}
        liveLink={paths.news.view(article.id!)}
        publish={publish || ''}
        onChangePublish={handleChangePublish}
        publishOptions={POST_PUBLISH_OPTIONS}
      />

      <ArticleDetailsHero title={article.title ?? ''} coverUrl={article.imageUrl ?? ''} />

      <Stack
        sx={{
          maxWidth: 720,
          mx: 'auto',
          mt: { xs: 5, md: 10 },
        }}
      >
        <Typography variant="subtitle1" sx={{ mb: 5 }}>
          {article.teaser}
        </Typography>

        {article.json ? parse(article.body ?? '') : <Markdown children={article.body ?? ''} />}
      </Stack>
    </>
  );

  return (
    <Container maxWidth={false}>
      {isLoading && renderSkeleton}

      {errorMessage && renderError}

      {article && renderPost}
    </Container>
  );
}
