import { Helmet } from 'react-helmet-async';
// sections
import { useLocales } from '../../../locales';
import ContractEditView from '../../../sections/contracts/view/contract-edit-view';

// ----------------------------------------------------------------------

export default function ContractEditPage() {
  const { t } = useLocales();

  return (
    <>
      <Helmet>
        <title> {t('Dashboard: Contract Edit')}</title>
      </Helmet>

      <ContractEditView />
    </>
  );
}
