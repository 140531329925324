import { Helmet } from 'react-helmet-async';
// sections
import { EmailTemplateDetailsView } from 'src/sections/email-template/view';

// ----------------------------------------------------------------------

export default function EmailTemplateDetailsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Analytics</title>
      </Helmet>

      <EmailTemplateDetailsView />
    </>
  );
}
