import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
// utils
import { localStorageGetItem } from 'src/utils/storage-available';
//
import { defaultLang } from './config-lang';
//
import translationEn from './langs/en.json';
import translationDa from './langs/da.json';
import translationDe from './langs/de.json';
import translationNb from './langs/nb.json';
import translationSv from './langs/sv.json';
import translationEs from './langs/es.json';
import translationFi from './langs/fi.json';

// ----------------------------------------------------------------------

const lng = localStorageGetItem('i18nextLng', defaultLang.value);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: translationEn },
      da: { translations: translationDa },
      de: { translations: translationDe },
      nb: { translations: translationNb },
      sv: { translations: translationSv },
      es: { translations: translationEs },
      fi: { translations: translationFi },
    },
    lng,
    fallbackLng: lng,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
